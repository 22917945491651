import React, { Component } from 'react';
import {Message} from "../global/models";
import {Link} from "react-router-dom";
import {EditVendingMachineModal} from "./edit_vending_machine_modal";
import {QrCodeModal} from "./qr_code_modal";
import {V1VendingMachineInfo} from "../gen/typescript-axios";
import {getAdminVendingMachineServiceAPI} from "../global/api";
import BusyIndicator, {LoadingSpanner} from "../busy_indicator";
import { sortTagsByName } from '../custom_select_dropdown/custom_select_dropdown';

interface Props {
    reloadAllVendingMachines():void
    vendingMachine:V1VendingMachineInfo
    pushFlashMessage(message: Message): void;
}

interface State {
    isEditModalOpen:boolean
    isQrCodeModalOpen:boolean
    isRefilling:boolean
}

class VendingMachineRow extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditModalOpen: false,
            isQrCodeModalOpen: false,
            isRefilling: false
        };
    }

    handleCloseModal(){
        this.setState({isEditModalOpen:false})
    }

    handleOpenModal(){
        this.setState({isEditModalOpen:true})
    }

    handleCloseQrCodeModal(){
        this.setState({isQrCodeModalOpen:false})
    }

    handleOpenQrCodeModal(){
        this.setState({isQrCodeModalOpen:true})
    }

    handleRefill(e:any){
        e.preventDefault();
        e.stopPropagation();
        if (!this.state.isRefilling){
            if (window.confirm(`Are you sure to refill vending machine ${this.props.vendingMachine.name}`)){
                this.setState({
                    isRefilling: true
                })
                getAdminVendingMachineServiceAPI().adminVendingMachineServiceRefillVendingMachine(this.props.vendingMachine.uuid ?? "", {}).then((response)=>{
                    if (response.data.success){
                        this.props.reloadAllVendingMachines();
                        this.props.pushFlashMessage(new Message("Refilled", "success"))
                    } else {
                        this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                    }
                }).catch(()=>{
                    this.props.pushFlashMessage(new Message("Server error", "error"))
                }).finally(()=>{
                    this.setState({
                        isRefilling: false
                    })
                })
            }
        }
    }

    render() {
        return <tr key={this.props.vendingMachine.uuid}>
            <EditVendingMachineModal pushFlashMessage={this.props.pushFlashMessage.bind(this)} isOpen={this.state.isEditModalOpen} closeEditVendingMachineModal={this.handleCloseModal.bind(this)} reloadAllVendingMachines={this.props.reloadAllVendingMachines.bind(this)} vendingMachine={this.props.vendingMachine}/>
            <QrCodeModal isOpen={this.state.isQrCodeModalOpen} closeQrCodeModal={this.handleCloseQrCodeModal.bind(this)} vendingMachine={this.props.vendingMachine}/>
            <td>{this.props.vendingMachine.uuid}</td>
            <td>{this.props.vendingMachine.name}</td>
            <td>{this.props.vendingMachine.site}</td>
            <td>{(this.props.vendingMachine.is_ready ?? false) ? "Enabled": "Disabled"}</td>
            <td>{this.props.vendingMachine.pin_code}</td>
            <td>{this.props.vendingMachine.timezone}</td>
            <td>{this.props.vendingMachine.photo_url != null && <img src={this.props.vendingMachine.photo_url} alt={"vending machine"} width={"80"}/>}</td>
            <td>
                {
                    this.props.vendingMachine.tags && this.props.vendingMachine.tags.length > 0
                        ? sortTagsByName(this.props.vendingMachine.tags).map(tag => tag.name).join(', ')
                        : ''
                }
            </td>
            <td>
                <div className={"row"}>
                    <div className={"col-lg-3"}>
                        <Link className={"btn btn-warning"} to={`/vending_machines/${this.props.vendingMachine.uuid}/product_maps`}>Product Map</Link>
                    </div>
                    <div className="col-lg-3">
                        <button className={"btn btn-warning"} onClick={this.handleOpenQrCodeModal.bind(this)}>QR Code</button>
                    </div>
                    <div className={"col-lg-3"}>
                        <button className={"btn btn-warning"} onClick={this.handleOpenModal.bind(this)}>Edit</button>
                    </div>
                    <div className={"col-lg-3"}>
                        <BusyIndicator busy={this.state.isRefilling} busyIndicator={<LoadingSpanner/>}>
                            <button className={"btn btn-warning"} onClick={this.handleRefill.bind(this)}>Refill</button>
                        </BusyIndicator>
                    </div>
                </div>
            </td>
        </tr>;
    }
}

export {VendingMachineRow}
