import React, { Component } from 'react';
import Modal from "react-modal";
import {getAdminCompanyInformationServiceAPI, getAdminKeyCardServiceAPI} from "../global/api";
import {Message} from "../global/models";
import {Highlighter, AsyncTypeahead} from "react-bootstrap-typeahead";
import {PpeEmployee} from "../gen";

interface Props {
    isOpen: boolean
    closeAllocateKeyCardModal():void
    keyCardNumber: string
    pushFlashMessage(message: Message): void;
}

interface State {
    newEmployeeUuid:string|null
    employeeOptions: PpeEmployee[]
    isEmployeeOptionsLoading: boolean
    newEmployee:PpeEmployee|null
    saving:boolean
}

class AllocateKeyCardModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newEmployeeUuid:null,
            employeeOptions:[],
            isEmployeeOptionsLoading:false,
            newEmployee:null,
            saving:false
        };
    }

    onEmployeeChange(employees: Array<PpeEmployee>) {
        if (employees.length > 0) {
            this.setState({newEmployee: employees[0]});
        } else {
            this.setState({newEmployeeUuid:null})
        }
    }

    onAllocate(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            saving:true
        });
        getAdminKeyCardServiceAPI().adminKeyCardServiceAllocatedKeyCardToEmployee({
            key_card_number:this.props.keyCardNumber,
            employee_uuid: this.state.newEmployee?.uuid,
        }).then((response)=>{
            if (response.data.success ?? false){
                this.props.closeAllocateKeyCardModal()
                this.props.pushFlashMessage(new Message("Key Card Allocated", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(()=>{
            this.props.pushFlashMessage(new Message("Server error", "error"))
        }).finally(()=>{
            this.setState({
                saving:false
            });
        })
    }

    render() {
        const customStyles = {
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)',
                width:'50%'
            }
        };
        Modal.setAppElement('#root');
        const renderMenuItemChildren = (option: PpeEmployee, props:any) => {
            return [
                <Highlighter key={"name"} search={props.text}>{option.full_name + "(" + (option.employee_number ?? 'N/A') + ")"}</Highlighter>,
            ];
        };

        const selected = this.state.newEmployee ? [this.state.newEmployee] : [];
        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.props.closeAllocateKeyCardModal}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Allocate Key Card</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please assign an employee to following key card</p>
                <form>
                    <div className="form-group">
                        <label>Key Card Number:*</label>
                        <input type="text" className="form-control" value={this.props.keyCardNumber} disabled/>
                    </div>
                    <div className="form-group">
                        <label>Employee</label>
                        <AsyncTypeahead
                            id={"typeahead"}
                            isLoading={this.state.isEmployeeOptionsLoading}
                            labelKey={(item)=>{
                                return item.full_name ?? ""
                            }}
                            onSearch={(query) => {
                                this.setState({isEmployeeOptionsLoading: true});
                                getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceSearchEmployees({
                                    term:query,
                                    per_page:10000,
                                    page:1
                                }).then((response)=>{
                                    if (response.data.success){
                                        const employees = response.data.data?.employees?.filter((employee)=>{
                                            return employee.is_enabled === true && employee.is_tmp_access === false;
                                        })
                                        this.setState({
                                            employeeOptions:employees ?? []
                                        })
                                    } else {
                                        window.alert(response.data.error_message ?? "")
                                    }
                                }).catch(reason => {
                                    window.alert("Server error")
                                }).finally(()=>{
                                    this.setState({
                                        isEmployeeOptionsLoading:false,
                                    })
                                })
                            }}
                            selected={selected}
                            onChange={this.onEmployeeChange.bind(this)}
                            options={this.state.employeeOptions}
                            renderMenuItemChildren={renderMenuItemChildren}
                        />
                    </div>
                    <button className="btn btn-primary" onClick={this.onAllocate.bind(this)} disabled={this.state.newEmployee === null}>Save</button>&nbsp;
                    <button className="btn iq-bg-danger"
                            onClick={this.props.closeAllocateKeyCardModal}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export {AllocateKeyCardModal}
