import React, {useEffect, useState} from 'react';
import {Message} from "../global/models";
import Modal from "react-modal";
import {getAdminTemporaryAccessServiceAPI} from "../global/api";
import 'react-phone-number-input/style.css'
import {PpeEmployee} from "../gen";


interface Props {
    isOpen: boolean

    url: string

    closeModal(): void

    timezone:string

    employee:PpeEmployee

    pushFlashMessage(message: Message): void;
}

function EmployeeTemporaryAccessUrlModal(props:Props) {

    const [email, setEmail] = useState<string>("");

    const customStyles = {
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            width:'50%'
        }
    };
    Modal.setAppElement('#root');

    const handleEmailChange = (e:any)=>{
        setEmail(e.target.value);
    }

    const sendUrlToUser = (e:any)=>{
        e.preventDefault();
        e.stopPropagation();

        getAdminTemporaryAccessServiceAPI().adminTemporaryAccessServiceSendTemporaryAccessUrl(props.employee.uuid??"", {
            email:email
        }).then(response => {
            if (response.data.success){
                props.pushFlashMessage(new Message(`Secure Access is sent to ${email}`, "success"))
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "Unknown error", "error"))
            }
        }).catch((reason)=>{
            console.log(reason);
            props.pushFlashMessage(new Message("Server error, please try again later.", "error"))
        }).finally(()=>{
            props.closeModal();
        })
    }


    return (
        <Modal isOpen={props.isOpen} style={customStyles} onRequestClose={props.closeModal}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Temporary Access URL</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <form>
                    <div className="form-group">
                        <label htmlFor="name">URL:</label>
                        <input type="text" className="form-control" value={props.url} disabled={true}/>
                    </div>
                </form>
            </div>
            <div className="iq-card-body">
                <h5>Send the access URL by email</h5>
                <form>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" className="form-control" value={email} onChange={handleEmailChange}/>
                    </div>
                    <button onClick={sendUrlToUser} className="btn btn-primary">Send</button>&nbsp;
                    <button type="button" className="btn iq-bg-danger"
                            onClick={props.closeModal}>Cancel
                    </button>
                </form>
            </div>
        </Modal>
    );
}

export default EmployeeTemporaryAccessUrlModal;
