import React, {Fragment,  Component } from 'react';
import {Message, VendingMachine} from "../global/models";
import {AllocateKeyCardModal} from "./key_card_allocation_modal";
import {DatetimeDisplay} from "../global/datetime_display";
import {PpeKeyCardTapEvent} from "../gen";
import {V1VendingMachineInfo} from "../gen/typescript-axios";
import { FromVotCardNumberToCardNumber } from '../employee_page/from_vot_card_number_to_card_number';
import {HidSettings} from "../hid";

interface Props{
    keyCardTapEvent:PpeKeyCardTapEvent
    reloadKeyCardTapEvent():void
    allVendingMachine:V1VendingMachineInfo[]
    findVendingMachine(uuid:string):VendingMachine | undefined
    pushFlashMessage(message: Message): void;
    timezone:string
    hidInfo?: HidSettings
}

interface State {
    isAllocateEmployeeOpen:boolean
}

class KeyCardTapEventRow extends Component<Props, State>{
    constructor(props:Props) {
        super(props);

        this.state = {
            isAllocateEmployeeOpen:false
        };
    }

    openEditModal(){
        this.setState({isAllocateEmployeeOpen:true})
    }

    closeEditModal(){
        this.setState({isAllocateEmployeeOpen:false})
        this.props.reloadKeyCardTapEvent()
    }

    render() {
        const vendingMachine = this.props.findVendingMachine(this.props.keyCardTapEvent.vending_machine_uuid ?? "");
        return <Fragment>
            <AllocateKeyCardModal pushFlashMessage={this.props.pushFlashMessage.bind(this)} isOpen={this.state.isAllocateEmployeeOpen}
                                  closeAllocateKeyCardModal={this.closeEditModal.bind(this)}
                                  keyCardNumber={this.props.keyCardTapEvent.key_card_number ?? ""}/>
            <tr>
                <td>
                    <DatetimeDisplay timezone={this.props.timezone} timestamp={this.props.keyCardTapEvent.epoch ?? 0}/>
                </td>
                <td> <FromVotCardNumberToCardNumber votCardNumber={this.props.keyCardTapEvent.key_card_number ?? ""} facilityCode={this.props.hidInfo?.facilityCode ?? ''} facilityCodeLength={this.props.hidInfo?.facilityCodeLength ?? 0}/></td>
                <td>{vendingMachine !== undefined ? vendingMachine.name : "Error"}</td>
                <td>{vendingMachine !== undefined ? vendingMachine.site : "Error"}</td>
                <td>{this.props.keyCardTapEvent.is_key_card_allocated ?
                    this.props.keyCardTapEvent.employee_name :
                    <button className={"btn iq-bg-warning btn-rounded btn-sm my-0 mx-0"} onClick={this.openEditModal.bind(this)}>
                        Allocate
                    </button>}
                </td>
            </tr>
        </Fragment>;
    }
}

export {KeyCardTapEventRow}
