import React, {Component} from 'react';
import ReactPaginate from "react-paginate";
import {getAdminCompanyInformationServiceAPI, getAdminTransactionServiceApi} from "../global/api";
import {TransactionForCsv} from "../global/models";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {Helmet} from "react-helmet";
import {DownloadTransactionsCsv} from "./download_transactions_csv";
import {PpeEmployee} from "../gen";
import TransactionRow from "./transaction_row";
import { Ppeemployerv1Transaction } from "../gen/typescript-axios";



interface Props extends IGlobalDataContext{
    match: any
}

interface State {
    transactions: Ppeemployerv1Transaction[]
    currentPage:number
    pageCount:number
    allTransactionsCSV:TransactionForCsv[]
    employee:PpeEmployee | null
}

class _EmployeeTransaction extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            transactions:[],
            currentPage:0,
            pageCount:0,
            allTransactionsCSV:[],
            employee:null
        };
    }

    componentDidMount() {
        this.reloadTransaction()
        this.getEmployee()
    }

    getEmployee(){
        getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceGetEmployeeDetails(this.props.match.params.uuid).then((response)=>{
            if (response.data.success){
                this.setState({employee: response.data.employee ?? null})
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(reason => {
            window.alert("Server error")
        })
    }

    reloadTransaction(){
        getAdminTransactionServiceApi().adminTransactionServiceGetTransactions({
            employee_uuid: this.props.match.params.uuid,
            page:this.state.currentPage+1,
            per:25
        }).then((response)=>{
            if (response.data.success){
                this.setState({
                    transactions: response.data.transactions ?? [],
                    pageCount:response.data.number_of_page ?? 0
                })
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(()=>{
            window.alert("Server error")
        }).finally(()=>{

        });
    }

    handlePageChange(selectedItem: { selected: number }): void {
        this.setState({currentPage: selectedItem.selected}, () => {
            this.reloadTransaction();
        });
    }


    render() {
        const rows = this.state.transactions.map((transaction)=>{
            return <TransactionRow currency_config={this.props.organization?.currency_config} transaction={transaction} timezone={this.props.timezone} reload={this.reloadTransaction.bind(this)}/>
        })

        return <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Transactions - " + this.props.company + " - " + this.props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">{this.state.employee !== null && this.state.employee.full_name}'s Transactions</h4>
                                </div>
                                <div>
                                    <DownloadTransactionsCsv currency_config={this.props.organization?.currency_config} postAttributes={{
                                        employee_uuid:this.props.match.params.uuid,
                                    }}/>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div>
                                    <table
                                        id="datatable"
                                        className="table table-striped table-bordered"
                                    >
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">UUID</th>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Key Card</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Vending Machine</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">Job ID</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    pageCount={this.state.pageCount}
                                    initialPage={this.state.currentPage}
                                    onPageChange={this.handlePageChange.bind(this)}
                                    containerClassName={"pagination mb-0"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item page-link"}
                                    nextClassName={"page-item page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export const EmployeeTransaction = withGlobalData(_EmployeeTransaction)
