import React, {useEffect, useState} from "react";
import {getAdminSsoServiceAPI, getEmployeeSsoServiceApi} from "../global/api";
import GoogleLogin from "react-google-login";
import {UserType} from "../global/models";
import {IAuthenticationContext, withAuthentication} from "../authentication";

interface Props extends IAuthenticationContext {
    role: UserType
}

function _googleLoginBox(props: Props) {
    const [enabled, setEnabled] = useState(false);

    const [scopes, setScopes] = useState("");
    const [clientID, setClientID] = useState<string | null>(null);
    const [error, setError] = useState<String | null>(null);

    useEffect(() => {
        function loadGoogleConfig() {
            if (props.role === UserType.ADMIN){
                getAdminSsoServiceAPI().adminSsoServiceGetGoogleSsoConfig().then((response)=>{
                    if (response.data.success){
                        setScopes(response.data.config?.scopes ?? "");
                        setClientID(response.data.config?.client_id ?? "");
                        setEnabled(response.data.config?.enabled ?? false);
                    }
                })
            }
            if (props.role === UserType.EMPLOYEE){
                getEmployeeSsoServiceApi().ssoServiceGetGoogleSsoConfig().then((response)=>{
                    if (response.data.success){
                        setScopes(response.data.scopes ?? "");
                        setClientID(response.data.client_id ?? "");
                        setEnabled(response.data.enabled ?? false);
                    }
                })
            }
        }

        setEnabled(false);
        loadGoogleConfig();
    }, [])


    function responseGoogle(result: any) {
        setError(null);

        if (result.error) {
            setError(result.details);
            return
        }
        if (props.role === UserType.ADMIN) {
            getAdminSsoServiceAPI().adminSsoServiceExchangeSecurityTokenWithGoogleToken({
                google_token: result.tokenId,
                google_access_token: result.accessToken,
            }).then((response)=>{
                console.log(response.data);
                if (response.data.success) {
                    props.login(response.data.security_token ?? "", response.data.email ?? "", props.role);
                } else {
                    setError(response.data.error_message ?? "");
                }
            }).catch((reason) => {
                console.log(reason);
                setError("Failed to communicate with server. Please retry later.");
            })
        }
        if (props.role === UserType.EMPLOYEE) {
            getEmployeeSsoServiceApi().ssoServiceExchangeSessionTokenWithGoogleAccessToken({
                token: result.tokenId,
                access_token: result.accessToken
            }).then((response)=>{
                console.log(response.data);
                if (response.data.success) {
                    props.login(response.data.session_token ?? "", "", props.role);
                } else {
                    setError(response.data.error_message ?? "");
                }
            })
        }
    }

    return (
        <div>
            {clientID && enabled &&
            <div>
                <GoogleLogin
                    clientId={clientID}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    scope={scopes}
                />

                {error &&
                <div className={"text text-danger"}>
                    {error}
                </div>
                }
            </div>
            }
        </div>
    );
}

export const GoogleLoginBox = withAuthentication(_googleLoginBox);
