import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom'
import {getAdminAuthServiceAPI} from "../global/api";
import {IAuthenticationContext, withAuthentication} from "../authentication";
import BusyIndicator, {LoadingSpanner} from "../busy_indicator";


interface Props extends IAuthenticationContext{
}

interface State {
    email: string,
    isLoading: boolean
}

class _ForgotPassword extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            email:"",
            isLoading: false
        };
    }

    handleEmailChange(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.setState({email:event.target.value})
    }

    handleSubmit(event:any){
        event.stopPropagation()
        event.preventDefault()
        if (this.state.isLoading){
            return;
        }
        this.setState({
            isLoading: true
        })
        getAdminAuthServiceAPI().adminAuthenticationServiceAdministratorForgotPassword({
            email:this.state.email
        }).then((response)=>{
            if (response.data.success){
                window.alert("Your reset password email is heading your way.")
                return <Redirect to="/"/>;
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(reason => {
            window.alert("Server error. try again later");
        }).finally(()=>{
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        if (this.props.authentication.authenticated) {
            return <Redirect to="/"/>;
        }
        return <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Reset Password</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <p>Please enter your email address</p>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input className="form-control" onChange={this.handleEmailChange.bind(this)}/>
                                    </div>
                                    <BusyIndicator busy={this.state.isLoading} busyIndicator={<LoadingSpanner/>}>
                                        <button onClick={this.handleSubmit.bind(this)} className={"btn btn-primary"}>Submit</button>
                                    </BusyIndicator>
                                    <Link to={"/login"}><button className="btn iq-bg-danger">Cancel</button></Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

const ForgotPassword = withAuthentication(_ForgotPassword);

export {ForgotPassword};
