import React, { Component } from 'react';
import { UploadProductMapCSVModal } from './upload_product_map_csv_modal';
import {Message} from "../../global/models";
import { hasSpecialCharacters } from '../../has_special_characters';

interface Props {
    reloadProductMap(): void
    pushFlashMessage(message: Message): void;
    vendingMachineUuid:string;
}

interface State {
  isShowModal: boolean;
  csvData: ParsedProductMapInfo[];
  ready: boolean;
  errorMessage: string;
}

class UploadProductMapCsv extends Component<Props, State> {
  private csvLink: React.RefObject<any>;
  private fileInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.fileInputRef = React.createRef();
    
    this.state = {
      csvData: [],
      ready: false,
      isShowModal: false,
      errorMessage: '',
    };

    this.csvLink = React.createRef();
  }

  openUploadProductMapCSVModal() {
    this.setState({isShowModal: true})
  }

  closeUploadProductMapModal() {
      this.setState({isShowModal: false})
  }

  clearCsvData() {
    this.setState({
        csvData: [],
        ready: false,
        errorMessage: '',
      });
  }

  openfile(){
    this.fileInputRef.current?.click();
  }

  handleFile = (file: File | null) => {
    this.clearCsvData();
    if (!file) {
      return;
    }

    if (file.type !== 'text/csv') {
      alert('Please select a CSV file.');
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const content = event.target?.result as string;
        const contentWithoutTrailingWhitespace = content.trim();
        const lines = contentWithoutTrailingWhitespace.split(/\r\n|\n/);

        
        const expectedHeaders = [
          'Product Name','Manufacturer','Manufacturer Code','Supplier','Supplier Code',
          'MDB Code','Current Stock','Cap', 'Restock','Average Daily Consumption',
          'Low Stock Alert','Trackable Item Name',
        ];
  
        const headers = lines[0].split(',');
  
        if (!this.areHeadersValid(headers, expectedHeaders)) {
          this.props.pushFlashMessage(new Message('Invalid CSV file format. Please check the headers.', 'error'));
          return;
        }

        const mdbCodesSet = new Set<number>();
  
        const csvData: ParsedProductMapInfo[] = lines
          .slice(1)
          .map((line) => {
            const columns = line.split(',');
  
            const sanitizedColumns = columns.map((col) => col.replace(/"/g, '').trim() || '');

            const mdbCode = Number(sanitizedColumns[5]) || 0;
            if (mdbCode === 9999){
              this.setState({errorMessage: 'MDB Code cannot be 9999. Please change it to a different value.'});
            }

            if (mdbCodesSet.has(mdbCode)) {
              this.setState({errorMessage: 'Duplicate MDB Code found in CSV. Each MDB Code must be unique.'});
            }

            mdbCodesSet.add(mdbCode);

            const cap = sanitizedColumns[7] !== '' ? Number(sanitizedColumns[7]) : -1;
            const restock = sanitizedColumns[8] !== '' ? Number(sanitizedColumns[8]) : -1;

            if (cap !== -1 && restock !== -1 && cap < restock) {
              console.log('cap>>>>',cap);
              console.log('restock>>>>',restock);
              this.setState({errorMessage: 'Restock value should not be greater than Cap value.'});
            }
  
            return {
              product_name: sanitizedColumns[0],
              mdb_code: Number(sanitizedColumns[5]) || 0,
              cap: sanitizedColumns[7],
              restock: sanitizedColumns[8],
              low_stock_alert: Number(sanitizedColumns[10]) || 0,
            };
          });

        for (let csv of csvData) {
          if (hasSpecialCharacters(csv.product_name)) {
              this.props.pushFlashMessage(new Message('Product name contains special characters. Please remove them and try again.','error'));
              return;
          }
        }

  
        this.setState({
          csvData,
          ready: true,
        });
  
        this.openUploadProductMapCSVModal();
      } catch (error) {
        this.props.pushFlashMessage(new Message('Error parsing CSV file, make sure it is valid, and try again.', 'error'));
      }
    };
  
    reader.readAsText(file);
  };

  areHeadersValid = (headers: string[], expectedHeaders: string[]): boolean => {
    const sanitizedExpectedHeaders = expectedHeaders.map(header => header.trim().replace(/"/g, ''));
  
    return headers.length === sanitizedExpectedHeaders.length &&
      headers.every((header, index) => header.trim().replace(/"/g, '') === sanitizedExpectedHeaders[index]);
  };
  
  prepareCsvHeader() {
    return [
      { label: 'Product Name', key: 'product_name' },
      { label: 'MDB Code', key: 'mdb_code' },
      { label: 'Cap', key: 'cap' },
      { label: 'Restock', key: 'restock' },
      { label: 'Low Stock Alert', key: 'low_stock_alert' },
    ];
  }

  prepareCsvData() {
    const csvData = this.state.csvData.map(productMap => ({
      product_name: productMap.product_name,
      mdb_code: productMap.mdb_code,
      cap: productMap.cap,
      restock: productMap.restock,
      low_stock_alert: productMap.low_stock_alert,
    }));
    return csvData;
  }

  handleUpload = () => {
    if (this.state.ready) {
      this.openUploadProductMapCSVModal();
    }else {
      this.props.pushFlashMessage(new Message('Please select a CSV file.', 'error'));
    }
  };

  render() {
    return (
      <div>
        <UploadProductMapCSVModal pushFlashMessage={this.props.pushFlashMessage}
                                  reloadProductMap={this.props.reloadProductMap}
                                  closeUploadProductMapCSVkModal={this.closeUploadProductMapModal.bind(this)}
                                  isOpen={this.state.isShowModal} 
                                  csvData={this.state.csvData} 
                                  errorMessage={this.state.errorMessage}
                                  vendingMachineUuid={this.props.vendingMachineUuid}/>
        <input ref={this.fileInputRef} 
                key={Date.now()} 
                type="file" 
                style={{display:'none'}} 
                onChange={(e) => this.handleFile(e.target.files && e.target.files[0])} />
        <button className="btn btn-primary btn-sm" 
                onClick={e => this.openfile()}>
          Batch Import Product Map(Upload CSV)
        </button>
      </div>
    );
  }
}

export { UploadProductMapCsv };


export interface ParsedProductMapInfo {
  product_name: string;
  mdb_code: number;
  cap: string;
  restock: string;
  low_stock_alert: number;
}
