import React, { Component } from 'react';

interface Props {
    name:string
    tooltip:string
    statistic:number
    icon:string
    iconBg:string
}

interface State {
}

class StatisticCard extends Component<Props, State>{
    constructor(props:Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body iq-box-relative">
                    <div
                        className={"iq-box-absolute icon iq-icon-box rounded-circle " + this.props.iconBg}
                    >
                            <span className="d-inline-block" data-toggle="tooltip"
                                  title={this.props.tooltip}>
                                <i className={this.props.icon}></i>
                            </span>
                    </div>
                    <p className="text-secondary">{this.props.name}</p>
                    <div
                        className="d-flex align-items-center justify-content-between"
                    >
                        <h4><b>{this.props.statistic}</b></h4>
                    </div>
                </div>
            </div>);
    }
}

export {StatisticCard}