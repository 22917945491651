import React from "react";
import votLogo from './VOT-logo.jpg';
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
// @ts-ignore
import FreshChat from 'react-freshchat'
import {IGlobalDataContext, withGlobalData} from "./global/global-data";

interface Props extends IGlobalDataContext{

}

function _HelpPage(props: Props) {
    const location = useLocation();
    const path = location.pathname;
    const query = location.search;
    let fullUrl = decodeURIComponent(path + query);

    return <div>
        <Helmet title={"PPE - Help - Vending on Track"}/>
        <div className="container p-0">
            <div className="row no-gutters height-self-center">
                <div className="col-sm-12 align-self-center rounded">
                    <div className="row m-0 justify-content-md-center">
                        <div className={"col-xs-12"}>
                            <Link to={"https://www.vendingontrack.com"}>
                                <img src={votLogo} width={"20%"}/>
                            </Link>

                            <h1>Contact Vending on Track for support</h1>

                            <table className={"table table-border"}>
                                <tr>
                                    <th>Support</th>
                                    <th>Contact</th>
                                </tr>

                                <tr>
                                    <td>Vending on Track HQ</td>
                                    <td>Suite 2.10, 56 Delhi Rd, Macquarie Park, NSW 2113 Australia</td>
                                </tr>

                                <tr>
                                    <td>Office Landline</td>
                                    <td><a href="tel://+61291606222">+61 2 9160 6222</a></td>
                                </tr>

                                <tr>
                                    <td>Support Landline</td>
                                    <td><a href="tel://+61290540608">+61 2 9054 0608</a></td>
                                </tr>

                                <tr>
                                    <td>Email Support</td>
                                    <td><a href="mailto:support@vendingontrack.com">support@vendingontrack.com</a></td>
                                </tr>

                                <tr>
                                    <td>Support Portal (JIRA service desk)</td>
                                    <td><a
                                        href="https://vendingontrack.atlassian.net/servicedesk/customer/portal/1">https://vendingontrack.atlassian.net/servicedesk/customer/portal/1</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Online Chat Support</td>
                                    <td>Blue chat box on the bottom right corner of this page</td>
                                </tr>

                                <tr>
                                    <td>PPE & Managed Dispensing Prioritised Email Support</td>
                                    <td><a
                                        href="mailto:support+ppe@vendingontrack.com">support+ppe@vendingontrack.com</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Connectivity Platform T3 support</td>
                                    <td><a
                                        href="mailto:support+connectivity@vendingontrack.com">support+connectivity@vendingontrack.com</a>
                                    </td>
                                </tr>
                            </table>

                            <footer>
                                <ul>
                                    <li><Link to={"/privacy"}>Privacy Policy</Link></li>
                                    <li><Link to={"/tos"}>ToS</Link></li>
                                    <li><a href="/manual">Manual for PPE Vending</a></li>
                                </ul>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FreshChat token={'31036c68-008d-47a2-bd78-f25319511c94'}
                   onInit={(widget:any) => {
                       widget.user.setProperties({
                           email: props.authentication.email,
                           full_url: fullUrl,
                           source: 'PPE'
                       });
                   }}
        />
    </div>
}

export const HelpPage = withGlobalData(_HelpPage);

