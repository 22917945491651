import React, { Component} from 'react';
import {CSVLink} from "react-csv";
import moment from "moment";
import {Ppeemployerv1ProductMapInfo} from "../../gen/typescript-axios";
import {Ppeemployerv1Transaction} from "../../gen/typescript-axios";


interface Props {
    allProductMaps:Ppeemployerv1ProductMapInfo[]
}

interface State {
    csvData: Ppeemployerv1Transaction[]
    ready:boolean
}

class DownloadProductMapCsv extends Component<Props, State> {
    private csvLink: React.RefObject<any>;
    constructor(props: Props) {
        super(props);

        this.state = {
            csvData: [],
            ready: false,
        };
        this.csvLink = React.createRef();
    }

    getData(){
        this.csvLink.current.link.click();
    }

    prepareCsvHeader(){
        return [
            {label: 'Product Name', key: 'product_name'},
            {label: 'Manufacturer', key: 'manufacturer'},
            {label: 'Manufacturer Code', key: 'manufacturer_code'},
            {label: 'Supplier', key: 'supplier'},
            {label: 'Supplier Code', key: 'supplier_code'},
            {label: 'MDB Code', key: 'mdb_code'},
            {label: 'Current Stock', key: 'current_stock'},
            {label: 'Cap', key: 'cap'},
            {label: 'Restock', key: 'restock'},
            {label: 'Average Daily Consumption', key: 'average_daily_consumption'},
            {label: 'Low Stock Alert', key: 'low_stock_alert'},
            {label: 'Trackable Item Name', key: 'trackable_item_name'},
        ]
    }

    prepareCsvData(){
        let csvData:{}[] = [];
        this.props.allProductMaps.forEach((productMap)=>{
            csvData.push({
                "product_name": productMap.product?.name,
                "manufacturer": productMap.product?.manufacturer,
                "manufacturer_code": productMap.product?.manufacturer_code,
                "supplier": productMap.product?.supplier,
                "supplier_code": productMap.product?.supplier_code,
                "mdb_code": productMap.mdb_code,
                "current_stock": productMap.current_stock,
                "cap": productMap.capacity,
                "restock": productMap.restock_count,
                "average_daily_consumption": productMap.day_average_consumption,
                "low_stock_alert": productMap.low_stock_alert,
                "trackable_item_name": productMap.trackable_item?.name,
            })
        })
        return csvData
    }

    render() {
        return <div>
            <button className="btn btn-primary btn-sm" onClick={this.getData.bind(this)}>Download as CSV</button>

            <CSVLink
                data={this.prepareCsvData()} headers={this.prepareCsvHeader()}
                filename={'product_map_'+moment().unix()+'_.csv'}
                className="hidden"
                ref={this.csvLink}
                target="_blank"
            />
        </div>
    }

}

export {DownloadProductMapCsv}
