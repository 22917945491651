import React from 'react';
import {Authentication, UserType} from "./global/models";

export interface IAuthenticationContext {
    authentication: Authentication;

    login(token: string, email: string, userType: UserType): void;

    logout(userType?: UserType| undefined): void;
}

const AuthenticationContext = React.createContext<IAuthenticationContext>(
    {
        authentication: {
            authenticated: null,
        },
        logout: () => {
        },
        login: (t, e, u): Promise<any> => {
            return new Promise<any>(() => {
            })
        },
    }
);

function withAuthentication(C: any) {
    return function AuthenticationComponent(props: any) {
        return (
            <AuthenticationContext.Consumer>
                {({authentication, login, logout}) =>
                    <C {...props} authentication={authentication} login={login} logout={logout}/>}
            </AuthenticationContext.Consumer>
        );
    }
}

export {AuthenticationContext, withAuthentication}
