import React, {Component, Fragment} from 'react';
import {Message} from "../global/models";
import {getAdminCompanyInformationServiceAPI, getAdminTemporaryAccessServiceAPI} from "../global/api";
import {EditEmployeeModal} from "./edit_employee_modal";
import {Link} from "react-router-dom";
import {DatetimeDisplay} from "../global/datetime_display";
import {ExtendAccessModal} from "./extend_access_modal";
import EmployeeTemporaryAccessUrlModal from "./employee_temporary_access_url_modal";
import {HidSettings} from "../hid";
import { FromVotCardNumberToCardNumber } from './from_vot_card_number_to_card_number';
import { sortTagsByName } from '../custom_select_dropdown/custom_select_dropdown';
import { V1Employee, V1EmployeeStatus } from "../gen/typescript-axios";

interface Props {
    employee: V1Employee
    timezone: string

    pushFlashMessage(message: Message): void;
    hidInfo: HidSettings

    reloadAllEmployees(): void
}

interface State {
    isEditEmployeeOpen:boolean
    isExtendAccessModalOpen:boolean
    isShowAccessUrlModalOpen:boolean
    tmpAccessUrl: string
}

class EmployeeRow extends Component<Props, State>{
    constructor(props:Props) {
        super(props);

        this.state = {
            isEditEmployeeOpen: false,
            isExtendAccessModalOpen: false,
            isShowAccessUrlModalOpen: false,
            tmpAccessUrl: ""
        };
    }

    handleDisableEmployee(event:any){
        event.preventDefault();
        event.stopPropagation();

        if(window.confirm(`Are you sure to block employee [${this.props.employee.full_name}]? After the change, the user won't able to perform any transactions.`)){
            getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceUpdateEmployeeStatus(this.props.employee.uuid ?? "", {
                status: V1EmployeeStatus.Disabled
            }).then(response => {
                if (response.data.success) {
                    this.props.pushFlashMessage(new Message("Account blocked", "success"))
                } else {
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch((reason)=>{
                this.props.pushFlashMessage(new Message("Server error, please try again later.", "error"))
            }).finally(()=>{
                this.props.reloadAllEmployees();
            })
        }
    }

    handleRevokeTempAccess(event:any){
        event.preventDefault();
        event.stopPropagation();
        if(window.confirm(`Are you sure to revoke the temporary access for ${this.props.employee.full_name}?`)){
            getAdminTemporaryAccessServiceAPI().adminTemporaryAccessServiceRevokeTemporaryAccess(this.props.employee.uuid ?? "", {}).then(response => {
                if (response.data.success) {
                    this.props.pushFlashMessage(new Message("Temporary access revoked", "success"))
                } else {
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch((reason)=>{
                this.props.pushFlashMessage(new Message("Server error, please try again later.", "error"))
            }).finally(()=>{
                this.props.reloadAllEmployees();
            })
        }
    }

    handleEnableEmployee(event:any){
        event.preventDefault();
        event.stopPropagation();

        if(window.confirm(`Are you sure to enable employee ${this.props.employee.full_name}?`)){
            getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceUpdateEmployeeStatus(this.props.employee.uuid ?? "", {
                status: V1EmployeeStatus.Enabled
            }).then(response => {
                if (response.data.success) {
                    this.props.pushFlashMessage(new Message("Account enabled", "success"))
                } else {
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch((reason)=>{
                this.props.pushFlashMessage(new Message("Server error, please try again later.", "error"))
            }).finally(()=>{
                this.props.reloadAllEmployees();
            })
        }
    }

    getAccessUrl(){
        if (this.props.employee.is_tmp_access){
            getAdminTemporaryAccessServiceAPI().adminTemporaryAccessServiceGetTemporaryAccessUrl(this.props.employee.uuid ?? "").then(response => {
                if (response.data.success) {
                    this.setState({
                        tmpAccessUrl: response.data.url ?? ""
                    })
                    this.setState({isShowAccessUrlModalOpen:true})
                } else {
                    window.alert(response.data.error_message ?? "")
                }
            }).catch(()=>{
                window.alert("Server error")
            })
        }
    };

    openEditModal(){
        this.setState({isEditEmployeeOpen:true})
    }

    closeEditModal(){
        this.setState({isEditEmployeeOpen:false})
    }

    openExtendModal(){
        this.setState({isExtendAccessModalOpen:true})
    }

    closeExtendModal(){
        this.setState({isExtendAccessModalOpen:false})
    }

    openUrlModal(){
        this.getAccessUrl()
    }

    closeUrlModal(){
        this.setState({isShowAccessUrlModalOpen:false})
    }

    render() {
        const keycards = this.props.employee.key_cards?.map((keycard, index)=>{
            return <Fragment key={index}>
                <FromVotCardNumberToCardNumber index={index} votCardNumber={keycard} facilityCode={this.props.hidInfo.facilityCode} facilityCodeLength={this.props.hidInfo.facilityCodeLength}/>
                <br/>
            </Fragment>
        })

        const secondaryEmail = this.props.employee.secondary_emails?.map((secondaryEmail, index)=>{
            return <Fragment key={secondaryEmail}>
                <br/>
                <span>
                    {secondaryEmail}
                </span>
            </Fragment>
        })

        return <Fragment>
            <EmployeeTemporaryAccessUrlModal pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                url={this.state.tmpAccessUrl} isOpen={this.state.isShowAccessUrlModalOpen}
                closeModal={this.closeUrlModal.bind(this)} timezone={this.props.timezone}
                employee={this.props.employee}/>
            <EditEmployeeModal hidSettings={this.props.hidInfo}
                pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                isOpen={this.state.isEditEmployeeOpen}
                closeEditEmployeeModal={this.closeEditModal.bind(this)}
                reloadAllEmployee={this.props.reloadAllEmployees.bind(this)}
                employee={this.props.employee}/>
            <ExtendAccessModal isOpen={this.state.isExtendAccessModalOpen}
                closeAddEmployeeModal={this.closeExtendModal.bind(this)}
                pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                refreshEmployeeOnAdd={this.props.reloadAllEmployees.bind(this)}
                timezone={this.props.timezone} employee={this.props.employee}/>
            <tr className={this.props.employee.is_enabled ? "" : "table-danger"}>
                <td><Link to={`/employees/${this.props.employee.uuid}/transactions`}>{this.props.employee.full_name}</Link></td>
                <td>{this.props.employee.is_tmp_access ? "Not Applicable" : <div>{this.props.employee.employee_number}<br />{this.props.employee.department && <div>Department: {this.props.employee.department}</div>}</div>}</td>
                <td>{this.props.employee.is_tmp_access ? "Not Applicable" : keycards}</td>
                <td><span>
                    <b>{this.props.employee.email}</b>
                </span>
                    {secondaryEmail}</td>
                <td>{this.props.employee.is_enabled ? "Enabled" : "Disabled"}
                    {this.props.employee.is_tmp_access ? <Fragment> <br /> <span className={"text-danger"}>Temporary Access until <br /> <DatetimeDisplay timestamp={this.props.employee.expiry_at ?? 0} timezone={this.props.timezone} /></span></Fragment> : ""}
                </td>
                <td>
                    {this.props.employee.is_tmp_access ? "Not Applicable" : this.props.employee.tags && this.props.employee.tags.length > 0
                        ? sortTagsByName(this.props.employee.tags).map(tag => tag.name).join(', ')
                        : ''}
                </td>
                {/*<td>{this.props.employee.is_authed_all_products ? "All Groups" : this.props.employee.authed_categories.length === 0 ? "No Group Authorised" : authedCategories}</td>*/}
                <td>
                    <div className="row">
                        {!this.props.employee.is_tmp_access && <Fragment>
                            <div className="col-lg-5">
                                <button onClick={this.openEditModal.bind(this)} className="btn btn-warning">Edit Employee</button>
                            </div>
                            <div className="col-lg-2">
                                &nbsp;
                            </div>
                            <div className="col-lg-5">
                                {this.props.employee.is_enabled &&  <button onClick={this.handleDisableEmployee.bind(this)} className="btn btn-danger">Disable</button>}
                                {!this.props.employee.is_enabled &&  <button onClick={this.handleEnableEmployee.bind(this)} className="btn btn-danger">Enable</button>}
                            </div>
                        </Fragment>}
                        {this.props.employee.is_tmp_access && <Fragment>
                            <div className="col-lg-4">
                                {this.props.employee.is_enabled && <button onClick={this.openExtendModal.bind(this)} className="btn btn-warning">Extend Access</button>}
                            </div>
                            <div className="col-lg-4">
                                {this.props.employee.is_enabled && <button onClick={this.openUrlModal.bind(this)} className="btn btn-warning">Show Access URL</button>}

                            </div>
                            <div className="col-lg-4">
                                {this.props.employee.is_enabled &&  <button onClick={this.handleRevokeTempAccess.bind(this)} className="btn btn-danger">Revoke</button>}
                            </div>
                        </Fragment>}
                    </div>
                </td>
            </tr>
        </Fragment>;
    }
}

export { EmployeeRow }
