import React, {useEffect, useState} from 'react';
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {useParams} from "react-router-dom";
import {getAdminJobServiceAPI} from "../global/api";
import {Message} from "../global/models";
import {Helmet} from "react-helmet";
// import {PpeJobDetail} from "../gen";
import {V1JobDetail} from "../gen/typescript-axios";
import {DatetimeDisplay} from "../global/datetime_display";
import TransactionTable from "../transaction_page/transaction_table";


interface Props extends IGlobalDataContext{

}

interface Params {
    uuid: string
}
function _JobDetailsPage(props:Props) {
    let {uuid} = useParams<Params>();
    const [jobDetail, setJobDetail] = useState<V1JobDetail | null>();
    const [loading, setLoading] = useState(false);

    const reloadJobDetails = ()=>{
        if (loading){
            return;
        }
        setLoading(true)
        getAdminJobServiceAPI().adminJobServiceGetJobDetail(uuid).then((response)=>{
            if (response.data.success ?? false){
                setJobDetail(response.data.job_detail)
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(()=>{
            props.pushFlashMessage(new Message("Server error", "error"))
        }).finally(()=>{
            setLoading(false)
        })
    }

    useEffect(()=>{
        reloadJobDetails()
    }, []);

    return (
        <div id="content-page" className="content-page">
            <Helmet>
                <title>Job Details</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h3 className="card-title">{jobDetail?.job?.job_code} Details</h3>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <table className={"table table-bordered"}>
                                    <tbody>
                                    <tr>
                                        <td>Code</td>
                                        <td>{jobDetail?.job?.job_code}</td>
                                    </tr>
                                    <tr>
                                        <td>Created at</td>
                                        <td><DatetimeDisplay timestamp={jobDetail?.job?.created_epoch} timezone={props.timezone}/></td>
                                    </tr>
                                    <tr>
                                        <td>Updated at</td>
                                        <td><DatetimeDisplay timestamp={jobDetail?.job?.updated_epoch} timezone={props.timezone}/></td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{jobDetail?.job?.job_status}</td>
                                    </tr>
                                    <tr>
                                        <td>Admin Notes</td>
                                        <td>{jobDetail?.job?.admin_note}</td>
                                    </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h3 className="card-title">Transactions</h3>

                                </div>
                            </div>
                            <div className="iq-card-body">
                                <TransactionTable currency_config={props.organization?.currency_config} transactions={jobDetail?.transactions??[]} timezone={props.timezone} reload={reloadJobDetails}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const JobDetailsPage = withGlobalData(_JobDetailsPage);
