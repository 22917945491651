export const hasSpecialCharacters = function hasSpecialCharacters(...strings: string[]) {
    const specialCharactersRegex = /[,"“”]/; 
    for (let str of strings) {
      if (specialCharactersRegex.test(str)) {
        return true;
      }
    }
    
    return false;
  }
  