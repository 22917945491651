import React, {Component, Fragment} from 'react';
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {EmployeeRow} from "./employee_row";
import {AddEmployeeModal} from "./add_employee_modal";
import {Helmet} from "react-helmet";
import {Employee, Message} from "../global/models";
import {getAdminCompanyInformationServiceAPI, getAdminOrganizationServiceAPI} from "../global/api";
import ReactPaginate from "react-paginate";
import {GrantTmpAccessModal} from "./grant_tmp_access_modal";
import {V1Employee,V1EmployeeStatus} from "../gen/typescript-axios";
import {HidSettings} from "../hid";


interface Props extends IGlobalDataContext{
    location:any
    match:any
    history:any
}

export enum Status {
    ALL='ALL',
    ENABLED= 'EMPLOYEE_STATUS_ENABLED',
    DISABLED= 'EMPLOYEE_STATUS_DISABLED',
}

interface State {
    isAddEmployeeModalOpen:boolean
    isGrantTmpAccessModelOpen:boolean
    statusFilter: V1EmployeeStatus
    employees:Employee[]
    v2Employees:V1Employee[]
    currentPage:number
    pageCount:number
    employeeSearchValue:string,
    hidInfo: HidSettings
}

class _EmployeePage extends Component<Props, State>{
    qs = require('qs');
    params = this.qs.parse(this.props.location.search.slice(1));
    constructor(props:Props) {
        super(props);

        this.state = {
            isAddEmployeeModalOpen: false,
            isGrantTmpAccessModelOpen: false,
            statusFilter: this.params['status'] !== "" ? this.params['status'] !== undefined ? this.params['status'] : Status.ENABLED : Status.ENABLED,
            employees: [],
            v2Employees: [],
            pageCount: 0,
            currentPage: this.params['page'] !== "" ? this.params['page'] !== undefined ? parseInt(this.params['page']) - 1 : 0 : 0,
            employeeSearchValue: "",
            hidInfo: {
                rawWiegandReading: props.hidInfo?.rawWiegandReading ?? false,
                facilityCode: "",
                facilityCodeLength: 0
            }
        };
    }

    isSearching():boolean{
        return this.state.employeeSearchValue !== "";
    }

    componentDidMount() {
        this.reload()
    }

    componentDidUpdate(prevProps:any, prevState:any, snapshot:any) {
        if (prevProps.location.search.slice(1) !== this.props.location.search.slice(1)) {
            this.getParamsFromUrl();
        }
    }

    getParamsFromUrl(){
        const params = this.qs.parse(this.props.location.search.slice(1));
        this.setState({
            statusFilter:params['status'] !== "" ? params['status'] !== undefined ? params['status'] : Status.ENABLED : Status.ENABLED,
            currentPage:params['page'] !== "" ? params['page'] !== undefined ? parseInt(params['page'])-1 : 0 : 0,
        }, ()=>{
            this.reload()
        })
    }

    reloadEmployees(){
        let status:V1EmployeeStatus | undefined;
        if (this.state.statusFilter === V1EmployeeStatus.Enabled){
            status = V1EmployeeStatus.Enabled
        }
        if (this.state.statusFilter === V1EmployeeStatus.Disabled){
            status = V1EmployeeStatus.Disabled
        }
        getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceGetAllEmployees(
            status,
            undefined,
            this.state.currentPage+1
        ).then((response)=>{
            console.log("all employee v2", response)
            if (response.data.success ?? false){
                if (this.state.employeeSearchValue !== ""){
                    return;
                }
                this.setState({
                    v2Employees: response.data.data?.employees ?? [],
                    pageCount: response.data.data?.to ?? 1,
                })
            } else {
                window.alert(response.data.error_message ?? "");
            }
        }).catch((reason)=>{
            window.alert("Server Error, Try Again later");
        })
    }

    handlePageChange(selectedItem: { selected: number }): void {
        this.setState({currentPage: selectedItem.selected}, () => {
            if (this.state.employeeSearchValue === ""){
                this.reload();
            } else {
                this.searchEmployee(this.state.currentPage)
            }
            this.handleParamsChange("page", (selectedItem.selected + 1).toString())
        });
    }

    closeAddEmployeeModal(){
        this.setState({isAddEmployeeModalOpen:false})
    }

    refreshEmployeeOnAdd(){
        this.setState({currentPage: 0}, () => {
            this.reload();
            this.handleParamsChange("page", "1")
        });
    }

    openAddEmployeeModal(){
        this.setState({isAddEmployeeModalOpen:true})
    }

    openGrantTmpAccessModal(){
        this.setState({isGrantTmpAccessModelOpen:true})
    }

    closeGrantTmpAccessModal(){
        this.setState({isGrantTmpAccessModelOpen:false})
    }

    onStatusFilterChange(e:any){
        this.setState({statusFilter:e.target.value, currentPage:0}, ()=>{
            this.reload()
            this.handleAllParamsChange({"status":e.target.value, "page":this.state.currentPage + 1})
        })
    }

    handleUpdate(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.props.pushFlashMessage(new Message("employee results updated", "success"));
    }

    handleParamsChange(currentParam: string, newValue:string){
        const qs = require('qs');
        let params = qs.parse(this.props.location.search.slice(1));
        params[currentParam] = newValue;
        const keys = Object.keys(params);
        let paramsStr = "?";
        keys.forEach((key)=>{
            paramsStr = paramsStr + key + "=" + params[key] + "&"
        })
        this.props.history.push(paramsStr);
    }

    handleAllParamsChange(allParams:any){
        const qs = require('qs');
        let urlParams = qs.parse(this.props.location.search.slice(1));
        const localKeys = Object.keys(allParams);
        localKeys.forEach((localKey)=>{
            // @ts-ignore
            urlParams[localKey] = allParams[localKey]
        })
        const urlKeys = Object.keys(urlParams);
        let urlParamsStr = "?";
        urlKeys.forEach((urlKey)=>{
            urlParamsStr = urlParamsStr + urlKey + "=" + urlParams[urlKey] + "&"
        })
        this.props.history.push(urlParamsStr);
    }

    reload() {

        getAdminOrganizationServiceAPI().adminOrganizationServiceGetOrganizationDetail().then((response) => {
            return response.data
        }).then((data) => {
            this.setState({
                hidInfo: {
                    rawWiegandReading: data.organization?.raw_wiegand_reading ?? false,
                    facilityCode: data?.organization?.default_wiegand_facility_code ?? "",
                    facilityCodeLength: data?.organization?.facility_code_length ?? 0
                }
            });
        });

        if (this.isSearching()) {
            this.searchEmployee(this.state.currentPage);
        } else {
            this.reloadEmployees();
        }
    }

    onSearchEmployeeValueChange(){
        if (this.isSearching()){
            this.searchEmployee(0);
        } else {
            this.reloadEmployees();
        }
    }

    updateSearchEmployeeInput(e:any){
        this.setState({employeeSearchValue:e.target.value}, ()=>{
            this.reload();
        })
    }

    searchEmployee(page:number){
        const currentTerm = this.state.employeeSearchValue
        getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceSearchEmployees({
            term:this.state.employeeSearchValue,
            page: page + 1
        }).then((response)=>{
            if (response.data.success ?? false){
                if (this.state.employeeSearchValue !== currentTerm){
                    return;
                }
                console.log("search employee v2", response)
                this.setState({
                    v2Employees: response.data.data?.employees ?? [],
                    pageCount: response.data.data?.to ?? 1,
                })
            } else {
                window.alert(response.data.error_message);
            }
        }).catch((reason)=>{
            window.alert("Server Error, Try Again later");
        })
    }

    render() {
        const rows = this.state.v2Employees.map((employee)=>{
            return <EmployeeRow hidInfo={this.state.hidInfo} pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                                key={employee.uuid} employee={employee} reloadAllEmployees={this.reload.bind(this)}
                                timezone={this.props.timezone}/>
        })
        return <Fragment>
            <Helmet>
                <title>{"Employees - " + this.props.company + " - " + this.props.globalTitle}</title>
            </Helmet>
            <GrantTmpAccessModal pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                              isOpen={this.state.isGrantTmpAccessModelOpen}
                              closeAddEmployeeModal={this.closeGrantTmpAccessModal.bind(this)}
                              refreshEmployeeOnAdd={this.refreshEmployeeOnAdd.bind(this)}
                                 timezone={this.props.timezone}/>
            <AddEmployeeModal pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                              isOpen={this.state.isAddEmployeeModalOpen}
                              closeAddEmployeeModal={this.closeAddEmployeeModal.bind(this)}
                              hidSettings={this.state.hidInfo}
                              refreshEmployeeOnAdd={this.refreshEmployeeOnAdd.bind(this)}/>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Employee Management</h4>
                                    </div>
                                    <div>
                                        <span className="btn btn-primary btn-sm mr-3" onClick={this.openGrantTmpAccessModal.bind(this)}>
                                              Grant Temporary Access
                                        </span>
                                        <span className="btn btn-primary btn-sm" onClick={this.openAddEmployeeModal.bind(this)}>
                                              Add Employee
                                        </span>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <form>
                                                <div className="form-row">
                                                    <div className="col-md-3 mb-3">
                                                        <label htmlFor="startDate">Employee Status: </label>
                                                        <select className="form-control" disabled={this.isSearching()} value={this.isSearching() ? Status.ALL : this.state.statusFilter} onChange={this.onStatusFilterChange.bind(this)}>
                                                            <option value={Status.ALL}>All</option>
                                                            <option value={Status.ENABLED}>Enabled</option>
                                                            <option value={Status.DISABLED}>Disabled</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3 mb-3">
                                                        <label htmlFor="startDate">Search By Name: </label>
                                                        <input className="form-control" type="text" onChange={this.updateSearchEmployeeInput.bind(this)} value={this.state.employeeSearchValue}/>
                                                    </div>
                                                    <div className="col-md-3 mb-3 form-group">
                                                        <br/>
                                                        <button style={{marginTop:"9px"}} className="btn btn-primary" onClick={this.handleUpdate.bind(this)}>
                                                            Update
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div>
                                        <table
                                            className="table table-bordered table-responsive-md table-striped text-center"
                                            id="user_table">
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Employee Info</th>
                                                <th>Key Card Number</th>
                                                <th>Email</th>
                                                <th>Status</th>
                                                {/*<th>Access Group</th>*/}
                                                <th>Tags</th>
                                                <th>Operation</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rows}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        pageRangeDisplayed={2}
                                        marginPagesDisplayed={2}
                                        pageCount={this.state.pageCount}
                                        initialPage={this.state.currentPage}
                                        onPageChange={this.handlePageChange.bind(this)}
                                        containerClassName={"pagination mb-0"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item page-link"}
                                        nextClassName={"page-item page-link"}
                                        activeClassName={"active"} forcePage={this.state.currentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>;
    }
}

export const EmployeePage = withGlobalData(_EmployeePage);

