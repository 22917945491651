import React, { Component } from 'react';
import {getAdminUserServiceAPI} from "../global/api";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {Message} from "../global/models";


interface Props extends IGlobalDataContext{
    isEnabled:boolean
    reload(): void;
}

interface State {
    qrImage:string | null
    secret:string | null
    oneTimeCode:string
    isLoading: boolean
    isSaving: boolean
}

class _TfaTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            qrImage:null,
            secret:null,
            oneTimeCode:"",
            isLoading: false,
            isSaving: false
        };
    }

    componentDidMount() {
        this.loadTfaSetupDetail()
    }

    loadTfaSetupDetail(){
        if (!this.props.isEnabled){
            this.setState({isLoading: true})
            getAdminUserServiceAPI().adminUserServiceGetTfaSetupData().then((response)=>{
                if (response.data.success){
                    this.setState({
                        qrImage: response.data.tfa_setup_data?.qr_code ?? null,
                        secret: response.data.tfa_setup_data?.secret ?? null
                    })
                } else {
                    window.alert(response.data.error_message ?? "")
                }
            }).catch(()=>{
                window.alert("Server error")
            }).finally(()=>{
                this.setState({isLoading: false})
            })
        }
    }

    setupTfa(event:any){
        event.preventDefault();
        event.stopPropagation();
        if (this.state.oneTimeCode !== ""){
            this.setState({isSaving: true})
            getAdminUserServiceAPI().adminUserServiceSetupTfa({
                secret: this.state.secret ?? "",
                one_time_code: this.state.oneTimeCode ?? ""
            }).then((response)=>{
                if (response.data.success){
                    this.props.reload();
                    this.props.pushFlashMessage(new Message("success", "success"))
                } else {
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch(()=>{
                this.props.pushFlashMessage(new Message("Server error", "error"))
            }).finally(()=>{
                this.setState({isSaving: false})
            });
        } else {
            this.props.pushFlashMessage(new Message("Please enter the random code that generated by Google Authenticator before continuing!", "error"))
        }
    }

    handleOneTimeCodeChange(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.setState({oneTimeCode:event.target.value})
    }

    render() {
        return <div className="tab-pane fade" id="tfa" role="tabpanel">
            <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                        <h4 className="card-title">Two Factor Authentication</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                    {this.props.isEnabled ?
                        <p>The TFA has been setup</p> :
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <div className="panel panel-default">
                                        <div className="panel-heading">Set up Google Authenticator
                                        </div>

                                        <div className="panel-body" style={{textAlign:"center"}}>
                                            <p>Set up your two factor authentication by scanning the
                                                barcode below. Alternatively, you can use the
                                                code {this.state.secret}</p>
                                            <div>
                                                <img src={this.state.qrImage !== null ? this.state.qrImage : ""} alt={"TFA QR CODE"}/>
                                            </div>
                                            <p>You must set up your Google Authenticator app before
                                                continuing. You will be unable to login
                                                otherwise</p>
                                            <div>
                                                <label>Please enter the random code that generates from Google Authenticator</label>
                                            </div>
                                            <div>
                                                <input type="text" placeholder={"Random Code"} value={this.state.oneTimeCode} onChange={this.handleOneTimeCodeChange.bind(this)}/>
                                            </div>
                                            <div className={"mt-4"}>
                                                <button className="btn-primary" onClick={this.setupTfa.bind(this)}>
                                                    Complete 2FA
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>;
    }
}

export const TfaTab = withGlobalData(_TfaTab);
