import React, { Component } from 'react';
import { StatisticCard } from "./statistic_card";
import { DispensingStatsChart } from "./dispansing_stats_chart";
import { LatestTransactions } from "./latest_transactions";
import { getAdminOrganizationServiceAPI, getAdminTransactionServiceApi } from "../global/api";
import { DailyStats, ProductStats } from "../global/models";
import { Helmet } from "react-helmet";
import { IGlobalDataContext, withGlobalData } from "../global/global-data";
import { PpeTransactionsCount, PpeTransactionsCountByProduct } from "../gen";
import { Ppeemployerv1Transaction } from "../gen/typescript-axios";
import moment from "moment";

interface Props extends IGlobalDataContext {
}

interface State {
    isLoading: boolean
    numberOfDay: number
    totalItemDispensedToday: number
    totalItemDispensedThisWeek: number
    activeEmployees: number
    totalEmployees: number
    dailyStats: DailyStats[]
    productStats: ProductStats[]
    transactionCount: PpeTransactionsCount[]
    transactionCountByProduct: PpeTransactionsCountByProduct[]
    recentTransaction: Ppeemployerv1Transaction[]
}

class _Home extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            numberOfDay: 28,
            totalItemDispensedToday: 0,
            totalItemDispensedThisWeek: 0,
            activeEmployees: 0,
            totalEmployees: 0,
            dailyStats: [],
            productStats: [],
            transactionCount: [],
            transactionCountByProduct: [],
            recentTransaction: []
        };
    }

    componentDidMount() {
        if (this.props.authentication.authenticated) {
            this.getBasicStatistic();
            this.getChartData();
            this.getLatestTransaction();
        }
    }

    getBasicStatistic() {
        getAdminOrganizationServiceAPI().adminOrganizationServiceGetOrganizationBasicStatistics().then((response) => {
            console.log("dashboard basic statistic", response)
            if (response.data.success) {
                this.setState({
                    totalItemDispensedToday: response.data.total_item_dispensed_today ?? 0,
                    totalItemDispensedThisWeek: response.data.total_item_dispensed_in_last_seven_days ?? 0,
                    totalEmployees: response.data.total_employees ?? 0,
                    activeEmployees: response.data.total_active_employees ?? 0
                })
            } else {
                window.alert(response.data.error_message ?? "");
            }
        }).catch(() => {
            window.alert("Server error");
        })
    }

    getChartData() {
        this.setState({ isLoading: true })

        Promise.all([
            getAdminTransactionServiceApi().adminTransactionServiceGetTransactionsCount({
                start_epoch: moment().subtract(this.state.numberOfDay, 'day').unix()
            }).then((response) => {
                if (response.data.success ?? false) {
                    this.setState({
                        transactionCount: response.data.transactions_count ?? []
                    })
                } else {
                    window.alert(response.data.error_message ?? "")
                }
            }).catch(() => {
                window.alert("Server error")
            }).finally(() => {

            }),
            getAdminTransactionServiceApi().adminTransactionServiceGetTransactionsCountByProduct({
                start_epoch: moment().subtract(this.state.numberOfDay, 'day').unix()
            }).then((response) => {
                if (response.data.success ?? false) {
                    this.setState({
                        transactionCountByProduct: response.data.transactions_count_by_product ?? []
                    })
                } else {
                    window.alert(response.data.error_message ?? "")
                }
            }).catch(() => {

            }).finally(() => {

            })
        ]).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    getLatestTransaction() {
        getAdminTransactionServiceApi().adminTransactionServiceGetTransactions({
            page: 1,
            per: 100
        }).then((response) => {
            console.log("latest transactions", response)
            if (response.data.success) {
                this.setState({ recentTransaction: response.data.transactions ?? [] })
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(() => {
            window.alert("Server Error")
        }).finally(() => {

        });
    }

    handleNumberOfDayChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ numberOfDay: e.target.value }, () => { this.getChartData() })
    }


    render() {
        return (
            <div id="content-page" className="content-page">
                <Helmet>
                    <title>{"Dashboard - " + this.props.company + " - " + this.props.globalTitle}</title>
                </Helmet>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-3">
                            <StatisticCard name={"Total Item Dispensed Today"}
                                tooltip={"How many PPEs have been dispensed today"}
                                statistic={this.state.totalItemDispensedToday}
                                icon={"ri-focus-2-line"}
                                iconBg={"iq-bg-primary"} />
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3">
                            <StatisticCard name={"Total Item Dispensed (Last 7 Days)"}
                                tooltip={"How many PPEs have been dispensed in last 7 days"}
                                statistic={this.state.totalItemDispensedThisWeek}
                                icon={"ri-pantone-line"}
                                iconBg={"iq-bg-danger"} />
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3">
                            <StatisticCard name={"Active Employees"}
                                tooltip={"How many employees who have claimed at least 1 product in the last 7 days"}
                                statistic={this.state.activeEmployees}
                                icon={"ri-pie-chart-2-line"}
                                iconBg={"iq-bg-warning"} />
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3">
                            <StatisticCard name={"Total Employees"}
                                tooltip={"How many employees does the organization have in total"}
                                statistic={this.state.totalEmployees}
                                icon={"ri-database-2-line"}
                                iconBg={"iq-bg-success"} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <DispensingStatsChart transactionCountByProduct={this.state.transactionCountByProduct}
                                transactionCountByDay={this.state.transactionCount}
                                handleNumberOfDayChange={this.handleNumberOfDayChange.bind(this)}
                                numberOfDay={this.state.numberOfDay}
                                isLoading={this.state.isLoading} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <LatestTransactions recentTransactions={this.state.recentTransaction} reload={this.getLatestTransaction.bind(this)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export const Home = withGlobalData(_Home)
