import React from 'react';
// import {PpeTransaction} from "../gen";
import TransactionRow from "./transaction_row";
import { Ppeemployerv1Transaction, V1CurrencyConfig } from "../gen/typescript-axios";
import { IGlobalDataContext } from '../global/global-data';

interface Props {
    transactions: Ppeemployerv1Transaction[]
    timezone: string
    reload(): void
    currency_config: V1CurrencyConfig | undefined,
}

function TransactionTable(props: Props) {
    const rows = props.transactions.map((transaction) => {
        return <TransactionRow transaction={transaction} timezone={props.timezone} key={transaction.uuid} reload={props.reload} currency_config={props.currency_config} />
    })
    return (
        <div>
            <table className="table mb-0">
                <thead className="thead-light">
                    <tr>
                        <th scope="col">UUID</th>
                        <th scope="col">Employee</th>
                        <th scope="col">Key Card Number</th>
                        <th scope="col">Time</th>
                        <th scope="col">Product</th>
                        <th scope="col">Vending Machine/Locker</th>
                        <th scope="col">Location</th>
                        <th scope="col">Job ID</th>
                        <th scope="col">Value</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    );
}

export default TransactionTable;
