import React, {ChangeEvent, useState} from 'react';
import {PpeLockerInformation} from "../gen";
import {Message} from "../global/models";
import {getAdminLockerServiceApi} from "../global/api";
import Modal from "react-modal";
import BusyIndicator, {LoadingSpanner} from "../busy_indicator";

interface Props {
    isOpen: boolean
    onCloseModal():void
    onRefresh():void
    locker: PpeLockerInformation | null
    pushFlashMessage(message: Message): void;
}

function EditLockerModal(props: Props) {
    const [name, setName] = useState<string>(props.locker?.name ?? "");
    const [pictureEncodedContent, setPictureEncodedContent] = useState<string>("");
    const [isRemovePhoto, setIsRemovePhoto] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const afterOpen = ()=>{
        setName(props.locker?.name ?? "");
    }

    const handleCloseModal = ()=>{
        props.onCloseModal();
        props.onRefresh();
        setName(props.locker?.name ?? "");
        setPictureEncodedContent("");
        setIsRemovePhoto(false);
    }

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>)=>{
        event.preventDefault();
        event.stopPropagation();
        setName(event.target.value);
    }

    const removePhoto = (event: React.MouseEvent<HTMLButtonElement>)=>{
        event.preventDefault();
        event.stopPropagation();
        setIsRemovePhoto(true);
    }

    const onSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        fileReader.onerror = (_e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (_e) => {
            const pictureContent: string = fileReader.result as string;
            setPictureEncodedContent(window.btoa(pictureContent))
        };
        fileReader.readAsBinaryString((event.target.files ?? [])[0]);
    }

    const updateLockerInfo = (event: React.MouseEvent<HTMLButtonElement>)=>{
        event.preventDefault();
        event.stopPropagation();
        if (isSaving){
            return;
        }
        setIsSaving(true);
        getAdminLockerServiceApi().adminLockerServiceUpdateLocker(props.locker?.uuid ?? "", {
            name: name,
            photo: pictureEncodedContent,
            remove_photo: isRemovePhoto
        }).then((response)=>{
            if (response.data.success){
                handleCloseModal();
                props.pushFlashMessage(new Message("Locker Updated", "success"))
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch((reason)=>{
            console.log(reason);
            props.pushFlashMessage(new Message("Server error", "error"))
        }).finally(()=>{
            setIsSaving(false)
        })
    }

    const customStyles = {
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            width:'50%'
        }
    };
    Modal.setAppElement('#root');
    return (
        <Modal isOpen={props.isOpen} style={customStyles} onRequestClose={handleCloseModal} onAfterOpen={afterOpen}>
            <div className="iq-card-body">
                <h4 className="card-title">Edit Lockers</h4>
                <form>
                    <div className="form-group">
                        <label htmlFor="name">locker Name:*</label>
                        <input type="text" onChange={handleNameChange} className="form-control" value={name} required/>
                    </div>
                    <div className="form-group">
                        <label>Vending Machine Picture (optional)</label>
                        {!isRemovePhoto && props.locker?.photo_url !== null && <div>
                            <img src={props.locker?.photo_url} alt={"Picture"} width={"80"}/>
                            <button type="button" className="btn iq-bg-danger"
                                    onClick={removePhoto}>Remove
                            </button>
                        </div>}
                        <input type="file" onChange={onSelectFile} className={"form-control"}/>
                    </div>
                    <BusyIndicator busy={isSaving} busyIndicator={<LoadingSpanner/>}>
                        <button onClick={updateLockerInfo} className="btn btn-primary">Save</button>
                    </BusyIndicator>
                    &nbsp;
                    <button type="button" className="btn iq-bg-danger"
                            onClick={handleCloseModal}>Cancel
                    </button>
                </form>
            </div>
        </Modal>
    );
}

export default EditLockerModal;
