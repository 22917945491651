import React, { Component } from 'react';
import moment from "moment-timezone";


interface Props {
    timestamp?:number
    timezone?:string
}

interface State {
}

class DatetimeDisplay extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        if (this.props.timestamp){
            if (this.props.timezone){
                return <span>{moment.unix(this.props.timestamp).tz(this.props.timezone).format("ddd DD MMM YYYY HH:mm:ss z")}</span>;
            } else {
                return <span>{moment.unix(this.props.timestamp).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("ddd DD MMM YYYY HH:mm:ss z")}</span>;
            }
        } else {
            return <span>-</span>
        }
    }
}

export {DatetimeDisplay}
