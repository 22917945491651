import React, { Component } from 'react';
import {Redirect} from 'react-router-dom'
import {getAdminAuthServiceAPI} from "../global/api";
import {IAuthenticationContext, withAuthentication} from "../authentication";


interface Props extends IAuthenticationContext{
    match: any
}

interface State {
    password:string
    verifyPassword:string
    isRedirect: boolean
}

class _ResetPassword extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            password:"",
            verifyPassword:"",
            isRedirect: false
        };
    }
    componentDidMount() {
        this.loadAccountInfo()
    }

    loadAccountInfo(){
    }

    handleResetPassword(event:any){
        event.preventDefault();
        event.stopPropagation();
        if (this.state.password === this.state.verifyPassword){
            getAdminAuthServiceAPI().adminAuthenticationServiceResetPassword(this.props.match.params.url, {
                new_password:this.state.password,
            }).then((response)=>{
                if (response.data.success){
                    window.alert("successfully reset password")
                    this.setState({
                        isRedirect: true
                    })
                } else {
                    window.alert(response.data.error_message)
                }
            }).catch(reason => {
                window.alert("Server error");
            })
        } else {
            window.alert("Password are not matching")
        }
    }

    handlePasswordChange(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.setState({password:event.target.value})
    }

    handleVerifyPasswordChange(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.setState({verifyPassword:event.target.value})
    }


    render() {
        if (this.state.isRedirect){
            return (<Redirect to="/login"/>);
        }
        return <div className={"container"}>
            <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                        <h4 className="card-title">Reset Password</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                    <p>Hi, Please reset your password using the form below.</p>
                    <form>
                        <div className="form-group">
                            <label>Password:</label>
                            <input type="password" className="form-control" onChange={this.handlePasswordChange.bind(this)}/>
                        </div>
                        <div className="form-group">
                            <label>Verify Password:</label>
                            <input type="password" className="form-control" onChange={this.handleVerifyPasswordChange.bind(this)}/>
                        </div>
                        <button onClick={this.handleResetPassword.bind(this)} className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>;
    }
}

const ResetPassword = withAuthentication(_ResetPassword);

export {ResetPassword};
