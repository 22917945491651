import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {getAdminLockerServiceApi} from "../global/api";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {Message} from "../global/models";
import {PpeLockerInformation} from "../gen";
import DataTable, {TableColumn} from "react-data-table-component";
import {Link} from "react-router-dom";

interface Props extends IGlobalDataContext {

}


const columns: TableColumn<PpeLockerInformation>[] = [
    {
        name: "Name",
        selector: (item: PpeLockerInformation) => {
            return item.name || "-"
        },
        cell: (item) => {
            return <Link to={`/lockers/${item.uuid}`}>{item.name}</Link>
        },
        sortable: true,
    },
    {
        name: "Cols",
        selector: (item: PpeLockerInformation) => {
            return item.cols!
        },
        sortable: true,
    },
    {
        name: "Rows",
        selector: (item: PpeLockerInformation) => {
            return item.rows!
        },
        sortable: true,
    },
    {
        name: "Actions",
        cell: (item) => {
            return <div>
                <a href={`/lockers/${item.uuid}`}>Information</a>
            </div>
        }
    },
    {
        name: "Status",
        selector: (item: PpeLockerInformation) => {
            return item.is_online ?? false
        },
        cell: (item) => {
            return <span>{item.is_online ? "Online" : "Offline"}</span>
        },
        sortable: true,
    }
]

function _lockersPage(props: Props) {
    const [loading, setLoading] = useState(false);
    const [lockers, setLockers] = useState<Array<PpeLockerInformation>>([]);

    function loadLockers() {
        setLoading(true);

        getAdminLockerServiceApi().adminLockerServiceListLockers().then(resp => resp.data).then((resp) => {
            if (!resp.success) {
                props.pushFlashMessage(new Message(resp.error_message!, "error"));
            } else {
                setLockers(resp.lockers!);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        loadLockers();
    }, [])

    return (<div id="content-page" className="content-page">
        <Helmet>
            <title>Lockers</title>
        </Helmet>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h3 className="card-title">Lockers</h3>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <p>Please note, only standalone lockers are listed here. Vending machine lockers are treated
                                as part of individual vending machine.</p>
                        </div>
                    </div>

                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">All lockers</h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <DataTable title="" columns={columns} data={lockers}
                                       progressPending={loading}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export const LockersPage = withGlobalData(_lockersPage);
