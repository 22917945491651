import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import ReactPaginate from "react-paginate";
import {getEmployeeUserServiceApi} from "../global/api";
import {Source} from "../global/models";
import {DatetimeDisplay} from "../global/datetime_display";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {History, Location} from 'history';
import {PpeEmployeeSession, PpeEmployeeSessionSource} from "../gen-employee";


interface Props extends IGlobalDataContext{
    location: Location,
    history:History
}

function EmployeeSecuritySession(props:Props) {
    const qs = require('qs');
    const params = qs.parse(props.location.search.slice(1));
    const getInitPage = () => {
        let initPage = 0;
        if (params['page'] !== "" && params['page'] !== undefined){
            initPage = parseInt(params['page'])-1
        }
        return initPage;
    }

    const getInitSource = () => {
        let initSource = Source.ALL;
        if (params['source'] !== "" && params['source'] !== undefined){
            initSource = params['source']
        }
        return initSource;
    }

    const [sessions, setSessions] = useState<Array<PpeEmployeeSession>>([]);
    const [currentPage, setCurrentPage] = useState<number>(getInitPage());
    const [pageCount, setPageCount] = useState<number>(0);
    const [sourceFilter, setSourceFilter] = useState<Source>(getInitSource());


    const reloadSessions = () => {
        let source = undefined;
        if (sourceFilter === Source.APP){
            source = PpeEmployeeSessionSource.App
        }
        if (sourceFilter === Source.WEB){
            source = PpeEmployeeSessionSource.Web
        }

        getEmployeeUserServiceApi().userServiceGetUserSession({
            source: source,
            page: currentPage+1,
        }).then((response)=>{
            if (response.data.success){
                setSessions(response.data.data?.sessions ?? []);
                setPageCount(response.data.data?.to ?? 0);
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(()=>{
            window.alert("Server error, please try again later.")
        }).finally(()=>{

        })
    }

    useEffect(reloadSessions, [currentPage, sourceFilter])

    const handlePageChange = (selectedItem: { selected: number })=>{
        setCurrentPage(selectedItem.selected)
        handleParamsChange("page", (selectedItem.selected + 1).toString())
    }

    const handleLoginFilter = (event:any)=>{
        event.preventDefault();
        setSourceFilter(event.target.value as Source);
        handleParamsChange("source", event.target.value)
    }

    const handleParamsChange= (currentParam: string, newValue:string)=>{
        const qs = require('qs');
        let params = qs.parse(props.location.search.slice(1));
        params[currentParam] = newValue;
        const keys = Object.keys(params);
        let paramsStr = "?";
        keys.forEach((key)=>{
            paramsStr = paramsStr + key + "=" + params[key] + "&"
        })
        props.history.push(paramsStr);
    }

    const rows = sessions.map((session, index) => {
        return <tr key={index}>
            <td>
                <DatetimeDisplay timezone={props.timezone} timestamp={session.created_at_epoch ?? 0}/>
            </td>
            <td>
                {
                    session.last_access_at_epoch && <DatetimeDisplay timezone={props.timezone} timestamp={session.last_access_at_epoch ?? 0}/>
                }
                {
                    session.last_access_at_epoch === null && "-"
                }
            </td>
            <td>
                {session.ip}
            </td>
            <td>
                {session.user_agent}
            </td>
        </tr>
    })

    return (
        <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Transactions - " + props.company + " - " + props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">My Sessions</h4>
                                </div>
                                <div>
                                    <select className="form-control" onChange={handleLoginFilter} value={sourceFilter}>
                                        <option value="ALL">ALL</option>
                                        <option value="APP">APP</option>
                                        <option value="WEB">WEB</option>
                                    </select>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div>
                                    <table
                                        id="datatable"
                                        className="table table-striped table-bordered"
                                    >
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Login Time</th>
                                            <th scope="col">Last Access Time</th>
                                            <th scope="col">Last Access IP</th>
                                            <th scope="col">Platform</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    initialPage={currentPage}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination mb-0"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item page-link"}
                                    nextClassName={"page-item page-link"}
                                    activeClassName={"active"} forcePage={currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withGlobalData(EmployeeSecuritySession);
