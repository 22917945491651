import React, { useRef, useState } from 'react';
import { getAdminDedicatedLockerServiceAPI } from "../global/api";
import { CSVLink } from "react-csv";
import moment from "moment";
import { V1DedicatedLockerCellDoorOpenEvent } from "../gen/typescript-axios";
import { IGlobalDataContext, withGlobalData } from '../global/global-data';

interface Props extends IGlobalDataContext {
    lockerUuid: string | undefined,
    cellUuid: string | undefined,
    employeeUuid: string | undefined,
    adminEmail: string | undefined,
    perPage: number,
    page: number,
}

const _DownloadDedicatedLockersEventsCsv: React.FC<Props> = (props) => {
    const csvLink = useRef<any>(null);
    const [csvData, setCsvData] = useState<V1DedicatedLockerCellDoorOpenEvent[]>([]);
    const [ready, setReady] = useState<boolean>(false);

    const sleep = (milliseconds: number) => {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    const getData = () => {
        getAdminDedicatedLockerServiceAPI().adminDedicatedLockerServiceListDedicatedLockerCellDoorOpenEvents({
            locker_uuid: props.lockerUuid,
            cell_uuid: props.cellUuid,
            employee_uuid: props.employeeUuid,
            admin_email: props.adminEmail,
            per_page: props.perPage,
            page: props.page,
        }).then((response) => {
            if (response.data.success ?? false) {
                setCsvData(response.data.events ?? []);
                setReady(true);
                if (csvLink.current) csvLink.current.link.click();
            } else {
                window.alert(response.data.error_message ?? "");
            }
        }).catch(() => {
            window.alert("Server error");
        });
    }

    const prepareCsvHeader = () => {
        return [
            { label: 'Time', key: 'time' },
            { label: 'Key Card Number', key: 'key_card_number' },
            { label: 'Locker & Cell', key: 'locker_cell' },
            { label: 'Employee', key: 'employees' },
            { label: 'Admin', key: 'amdin' },
        ]
    }

    const formatTimestamp = (timestamp: number, timezone: string) => {
        if (timestamp) {
            if (timezone) {
                return moment.unix(timestamp).tz(timezone).format("ddd DD MMM YYYY HH:mm:ss z");
            } else {
                return moment.unix(timestamp).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("ddd DD MMM YYYY HH:mm:ss z");
            }
        } else {
            return "-";
        }
    }

    const prepareCsvData = () => {
        return csvData.map((transaction) => {
            let transactionExport: any = transaction
            transactionExport['time'] = formatTimestamp(transaction.open_request_epoch ?? 0, props.timezone)
            transactionExport['key_card_number'] = transaction.key_card_number ?? "-"
            transactionExport['locker_cell'] = `Locker: ${transaction.locker?.name} \nCell: ${transaction.cell?.display_name}[${transaction.cell?.row}, ${transaction.cell?.col}]`
            transactionExport['employees'] = `${transaction.employee !== null && transaction.employee !== undefined ? `${transaction.employee?.full_name} [${transaction.employee?.email}]` : "-"} \n ${transaction.reference !== null && transaction.reference !== undefined ? `Reference: ${transaction.reference}` : ''}`;
            transactionExport['amdin'] = `${transaction.admin !== null ? `${transaction.admin?.name} [${transaction.admin?.email}]` : "-"}`
            return transactionExport
        });
    }

    return (
        <div>
            <button className="btn btn-primary btn-sm" onClick={getData}>Download as CSV</button>

            <CSVLink
                data={prepareCsvData()} headers={prepareCsvHeader()}
                filename={'dedicated_locker_events_' + moment().unix() + '_.csv'}
                className="hidden"
                ref={csvLink}
                target="_blank"
            />
        </div>
    );
}

export const DownloadDedicatedLockersEventsCsv = withGlobalData(_DownloadDedicatedLockersEventsCsv);
