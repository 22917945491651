import React, { Component } from 'react';
import {getAdminUserServiceAPI} from "../global/api";
import {Message} from "../global/models";


interface Props {
    pushFlashMessage(message: Message): void;
}

interface State {
    currentPassword: string
    newPassword: string
    verifyPassword: string
    isSaving: boolean
}

class ChangePasswordTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentPassword:"",
            newPassword:"",
            verifyPassword:"",
            isSaving: false
        };
    }

    handleCurrentPasswordChange(event:any){
        this.setState({currentPassword:event.target.value})
    }

    handleNewPasswordChange(event:any){
        this.setState({newPassword:event.target.value})
    }

    handleVerifyPasswordChange(event:any){
        this.setState({verifyPassword:event.target.value})
    }

    handleSubmit(event:any){
        event.preventDefault();
        event.stopPropagation();
        if (this.state.currentPassword !== ""){
            if (this.state.newPassword !== ""){
                if (this.state.verifyPassword !== ""){
                    if (this.state.newPassword === this.state.verifyPassword){
                        this.setState({
                            isSaving: true
                        })
                        getAdminUserServiceAPI().adminUserServiceUpdatePassword({
                            current_password: this.state.currentPassword,
                            new_password: this.state.newPassword
                        }).then((response)=>{
                            if (response.data.success){
                                this.props.pushFlashMessage(new Message("Password changed", "success"))
                            } else {
                                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                            }
                        }).catch(()=>{
                            this.props.pushFlashMessage(new Message("Server Error", "error"))
                        }).finally(()=>{
                            this.setState({
                                currentPassword: "",
                                newPassword: "",
                                verifyPassword: "",
                                isSaving: false
                            })
                        })
                    } else {
                        this.props.pushFlashMessage(new Message("Password don't match", "error"))
                        //new password and verify password not match
                    }
                } else {
                    this.props.pushFlashMessage(new Message("you must verify your password", "error"))
                    //you must verify your password
                }
            } else {
                this.props.pushFlashMessage(new Message("you must provide a new password", "error"))
                // you must provide a new password
            }
        } else {
            this.props.pushFlashMessage(new Message("current password cannot be empty", "error"))
            // current password cannot be empty
        }
    }

    render() {
        return <div className="tab-pane fade" id="changPwd" role="tabpanel">
            <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                        <h4 className="card-title">Change Password</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                    <form>
                        <div className="form-group">
                            <label>Current Password:</label>
                            <input type="Password" className="form-control" required onChange={this.handleCurrentPasswordChange.bind(this)} value={this.state.currentPassword}/>
                        </div>
                        <div className="form-group">
                            <label>New Password:</label>
                            <input type="Password" className="form-control" required onChange={this.handleNewPasswordChange.bind(this)} value={this.state.newPassword}/>
                        </div>
                        <div className="form-group">
                            <label>Verify Password:</label>
                            <input type="Password" className="form-control" required onChange={this.handleVerifyPasswordChange.bind(this)} value={this.state.verifyPassword}/>
                        </div>
                        <button className="btn btn-primary mr-2" onClick={this.handleSubmit.bind(this)}>Submit</button>
                    </form>
                </div>
            </div>
        </div>;
    }
}

export {ChangePasswordTab}
