import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {matchPath, Redirect, withRouter} from "react-router-dom";
import {UserType} from "./global/models";

interface Props extends IAuthenticationContext {
    location:any
}

class _AuthenticationProtected extends Component<Props> {
    render() {
        if (this.props.authentication.userType !== UserType.ADMIN && this.props.authentication.userType !== UserType.EMPLOYEE){
            return this.props.children
        }

        if (this.props.authentication.authenticated === null) {
            return (<div/>);
        }


        if (!this.props.authentication.authenticated) {
            return (<Redirect to="/login"/>);
        }

        if (!(this.props.authentication.organization_selected ?? false)){
            if (this.props.authentication.userType === UserType.ADMIN){
                return (<Redirect to="/organization_selection"/>);
            }
        }

        const isPartnerRoute = matchPath("/partners", {path: this.props.location.pathname, exact: true})
        if (isPartnerRoute){
            return (<Redirect to="/"/>);
        }

        if (this.props.authentication.authenticated) {
            return this.props.children
        }
    }
}

const AuthenticationProtected = withRouter(withAuthentication(_AuthenticationProtected));
export {AuthenticationProtected};
