import React, {Fragment, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {getAdminLockerServiceApi} from "../global/api";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {Message} from "../global/models";
import {PpeCellInformation, PpeLockerInformation} from "../gen";
import DataTable, {TableColumn} from "react-data-table-component";
import {useParams} from "react-router-dom";
import {AssignProductToCellModal} from "./assign_product_to_cell_modal";
import AddTrackableItemToCellModal from "./add_trackable_item_to_cell_modal";
import {LockerQrCodeModal} from "./locker_qr_code_modal";
import EditLockerModal from "./edit_locker_modal";


interface Props extends IGlobalDataContext {

}

interface ProductCellProps extends IGlobalDataContext {
    locker: PpeLockerInformation
    cell: PpeCellInformation

    reload(): void
}

function ProductCell_(props: ProductCellProps) {
    return <div>
        {props.cell.product_name || "-"}
    </div>;
}

const ProductCell = withGlobalData(ProductCell_);

interface CellActionProps extends IGlobalDataContext {
    locker: PpeLockerInformation
    cell: PpeCellInformation

    reload(): void
}

function _CellAction(props:CellActionProps){
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [isTrackableItemModalOpen, setIsTrackableItemModalOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const toggleProductModal = () => {
        setIsProductModalOpen(!isProductModalOpen);
    }

    const toggleTrackableItemModal = () => {
        setIsTrackableItemModalOpen(!isTrackableItemModalOpen);
    }

    const clear = (e: any) => {
        e.preventDefault();
        console.log('clear');
        if (!isSaving){
            setIsSaving(true);
            getAdminLockerServiceApi().adminLockerServiceClearLockerCell({
                cell_uuid: props.cell.uuid,
                locker_uuid: props.locker.uuid
            }).then(resp => resp.data).then((resp) => {
                if (resp.success) {
                    props.reload()
                } else {
                    window.alert(resp.error_message);
                }
            }).finally(() => {
                setIsSaving(false);
            })
        }
    }

    function refill(e: any) {
        e.preventDefault();
        console.log('refill');
        if (!isSaving){
            setIsSaving(true);
            getAdminLockerServiceApi().adminLockerServiceRefillLockerCell({
                cell_uuid: props.cell.uuid,
                locker_uuid: props.locker.uuid
            }).then(resp => resp.data).then((resp) => {
                if (resp.success) {
                    props.reload()
                } else {
                    window.alert(resp.error_message);
                }
            }).catch((e) => {
                console.log(e);
                window.alert("Server error. Please retry.");

            }).finally(() => {
                setIsSaving(false);
            })
        }
    }

    const takeoutTrackableItem = (e: any)=>{
        e.preventDefault();
        if (!isSaving){
            setIsSaving(true);
            getAdminLockerServiceApi().adminLockerServiceTakeoutTrackableItemFromCell({
                locker_uuid:props.locker.uuid,
                cell_uuid:props.cell.uuid,
            }).then((response)=>{
                if (response.data.success) {
                    props.reload()
                } else {
                    window.alert(response.data.error_message);
                }
            }).catch(()=>{
                window.alert("Server error, please try again later");
            }).finally(()=>{
                setIsSaving(false);
            })
        }
    }

    const openCell = (e: any)=>{
        e.preventDefault();
        if (!isSaving){
            setIsSaving(true);
            getAdminLockerServiceApi().adminLockerServiceOpenLockerCellDoor({
                locker_uuid:props.locker.uuid,
                cell_uuid:props.cell.uuid,
            }).then((response)=>{
                if (response.data.success) {
                    window.alert("cell door opened")
                } else {
                    window.alert(response.data.error_message);
                }
            }).catch(()=>{
                window.alert("Server error, please try again later");
            }).finally(()=>{
                setIsSaving(false);
            })
        }
    }

    if (!props.cell.product_uuid){
        // assign a product(can be either trackable or consumable) to the cell
        return <Fragment>
            <AssignProductToCellModal
                isOpen={isProductModalOpen}
                locker={props.locker}
                cell={props.cell}
                reload={props.reload}
                toggleProductModal={toggleProductModal}/>
            <div className={"py-2"}>
                <div onClick={toggleProductModal} style={{cursor: "pointer"}}>
                    <i className="fa fa-product-hunt" aria-hidden="true"></i> <span>Assign Product</span>
                </div>
                <br/>
                <div onClick={openCell} style={{cursor: "pointer"}}>
                    <i className="fa fa-folder-open-o" aria-hidden="true"></i> <span>Open Cell Door</span>
                </div>
            </div>
        </Fragment>
    }
    if (props.cell.trackable && props.cell.occupied) {
        // takeout trackable item for inspection
        // replace the trackable item with another one
        return <Fragment>
            <div className={"py-2"}>
                <div onClick={takeoutTrackableItem} style={{cursor: "pointer"}}>
                    <i className="fa fa-hand-lizard-o" aria-hidden="true"></i> <span>Takeout Trackable Item</span>
                </div>
                <br/>
                <div onClick={openCell} style={{cursor: "pointer"}}>
                    <i className="fa fa-folder-open-o" aria-hidden="true"></i> <span>Open Cell Door</span>
                </div>
            </div>
        </Fragment>
    }
    if (!props.cell.trackable && props.cell.occupied){
        // clear the cell
        return <Fragment>
            <div className={"py-2"}>
                <div onClick={clear} style={{cursor: "pointer"}}>
                    <i className="fa fa-trash" aria-hidden="true"></i> <span>Clear Cell</span>
                </div>
                <br/>
                <div onClick={openCell} style={{cursor: "pointer"}}>
                    <i className="fa fa-folder-open-o" aria-hidden="true"></i> <span>Open Cell Door</span>
                </div>
            </div>
        </Fragment>
    }
    if (props.cell.trackable && !props.cell.occupied){
        //assign a trackable item to the cell
        return <Fragment>
            <AssignProductToCellModal
                isOpen={isProductModalOpen}
                locker={props.locker}
                cell={props.cell}
                reload={props.reload}
                toggleProductModal={toggleProductModal}/>
            <AddTrackableItemToCellModal
                cell={props.cell}
                toggleTrackableItemModal={toggleTrackableItemModal}
                isOpen={isTrackableItemModalOpen}
                locker={props.locker} reload={props.reload}/>
            <div className={"py-2"}>
                <div onClick={toggleProductModal} style={{cursor: "pointer"}}>
                    <i className="fa fa-product-hunt" aria-hidden="true"></i> <span>Update Product</span>
                </div>
                <br/>
                <div onClick={toggleTrackableItemModal} style={{cursor: "pointer"}}>
                    <i className="fa fa-tag" aria-hidden="true"></i> <span>Refill trackable item</span>
                </div>
                <br/>
                <div onClick={openCell} style={{cursor: "pointer"}}>
                    <i className="fa fa-folder-open-o" aria-hidden="true"></i> <span>Open Cell Door</span>
                </div>
            </div>
        </Fragment>
    }
    if (!props.cell.trackable && !props.cell.occupied){
        //load a consumable item
        return <Fragment>
            <AssignProductToCellModal
                isOpen={isProductModalOpen}
                locker={props.locker}
                cell={props.cell}
                reload={props.reload}
                toggleProductModal={toggleProductModal}/>
            <div className={"py-2"}>
                <div onClick={toggleProductModal} style={{cursor: "pointer"}}>
                    <i className="fa fa-product-hunt" aria-hidden="true"></i> <span>Update Product</span>
                </div>
                <br/>
                <div onClick={refill} style={{cursor: "pointer"}}>
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i> <span>Fill the cell</span>
                </div>
                <br/>
                <div onClick={openCell} style={{cursor: "pointer"}}>
                    <i className="fa fa-folder-open-o" aria-hidden="true"></i> <span>Open Cell Door</span>
                </div>
            </div>
        </Fragment>
    }
}

const CellAction = withGlobalData(_CellAction);



interface Params {
    uuid: string
}

interface OccupiedCellInterface {
    cell: PpeCellInformation
    locker: PpeLockerInformation

    reload(): void
}

function OccupiedCell(props: OccupiedCellInterface) {
    return <div>
        {(
            props.cell.occupied ? <div>
                    Occupied
                </div>
                :
                <div>
                    -
                </div>
        )}
    </div>
}

function _lockerInformationPage(props: Props) {
    let {uuid} = useParams<Params>();
    const [isQrCodeOpen, setIsQrCodeOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [locker, setLocker] = useState<PpeLockerInformation | null>(null);
    const [cells, setCells] = useState<Array<PpeCellInformation>>([]);

    const closeQrCodeModal = ()=>{
        setIsQrCodeOpen(false);
    }

    const openQrCodeModal = ()=>{
        setIsQrCodeOpen(true);
    }

    const closeEditModal = ()=>{
        setIsEditModalOpen(false);
    }

    const openEditModal = ()=>{
        setIsEditModalOpen(true);
    }


    const columns: TableColumn<PpeCellInformation>[] = [

        {
            name: "Row",
            selector: (item) => {
                return `${item.row! }, ${item.col!}`
            },
            cell: (item) => {
                return item.row!
            },
            sortable: true,
        },
        {
            name: "Col",
            selector: (item) => {
                return item.col!
            },
            sortable: true,
        },
        {
            name: "Display Name",
            selector: (item) => {
                return item.display_name!
            },
            sortable: true,
        },
        {
            name: "Occupied",

            sortable: true,
            cell: (item) => {
                return <OccupiedCell cell={item} locker={locker!} reload={reload}/>
            }
        },

        {
            name: "Product",
            selector: (item) => {
                return item.product_name || "-"
            },
            cell: (item) => {
                return <ProductCell cell={item} locker={locker} reload={reload}/>;
            },
            sortable: true
        },
        {
            name: "Trackable Item",
            selector: (item) => {
                return item.trackable_item_name || "-"
            },
            sortable: true
        },
        {
            name: "Actions",
            selector: (item)=>{
                return item.trackable_item_name || "-"
            },
            cell: (item) => {
                return <CellAction cell={item} locker={locker} reload={reload}/>
            },
            sortable:false,
            style:{display:"inline"}
        }
    ]

    function reload() {
        setLoading(true);

        Promise.all([
            getAdminLockerServiceApi().adminLockerServiceListLockers().then(resp => resp.data).then((resp) => {
                if (!resp.success) {
                    props.pushFlashMessage(new Message(resp.error_message!, "error"));
                } else {
                    let found = false;
                    resp.lockers!.forEach((v) => {
                        if (v.uuid === uuid) {
                            setLocker(v);
                            found = true;
                            return;
                        }
                    })

                    if (!found) {
                        throw new Error("Locker not found");
                    }
                }
            }),
            getAdminLockerServiceApi().adminLockerServiceGetLockerInformation(uuid).then(resp => resp.data).then((resp) => {
                if (resp.success) {
                    setCells(resp.cells!);
                } else {
                    props.pushFlashMessage(new Message(resp.error_message!, "error"));
                }
            }),
        ]).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        reload();
    }, [])

    return (<div id="content-page" className="content-page">
        <Helmet>
            <title>Locker</title>
        </Helmet>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h3 className="card-title">{locker?.name} Information</h3>

                            </div>
                            <div>
                                <button className="btn btn-primary btn-sm" onClick={openEditModal}>
                                    Edit
                                </button>
                                &nbsp; &nbsp;
                                <span className="btn btn-warning btn-sm" onClick={openQrCodeModal}>
                                    QR Code
                                </span>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <table className={"table table-bordered"}>
                                <tbody>
                                    <tr>
                                        <td>Rows</td>
                                        <td>{locker?.rows}</td>
                                    </tr>
                                    <tr>
                                        <td>Cols</td>
                                        <td>{locker?.cols}</td>
                                    </tr>
                                    <tr>
                                        <td>Cells</td>
                                        <td>{cells.length}</td>
                                    </tr>
                                    <tr>
                                        <td>Occupied Cells</td>
                                        <td>{cells.filter((cell) => cell.occupied).length}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{(locker?.is_online??false) ? "Online" : "Offline"}</td>
                                    </tr>
                                    <tr>
                                        <td>Photo</td>
                                        <td>{locker?.photo_url !== null && <img src={locker?.photo_url} alt={"Picture"} width={"300"}/>}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <EditLockerModal isOpen={isEditModalOpen} onCloseModal={closeEditModal} onRefresh={reload} locker={locker} pushFlashMessage={props.pushFlashMessage}/>
                    <LockerQrCodeModal isOpen={isQrCodeOpen} closeQrCodeModal={closeQrCodeModal} locker={locker}/>
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h3 className="card-title">Cells Information</h3>

                            </div>
                        </div>
                        <div className="iq-card-body">
                            <DataTable title="" columns={columns} data={cells}
                                       progressPending={loading} defaultSortFieldId={1} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);

}

export const LockerInformationPage = withGlobalData(_lockerInformationPage);
