import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from "../authentication";
import {Link} from "react-router-dom";
import {UserType} from "./models";
import {IGlobalDataContext, withGlobalData} from "./global-data";
import {DateTime} from "luxon";
import { Ppeemployerv1Role } from '../gen/typescript-axios';


interface Props extends IAuthenticationContext, IGlobalDataContext {
    name:string
    email:string
    logo:string
    flash:any
    userType:UserType
    roles:Ppeemployerv1Role[]
}

interface State {
}

class _Navbar extends Component<Props, State>{
    constructor(props:Props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const userTimeZoneOffset = DateTime.local().setZone(this.props.timezone).offset;
        const browserTimezoneOffset = DateTime.local().offset;
        return(
            <>
                <div className="iq-top-navbar">
                    <div className="iq-navbar-custom">
                        <nav className="navbar navbar-expand-lg navbar-light p-0">
                            <div className="iq-menu-bt d-flex align-items-center">
                                <div className="wrapper-menu">
                                    <div className="main-circle"><i className="ri-menu-line"></i></div>
                                    <div className="hover-circle"><i className="ri-close-fill"></i></div>
                                </div>
                                <div className="iq-navbar-logo d-flex justify-content-between ml-3">
                                    <a href="/home" className="header-logo">
                                        <img src={`data:image/jpeg;base64,${this.props.logo}`} className="img-fluid" alt="" id = "company_logo"/>
                                    </a>
                                </div>
                            </div>
                            {userTimeZoneOffset !== browserTimezoneOffset &&
                                <ul className="nav navbar-nav">
                                    <li>
                                        <Link to="/my_profile" style={{ color: 'red' }}>
                                            <i className="las la-clock"/>&nbsp;
                                            Your browser timezone differs from your profile timezone.
                                        </Link>
                                    </li>
                                </ul>
                            }
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                {this.props.flash && this.props.flash.cls === "error" &&
                                <div className="text-white bg-danger" role="alert">
                                    <div className="card-body"><span style={{fontWeight:"bold"}}>{this.props.flash.message}</span></div>
                                </div>
                                }
                                {this.props.flash && this.props.flash.cls === "success" &&
                                <div className="text-white bg-primary">
                                    <div className="card-body"><span style={{fontWeight:"bold"}}>{this.props.flash.message}</span></div>
                                </div>
                                }
                                <ul className="navbar-nav ml-auto navbar-list">
                                    <li className="nav-item nav-icon dropdown">
                                        <a />
                                    </li>
                                </ul>
                            </div>
                            <ul className="navbar-list">
                                <li className="line-height">
                                    <a href="#" className="search-toggle iq-waves-effect d-flex align-items-center">
                                            <div className="username">
                                                <h6 className="mb-0 line-height">{this.props.name}</h6>
                                                <p className="mb-0">{this.props.email}</p>
                                            </div>
                                    </a>
                                    <div className="iq-sub-dropdown iq-user-dropdown">
                                        <div className="iq-card shadow-none m-0">
                                            <div className="iq-card-body p-0 ">
                                                <div className="bg-primary p-3">
                                                    <h5 className="mb-0 text-white line-height">Hello {this.props.name}</h5>
                                                    <span className="text-white font-size-12">Online</span>
                                                </div>
                                                {this.props.userType === UserType.ADMIN && <Link to={"/my_profile"}>
                                                    <div className="iq-sub-card iq-bg-primary-hover">
                                                        <div className="media align-items-center">
                                                            <div className="rounded iq-card-icon iq-bg-primary">
                                                                <i className="ri-file-user-line"></i>
                                                            </div>
                                                            <div className="media-body ml-3">
                                                                <h6 className="mb-0 ">My Profile</h6>
                                                                <p className="mb-0 font-size-12">View personal profile
                                                                    details.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>}
                                                {(this.props.userType === UserType.ADMIN && 
                                                this.props.roles.includes(Ppeemployerv1Role.Admin)) && <Link to={"/company"}>
                                                    <div className="iq-sub-card iq-bg-primary-hover">
                                                        <div className="media align-items-center">
                                                            <div className="rounded iq-card-icon iq-bg-primary">
                                                                <i className="ri-profile-line"></i>
                                                            </div>
                                                            <div className="media-body ml-3">
                                                                <h6 className="mb-0 ">Organization Setting</h6>
                                                                <p className="mb-0 font-size-12">Manage Organization setting</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>}

                                                <div className="d-inline-block w-100 text-center p-3">
                                                    <Link to={"/organization_selection"} className="bg-primary iq-sign-btn">
                                                        Switch to another organization
                                                        <i className="ri-login-box-line ml-2"></i>
                                                    </Link>
                                                </div>

                                                <div className="d-inline-block w-100 text-center p-3">
                                                    <button className="bg-primary iq-sign-btn" onClick={()=>{this.props.logout(this.props.authentication.userType)}}>Sign
                                                        out<i className="ri-login-box-line ml-2"></i></button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </>
        )
    }
}


const Navbar = withGlobalData(withAuthentication(_Navbar));
export {Navbar}
