import React, {Component} from 'react';

interface Props {
}

interface State {
}

class Footer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <footer className="iq-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                        </div>
                        <div className="col-lg-6 text-right">
                            Copyright 2020 <a href="https://www.vendingontrack.com">Vending on Track Pty Ltd</a> All
                            Rights Reserved.
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export {Footer};
