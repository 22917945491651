import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import {PpeTransactionsCount} from "../gen";

interface Props {
    transactionCountByDay: PpeTransactionsCount[]
    isLoading: boolean
}

interface State {
}

class DispensingBarChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }
    render() {
        let data : (string | number)[][] = [['Date', 'Number of PPE Dispensed']]
        this.props.transactionCountByDay.forEach((_dailyStats)=>{
            data.push([_dailyStats.key ?? "", _dailyStats.count ?? 0])
        })
        if (this.props.isLoading){
            return <div>loading</div>
        }
        return (
                <Chart
                    height={350}
                    chartType="ColumnChart"
                    data={data}
                    options={{
                        hAxis: {
                            title: 'Date',
                            minValue: 0,
                        },
                        vAxis: {
                            title: 'Number of PPE Dispensed',
                            format: '0'
                        },
                    }}
                />
        );
    }
}

export {DispensingBarChart}
