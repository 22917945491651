import React from 'react';
import ReactTooltip from "react-tooltip";

export type TooltipPlacement = 'top'|'right'|'bottom'|'left';

interface Props {
    tooltipText: string,
    bgColor: string,
    tooltipPlacement:TooltipPlacement
    icon:React.ReactNode
}

function IconWithTooltip(props:Props) {
    return (
        <div data-tip={props.tooltipText} style={{
            width:20,
            height:20,
            background:props.bgColor,
            textAlign:"center",
            display:"inline-flex",
            justifyContent:"center",
            alignItems:"center"
        }}>
            {props.icon}
            <ReactTooltip multiline={true} place={props.tooltipPlacement}/>
        </div>
    );
}

export default IconWithTooltip;
