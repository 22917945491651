import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {matchPath, Redirect, withRouter} from "react-router-dom";
import {UserType} from "./global/models";

interface Props extends IAuthenticationContext {
    location:any
}

class _AdminOrganizationRequired extends Component<Props> {
    render() {
        if (this.props.authentication.userType !== UserType.ADMIN){
            return this.props.children
        }

        if (this.props.authentication.authenticated === null) {
            return (<div/>);
        }


        if (!this.props.authentication.authenticated) {
            return (<Redirect to="/login"/>);
        }

        if ((this.props.authentication.organization_selected ?? false)){
            const isOrganizationSelectionRoute = matchPath("/organization_selection", this.props.location.pathname)
            if (isOrganizationSelectionRoute){
                return (<Redirect to="/"/>);
            }
        }

        if (this.props.authentication.authenticated) {
            return this.props.children
        }
    }
}

const AdminOrganizationRequired = withRouter(withAuthentication(_AdminOrganizationRequired));
export {AdminOrganizationRequired};
