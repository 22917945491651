import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import React, {useState} from "react";
import {getAdminProductServiceAPI, getTrackableItemsServiceAPI} from "../global/api";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import {PpeProductInfo} from "../gen";

interface AddTrackableItemCardProps extends IGlobalDataContext {
    onSaved(): void
}

function _addTrackableItemCard(props: AddTrackableItemCardProps) {
    const [error, setError] = useState("");

    const [saving, setSaving] = useState(false);


    function submit(e: any) {
        setSaving(true);
        setError("");

        e.preventDefault();

        getTrackableItemsServiceAPI().adminTrackableItemServiceCreateTrackableItem({
            note: note,
            name: name,
            product_uuid: selectedProduct?.uuid
        }, {}).then((resp) => {
            return resp.data
        }).then((resp) => {
            if (resp.success) {
                props.onSaved();
            } else {
                setError(resp.error_message!);
            }
        }).catch((e) => {
            setError("Unknown server error. Please try again.");
            console.log(e);
        }).finally(() => {
            setSaving(false);
        })
    }

    const [name, setName] = useState("");
    const [note, setNote] = useState('');
    const [selectedProduct, setSelectedProduct] = useState<PpeProductInfo | null>(null);
    const [productOptions, setProductOptions] = useState<Array<PpeProductInfo>>([]);
    const [isProductOptionsLoading, setIsProductOptionsLoading] = useState<boolean>(false);

    const onProductChange = (selection: Array<PpeProductInfo>) => {
        if (selection.length > 0) {
            setSelectedProduct(selection[0]);
        } else {
            setSelectedProduct(null);
        }
    }

    const renderProductItemChildren = (option: PpeProductInfo, _props: any) => {
        return [
            <Highlighter key={"name"} search={_props.text}>{option.name}</Highlighter>,
        ];
    };

    const onSearchProduct = (query:string) => {
        setIsProductOptionsLoading(true)
        getAdminProductServiceAPI().adminProductServiceSearchProducts({
            only_current:true,
            term: query,
            per_page:10000,
            page: 1
        }).then((response)=>{
            if (response.data.success){
                setProductOptions(response.data.products ?? [])
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(() => {
            window.alert("Server error")
        }).finally(()=>{
            setIsProductOptionsLoading(false)
        })
    }

    return (
        <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Add Trackable Items</h4>
                </div>
            </div>

            <div className="iq-card-body">
                <form onSubmit={submit} className={"form"}>
                    <div className={"form-group"}>
                        <label>
                            Name:
                        </label>
                        <input className={"form-control"} type={"text"} value={name} onChange={(e: any) => {
                            setError("");
                            setName(e.target.value);
                        }}/>

                    </div>

                    <div className={"form-group"}>
                        <label>
                            Product:
                        </label>
                        <AsyncTypeahead
                            id={"typeahead"}
                            isLoading={isProductOptionsLoading}
                            labelKey={(option)=>{
                                return option.name ?? ""
                            }}
                            filterBy={(option)=>{
                                return option.is_trackable === true;
                            }}
                            onSearch={onSearchProduct}
                            selected={selectedProduct ? [selectedProduct] : []}
                            onChange={onProductChange}
                            options={productOptions}
                            renderMenuItemChildren={renderProductItemChildren}
                            clearButton
                        />
                    </div>

                    <div className={"form-group"}>
                        <label>
                            Notes:
                        </label>
                        <textarea className={"form-control"} value={note} onChange={(e: any) => {
                            console.log(e.target.value);
                            setError("");
                            setNote(e.target.value);
                        }}/>
                    </div>
                    <hr/>

                    {error &&
                        <div className={"text text-danger"}>
                            {error}
                        </div>
                    }

                    {!saving && <input type={"submit"} className={"btn btn-primary"} value={"Save"}/>}
                    &nbsp;
                    &nbsp;
                    <a href={""} onClick={(e) => {e.preventDefault(); props.onSaved(); return false}}>Cancel</a>
                </form>
            </div>
        </div>
    );
}

export const AddTrackableItemCard = withGlobalData(_addTrackableItemCard);
