import {PpeAvailableLockerCell, PpeTrackableItem} from "../gen";
import React, {useEffect, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import {getAdminLockerServiceApi, getTrackableItemsServiceAPI} from "../global/api";


function MovingItemAction(props: { cell: PpeAvailableLockerCell, item: PpeTrackableItem, reload: ()=>void }) {
    const [moving, setMoving] = useState(false);

    function move(e: any) {
        e.preventDefault()

        setMoving(true);

        getTrackableItemsServiceAPI().adminTrackableItemServiceMoveItemToLocker({
            trackable_item_uuid: props.item.uuid,
            locker_cell_uuid: props.cell.cell?.uuid!,
            locker_uuid: props.cell.locker?.uuid!,
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                props.reload();
            } else {
                window.alert(resp.error_message);
            }
        }).catch((e) => {
            console.log(e);
            window.alert("Unknown error");
        }).finally(() => {
            setMoving(false);
        })
    }

    return <div>
        <button onClick={move} disabled={moving} className={"btn btn-info"}>Move here</button>
    </div>
}

export function SearchSlots(props: { reload: () => void, item: PpeTrackableItem }) {
    const [loading, setLoading] = useState(false);
    const [availableSlots, setAvailableSlots] = useState<Array<PpeAvailableLockerCell>>([]);


    const columns: TableColumn<PpeAvailableLockerCell>[] = [
        {
            name: "Locker",
            selector: (item) => {
                return item.locker?.name!
            },
            sortable: true,
        },
        {
            name: "Cell",
            selector: (item) => {
                return `${item.cell?.row} - ${item.cell?.col}`
            },
            sortable: true,
        },
        {
            name: "Actions",
            cell: (item) => {
                return <MovingItemAction cell={item} item={props.item} reload={props.reload}/>
            }
        }
    ]

    useEffect(() => {
        getAdminLockerServiceApi().adminLockerServiceListAvailableLockerCells({
            product_uuid: props.item.product_uuid,
            quantity: 10
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                setAvailableSlots(resp.available_cells!);
            } else {
                window.alert(resp.error_message);
            }
        }).catch((e) => {
            console.log(e);
            window.alert("Unknown error");
        }).finally(() => {
            setLoading(false);
        })
    }, [props.item])
    return (<div className={"well"}>
        <h2>Search available slots</h2>

        <DataTable title="" columns={columns} data={availableSlots}
                   progressPending={loading}/>
    </div>);
}
