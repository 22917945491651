import React, {useEffect, useState} from 'react';
import Modal from "react-modal";
import {PpeCellInformation, PpeLockerInformation, PpeProductInfo} from "../gen";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import {getAdminLockerServiceApi, getAdminProductServiceAPI} from "../global/api";

interface Props extends IGlobalDataContext{
    isOpen:boolean
    locker: PpeLockerInformation
    cell: PpeCellInformation
    reload(): void
    toggleProductModal(): void
}

enum ProductFilter {
    ALL= 'ALL',
    TRACKABLE = 'TRACKABLE',
    NONTRACKABLE = 'NONTRACKABLE'
}

function _AssignProductToCellModal(props:Props) {
    const [productUuid, setProductUuid] = useState(props.cell.product_uuid);
    const [selectedProduct, setSelectedProduct] = useState<PpeProductInfo | null>(null);
    const [productOptions, setProductOptions] = useState<Array<PpeProductInfo>>([]);
    const [isProductOptionsLoading, setIsProductOptionsLoading] = useState<boolean>(false);

    const [saving, setSaving] = useState(false);
    const [filter, setFilter] = useState<ProductFilter>(ProductFilter.ALL);

    useEffect(()=>{getProduct()}, [productUuid])

    const getProduct = ()=>{
        if (productUuid !== "" && productUuid !== null){
            getAdminProductServiceAPI().adminProductServiceGetProduct(productUuid ?? "").then((response)=>{
                if (response.data.success){
                    setSelectedProduct(response.data.product ?? null)
                } else {
                    window.alert(response.data.error_message)
                }
            }).catch(() => {
                window.alert("Server error")
            })
        }
    }

    const onProductChange = (selection: Array<PpeProductInfo>) => {
        if (selection.length > 0) {
            setProductUuid(selection[0].uuid,)
            setSelectedProduct(selection[0]);
        } else {
            setProductUuid("");
            setSelectedProduct(null);
        }
    }

    const onFilterChange = (e:any) => {
        let filter: ProductFilter = ProductFilter[e.target.value as keyof typeof ProductFilter];
        console.log(filter)
        setFilter(filter);
    }

    const renderProductItemChildren = (option: PpeProductInfo, _props: any) => {
        return [
            <Highlighter key={"name"} search={_props.text}>{option.name}</Highlighter>,
        ];
    };

    const save = (e: any) => {
        e.preventDefault();

        setSaving(true);

        getAdminLockerServiceApi().adminLockerServiceUpdateProductCell({
            cell_uuid: props.cell.uuid,
            product_uuid: productUuid || "",
            locker_uuid: props.locker.uuid
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                props.reload();
            } else {
                window.alert(resp.error_message);
            }
        }).catch(()=>{
            window.alert("Server error, try again later");
        }).finally(() => {
            setSaving(false);
        })
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%'
        }
    };
    Modal.setAppElement('#root');

    const handleCloseModal = (e:any)=>{
        e.preventDefault();
        setProductUuid(props.cell.product_uuid)
        props.toggleProductModal();
    }

    const onSearchProduct = (query:string) => {
        setIsProductOptionsLoading(true)
        getAdminProductServiceAPI().adminProductServiceSearchProducts({
            only_current:true,
            term: query,
            per_page:10000,
            page: 1
        }).then((response)=>{
            if (response.data.success){
                setProductOptions(response.data.products ?? [])
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(() => {
            window.alert("Server error")
        }).finally(()=>{
            setIsProductOptionsLoading(false)
        })
    }

    return (
        <Modal isOpen={props.isOpen} style={customStyles} onRequestClose={handleCloseModal}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Assign Product To Cell [locker: {props.locker.name}  Col: {props.cell.col}, Row: {props.cell.row}]</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please select a product</p>

                <form>
                    <div>
                        <label htmlFor="productFilter">Product Filter</label>
                        <br/>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                   value= "ALL" checked={filter === ProductFilter.ALL} onChange={onFilterChange}/>
                                <label className="form-check-label" htmlFor="inlineRadio1">All</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                   value="TRACKABLE" checked={filter === ProductFilter.TRACKABLE} onChange={onFilterChange}/>
                                <label className="form-check-label" htmlFor="inlineRadio2">Trackable</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                   value="NONTRACKABLE" checked={filter === ProductFilter.NONTRACKABLE} onChange={onFilterChange}/>
                                <label className="form-check-label" htmlFor="inlineRadio3">Non-Trackable</label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Product:</label>
                        <AsyncTypeahead
                            id={"typeahead"}
                            isLoading={isProductOptionsLoading}
                            labelKey={(option)=>{
                                return option.name ?? ""
                            }}
                            filterBy={(option)=>{
                                if (filter === ProductFilter.TRACKABLE){
                                    return option.is_trackable === true;
                                }
                                if (filter === ProductFilter.NONTRACKABLE){
                                    return !option.is_trackable;
                                }
                                return true
                            }}
                            onSearch={onSearchProduct}
                            selected={selectedProduct ? [selectedProduct] : []}
                            onChange={onProductChange}
                            options={productOptions}
                            renderMenuItemChildren={renderProductItemChildren}
                            clearButton
                        />
                    </div>


                    <button onClick={save} className={"btn btn-info"}>Update</button>
                    &nbsp;
                    <button onClick={handleCloseModal} className={"btn btn-normal"}>Cancel</button>
                </form>
            </div>
        </Modal>
    );
}


export const AssignProductToCellModal = withGlobalData(_AssignProductToCellModal);

