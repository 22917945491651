import React, { Component} from 'react';
import {getEmployeeTransactionServiceApi} from "../global/api";
import {CSVLink} from "react-csv";
import moment from "moment";
import {PpeEmployeeTransactionCsv} from "../gen-employee";


interface Props {
}

interface State {
    data:PpeEmployeeTransactionCsv[]
    ready:boolean
}

class DownloadTransactions extends Component<Props, State> {
    private csvLink: React.RefObject<any>;
    constructor(props: Props) {
        super(props);

        this.state = {
            data : [],
            ready: false,
        };
        this.csvLink = React.createRef();
    }

    sleep(milliseconds:number) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    getData(){
        getEmployeeTransactionServiceApi().transactionServiceGetTransactionsCsv({
            from_epoch:0,
            to_epoch: moment().unix()
        }).then((response)=>{
            console.log("all csv data", response)
            this.setState({
                data:response.data.data??[]
            }, ()=>{
                this.setState({ready:true}, ()=>{this.csvLink.current.link.click();})
            })
        }).catch((result)=>{
            window.alert("Server error, please try again later.")
        }).finally(()=>{

        })
    }

    prepareCsvHeader(){
        return [
            {label: 'UUID', key: 'uuid'},
            {label: 'Employee', key: 'employee_name'},
            {label: 'Key Card', key: 'key_card_number'},
            {label: 'Date', key: 'date'},
            {label: 'Item Name', key: 'product_name'},
            {label: 'Item Number', key: 'mdb_item_number'},
            {label: 'Vending Machine', key: 'vending_machine_name'},
            {label: 'Site', key: 'vending_machine_site'},
        ]
    }

    prepareCsvData(){
        return this.state.data.map((transaction)=>{
            let transactionExport:any = transaction
            transactionExport['date'] = moment.unix(transaction.timestamp ?? 0).toString()
            return transactionExport;
        });
    }

    render() {
        return <div>
            <button className="btn btn-primary btn-sm" onClick={this.getData.bind(this)}>Download as CSV</button>

            <CSVLink
                data={this.prepareCsvData()} headers={this.prepareCsvHeader()}
                filename={'transactions_'+moment().unix()+'_.csv'}
                className="hidden"
                ref={this.csvLink}
                target="_blank"
            />
        </div>
    }

}

export {DownloadTransactions}
