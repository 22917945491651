import { Component } from 'react';
import Modal from "react-modal";
import { Message } from "../../global/models";
import "react-datepicker/dist/react-datepicker.css";
import { Ppeemployerv1ProductMapInfo } from "../../gen/typescript-axios";
import { getAdminVendingMachineServiceAPI } from "../../global/api";

interface Props {
    productMap: Ppeemployerv1ProductMapInfo
    isOpen: boolean
    closeChangeStockModal(): void
    reloadProductMap(): void
    pushFlashMessage(message: Message): void;
    Cap: number | undefined;
    Current: number | undefined;
}

interface State {
    stockValue: number | undefined
    isSaving: boolean,
}

class ChangeStockModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            stockValue: this.props.Current,
            isSaving: false,
        };
    }

    closeChangeStockModal() {
        this.setState({
            stockValue: this.props.Current,
        })
        this.props.closeChangeStockModal()
    }


    changeStockSave(e: any) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isSaving: true
        })

        if (this.state.stockValue === undefined || this.props.Cap === undefined || this.state.stockValue > this.props.Cap) {
            this.props.pushFlashMessage(new Message("Stock value cannot be greater than Cap value", "error"));
            return
        }

        getAdminVendingMachineServiceAPI().adminVendingMachineServiceUpdateProductMapStock(
            this.props.productMap.vending_machine_uuid as string,
            this.props.productMap.uuid as string,
            {
                current_stock: this.state.stockValue,
            }).then(response => {
                if (response.data.success) {
                    this.props.closeChangeStockModal();
                    this.props.reloadProductMap();
                    this.props.pushFlashMessage(new Message("Product Stock changed", "success"))
                } else {
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch(reason => {
                this.props.pushFlashMessage(new Message("Server error", "error"))
            }).finally(() => {
                this.setState({
                    isSaving: false,
                })
            })
    }

    onStockValueChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ stockValue: parseInt(e.target.value) })
    }

    render() {
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%'
            }
        };
        Modal.setAppElement('#root');

        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.closeChangeStockModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Change Stock Level</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <form>
                    <div className="form-group">
                        <label>New Stock Level</label>
                        <input type="number" className={"form-control"} value={this.state.stockValue} onChange={this.onStockValueChange.bind(this)} />
                    </div>
                    <button onClick={this.changeStockSave.bind(this)} className="btn btn-primary">Save</button>
                    <button type="button" className="btn iq-bg-danger"
                        onClick={this.closeChangeStockModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export { ChangeStockModal }
