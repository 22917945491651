import React, { Component } from 'react';
import {getAdminUserServiceAPI} from "../global/api";
import {Message} from "../global/models";
import {Ppeemployerv1Admin} from "../gen/typescript-axios";
import {DatetimeDisplay} from "../global/datetime_display";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";


interface Props extends IGlobalDataContext {
    admin:Ppeemployerv1Admin
    reload():void
    pushFlashMessage(message: Message): void;
    openEditAdminRolesModal(admin: Ppeemployerv1Admin):void
}

interface State {
}

class _AdminRow extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    handleDisableAdmin(e:any){
        e.preventDefault();
        e.stopPropagation();

        if (window.confirm(`Are you sure to revoke admin access for ${this.props.admin.name}`)){
            getAdminUserServiceAPI().adminUserServiceRevokeAdmin({
                email:this.props.admin.email
            }).then((response)=>{
                if (response.data.success){
                    this.props.reload()
                    this.props.pushFlashMessage(new Message("Admin access revoked", "success"))
                } else {
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch(()=>{
                this.props.pushFlashMessage(new Message("Server error", "error"))
            })
        }
    }

    handleEditRolesClick(admin: Ppeemployerv1Admin) {
        this.props.openEditAdminRolesModal(admin);
    }


    render() {
        return <tr>
            <td>
                {this.props.admin.name}
                {this.props.admin.is_tfa_enabled &&
                    <i className="fa fa-shield" title="Two-Factor Authentication Enabled"/>}
            </td>
            <td>{this.props.admin.email}</td>
            <td>{this.props.admin.created_epoch ?
                <DatetimeDisplay timestamp={this.props.admin.created_epoch} timezone={this.props.timezone}/> : "-"}</td>
            <td>{this.props.admin.is_activated ? "Enabled" : "Disabled"}</td>
            <td>
                <div>
                    {this.props.admin.roles?.join(', ')}
                </div>
            </td>
            <td>
                <div className={"row"}>
                    <div className="col-lg-5">
                        <button className="btn btn-warning"
                                onClick={() => this.handleEditRolesClick(this.props.admin)}>Edit
                        </button>
                    </div>
                    <div className={"col-lg-1"}/>
                    <div className="col-lg-5">
                        {this.props.admin.is_activated &&
                            <button className="btn iq-bg-danger btn-rounded btn-sm my-0"
                                    onClick={this.handleDisableAdmin.bind(this)}>Disable</button>
                        }
                    </div>
                </div>
            </td>
        </tr>;
    }
}

export const
    AdminRow = withGlobalData(_AdminRow)
