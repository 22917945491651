import {PpePartner, PpePartnerUserOrganization} from "../gen";

export interface PartnerTreeNode {
    title: string
    subtitle: string
    children: PartnerTreeNode[]
    expanded: boolean,
    uuid: string,
    organizations: PpePartnerUserOrganization[]
    isOrganization: boolean
}

const findChildren = (partners: PpePartner[], parentUuid: string | null): PartnerTreeNode[]=>{
    const nodes = partners.filter((partner)=>{
        return partner.parent_uuid === parentUuid;
    }).sort((partner1, partner2)=>{
        return (partner1.name ?? "").localeCompare((partner2.name ?? ""))
    });
    return  nodes.map((partner)=>{
        const children = findChildren(partners, partner.uuid ?? "")
        const leafs = getOrganizations(partner.organizations ?? [])
        return {
            title: partner.name ?? "",
            subtitle: "Partner",
            children:(children ?? []).concat(leafs),
            expanded: true,
            uuid: partner.uuid ?? "",
            organizations: partner.organizations ?? [],
            isOrganization: false
        }
    })
}

const getTreeRoot = (partners: PpePartner[]): PpePartner[] => {
    let roots:PpePartner[] =[]

    const partnerUuids = partners.map((partner)=>{
        return partner.uuid;
    })

    partners.forEach((partner)=>{
        if (!partnerUuids.includes(partner.parent_uuid)){
            roots.push(partner)
        }
    })

    console.log("roots", roots)

    return roots;
}

const getOrganizations = (organizations: PpePartnerUserOrganization[]) => {
    let leafs: PartnerTreeNode[] = []
    organizations.forEach((organization)=>{
        leafs.push({
            title: organization.name ?? "",
            subtitle: "Organization",
            children: [],
            expanded: false,
            uuid: organization.uuid ?? "",
            organizations:  [],
            isOrganization: true
        })
    })
    return leafs;
}

const formATree = (partners: PpePartner[]):PartnerTreeNode[]=>{
    const roots = getTreeRoot(partners);

    return roots.map((root)=>{
        const leafs = getOrganizations(root.organizations ?? [])
        const children = findChildren(partners, root.uuid ?? "")
        return {
            title: root.name ?? "",
            subtitle: "Partner",
            children:(children ?? []).concat(leafs),
            expanded: true,
            uuid: root.uuid ?? "",
            organizations: root.organizations ?? [],
            isOrganization: false
        }
    })
}

export default formATree;
