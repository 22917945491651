import React, {Component, Fragment} from 'react';
import {Ppeemployerv1ProductInfo} from "../gen/typescript-axios";

interface TransactionRowProps {
    transaction: Ppeemployerv1ProductInfo | undefined,
}

class TransactionProductAdditionalInformation extends Component<TransactionRowProps> {
    // eslint-disable-next-line
    constructor(props: TransactionRowProps) {
        super(props);
    }
    render() {
        const { transaction } = this.props;
        console.log('transaction',transaction?.name ?? 'non');
        return <Fragment>
            <tr key={this.props.transaction?.manufacturer_code}>
                <td colSpan={12}>
                    <table className={"table table-striped"}>
                        <tbody>
                            <tr>
                                <td>Manufacturer:</td>
                                <td>
                                    {transaction?.manufacturer ?? ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Manufacturer Code:</td>
                                <td>
                                    {transaction?.manufacturer_code ?? ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Supplier:</td>
                                <td>
                                    {transaction?.supplier ?? ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Supplier Code:</td>
                                <td>
                                    {transaction?.supplier_code ?? ''}
                                </td>
                            </tr>                            
                        </tbody>
                    </table>
                </td>
            </tr>
        </Fragment>;
    }
}

export {TransactionProductAdditionalInformation}
