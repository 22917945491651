import React, { useState, ChangeEvent } from 'react';
import Modal from "react-modal";
import { getAdminOrganizationServiceAPI } from "../global/api";
import { IGlobalDataContext, withGlobalData } from '../global/global-data';
import { Message } from '../global/models';

interface Props extends IGlobalDataContext {
    isOpen: boolean;
    title: string;
    content: string;
    closeOrgainizationCloneModal(): void;
    reload(): void;
}

interface State {
    organizationName: string;
    cloneProducts: boolean;
    cloneTrackableItems: boolean;
    cloneAdministrators: boolean;
    isSaving: boolean;
}

const OriganzationCloneModal: React.FC<Props> = ({ isOpen, title, content, closeOrgainizationCloneModal: closeAddAdminModal, reload: reloadCompanyDetail, pushFlashMessage }) => {
    const [state, setState] = useState<State>({
        organizationName: "",
        cloneProducts: false,
        cloneTrackableItems: false,
        cloneAdministrators: false,
        isSaving: false
    });

    const handleCloseModal = () => {
        setState(prevState => ({
            ...prevState,
            organizationName: "",
            cloneProducts: false,
            cloneTrackableItems: false,
            cloneAdministrators: false
        }));
        closeAddAdminModal();
    }

    const handleOrganizationNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, organizationName: event.target.value });
    }

    const handleCheckedContantsChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: checked
        }));
    }

    const save = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        if (state.isSaving) {
            return;
        }

        if (!state.organizationName) {
            window.alert("Organization Name is required");
            return;
        }

        setState({ ...state, isSaving: true });

        getAdminOrganizationServiceAPI().adminOrganizationServiceCloneOrganization({
            new_organization_name: state.organizationName,
            clone_products: state.cloneProducts,
            clone_trackable_items: state.cloneTrackableItems,
            clone_administrators: state.cloneAdministrators,
        }).then(response => {
            if (response.data.success) {
                reloadCompanyDetail(); pushFlashMessage(new Message("Organization cloned successfully", "success"))
            } else {
                pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            pushFlashMessage(new Message("Server error", "error"))
        }).finally(() => {
            setState({ ...state, isSaving: false });
            handleCloseModal();
        });
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%'
        }
    };

    return (
        <Modal isOpen={isOpen} style={customStyles} onRequestClose={handleCloseModal}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">{title}</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>{content}</p>
                <form>
                    <div className="form-group">
                        <label htmlFor="name">Organization Name:*</label>
                        <input
                            type="text"
                            onChange={handleOrganizationNameChange}
                            className="form-control"
                            value={state.organizationName}
                            autoFocus={true}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Clone content: </label>
                        &nbsp;&nbsp;
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    name="cloneProducts"
                                    checked={state.cloneProducts}
                                    onChange={handleCheckedContantsChange}
                                    style={{ marginRight: 4, verticalAlign: "middle" }}
                                />
                                <span style={{ verticalAlign: "middle" }}>Clone Products</span>
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    name="cloneTrackableItems"
                                    checked={state.cloneTrackableItems}
                                    onChange={handleCheckedContantsChange}
                                    style={{ marginRight: 4, verticalAlign: "middle" }}
                                />
                                <span style={{ verticalAlign: "middle" }}>Clone Trackable Items</span>
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    name="cloneAdministrators"
                                    checked={state.cloneAdministrators}
                                    onChange={handleCheckedContantsChange}
                                    style={{ marginRight: 4, verticalAlign: "middle" }}
                                />
                                <span style={{ verticalAlign: "middle" }}>Clone Administrators</span>
                            </label>
                        </div>
                    </div>
                    <button onClick={save} className="btn btn-primary">{state.isSaving ? "Saving..." : "Save"}</button>
                    &nbsp;&nbsp;
                    <button type="button" className="btn iq-bg-danger" onClick={handleCloseModal}>Cancel</button>
                </form>
            </div>
        </Modal>
    );
}

export default withGlobalData(OriganzationCloneModal);
