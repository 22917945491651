import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {matchPath, Redirect, withRouter} from "react-router-dom";
import {UserType} from "./global/models";

interface Props extends IAuthenticationContext {
    location:any
}

class _PartnerAuthenticationProtected extends Component<Props> {
    render() {
        if (this.props.authentication.userType !== UserType.PARTNER){
            return this.props.children
        }


        if (this.props.authentication.authenticated === null) {
            return (<div/>);
        }


        if (!this.props.authentication.authenticated) {
            return (<Redirect to="/partners/login"/>);
        }

        if (this.props.authentication.authenticated) {
            const isRootRoute = matchPath("/", this.props.location.pathname)
            if (isRootRoute){
                return (<Redirect to="/partners"/>);
            }
            return this.props.children
        }
    }
}

const PartnerAuthenticationProtected = withRouter(withAuthentication(_PartnerAuthenticationProtected));
export {PartnerAuthenticationProtected};
