import React, {Component} from 'react';
import {PersonalInformationTab} from "./personal_information_tab";
import {ChangePasswordTab} from "./change_password_tab";
import {TfaTab} from "./tfa_tab";
import {Helmet} from "react-helmet";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {SsoTab} from "./sso_tab";
import {getAdminUserServiceAPI} from "../global/api";
import {PpeMyInfo} from "../gen";


interface Props extends IGlobalDataContext{
}

interface State {
    myInfo: PpeMyInfo | null
    isLoading: boolean
}


class _MyProfile extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            myInfo: null,
            isLoading: false
        };
    }

    componentDidMount() {
        this.reloadMyInfo();
    }

    reloadMyInfo(){
        this.setState({
            isLoading: true
        })
        getAdminUserServiceAPI().adminUserServiceGetMyInfo().then((response)=>{
            if (response.data.success ?? false){
                this.setState({
                    myInfo: response.data.my_info ?? null
                })
            } else {
                window.alert(response.data.error_message ?? "");
            }
        }).catch(()=>{
            window.alert("Server error");
        }).finally(()=>{
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        return <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Profile - " + this.props.company + " - " + this.props.globalTitle}</title>
            </Helmet>
            {!this.state.isLoading && <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="iq-card">
                            <div className="iq-card-body p-0">
                                <div className="iq-edit-list">
                                    <ul className="iq-edit-profile d-flex nav nav-pills" id='tabMenu' role="tablist">
                                        <li className="col-md-3 p-0">
                                            <a className="nav-link active" data-toggle="tab"
                                               href="#personal-information">
                                                Personal Information
                                            </a>
                                        </li>
                                        <li className="col-md-3 p-0">
                                            <a className="nav-link" data-toggle="tab" href="#changPwd">
                                                Change Password
                                            </a>
                                        </li>
                                        <li className="col-md-3 p-0">
                                            <a className="nav-link" data-toggle="tab" href="#sso">
                                                Single Sign On (SSO)
                                            </a>
                                        </li>
                                        <li className="col-md-3 p-0">
                                            <a className="nav-link" data-toggle="tab" href="#tfa">
                                                Two Factor Authentication
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="iq-edit-list-data">
                            <div className="tab-content">
                                <PersonalInformationTab
                                    pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                                    reload={this.reloadMyInfo.bind(this)}
                                    admin={this.state.myInfo?.admin ?? null}
                                    emailPreferences={this.state.myInfo?.email_preferences ?? null}/>
                                <ChangePasswordTab pushFlashMessage={this.props.pushFlashMessage.bind(this)}/>
                                <SsoTab pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                                        reload={this.reloadMyInfo.bind(this)}
                                        microsoftSsoSetting={this.state.myInfo?.microsoft_sso_setting ?? null}/>
                                <TfaTab isEnabled = {this.state.myInfo?.admin?.is_tfa_enabled ?? true}
                                        reload={this.reloadMyInfo.bind(this)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    }
}

export const MyProfile = withGlobalData(_MyProfile)

