import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import DataTable, {TableColumn} from "react-data-table-component";
import {getAdminProductServiceAPI} from "./global/api";
import {PpeCellInformation, PpeProductInfo, PpeRestriction} from "./gen";
import moment from "moment";


interface Params {
    product_uuid: string
}


interface Props {

}

function AddRuleBox(props: { productUUID: string, reload(): void; cancel(): void }) {
    const [duration, setDuration] = useState(3600);
    const [maxQuantity, setMaxQuantity] = useState(1);
    const [saving, setSaving] = useState(false);

    function updateMaxQuantity(e: any) {
        setMaxQuantity(e.target.value);
    }

    function updateDuration(e: any) {
        setDuration(parseInt(e.target.value));
    }

    function save(e: any) {
        e.preventDefault();

        setSaving(true);

        getAdminProductServiceAPI().adminProductServiceAddRestriction({
            inspection_period: duration,
            max_quantity: maxQuantity,
            product_uuid: props.productUUID,
            daily_reset: dailyReset
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                props.reload();
                props.cancel();
            } else {
                window.alert(resp.error_message);
            }
        }).catch((e) => {
            console.log(e);
            window.alert(e);
        }).finally(() => {
            setSaving(false);
        })

        console.log('save');
    }

    const [dailyReset, setDailyReset] = useState(false);

    function toggleDailyReset() {
        setDailyReset(!dailyReset);
    }

    return <div>
        <h3>Add new restriction rule</h3>

        <form className={"form"} onSubmit={save}>
            <div className={"form-group"}>
                <label>
                    Duration (seconds)
                </label>
                <input className={"form-control"} value={duration} onChange={updateDuration}/>
            </div>

            <div className={"form-group"}>
                <label>
                    Max Quantity
                </label>
                <input className={"form-control"} value={maxQuantity} onChange={updateMaxQuantity}/>
            </div>

            <div className={"form-group"}>
                <label>
                    Daily Reset &nbsp;
                    <input  type={"checkbox"} checked={dailyReset} onChange={toggleDailyReset} />
                </label>
            </div>

            {!saving &&
                <div>
                    <hr/>
                    <div className={"form-group"}>
                        <input readOnly={true} className="btn btn-primary" type="submit" value={"Save"}/>

                        &nbsp; &nbsp;
                        <input value="Cancel" onClick={props.cancel} className="btn btn-secondary"/>
                    </div>


                </div>


            }
        </form>
    </div>
}

function ProductRestrictions(props: Props) {
    const {product_uuid} = useParams<Params>();

    const [loading, setLoading] = useState(false);

    const [product, setProduct] = useState<PpeProductInfo | null>(null);
    const [restrictionRules, setRestrictionRules] = useState<Array<PpeRestriction>>([]);

    const [loadingRules, setLoadingRules] = useState(false);

    function loadProduct() {
        loadRules();
        setLoading(true);
        getAdminProductServiceAPI().adminProductServiceListProducts({
            page: 0,
            only_current: true
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                setProduct(resp.products?.find((p, idx) => {
                    if (p.uuid === product_uuid) {
                        return true;
                    }

                    return false;
                }) || null)
            } else {
                window.alert(resp.error_message)
            }
        }).finally(() => {
        })
        setLoading(false);
    }

    function loadRules() {
        setLoadingRules(true);
        getAdminProductServiceAPI().adminProductServiceListRestrictions({product_uuid: product_uuid}).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                setRestrictionRules(resp.restrictions || []);
            } else {
                window.alert(resp.error_message);
            }
        }).catch((e) => {
            console.log(e);
            window.alert(e);
        }).finally(() => {
            setLoadingRules(false);
        })
    }

    useEffect(() => {
        loadProduct();
        loadRules();
    }, []);

    function deleteRule(productUUID: string, ruleUUID: string) {
        console.log(ruleUUID);

        if (!window.confirm("Are you sure to remove restriction?")) {
            return;
        }

        getAdminProductServiceAPI().adminProductServiceRemoveRestriction({
            restriction_uuid: ruleUUID,
            product_uuid: productUUID
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                loadRules();
            } else {
                window.alert(resp.error_message)
            }
        }).catch((e) => {
            console.log(e);
            window.alert("unknown error, please retry");
        })
    }

    const columns: TableColumn<PpeRestriction>[] = [
        {
            name: "Duration",
            sortable: true,
            selector: r => {
                return r.inspection_period!
            }
        },
        {
            name: "Max Quantity",
            sortable: true,
            selector: (r) => {
                return r.max_quantity || "N/A"
            }
        },
        {
            name: "Created At",
            sortable: true,
            selector: (r) => {
                return r.added_at_epoch_second ? moment.unix(r.added_at_epoch_second!).toString() : 'N/A'
            }
        },
        {
            name: "Daily Reset",
            sortable: true,
            selector: (r) => {
                return (r.daily_reset || false) ? "Yes" : "-"
            }
        },
        {
            name: "Actions",
            sortable: false,
            cell: (r) => {
                return <div>
                    <button onClick={(e) => {
                        e.preventDefault();
                        deleteRule(product_uuid, r.restriction_uuid || "");
                    }}>Delete
                    </button>
                </div>
            }
        }
    ];

    const [adding, setAdding] = useState(false);

    return <div id="content-page" className="content-page">
        <Helmet>
            <title>Product Restrictions</title>
        </Helmet>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h3 className="card-title">Product Restrictions [{product?.name}]</h3>

                                {loading && <div>Loading...</div>}
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <p>Product Restrictions allow manager to specify the list of rules that would automatically
                                reject vends based a list of time-boxed max quality criteria. </p>
                        </div>
                    </div>
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h3 className="card-title">Current Restrictions
                                    &nbsp;
                                    <small>

                                        <a href={"#"} onClick={() => setAdding(!adding)}>
                                            <i className={"fa fa-plus"}></i>
                                        </a>

                                    </small>

                                </h3>

                            </div>
                        </div>
                        <div className="iq-card-body">
                            {adding &&
                                <AddRuleBox productUUID={product_uuid} reload={loadRules}
                                            cancel={() => {
                                                setAdding(false);
                                            }}
                                />
                            }

                            <DataTable title="" columns={columns} data={restrictionRules}
                                       progressPending={loading}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export {ProductRestrictions};
