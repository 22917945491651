import React, { Component } from 'react';
import Modal from "react-modal";
import {getAdminUserServiceAPI} from "../global/api";
import {Ppeemployerv1Admin, Ppeemployerv1Role} from "../gen/typescript-axios";


interface Props {
    isOpen: boolean
    closeAddAdminModal():void
    reloadCompanyDetail():void
    admin:Ppeemployerv1Admin | null
}

interface State {
    checkedRoles: Ppeemployerv1Role[],
    isSaving: boolean
}

class EditAdminRolesModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isSaving: false,
            checkedRoles: this.props.admin?.roles ?? [] ,
        };
    }

    handleCloseModal(){
        this.setState({
            checkedRoles:this.props.admin?.roles ?? [] ,
        })
        this.props.closeAddAdminModal()
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.admin?.roles !== prevProps.admin?.roles) {
            this.setState({
                checkedRoles: this.props.admin?.roles ?? [],
            });
        }
    }

    handleCheckedRolesChange(event:any) {
        const { value, checked } = event.target;
        const { checkedRoles } = this.state;

        if (checked) {
            this.setState({
                checkedRoles: [...checkedRoles, value]
            });
        } else {
            this.setState({
                checkedRoles: checkedRoles.filter(role => role !== value)
            });
        }
    }

    saveAdmin(event:any){
        event.preventDefault();
        event.stopPropagation();
        if (this.state.isSaving){
            return;
        }
        this.setState({
            isSaving: true
        })
        getAdminUserServiceAPI().adminUserServiceUpdateRoles({
            email:this.props.admin?.email,
            roles:this.state.checkedRoles,
        }).then(response => {
            if (response.data.success) {
                this.handleCloseModal()
                this.props.reloadCompanyDetail();
                window.alert("Success")
            } else {
                console.log('response.options.data.error_message +++++',response.data.error_message);
                window.alert(response.data.error_message ?? "")
            }
        }).catch(()=>{
            window.alert("Server error");
        }).finally(()=>{
            this.setState({
                isSaving:false
            })
        })
    }

    render() {
        const customStyles = {
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)',
                width:'50%'
            }
        };

        const roles = [
            Ppeemployerv1Role.Admin,
            Ppeemployerv1Role.EmployeeManagement,
            // Ppeemployerv1Role.VendingMachineManagement,
            // Ppeemployerv1Role.Report
        ];

        Modal.setAppElement('#root');
        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.handleCloseModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Edit Admin</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please Edit the role of admin</p>
                <form>
                    <div className="form-group">
                        <label htmlFor="name">Name: {this.props.admin?.name}</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email: {this.props.admin?.email}</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="role">Role: </label>
                        &nbsp;&nbsp;
                        {roles.map(role => (
                            <label key={role} style={{marginRight:20}}>
                                <input
                                    type="checkbox"
                                    value={role}
                                    checked={this.state.checkedRoles.includes(role)}
                                    onChange={this.handleCheckedRolesChange.bind(this)}
                                    style={{marginRight:4, verticalAlign:"middle"}}
                                />
                                <span style={{verticalAlign:"middle"}}>{role}</span>
                            </label>
                        ))}
                    </div>
                    <button onClick={this.saveAdmin.bind(this)} className="btn btn-primary">Save</button>
                    <button type="button" className="btn iq-bg-danger"
                            onClick={this.handleCloseModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export {EditAdminRolesModal}
