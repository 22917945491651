import React, {useState} from 'react';
import Modal from "react-modal";
import {V1DedicatedLockerCell, V1DedicatedLockerDetails} from "../gen/typescript-axios";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import {getAdminCompanyInformationServiceAPI, getAdminDedicatedLockerServiceAPI} from "../global/api";
import {PpeEmployee} from "../gen";
import DatePicker from "react-datepicker";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import moment from "moment-timezone";
import {Message} from "../global/models";

interface Props extends IGlobalDataContext{
    isOpen: boolean
    closeModal(): void
    locker: V1DedicatedLockerDetails
    cell: V1DedicatedLockerCell
    reload(): void
}

function _AssignEmployeeToDedicatedLockersModal(props: Props) {
    const [isEmployeeOptionsLoading, setIsEmployeeOptionsLoading] = useState<boolean>(false);
    const [employeeOptions, setEmployeeOptions] = useState<PpeEmployee[]>([])
    const [selectedEmployee, setSelectedEmployee] = useState<PpeEmployee | null>(null)
    const [expiryAtDate, setExpiryAtDate] = useState<Date | null>(null)
    const [reference, setReference] = useState<string>("")

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%'
        }
    };
    Modal.setAppElement('#root');

    const searchEmployee = (query:string) => {
        getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceSearchEmployees({
            term:query,
            per_page:10000,
            page:1
        }).then((response)=>{
            if (response.data.success){
                const employees = response.data.data?.employees?.filter((employee)=>{
                    return employee.is_enabled === true && employee.is_tmp_access === false;
                })
                setEmployeeOptions(employees ?? [])
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(reason => {
            window.alert("Server error")
        }).finally(()=>{
            setIsEmployeeOptionsLoading(false)
        })
    }

    const onEmployeeChange = (employees: Array<PpeEmployee>) => {
        if (employees.length > 0) {
            setSelectedEmployee(employees[0])
        } else {
            setSelectedEmployee(null)
        }
    }

    const assignEmployeeToLocker = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        getAdminDedicatedLockerServiceAPI().adminDedicatedLockerServiceAssignEmployeeToDedicatedLockerCell({
            locker_uuid: props.locker.uuid,
            cell_uuid: props.cell.uuid,
            employee_uuid: selectedEmployee?.uuid,
            expiry_at: moment(expiryAtDate).tz(props.timezone, true).unix(),
            reference: reference
        }).then((response) => {
            if (!response.data.success){
                props.pushFlashMessage(new Message(response.data.error_message ?? "Unknown Error", "error"));
                return;
            }
            props.reload();
            props.closeModal();
            props.pushFlashMessage(new Message("success", "success"));
        }).catch(() => {
            props.pushFlashMessage(new Message("Unknown Error", "error"));
        })
    }

    const handleExpiryAtChange = (date:Date | null) => {
        setExpiryAtDate(date);
    }

    const handleReferenceChange = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        setReference(e.target.value);
    }

    const renderMenuItemChildren = (option: PpeEmployee, props:any) => {
        return [
            <Highlighter key={"name"} search={props.text}>{option.full_name + "(" + (option.employee_number ?? 'N/A') + ")"}</Highlighter>,
        ];
    };

    const selected = selectedEmployee ? [selectedEmployee] : [];
    return (
        <Modal isOpen={props.isOpen} style={customStyles} onRequestClose={props.closeModal}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Assign Employee To Cell [Locker: {props.locker.name}, Row: {props.cell.row}, Col: {props.cell.col}]</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please select an employee</p>
                <form>
                    <div className="form-group">
                        <label>Employee</label>
                        <AsyncTypeahead
                            id={"typeahead"}
                            isLoading={isEmployeeOptionsLoading}
                            labelKey={(item)=>{
                                return item.full_name ?? ""
                            }}
                            onSearch={searchEmployee}
                            selected={selected}
                            onChange={onEmployeeChange}
                            options={employeeOptions}
                            renderMenuItemChildren={renderMenuItemChildren}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="startDate">Expiry - (Timezone: {props.timezone}):</label>
                        <DatePicker className={"form-control"}
                                    selected={expiryAtDate}
                                    onChange={handleExpiryAtChange}
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    wrapperClassName={"datePicker"}
                                    showTimeSelect={true}
                                    isClearable
                        />
                    </div>
                    <div className="form-group">
                        <label>Reference</label>
                        <textarea className={"form-control"} rows={5} onChange={handleReferenceChange} value={reference}>

                        </textarea>
                    </div>
                    <button className="btn btn-primary" onClick={assignEmployeeToLocker} disabled={selectedEmployee === null}>Save</button>&nbsp;
                    <button className="btn iq-bg-danger"
                            onClick={props.closeModal}>Cancel
                    </button>
                </form>
            </div>
        </Modal>
    );
}

export const AssignEmployeeToDedicatedLockersModal = withGlobalData(_AssignEmployeeToDedicatedLockersModal);
