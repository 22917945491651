import React, {Component} from 'react';
import {Message} from "../../global/models";
import {getAdminVendingMachineServiceAPI} from "../../global/api";
import {EditProductMapModal} from "./edit_product_map_modal";
import {Ppeemployerv1ProductMapInfo,Ppeemployerv1MotorCoupling} from "../../gen/typescript-axios";
import {PpeTrackableItem} from "../../gen";
import { ChangeStockModal } from './change_stock_modal';

interface Props {
    productMap: Ppeemployerv1ProductMapInfo
    advanced: boolean

    reloadAllProductMap(): void

    pushFlashMessage(message: Message): void;

    allTrackableItems:PpeTrackableItem[]|undefined

    motorCouplings:Ppeemployerv1MotorCoupling[]
    
    vendingMachineUuid: string;
}

interface State {
    isEditModalOpen: boolean,
    isRemoving: boolean,
    isOpenChangeStockModal: boolean,
}

class ProductMapRow extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditModalOpen: false,
            isRemoving: false,
            isOpenChangeStockModal: false
        };
    }

    closeEditModal() {
        this.setState({isEditModalOpen: false})
    }

    openEditModal() {
        this.setState({isEditModalOpen: true})
    }


    openChangeStockModal() {
        this.setState({isOpenChangeStockModal: true})
    }

    closeChangeStockModal() {
        this.setState({isOpenChangeStockModal: false})
    }

    removeProductMap(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm(`Are you sure to delete the product map with mdb code: ${this.props.productMap.mdb_code}`)) {
            this.setState({
                isRemoving:true
            })
            getAdminVendingMachineServiceAPI().adminVendingMachineServiceRemoveProductMap(
                this.props.productMap.vending_machine_uuid ?? "",
                this.props.productMap.uuid ?? "", {}
            ).then(response => {
                if (response.data.success) {
                    this.props.reloadAllProductMap();
                    this.props.pushFlashMessage(new Message("Product Map Removed", "success"))
                } else {
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch(reason => {
                this.props.pushFlashMessage(new Message("Server error", "error"))
            }).finally(()=>{
                this.setState({
                    isRemoving:false
                })
            })
        }
    }

    removeMotorCoupling = (motorCoupling: Ppeemployerv1MotorCoupling) => {
        return (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            if (window.confirm("Remove motor coupling?")) {
                console.log("Removing motor coupling");
                console.log(motorCoupling);

                this.props.motorCouplings.splice(this.props.motorCouplings.indexOf(motorCoupling), 1);

                getAdminVendingMachineServiceAPI().adminVendingMachineServiceUpdateMotorCouplings(this.props.vendingMachineUuid, {
                    motor_couplings: this.props.motorCouplings
                }).then((resp: any) => {
                    console.log(resp);

                    this.props.reloadAllProductMap()
                })
            }
        }
    }

    render() {
        const cap = this.props.productMap.capacity;
        const restock = this.props.productMap.restock_count;
        const current = this.props.productMap.current_stock;

        

        const motorCoupling = this.props.motorCouplings.find((mc) => {
            return parseInt(
                mc.main_mdb_item_number?.toString() ?? "0") === this.props.productMap.mdb_code
        })

        return <tr>
            <EditProductMapModal pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                                 isOpen={this.state.isEditModalOpen}
                                 closeEditProductMapModal={this.closeEditModal.bind(this)}
                                 reloadProductMap={this.props.reloadAllProductMap}
                                 productMap={this.props.productMap}
                                 allTrackableItems={this.props.allTrackableItems}
                                 />
            <ChangeStockModal pushFlashMessage={this.props.pushFlashMessage.bind(this)} 
                                 isOpen={this.state.isOpenChangeStockModal}
                                 closeChangeStockModal={this.closeChangeStockModal.bind(this)}
                                 reloadProductMap={this.props.reloadAllProductMap}
                                 productMap={this.props.productMap}
                                 Cap={cap}
                                 Current={current}/>
            <td>{this.props.productMap.uuid?.substr(0, 4)}</td>
            <td style={{textAlign: "left"}}>{this.props.productMap.product?.name}
            <br/>
            {
                motorCoupling &&
                <span className={"text-green"}>Motor Coupling: {
                     motorCoupling.coupled_mdb_item_number
                }
                    <button onClick={
                        this.removeMotorCoupling(motorCoupling).bind(this)
                    }> <i
                        className={"fas fa fa-cogs"}/></button> </span>
            }
                <br />Manufacturer: { this.props.productMap.product?.manufacturer ?? ''}
                <br />Manufacturer Code: { this.props.productMap.product?.manufacturer_code ?? ''}
                <br />Supplier: { this.props.productMap.product?.supplier ?? ''}
                <br />Supplier Code: { this.props.productMap.product?.supplier_code ?? ''}
            </td>
            <td>{this.props.productMap.mdb_code}</td>
            <td>
                {this.props.productMap.trackable_item ? "-" :  this.props.productMap.consumed}
                {this.props.advanced &&
                    <div>
                        Current: {current}, Cap: {cap}, Restock: {restock}
                    </div>
                }
            </td>
            <td>{this.props.productMap.day_average_consumption}</td>
            <td>{this.props.productMap.low_stock_alert}</td>
            <td>{this.props.productMap.trackable_item?.name}</td>
            <td>
                <div className="row">
                    <div className="col-lg-3">
                        <button className={"btn btn-warning"} onClick={this.openEditModal.bind(this)}>Edit</button>
                    </div>
                    <div className="col-lg-1">
                        &nbsp;
                    </div>
                    <div className="col-lg-3">
                        <button className={"btn btn-warning"} onClick={this.openChangeStockModal.bind(this)}>Change Stock Level</button>
                    </div>
                    <div className="col-lg-2">
                        &nbsp;
                    </div>
                    <div className="col-lg-3">
                        <button className={"btn btn-danger"} onClick={this.removeProductMap.bind(this)}>Remove</button>
                    </div>
                </div>
            </td>
        </tr>;
    }
}

export {ProductMapRow}
