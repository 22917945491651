import {getMicrosoftAuth} from "./microsoft";
import ms365logo from "../microsoft365.png";
import React, {useEffect, useState} from "react";
import {UserType} from "../global/models";
import {getAdminSsoServiceAPI, getEmployeeSsoServiceApi} from "../global/api";

interface Props {
    userType: UserType
}

export function LoginWithMS365Box(props: Props) {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        if (props.userType === UserType.ADMIN){
            getAdminSsoServiceAPI().adminSsoServiceGetMicrosoftSsoConfig().then((response) => {
                if (response.data.success){
                    setEnabled(response.data.config?.enabled ?? false);
                }
            })
        }
        if (props.userType === UserType.EMPLOYEE){
            getEmployeeSsoServiceApi().ssoServiceGetMicrosoftSsoConfig().then((response) => {
                if (response.data.success){
                    setEnabled(response.data.enabled ?? false);
                }
            })
        }
    }, []);

    function loginWithMS365(event: any) {
        event.preventDefault()

        getMicrosoftAuth(props.userType).then((auth) => {
            if (auth) {
                const redirectURL = auth.token.getUri();

                if (redirectURL) {
                    // @ts-ignore
                    window.location = redirectURL;

                } else {
                    window.alert("Failed to login with Microsoft 365. Please contact VoT support");
                }
            } else {
                window.alert("Failed to initialise Microsoft 365. Please try again later");
            }
        });
    }

    if (!enabled) {
        return <div></div>;
    }

    return <div>
        <a href={"#"} onClick={loginWithMS365}>
            <img
                alt={"Login using Microsoft 365"}
                src={ms365logo}/>
        </a>
    </div>;
}
