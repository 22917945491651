import React, { Component } from 'react';
import { getAdminFeedbackServiceAPI } from "../global/api";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import { IGlobalDataContext, withGlobalData } from "../global/global-data";
import { DatetimeDisplay } from "../global/datetime_display";
import { V1FeedbackDetail } from "../gen/typescript-axios";


interface Props extends IGlobalDataContext {
}

interface State {
    currentPage: number
    pageCount: number
    feedbacks: V1FeedbackDetail[]
    isLoading: boolean
}

class _FeedBackLogPage extends Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = {
            currentPage: 0,
            pageCount: 0,
            feedbacks: [],
            isLoading: false
        };
    }

    componentDidMount() {
        this.reloadFeedBackLog();
    }

    reloadFeedBackLog() {
        this.setState({
            isLoading: true
        });

        getAdminFeedbackServiceAPI().adminFeedbackServiceListFeedbacks(
            this.state.currentPage + 1, 10,
        ).then((response) => {
            if (response.data.success ?? false) {
                this.setState({
                    feedbacks: response.data.feedbacks ?? [],
                    pageCount: response.data.total_page ?? 1
                })
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(() => {
            window.alert("Server error")
        }).finally(() => {
            this.setState({
                isLoading: false
            });
        })
    }

    handlePageChange(selectedItem: { selected: number }): void {
        this.setState({ currentPage: selectedItem.selected }, () => {
            this.reloadFeedBackLog();
        });
    }
    render() {
        const rows = this.state.feedbacks.map((feedback, index) => {
            return <tr key={index}>
                <td>
                    <DatetimeDisplay timezone={this.props.timezone} timestamp={Number(feedback.feedback?.created_at) ?? 0} />
                </td>
                <td>{feedback.employee?.full_name ?? '-'}</td>
                <td>{feedback.feedback?.feedback_type === 'FEEDBACK_TYPE_TAKE_PRODUCT' ? 'TAKE' : 'RETURN' ?? '-'}</td>
                <td>{feedback.product_map?.product?.name ?? '-'}</td>
                <td>{feedback.feedback?.message ?? '-'}</td>
                <td>{feedback.vending_machine?.name ?? '-'}</td>

            </tr>
        })
        return <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Audits - " + this.props.company + " - " + this.props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Feedbacks</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div>
                                    <table
                                        id="datatable"
                                        className="table table-striped table-bordered mt-4"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Time</th>
                                                <th>Employee</th>
                                                <th>Type</th>
                                                <th>Product</th>
                                                <th>Message</th>
                                                <th>Machine Name</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows}
                                        </tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    pageCount={this.state.pageCount}
                                    initialPage={this.state.currentPage}
                                    onPageChange={this.handlePageChange.bind(this)}
                                    containerClassName={"pagination mb-0"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item page-link"}
                                    nextClassName={"page-item page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export const FeedBackLogPage = withGlobalData(_FeedBackLogPage)
