import React, { Component } from 'react';
import Modal from "react-modal";
import { Message } from "../global/models";
import { getAdminVendingMachineServiceAPI } from "../global/api";
import { Ppeemployerv1ReturnShortCutStatus, Ppeemployerv1ItemPickerMethod, V1AdminTag } from "../gen/typescript-axios";
import { V1VendingMachineInfo } from "../gen/typescript-axios";
import CustomSelectDropdown from '../custom_select_dropdown/custom_select_dropdown';


interface Props {
    isOpen: boolean
    closeEditVendingMachineModal(): void
    reloadAllVendingMachines(): void
    vendingMachine: V1VendingMachineInfo
    pushFlashMessage(message: Message): void;
}

interface State {
    newVendingMachineName: string
    newVendingMachineLocation: string,
    newVendingMachinePinCode: string,
    newVendingMachineTimeZone: string,
    pictureEncodedContent: string
    isRemovePhoto: boolean
    returnShortCutStatus: Ppeemployerv1ReturnShortCutStatus
    defaultItemPickerMethod: Ppeemployerv1ItemPickerMethod,
    selectedTags: V1AdminTag[],
}

class EditVendingMachineModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newVendingMachineName: this.props.vendingMachine.name ?? "",
            newVendingMachineLocation: this.props.vendingMachine.site ?? "",
            newVendingMachinePinCode: this.props.vendingMachine.pin_code ?? "",
            newVendingMachineTimeZone: this.props.vendingMachine.timezone ?? "",
            pictureEncodedContent: "",
            isRemovePhoto: false,
            returnShortCutStatus: this.props.vendingMachine.return_short_cut_status ?? Ppeemployerv1ReturnShortCutStatus.Enabled,
            defaultItemPickerMethod: this.props.vendingMachine.default_item_picker_method ?? Ppeemployerv1ItemPickerMethod.Product,
            selectedTags: this.props.vendingMachine.tags ?? [],
        };
    }

    handleCloseModal() {
        this.props.closeEditVendingMachineModal();
        this.props.reloadAllVendingMachines()
        this.setState({
            newVendingMachineLocation: this.props.vendingMachine.site ?? "",
            newVendingMachineName: this.props.vendingMachine.name ?? "",
            isRemovePhoto: false,
        })
    }

    handleVendingMachineNameChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ newVendingMachineName: e.target.value })
    }

    handleVendingMachinePinCodeChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        const inputValue = e.target.value;
        if (/^\d{0,6}$/.test(inputValue)) {
            this.setState({ newVendingMachinePinCode: inputValue });
        }
    }

    handleVendingMachineTimeZoneChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ newVendingMachineTimeZone: e.target.value });
    }

    handleVendingMachineLocationChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ newVendingMachineLocation: e.target.value })
    }

    handleReturnShortStatusChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ returnShortCutStatus: e.target.value })
    }

    handleTakeItemPageDisPlayModeChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ defaultItemPickerMethod: e.target.value })
    }

    handleSelectionChange(newSelectedTags: V1AdminTag[]) {
        if (newSelectedTags !== this.state.selectedTags) {
            this.setState({ selectedTags: [...newSelectedTags] });
        }
    }


    updateVendingMachine(e: any) {
        e.preventDefault();
        e.stopPropagation();

        let data;

        if (this.state.pictureEncodedContent !== "" && this.state.pictureEncodedContent != null) {
            data = {
                name: this.state.newVendingMachineName,
                site: this.state.newVendingMachineLocation,
                photo: this.state.pictureEncodedContent,
                pin_code: this.state.newVendingMachinePinCode,
                timezone: this.state.newVendingMachineTimeZone,
                remove_photo: this.state.isRemovePhoto,
                return_short_cut_status: this.state.returnShortCutStatus ?? Ppeemployerv1ReturnShortCutStatus.Enabled,
                default_item_picker_method: this.state.defaultItemPickerMethod ?? Ppeemployerv1ItemPickerMethod.Product,
                tag_uuids: (
                    this.state.selectedTags.length > 0
                        ? this.state.selectedTags.map((tag) => tag.uuid).filter((uuid): uuid is string => uuid !== undefined)
                        : []
                )
            }
        } else {
            data = {
                name: this.state.newVendingMachineName,
                site: this.state.newVendingMachineLocation,
                pin_code: this.state.newVendingMachinePinCode,
                timezone: this.state.newVendingMachineTimeZone,
                remove_photo: this.state.isRemovePhoto,
                return_short_cut_status: this.state.returnShortCutStatus ?? Ppeemployerv1ReturnShortCutStatus.Enabled,
                default_item_picker_method: this.state.defaultItemPickerMethod ?? Ppeemployerv1ItemPickerMethod.Product,
                tag_uuids: (
                    this.state.selectedTags.length > 0
                        ? this.state.selectedTags.map((tag) => tag.uuid).filter((uuid): uuid is string => uuid !== undefined)
                        : []
                )
            }
        }

        getAdminVendingMachineServiceAPI().adminVendingMachineServiceUpdateVendingMachine(this.props.vendingMachine.uuid ?? "", data).then((response) => {
            if (response.data.success) {
                this.handleCloseModal();
                this.props.pushFlashMessage(new Message("Vending Machine Updated", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        })
    }

    removePhoto(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isRemovePhoto: true
        })
    }

    onSelectFile(e: any) {
        const fileReader = new FileReader();
        fileReader.onerror = (e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (e) => {
            const pictureContent: string = fileReader.result as string;
            this.setState({ pictureEncodedContent: window.btoa(pictureContent) })
        };
        fileReader.readAsBinaryString(e.target.files[0]);
    }

    render() {
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%'
            }
        };
        Modal.setAppElement('#root');
        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.handleCloseModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Edit Vending Machines</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please enter new vending machine details</p>

                <div className={"alert alert-info"}>Please note, the changes on this page may take up to 5 minutes to be applied the vending machine.</div>

                <form>
                    <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                        <div className="form-group">
                            <label htmlFor="name">Vending Machine Name:*</label>
                            <input type="text" onChange={this.handleVendingMachineNameChange.bind(this)} className="form-control"
                                value={this.state.newVendingMachineName} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="employee_number">Vending Machine Location:*</label>
                            <input type="text" onChange={this.handleVendingMachineLocationChange.bind(this)} className="form-control"
                                value={this.state.newVendingMachineLocation} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="employee_number">Vending Machine Express Selection Settings (only available for KioskForce vending
                                machines)</label>
                            <select className="form-control"
                                value={this.state.returnShortCutStatus}
                                onChange={this.handleReturnShortStatusChange.bind(this)}>
                                <option value={Ppeemployerv1ReturnShortCutStatus.Enabled}>Enable Express Selection - employees will be asked to choose
                                    between dispense/return
                                </option>
                                <option value={Ppeemployerv1ReturnShortCutStatus.Disabled}>Disable Express Selection - employees will be redirected to the
                                    dispense page first
                                </option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="employee_number">Default Item Picker:*</label>
                            <select className="form-control"
                                value={this.state.defaultItemPickerMethod}
                                onChange={this.handleTakeItemPageDisPlayModeChange.bind(this)}>
                                <option value={Ppeemployerv1ItemPickerMethod.Product}>Product
                                </option>
                                <option value={Ppeemployerv1ItemPickerMethod.Keypad}>Keypad
                                </option>
                                <option value={Ppeemployerv1ItemPickerMethod.AggregatedProduct}>Category
                                </option>
                                <option value={Ppeemployerv1ItemPickerMethod.Chocolate}>Chocolate Interface
                                </option>
                            </select>
                        </div>
                        <div id="form-roup">
                            <label htmlFor="employee_number">Vending Machine PIN Code:*</label>
                            <input type="text" onChange={this.handleVendingMachinePinCodeChange.bind(this)} className="form-control"
                                value={this.state.newVendingMachinePinCode} required />
                        </div>
                        <div id="form-roup">
                            <label>Timezone</label>
                            <input type={"text"} className="form-control" value={this.state.newVendingMachineTimeZone}
                                onChange={this.handleVendingMachineTimeZoneChange.bind(this)} required />
                        </div>
                        <div className="form-group">
                            <label>Vending Machine Picture (optional)</label>
                            {!this.state.isRemovePhoto && this.props.vendingMachine.photo_url !== null && <div>
                                <img src={this.props.vendingMachine.photo_url} alt={"vending machine"} width={"80"} />
                                <button type="button" className="btn iq-bg-danger"
                                    onClick={this.removePhoto.bind(this)}>Remove
                                </button>
                            </div>}
                            <input type="file" onChange={this.onSelectFile.bind(this)} className={"form-control"} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="tag">Tags:</label>
                            <CustomSelectDropdown selectedTags={this.state.selectedTags} onSelectionChange={this.handleSelectionChange.bind(this)}
                                pushFlashMessage={this.props.pushFlashMessage} />
                        </div>
                    </div>
                    <button onClick={this.updateVendingMachine.bind(this)} className="btn btn-primary">Save</button>
                    <button type="button" className="btn iq-bg-danger"
                        onClick={this.handleCloseModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export { EditVendingMachineModal }
