import React, {Component} from 'react';
import Modal from "react-modal";
import {getAdminTemporaryAccessServiceAPI} from "../global/api";
import {Message} from "../global/models";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import {PpeEmployee} from "../gen";

interface Props {
    isOpen: boolean

    closeAddEmployeeModal(): void

    pushFlashMessage(message: Message): void;

    refreshEmployeeOnAdd(): void

    timezone:string

    employee:PpeEmployee
}

interface State {
    expiryAtDate: Date | null}

class ExtendAccessModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            expiryAtDate: new Date()
        };
    }

    handleCloseModal() {
        this.setState({
            expiryAtDate: new Date()
        })
        this.props.closeAddEmployeeModal()
    }


    updateExpiryTime(event: any) {
        event.preventDefault();
        event.stopPropagation();
        getAdminTemporaryAccessServiceAPI().adminTemporaryAccessServiceExtentTemporaryAccess(this.props.employee.uuid ?? "", {
            new_expiry_epoch:moment(this.state.expiryAtDate).tz(this.props.timezone, true).unix()
        }).then(response => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("temporary access extended", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message??"", "error"))
            }
        }).catch((reason)=>{
            this.props.pushFlashMessage(new Message("Server error, please try again later.", "error"))
        }).finally(()=>{
            this.handleCloseModal()
            this.props.refreshEmployeeOnAdd()
        })
    }

    handleExpiryAtChange(date:Date | null){
        this.setState({expiryAtDate: date}, ()=>{console.log(moment(this.state.expiryAtDate).tz(this.props.timezone, true).unix())});
    }

    render() {
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%'
            }
        };
        Modal.setAppElement('#root');
        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.handleCloseModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Update expiry</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please select the new expiry date</p>
                <form>
                    <div className="form-group">
                        <label htmlFor="startDate">Contact Name</label>
                        <input type="text" className={"form-control"} value={this.props.employee.full_name} disabled={true}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="startDate">Temporary Email</label>
                        <input type="text" className={"form-control"} value={this.props.employee.email??""} disabled={true}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="startDate">Expiry - (Timezone: {this.props.timezone}):</label>
                        <DatePicker className={"form-control"}
                                    selected={this.state.expiryAtDate}
                                    onChange={this.handleExpiryAtChange.bind(this)}
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    wrapperClassName={"datePicker"}
                                    showTimeSelect={true}
                                    isClearable
                        />
                    </div>
                    <button onClick={this.updateExpiryTime.bind(this)} className="btn btn-primary">Update</button>&nbsp;
                    <button type="button" className="btn iq-bg-danger"
                            onClick={this.handleCloseModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export {ExtendAccessModal}
