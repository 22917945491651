import React, { Component } from 'react';
import Modal from "react-modal";
import {PpeLockerInformation} from "../gen";
import {customStyles, downloadQrCode} from "../qr_code_utils";


interface Props {
    isOpen: boolean
    closeQrCodeModal():void
    locker:PpeLockerInformation | null
}
interface State {

}

class LockerQrCodeModal extends Component<Props, State> {
    QRCode = require('qrcode.react');
    constructor(props: Props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const lockerUrlPrefix = "https://ppe-mobile.vendingontrack.com/locker?uuid=";
        Modal.setAppElement('#root');
        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.props.closeQrCodeModal.bind(this)}>
            <div className={"row"}>
                <div className={"col-sm"}>
                    <div className="iq-card-header d-flex justify-content-center">
                        <div className="iq-header-title">
                            <h4 className="card-title">Locker QR Code</h4>
                        </div>
                    </div>
                    <div className="iq-card-body d-flex justify-content-center">
                        <div>
                            <this.QRCode
                                id={lockerUrlPrefix + this.props.locker?.uuid}
                                value={lockerUrlPrefix + this.props.locker?.uuid}
                                size={290}
                                level={"H"}
                                includeMargin={true}
                            />
                        </div>
                    </div>
                    <div className="iq-card-body d-flex justify-content-center">
                        <button onClick={()=>{downloadQrCode(lockerUrlPrefix, this.props.locker?.uuid ?? "", this.props.locker?.name ?? "")}} className={"btn btn-info"}> Download QR </button>
                    </div>
                </div>
            </div>
        </Modal>;
    }
}

export {LockerQrCodeModal}
