import React, {useEffect, useState} from 'react';
import {getAdminLockerServiceApi, getTrackableItemsServiceAPI} from "../global/api";
import {PpeCellInformation, PpeLockerInformation, PpeTrackableItem, PpeTrackableItemState} from "../gen";
import {Highlighter, Typeahead} from "react-bootstrap-typeahead";
import Modal from "react-modal";
import {Link} from "react-router-dom";


interface Props{
    isOpen:boolean
    locker: PpeLockerInformation
    cell: PpeCellInformation
    reload(): void
    toggleTrackableItemModal(): void
}

function AddTrackableItemToCellModal(props:Props) {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [page, setPage] = useState(1);
    const [items, setItems] = useState<PpeTrackableItem[]>([]);
    const [selection, setSelection] = useState(props.cell.trackable_item_uuid);

    let selectedTrackableItem = null;
    if (selection) {
        selectedTrackableItem = items.find((item)=>{return item.uuid === selection})
    }
    const selected = selectedTrackableItem ? [selectedTrackableItem] : [];

    const onChange = (selection: Array<PpeTrackableItem>) => {
        if (selection.length > 0) {
            if (selection[0].state !== PpeTrackableItemState.Inventory){
                return;
            }
            setSelection(selection[0].uuid);
        } else {
            setSelection("");
        }
    }

    const renderTrackableItemChildren = (option:PpeTrackableItem, props: any) => {
        return [
            <div className={option.state !== PpeTrackableItemState.Inventory ? "text-danger" : ""}>
                <Highlighter search={props.text}>{`${option.name ?? ""} [Asset tag: ${option.asset_tag ?? ""}; State: ${option.state}]`.toString()}</Highlighter>,
                {option.state !== PpeTrackableItemState.Inventory && " unavailable"}
            </div>
        ];
    };

    const loadTrackableItems = (page:number) => {
        setLoading(true);
        getTrackableItemsServiceAPI().adminTrackableItemServiceListTrackableItems(page, 100).then((resp) => {
            return resp.data
        }).then((data) => {
            if (data.success) {
                if (page === 1){
                    setItems(data.items!);
                } else {
                    setItems(items.concat(data.items!));
                }
                setPage(page + 1)
            } else {
                window.alert(data.error_message);
            }
        }).catch(()=>{
            window.alert("Server error, try again later");
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        loadTrackableItems(1);
    }, [])

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%'
        }
    };
    Modal.setAppElement('#root');

    const handleCloseModal = (e:any)=>{
        e.preventDefault();
        loadTrackableItems(1);
        props.toggleTrackableItemModal();
    }

    const assignTrackableItem = (e: any)=>{
        e.preventDefault();
        if (!saving){
            setSaving(true);
            if (selection !== props.cell.trackable_item_uuid){
                getAdminLockerServiceApi().adminLockerServiceRefillTrackableItemToCell({
                    locker_uuid:props.locker.uuid,
                    cell_uuid:props.cell.uuid,
                    trackable_item_uuid:selection
                }).then((response)=>{
                    if (response.data.success) {
                        props.reload()
                        props.toggleTrackableItemModal();
                    } else {
                        window.alert(response.data.error_message);
                    }
                }).catch(()=>{
                    window.alert("Server error, please try again later");
                }).finally(()=>{
                    setSaving(false);
                })
            } else {
                props.toggleTrackableItemModal();
            }
        }
    }

    return (
        <Modal isOpen={props.isOpen} style={customStyles} onRequestClose={handleCloseModal}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Trackable item in [locker: {props.locker.name},  col: {props.cell.col}, row: {props.cell.row}]</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <Link to={"/trackable_items"} className="">Go to Trackable Items Page</Link>

                <form>
                    <div className="form-group">
                        <label htmlFor="name">Trackable Item:</label>
                        <Typeahead id={"typeahead"}
                                   options={items.filter((item)=>{return item.product_uuid === props.cell.product_uuid})}
                                   labelKey={(option) => {return `${option.name ?? ""} [Asset tag: ${option.asset_tag ?? ""}]`.toString()}}
                                   renderMenuItemChildren={renderTrackableItemChildren}
                                   paginate={true}
                                   onPaginate={()=>{loadTrackableItems(page)}}
                                   paginationText={"load more trackable item"}
                                   selected={selected}
                                   onChange={onChange}
                                   maxResults={99}
                                   clearButton
                        />
                    </div>


                    <button onClick={assignTrackableItem} className={"btn btn-info"}>Update</button>
                    &nbsp;
                    <button onClick={handleCloseModal} className={"btn btn-normal"}>Cancel</button>
                </form>
            </div>
        </Modal>
    );
}

export default AddTrackableItemToCellModal;
