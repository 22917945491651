import React, {useEffect} from "react";

import {Helmet} from "react-helmet";
import {getAdvancedReporting} from "../../global/api";
import {PpeReportTemplate} from "../../gen";
import moment from "moment";
import {Link} from "react-router-dom";


function NewTemplateForm(props: { onSave: () => void }) {
    const [name, setName] = React.useState<string>("");
    const [busy, setBusy] = React.useState<boolean>(false);

    const [filename, setFilename] = React.useState<string>("");
    const [contentBs64, setContentBs64] = React.useState<string>("");

    const create = () => {
        setBusy(true);

        getAdvancedReporting().advancedReportingServiceCreateTemplate({
            content_bs64: contentBs64,
            template_name: name,
            filename: filename,
        }).then((response) => {
            console.log(`response`, response);
            props.onSave();
        }).catch(() => {
            window.alert("Server error")
        }).finally(() => {
            setBusy(false);
        })
    }

    const pickFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            return;
        }

        setFilename(file.name);

        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target?.result;

            const b64 = content as string;

            setContentBs64(b64);
        }

        reader.readAsDataURL(file);
    }

    return <div className="container">

        <form className="form" onSubmit={(e) => {
            create();
            e.preventDefault()
        }}>
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
            </div>

            <div className={"form-group"}>
                <label>Template</label>
                <input onChange={pickFile} type="file" className="form-control"/>
            </div>

            <button disabled={busy} type="submit" className="btn btn-primary">Create</button>
        </form>
    </div>
}

export function TemplatesPage() {
    const [busy, setBusy] = React.useState<boolean>(false);

    const [templates, setTemplates] = React.useState<PpeReportTemplate[]>([]);

    const [showNewTemplateForm, setShowNewTemplateForm] = React.useState<boolean>(false);

    const toggleNewTemplateForm = () => {
        setShowNewTemplateForm(!showNewTemplateForm);
    }

    const loadTemplates = () => {
        setBusy(true);

        getAdvancedReporting().advancedReportingServiceListTemplates({}).then((response) => {
            setTemplates(response.data.templates ?? []);
        }).catch((e) => {
            window.alert(`Failed to load templates: ${e}`);
        }).finally(() => {
            setBusy(false);
        });
    }

    useEffect(() => {
        loadTemplates();
    }, []);

    if (busy) {
        return <div id="content-page" className="content-page">
            <h1>Loading</h1>
        </div>
    }

    const deleteTemplate = (uuid: string) => {
        getAdvancedReporting().advancedReportingServiceRemoveTemplate({
            template_uuid: uuid
        }).then(() => {
            loadTemplates();
        }).catch((e) => {
            if (e.response.data.message) {
                window.alert(`Failed to delete template: ${e.response.data.message}`);
            } else {
                window.alert(`Failed to delete template: ${e.message}`);
            }
        }).finally(() => {
        });
    }

    const previewTemplate = (uuid: string) => {
        getAdvancedReporting().advancedReportingServiceRunReport({
            template_uuid: uuid,
            start_date: moment().subtract(7, 'd').toISOString(true),
            end_date: moment().toISOString(true),
        }).then((response) => {
            const url = response.data.report_url;

            window.open(url, "_blank");
        }).catch((e) => {
            window.alert(`Failed to preview template: ${e}`);
        }).finally(() => {

        })
    }

    return <div id="content-page" className="content-page">
        <Helmet>
            <title>Report Templates</title>
        </Helmet>
        <div className="container-fluid">
            <div className={"iq-card"}>
                <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                        <h4 className="card-title">Templates</h4>
                    </div>


                    <div className="iq-card-header-toolbar d-flex align-items-center">
                        <button onClick={toggleNewTemplateForm} className="btn btn-primary">New Template</button>
                    </div>
                </div>
                <div className="iq-card-body">
                    {showNewTemplateForm &&
                        <NewTemplateForm onSave={loadTemplates}/>
                    }

                    <table className="table table-striped table-bordered mt-4">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Filename</th>
                            <th>Created At</th>
                            <th>Operations</th>
                        </tr>
                        </thead>
                        <tbody>
                        {templates.map((template) => {
                            return <tr key={template.uuid}>
                                <td>{template.name}</td>
                                <td>{template.original_filename}</td>
                                <td>{moment(template.created_at, moment.defaultFormat).format()}</td>
                                <td>
                                    <button className={"btn btn-danger"} onClick={(e) => {
                                        e.preventDefault();
                                        // confirmation
                                        if (!window.confirm("Are you sure?")) {
                                            return;
                                        }
                                        deleteTemplate(template.uuid ?? "");
                                    }}>Delete
                                    </button>

                                    &nbsp;
                                    &nbsp;

                                    <button className={"btn btn-primary"} onClick={(e) => {
                                        e.preventDefault();
                                        previewTemplate(template.uuid ?? "");
                                    }}>Preview</button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>

                    <hr />
                    <a href="/report-templates/Template09.07.2024.xlsx">Download Report Template</a>
                </div>
            </div>
        </div>
    </div>;
}
