import React, {useEffect, useState} from 'react';
import {getAdminOrderServiceAPI} from "../global/api";
import {Helmet} from "react-helmet";
import ReactPaginate from "react-paginate";
import {withGlobalData} from "../global/global-data";
import {DatetimeDisplay} from "../global/datetime_display";
import {PpeOrderInfo, PpeOrderState} from "../gen";

function FailedOrderPage(props:any) {
    const qs = require('qs');
    const params = qs.parse(props.location.search.slice(1));
    const [orders, setOrders] = useState<Array<PpeOrderInfo>>([]);
    const [currentPage, setCurrentPage] = useState<number>(params['page'] !== "" ? params['page'] !== undefined ? parseInt(params['page'])-1 : 0 : 0);
    const [pageCount, setPageCount] = useState<number>(0);

    const reloadFailedOrder = () => {
        getAdminOrderServiceAPI().adminOrderServiceListOrders({
            states:[PpeOrderState.Failure, PpeOrderState.Aborted],
            page: currentPage+1,
            per: 50
        }).then((response)=>{
            if (response.data.success){
                setOrders(response.data.orders ?? []);
                setPageCount(response.data.total_page ?? 1);
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(reason => {
            window.alert("Server error")
        })
    }

    useEffect(reloadFailedOrder, [currentPage])

    const rows = orders.map((order) => {
        return <tr key={order.uuid}>
            <td>{order.txn_ref}</td>
            <td>
                <DatetimeDisplay timezone={props.timezone} timestamp={order.created_epoch ?? 1}/>
            </td>
            <td>
                {order.employee != null ? order.employee.full_name : ""}
            </td>
            {order.selection != null && <td>
                <span>{order.product_name != null ? order.product_name : ""}</span>
                <span style={{fontSize:"0.8em"}}>[selection: {order.selection}]</span>
            </td>}
            {order.selection == null && <td>

            </td>}
            <td>{order.vending_machine_name}</td>
        </tr>
    })

    const handlePageChange = (selectedItem: { selected: number })=>{
        setCurrentPage(selectedItem.selected)
        reloadFailedOrder()
        handleParamsChange("page", (selectedItem.selected + 1).toString())
    }

    const handleParamsChange= (currentParam: string, newValue:string)=>{
        const qs = require('qs');
        let params = qs.parse(props.location.search.slice(1));
        params[currentParam] = newValue;
        const keys = Object.keys(params);
        let paramsStr = "?";
        keys.forEach((key)=>{
            paramsStr = paramsStr + key + "=" + params[key] + "&"
        })
        props.history.push(paramsStr);
    }


    return (
        <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Failed Order - " + props.company + " - " + props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Failed Orders</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div>
                                    <table id="datatable" className="table table-striped table-bordered">
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">TxnRef</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Item</th>
                                            <th scope="col">Vending Machine</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    initialPage={currentPage}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination mb-0"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item page-link"}
                                    nextClassName={"page-item page-link"}
                                    activeClassName={"active"} forcePage={currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withGlobalData(FailedOrderPage);
