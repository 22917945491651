import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { IGlobalDataContext, withGlobalData } from "../global/global-data";
import { getAdminTagServiceAPI } from "../global/api";
import { Message } from "../global/models";
import { V1AdminTag } from "../gen/typescript-axios";
import { useLocation } from "react-router-dom";
import { CreateOrEditTagModal } from './tag_modal';
import { sortTagsByName } from '../custom_select_dropdown/custom_select_dropdown';

interface Props extends IGlobalDataContext {

}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function _TagsPage(props: Props) {
    let query = useQuery();
    const [isTagModalOpen, setIsTagModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tags, setTags] = useState<V1AdminTag[]>([]);
    const [selectTag, setSelectTag] = useState<V1AdminTag | null>(null);

    useEffect(() => {
        reloadTags();
    }, []);

    const reloadTags = () => {
        setIsLoading(true);

        getAdminTagServiceAPI().adminTagServiceListTags({
        }).then((response) => {
            if (response.data.success ?? false) {
                setTags(response.data.tags ?? []);
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"));
            }
        }).catch(() => {
            props.pushFlashMessage(new Message("Server error", "error"));
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const removeTag = (e: React.MouseEvent<HTMLButtonElement>, tag: V1AdminTag) => {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm(`Are you sure to archive tag ${tag.name}`)) {
            getAdminTagServiceAPI().adminTagServiceRemoveTag(tag.uuid ?? '', {}).then(response => {
                reloadTags();
                if (response.data.success) {
                    props.pushFlashMessage(new Message("Tag archived", "success"))
                } else {
                    props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch((e) => {
                props.pushFlashMessage(new Message("Server error", "error"))
            })
        }
    }
    const editTag = (tag: V1AdminTag) => {
        setSelectTag(tag);
        setIsTagModalOpen(true);
    }

    const createTag = () => {
        setSelectTag(null);
        setIsTagModalOpen(true);
    }

    const rows = sortTagsByName(tags).map((tag, index) => {
        return (
            <tr key={index}>
                <td>{tag.name}</td>
                <td>{tag.admin_note}</td>
                <td>
                    <div className="row">
                        <div className="col-lg-3">
                            <button className={"btn btn-warning"} onClick={(e) => editTag(tag)}>Edit</button>
                        </div>
                        <div className="col-lg-3">
                            <button className={"btn btn-danger"} onClick={(e) => removeTag(e, tag)}>Archive
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
        )
    })

    return (
        <Fragment>
            <Helmet>
                <title>{"Tags - " + props.company + " - " + props.globalTitle}</title>
            </Helmet>
            <>
                {isTagModalOpen && <CreateOrEditTagModal isOpen={(isTagModalOpen)} closeModal={() => setIsTagModalOpen(false)} tag={selectTag} reloadTags={reloadTags} />}
                <div id="content-page" className="content-page">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="iq-card">
                                    <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Tags Management</h4>
                                            <h6>Tags are used to control employees' access to resources (vending machines and products). Please refer to the user manual.</h6>
                                        </div>
                                        <div>
                                            <span className="btn btn-primary btn-sm" onClick={(e) => createTag()}>
                                                Create New Tag
                                            </span>
                                        </div>
                                    </div>
                                    <div className="iq-card-body">
                                        <div className="iq-card-body">
                                            <table
                                                className="table table-bordered table-responsive-md table-striped text-center"
                                                id="user_table">
                                                <thead>
                                                    <tr>
                                                        <th>Tag</th>
                                                        <th>Notes</th>
                                                        <th>Operation</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Fragment>
    );
}

export const TagsPage = withGlobalData(_TagsPage);
