import React, {Component} from 'react';
import {getAdminTransactionServiceApi} from "../global/api";
import {CSVLink} from "react-csv";
import moment from "moment";
import {Aggregation} from "../global/models";
// import {PpeTransactionAggregation} from "../gen";

import {V1TransactionAggregation} from "../gen/typescript-axios";


interface Props {
    postAttributes : {}
    aggBy:Aggregation
}

interface State {
    data:any[]
    ready:boolean
}

class DownloadAggregationCsv extends Component<Props, State> {
    private csvLink: React.RefObject<any>;
    constructor(props: Props) {
        super(props);

        this.state = {
            data : [],
            ready: false,
        };
        this.csvLink = React.createRef();
    }

    getData(){
        getAdminTransactionServiceApi().adminTransactionServiceGetAggregatedTransaction(this.props.postAttributes).then((response)=>{
            if (response.data.aggregate_by === V1TransactionAggregation.AggregatedByMachine){
                this.setState({
                    data: response.data.aggregated_by_machine_transaction ?? []
                }, ()=>{
                    this.setState({ready:true}, ()=>{this.csvLink.current.link.click();})
                })
            }
            if (response.data.aggregate_by === V1TransactionAggregation.AggregatedByProduct){
                this.setState({
                    data: response.data.aggregated_by_product_transaction ?? []
                }, ()=>{
                    this.setState({ready:true}, ()=>{this.csvLink.current.link.click();})
                })
            }
            if (response.data.aggregate_by === V1TransactionAggregation.AggregatedByEmployee){
                this.setState({
                    data: response.data.aggregated_by_employee_transaction ?? []
                }, ()=>{
                    this.setState({ready:true}, ()=>{this.csvLink.current.link.click();})
                })
            }
        })
    }

    prepareCsvHeader(){
        if (this.props.aggBy === Aggregation.VENDINGMACHINE){
            return [
                {label: 'Vending Machine', key: 'machine_name'},
                {label: 'Count', key: 'count'},
            ]
        }
        else if (this.props.aggBy === Aggregation.ITEM){
            return [
                {label: 'Product', key: 'product_name'},
                {label: 'Count', key: 'count'},
            ]
        }
        else if (this.props.aggBy === Aggregation.EMPLOYEE){
            return [
                {label: 'Employee', key: 'employee_name'},
                {label: 'Count', key: 'count'},
            ]
        }
    }

    render() {
        return <div>
            <button className="btn btn-primary btn-sm" onClick={this.getData.bind(this)}>Download as CSV</button>

            <CSVLink
                data={this.state.data} headers={this.prepareCsvHeader()}
                filename={'aggregate_by_' + this.props.aggBy + "_" + moment().unix()+'.csv'}
                className="hidden"
                ref={this.csvLink}
                target="_blank"
            />
        </div>
    }

}

export {DownloadAggregationCsv}
