export interface DailyStats {
    date: string,
    count: number
}

export interface ProductStats {
    product_name: string,
    count: number
}

export interface Transaction {
    uuid: string,
    vending_machine_uuid: string,
    mdb_item_number: number,
    txn_ref: string,
    selection_name: string,
    employee_uuid: string,
    employee_name: string,
    key_card_number: string,
    timestamp: number,
    product_uuid: string | null,
    product_name: string,
}

export interface Category {
    uuid: string
    name: string
    company_uuid: string
}

export interface Product {
    name: string
    uuid: string
    company_uuid: string
    placeholder: boolean
    category_uuid: string | null

    servicenow_sysid: string | null

    photo_url:string | null

    trackable: boolean
}

export interface VendingMachine {
    name: string
    company_uuid: number
    site: string
    uuid: string
    status: string | null,
    photo_url:string | null,
}

export interface Employee {
    uuid: string
    name: string
    employee_number: string | null
    current: boolean
    company_uuid: string
    email: string | null,
    secondary_emails : string[]
    keycards: Keycard[]
    authed_categories: Category[]
    is_authed_all_products: boolean
    is_tmp_access: boolean
    expiry_at:number
}

export interface ProductMap {
    uuid: string
    vending_machine_uuid: string
    product_uuid: string
    mdb_code: number
    low_stock_alert:number
    capacity: number
    restock_count: number
    consumed: number
    trackable_item_uuid:string
}

export interface Keycard {
    key_card_number: string
    employee_uuid: string
    uuid: string
}

export interface Authentication {
    userType?: UserType;
    authenticated: boolean | null; // null means authentication is loading
    email?: string;
    authentication_token?: string;
    organization_selected?: boolean
}

export interface KeyCardTapEvent {
    uuid: string;
    vending_machine_uuid: string;
    employee: Employee | null;
    key_card_number: string;
    timestamp: number;
}

export interface AuditLog {
    timestamp: number;
    action: string;
    admin_name: string;
    summary: string;
}

export interface Admin {
    name: string;
    email: string;
    is_enabled: boolean;
    timestamp: number | null;
    is_tfa_enabled: boolean;
}

export interface TransactionForCsv {
    uuid: string,
    vending_machine_uuid: string,
    vending_machine_name: string,
    vending_machine_site: string,
    mdb_item_number: number,
    txn_ref: string,
    selection_name: string,
    employee_uuid: string,
    employee_name: string,
    employee_number: string,
    key_card_number: string,
    product_uuid: string,
    product_name: string,
    product_category: string,
    timestamp: number
}

export enum Aggregation {
    NONE = 'NONE',
    EMPLOYEE = 'EMPLOYEE',
    VENDINGMACHINE = 'VENDINGMACHINE',
    ITEM = 'ITEM',
}

export enum OrderSequence {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum OrderBy {
    NAME = 'NAME',
    COUNT = 'COUNT',
}

export interface AggregatedTransaction {
    employee_uuid: string | null,
    employee_name: string | null
    vending_machine_uuid: string | null,
    vending_machine_name: string | null,
    product_uuid: string | null,
    product_name: string | null,
    total: number
}

export class Message {
    message: string;
    cls: string;

    constructor(message: string, cls: string) {
        this.message = message;
        this.cls = cls;
    }
}

export enum UserType {
    ADMIN = 'ADMIN',
    EMPLOYEE = 'EMPLOYEE',
    PARTNER = 'PARTNER'
}

export interface PickupMethods {
    is_mobile_app_allowed: boolean,
    is_mifare_card_allowed: boolean
}


export interface MicrosoftSsoInformation {
    display_name: string
    email: string
    principal: string
}

export interface ProductSync {
    created_at: number,
    finished: boolean,
    total_products: number,
    processed_products: number,
    added: number,
    removed: number,
}

export interface Order {
    id: number,
    created_at: number,
    state:string,
    selection: Selection | null,
    vending_machine:VendingMachine,
    txn_ref: string
    employee: Employee | null

}

export interface Selection{
    selection: string,
    name: string | null,
    photo_url: string | null
}

export interface Session{
    created_at: number,
    last_access_time: number,
    user_agent: string,
    ip: string,
    source: Source | null
}

export enum Source{
    WEB = 'WEB',
    APP = 'APP',
    ALL = 'ALL'
}

export interface TrackableItem {
    uuid : string,
    name : string,
    timestamp: number,
    product: Product,
    tag : string | null,
}




