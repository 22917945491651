import React, {Component} from 'react';
import Modal from "react-modal";
import {
    getAdminProductServiceAPI,
    getAdminVendingMachineServiceAPI,
} from "../../global/api";
import {Message} from "../../global/models";
import {AsyncTypeahead, Highlighter,Typeahead} from 'react-bootstrap-typeahead';
import "react-datepicker/dist/react-datepicker.css";
import {PpeProductInfo, PpeTrackableItem} from "../../gen";

interface Props {
    isOpen: boolean
    closeAddProductMapModal():void
    reloadProductMap():void
    pushFlashMessage(message: Message): void;
    vendingMachineUuid:string
    allTrackableItems:PpeTrackableItem[]| undefined
}

interface State {
    productUuid : string | null;
    mdbCode : number | undefined
    lowStockAlert:number|undefined
    isSaving: boolean,
    selectedProduct: PpeProductInfo | null
    productOptions: PpeProductInfo[]
    isproductOptionsLoading: boolean
    selectedTrackableItem:PpeTrackableItem|null
}

class AddProductMapModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            productUuid : "",
            mdbCode: undefined,
            lowStockAlert:2,
            isSaving: false,
            selectedProduct: null,
            productOptions: [],
            isproductOptionsLoading: false,
            selectedTrackableItem:null,
        };
    }

    closeModal(){
        this.setState({
            productUuid : "",
            mdbCode: undefined,
            lowStockAlert: 2,
        })
        this.props.closeAddProductMapModal()
    }


    addProductMap(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isSaving: true
        })
        getAdminVendingMachineServiceAPI().adminVendingMachineServiceCreateProductMap(this.props.vendingMachineUuid, {
            mdb_code: this.state.mdbCode,
            product_uuid:this.state.productUuid ?? "",
            low_stock_alert:this.state.lowStockAlert ?? 2,
            trackable_item_uuid:this.state.selectedTrackableItem?.uuid ?? "",
        }).then(response => {
            if (response.data.success) {
                this.props.closeAddProductMapModal();
                this.props.reloadProductMap();
                this.props.pushFlashMessage(new Message("Product Map added", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(reason => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
        }).finally(()=>{
            this.setState({
                isSaving: false,
                selectedTrackableItem : null
            })
        })
    }

    onProductChange(products: Array<PpeProductInfo>) {
        if (products.length > 0) {
            this.setState({productUuid: products[0].uuid ?? null, selectedProduct:products[0]});
        } else {
            this.setState({productUuid:null, selectedProduct: null})
        }
    }

    onMdbCodeChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({mdbCode:parseInt(e.target.value)})
    }

    onLowStockAlertChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({lowStockAlert:parseInt(e.target.value)})
    }

    onSearchProduct(query:string){
        this.setState({isproductOptionsLoading: true});
        getAdminProductServiceAPI().adminProductServiceSearchProducts({
            only_current:true,
            term: query,
            per_page:10000,
            page: 1
        }).then((response)=>{
            if (response.data.success){
                this.setState({
                    productOptions:response.data.products ?? []
                })
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(() => {
            window.alert("Server error")
        }).finally(()=>{
            this.setState({
                isproductOptionsLoading:false,
            })
        })
    }

    handleTrackableItemChange(selectTrackableItems:Array<PpeTrackableItem>){
        if (selectTrackableItems.length > 0) {
            this.setState({ selectedTrackableItem:selectTrackableItems[0]});
        } else {
            this.setState({ selectedTrackableItem: null})
        }
    }

    render() {
        const customStyles = {
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)',
                width:'50%'
            }
        };
        Modal.setAppElement('#root');

        const renderProductItemChildren = (option: PpeProductInfo, props:any) => {
            return [
                <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
            ];
        };

        const selectedProduct = this.state.selectedProduct ? [this.state.selectedProduct] : [];

        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.closeModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Add Product Map</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <form>
                    <div className="form-group">
                        <label>Product Name:*</label>
                        <AsyncTypeahead
                            id={"typeahead"}
                            isLoading={this.state.isproductOptionsLoading}
                            labelKey={(option)=>{
                                return option.name ?? ""
                            }}
                            onSearch={this.onSearchProduct.bind(this)}
                            selected={selectedProduct}
                            onChange={this.onProductChange.bind(this)}
                            options={this.state.productOptions}
                            renderMenuItemChildren={renderProductItemChildren}
                            clearButton
                        />
                    </div>

                    {this.state.selectedProduct?.is_trackable && this.props.allTrackableItems &&  <div className="form-group" >
                        <label>Trackable Item:*</label>
                        <Typeahead id={"trackable item"}
                                   onChange={this.handleTrackableItemChange.bind(this)}
                                   defaultInputValue=""
                                   labelKey={(trackableItem)=>{
                                       if(trackableItem !== undefined){
                                           return trackableItem.name as string + " [" + trackableItem.state + "]"
                                       }else{
                                           return ""
                                       }
                                   }}
                                   options={this.props.allTrackableItems}
                                   filterBy={(trackableItem) => {
                                       return trackableItem.product_uuid === this.state.productUuid
                                   }}
                        />
                    </div>}

                    <div className="form-group">
                        <label>MDB Code:*</label>
                        <input type="number" className={"form-control"} value={this.state.mdbCode} onChange={this.onMdbCodeChange.bind(this)}/>
                    </div>

                    <div className="form-group">
                        <label>Low Stock Alert:*</label>
                        <input type="number" className={"form-control"} value={this.state.lowStockAlert} onChange={this.onLowStockAlertChange.bind(this)}/>
                    </div>
                    <button onClick={this.addProductMap.bind(this)} className="btn btn-primary">Save</button>
                    <button type="button" className="btn iq-bg-danger"
                            onClick={this.closeModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export {AddProductMapModal}
