import React from "react";
import {Helmet} from "react-helmet";


export const TosPage = function () {
    return <div id="content-page" className="">
        <Helmet>
            <title>Terms and Conditions of use</title>
        </Helmet>
        <div className="container">
            <p> MOBILE APP TERMS AND CONDITIONS OF USE</p>

            <p> 1. About the Application</p>

            <p>1.1.Welcome to PPE Vending (the 'Application'). The Application provides you with an opportunity to
                browse and purchase various products that have been listed for sale through the Application (the
                'Products '). The Application provides this service by way of granting you access to the content on the
                Application (the 'Purchase Services ').</p>

            <p>1.2.The Application is operated by VENDING ON TRACK PTY LTD (ABN 16 633 901 809 ). Access to and use of
                the Application, or any of its associated Products or Purchase Services, is provided by VENDING ON TRACK
                PTY LTD. Please read these terms and conditions (the 'Terms ') carefully. By using, browsing and/or
                reading the Application, this signifies that you have read, understood and agree to be bound by the
                Terms. If you do not agree with the Terms, you must cease usage of the Application, or any of its
                Purchase Services, immediately.</p>

            <p>1.3.VENDING ON TRACK PTY LTD reserves the right to review and change any of the Terms by updating this
                page at its sole discretion. When VENDING ON TRACK PTY LTD updates the Terms, it will use reasonable
                endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate
                effect from the date of their publication. Before you continue, we recommend you keep a copy of the
                Terms for your records.</p>

            <p> 2. Acceptance of the Terms</p>

            <p>You accept the Terms by using or browsing the Application. You may also accept the Terms by clicking to
                accept or agree to the Terms where this option is made available to you by VENDING ON TRACK PTY LTD in
                the user interface.</p>

            <p> 3. Registration to use the Purchase Services</p>

            <p>3.1.In order to access the Purchase Services, you must first register as a user of the Application. As
                part of the registration process, or as part of your continued use of the Purchase Services, you may be
                required to provide personal information about yourself (such as identification or contact details),
                including:</p>

            <p> (a) Email address</p>

            <p>3.2. You warrant that any information you give to VENDING ON TRACK PTY LTD in the course of completing
                the registration process will always be accurate, correct and up to date.</p>

            <p>3.3. Once you have completed the registration process, you will be a registered member of the Application
                ('Member ') and agree to be bound by the Terms. As a Member you will be granted immediate access to the
                Purchase Services.</p>

            <p>3.4. You may not use the Purchase Services and may not accept the Terms if:</p>

            <p> (a) you are not of legal age to form a binding contract with VENDING ON TRACK PTY LTD; or</p>

            <p> (b) you are a person barred from receiving the Purchase Services under the laws of Australia or other
                countries including the country in which you are resident or from which you use the Purchase
                Services.</p>

            <p> 4. Your obligations as a Member</p>

            <p> (a) As a Member, you agree to comply with the following:</p>

            <p>You will use the Purchase Services only for purposes that are permitted by:</p>

            <p> (i) the Terms;</p>

            <p> (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant
                jurisdictions;</p>

            <p> (b) you have the sole responsibility for protecting the confidentiality of your password and/or email
                address. Use of your password by any other person may result in the immediate cancellation of the
                Purchase Services;</p>

            <p> (c) any use of your registration information by any other person, or third parties, is strictly
                prohibited. You agree to immediately notify VENDING ON TRACK PTY LTD of any unauthorised use of your
                password or email address or any breach of security of which you have become aware;</p>

            <p> (d) access and use of the Application is limited, non-transferable and allows for the sole use of the
                Application by you for the purposes of VENDING ON TRACK PTY LTD providing the Purchase Services;</p>

            <p> (e) you will not use the Purchase Services or Application for any illegal and/or unauthorised use which
                includes collecting email addresses of Members by electronic or other means for the purpose of sending
                unsolicited email or unauthorised framing of or linking to the Application;</p>

            <p> (f) you agree that commercial advertisements, affiliate links, and other forms of solicitation may be
                removed from the Application without notice and may result in termination of the Purchase Services.
                Appropriate legal action will be taken by VENDING ON TRACK PTY LTD for any illegal or unauthorised use
                of the Application; and</p>

            <p> (g) you acknowledge and agree that any automated use of the Application or its Purchase Services is
                prohibited.</p>

            <p> 5. Purchase of Products and Returns Policy</p>

            <p> (a) In using the Purchase Services to purchase the Product through the Application, you will agree to
                the payment of the purchase price listed on the Application for the Product (the 'Purchase Price').</p>

            <p> (b) Payment of the Purchase Price may be made through one of the following third party providers: - (the
                'Payment Gateway Providers')</p>

            <p>In using the Purchase Services, you warrant that you have familiarised yourself with, and agree to be
                bound by, the applicable Terms and Conditions of Use, Privacy Policy and other relevant legal
                documentation provided by the Payment Gateway Providers.</p>

            <p> (c) Following payment of the Purchase Price being confirmed by VENDING ON TRACK PTY LTD, you will be
                issued with a receipt to confirm that the payment has been received and VENDING ON TRACK PTY LTD may
                record your purchase details for future use.</p>

            <p> (d) VENDING ON TRACK PTY LTD may, at their sole discretion, provide a refund on the return of the
                Products within 28 days where the Product packaging is unopened and remains in a saleable condition. You
                acknowledge and agree that you are liable for any postage and shipping costs associated with any refund
                pursuant to this clause.</p>

            <p> 6. Warranty</p>

            <p> (a) VENDING ON TRACK PTY LTD's Products come with guarantees that cannot be excluded under the
                Australian Consumer Law. You are entitled to a replacement or refund for a major failure of the Product
                and compensation for any other reasonably foreseeable loss or damage. You are also entitled to have the
                Products repaired or replaced if the Products fail to be of acceptable quality and the failure does not
                amount to a major failure (the 'Warranty ').</p>

            <p> (b) You may make a claim under this clause (the 'Warranty Claim') for material defects and workmanship
                in the Products within 12 from the date of purchase (the 'Warranty Period').</p>

            <p> (c) In order to make a Warranty Claim during the Warranty Period, you must provide proof of purchase to
                VENDING ON TRACK PTY LTD showing the date of purchase of the Products, provide a description of the
                Products and the price paid for the Products by sending written notice to VENDING ON TRACK PTY LTD at
                Suite 2.10 56 Delhi Rd, Macquarie Park, New South Wales, 2113 or by email at
                contact@vendingontrack.com.</p>

            <p> (d) Where the Warranty Claim is accepted then VENDING ON TRACK PTY LTD will, at its sole discretion,
                either repair or replace any defective Products or part thereof with a new or remanufactured equivalent
                during the Warranty Period at no charge to you for parts or labour. You acknowledge and agree that you
                will be solely liable for any postage or shipping costs incurred in facilitating the Warranty Claim.</p>

            <p> (e) The Warranty shall be the sole and exclusive warranty granted by VENDING ON TRACK PTY LTD and shall
                be the sole and exclusive remedy available to you in addition to other rights and under a law in
                relation to the Products to which this warranty relates.</p>

            <p> (f) All implied warranties including the warranties of merchantability and fitness for use are limited
                to the Warranty Period.</p>

            <p> (g) The Warranty does not apply to any appearance of the supplied Products nor to the additional
                excluded items set forth below nor to any supplied Products where the exterior of which has been damaged
                or defaced, which has been subjected to misuse, abnormal service or handling, or which has been altered
                or modified in design or construction.</p>

            <p> 7. Delivery</p>

            <p> (a) You acknowledge that the Purchase Services offered by VENDING ON TRACK PTY LTD integrate delivery
                (the 'Delivery Services') through the use of third party delivery companies (the 'Delivery Service
                Providers').</p>

            <p> (b) In providing the Purchase Services, VENDING ON TRACK PTY LTD may provide you with a variety of
                delivery and insurance options offered as part of the Delivery Services by the Delivery Service
                Providers. You acknowledge and agree that VENDING ON TRACK PTY LTD is not the provider of these delivery
                and insurance options and merely facilitates your interaction with the Delivery Service Providers in
                respect to providing the Delivery Services.</p>

            <p> (c) In the event that an item is lost or damaged in the course of the Delivery Services, VENDING ON
                TRACK PTY LTD asks that you:</p>

            <p> (a) contact the Delivery Service Provider directly to request a refund or to claim on any insurance
                options available; and</p>

            <p> (b) contact us by sending an email to ...... outlining in what way the Products were damaged in transit
                so we are able to determine if the Delivery Service Provider should be removed from the Purchase
                Services.</p>

            <p> 8. Copyright and Intellectual Property</p>

            <p> (a) The Application, the Purchase Services and all of the related products of VENDING ON TRACK PTY LTD
                are subject to copyright. The material on the Application is protected by copyright under the laws of
                Australia and through international treaties. Unless otherwise indicated, all rights (including
                copyright) in the site content and compilation of the Application (including text, graphics, logos,
                button icons, video images, audio clips and software) (the 'Content') are owned or controlled for these
                purposes, and are reserved by VENDING ON TRACK PTY LTD or its contributors.</p>

            <p> (b) VENDING ON TRACK PTY LTD retains all rights, title and interest in and to the Application and all
                related content. Nothing you do on or in relation to the Application will transfer to you:</p>

            <p> (i) the business name, trading name, domain name, trade mark, industrial design, patent, registered
                design or copyright of VENDING ON TRACK PTY LTD; or</p>

            <p> (ii) the right to use or exploit a business name, trading name, domain name, trade mark or industrial
                design; or</p>

            <p> (iii) a system or process that is the subject of a patent, registered design or copyright (or an
                adaptation or modification of such a system or process).</p>

            <p> (c) You may not, without the prior written permission of VENDING ON TRACK PTY LTD and the permission of
                any other relevant rights owners: broadcast, republish, upload to a third party, transmit, post,
                distribute, show or play in public, adapt or change in any way the Content or third party content for
                any purpose. This prohibition does not extend to materials on the Application, which are freely
                available for re-use or are in the public domain.</p>

            <p> 9. Privacy</p>

            <p>VENDING ON TRACK PTY LTD takes your privacy seriously and any information provided through your use of
                the Application and/or the Purchase Services are subject to VENDING ON TRACK PTY LTD's Privacy Policy,
                which is available at https://ppe.vendingontrack.com/privacy.</p>

            <p> 10. General Disclaimer</p>

            <p> (a) You acknowledge that VENDING ON TRACK PTY LTD does not make any terms, guarantees, warranties,
                representations or conditions whatsoever regarding the Products other than provided for pursuant to
                these Terms.</p>

            <p> (b) VENDING ON TRACK PTY LTD will make every effort to ensure a Product is accurately depicted on the
                Application, however, you acknowledge that sizes, colours and packaging may differ from what is
                displayed on the Application.</p>

            <p> (c) Nothing in these Terms limits or excludes any guarantees, warranties, representations or conditions
                implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by
                law may not be limited or excluded.</p>

            <p> (d) Subject to this clause, and to the extent permitted by law:</p>

            <p> (i) all terms, guarantees, warranties, representations or conditions which are not expressly stated in
                these Terms are excluded; and</p>

            <p> (ii) VENDING ON TRACK PTY LTD will not be liable for any special, indirect or consequential loss or
                damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an
                applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or
                in connection with the Purchase Services or these Terms (including as a result of not being able to use
                the Purchase Services or the late supply of the Purchase Services), whether at common law, under
                contract, tort (including negligence), in equity, pursuant to statute or otherwise.</p>

            <p> (e) Use of the Application, the Purchase Services, and any of the products of VENDING ON TRACK PTY LTDis
                at your own risk. Everything on the Application, the Purchase Services, and the Products of VENDING ON
                TRACK PTY LTD, are provided to you on an &quot;as is&quot; and &quot;as available&quot; basis, without
                warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents,
                contributors, third party content providers or licensors of VENDING ON TRACK PTY LTDmake any express or
                implied representation or warranty about its Content or any products or Purchase Services (including the
                products or Purchase Services of VENDING ON TRACK PTY LTD) referred to on the Application. This includes
                (but is not restricted to) loss or damage you might suffer as a result of any of the following:</p>

            <p> (i) failure of performance, error, omission, interruption, deletion, defect, failure to correct defects,
                delay in operation or transmission, computer virus or other harmful component, loss of data,
                communication line failure, unlawful third party conduct, or theft, destruction, alteration or
                unauthorised access to records;</p>

            <p> (ii) the accuracy, suitability or currency of any information on the Application, the Purchase Service,
                or any of its Content related products (including third party material and advertisements on the
                Application);</p>

            <p> (iii) costs incurred as a result of you using the Application, the Purchase Services or any of the
                Products;</p>

            <p> (iv) the Content or operation in respect to links which are provided for the User's convenience;</p>

            <p> (v) any failure to complete a transaction, or any loss arising from e-commerce transacted on the
                Application; or</p>

            <p> (vi) any defamatory, threatening, offensive or unlawful conduct of third parties or publication of any
                materials relating to or constituting such conduct.</p>

            <p> 11. Competitors</p>

            <p>If you are in the business of providing similar Services for the purpose of providing them to users for a
                commercial gain, whether business users or domestic users, then you are a competitor of VENDING ON TRACK
                PTY LTD. Competitors are not permitted to use or access any information or content on our Application.
                If you breach this provision, VENDING ON TRACK PTY LTD will hold you fully responsible for any loss that
                we may sustain and hold you accountable for all profits that you might make from such a breach.</p>

            <p> 12. Limitation of Liability</p>

            <p> (a) VENDING ON TRACK PTY LTD's total liability arising out of or in connection with the Purchase
                Services or these Terms, however arising, including under contract, tort (including negligence), in
                equity, under statute or otherwise, will not exceed the most recent Purchase Price paid by you under
                these Terms or where you have not paid the Purchase Price, then the total liability of VENDING ON TRACK
                PTY LTD is the resupply of information or Purchase Services to you.</p>

            <p> (b) You expressly understand and agree that VENDING ON TRACK PTY LTD, its affiliates, employees, agents,
                contributors, third party content providers and licensors shall not be liable to you for any direct,
                indirect, incidental, special consequential or exemplary damages which may be incurred by you, however
                caused and under any theory of liability. This shall include, but is not limited to, any loss of profit
                (whether incurred directly or indirectly), any loss of goodwill or business reputation and any other
                intangible loss.</p>

            <p> (c) VENDING ON TRACK PTY LTD is not responsible or liable in any manner for any site content (including
                the Content and Third Party Content) posted on the Application or in connection with the Purchase
                Services, whether posted or caused by users of the Application of VENDING ON TRACK PTY LTD, by third
                parties or by any of the Purchase Services offered by VENDING ON TRACK PTY LTD.</p>

            <p> (d) You acknowledge that VENDING ON TRACK PTY LTD does not provide the Delivery Services to you and you
                agree that VENDING ON TRACK PTY LTD will not be liable to you for any special, indirect or consequential
                loss or damage, loss of profit or opportunity, or damage to goodwill arising out of or in connection
                with the Delivery Services.</p>

            <p> 13. Termination of Contract</p>

            <p> (i) The Terms will continue to apply until terminated by either you or by VENDING ON TRACK PTY LTD as
                set out below.</p>

            <p> (ii) If you want to terminate the Terms, you may do so by:</p>

            <p> (i) notifying VENDING ON TRACK PTY LTD at any time; and (ii) closing your accounts for all of the
                Purchase Services which you use, where VENDING ON TRACK PTY LTD has made this option available to
                you.</p>

            <p>Your notice should be sent, in writing, to VENDING ON TRACK PTY LTD via the 'Contact Us' link on our
                homepage.</p>

            <p> (iii) VENDING ON TRACK PTY LTD may at any time, terminate the Terms with you if:</p>

            <p> (i) you have breached any provision of the Terms or intend to breach any provision;</p>

            <p> (ii) VENDING ON TRACK PTY LTD is required to do so by law;</p>

            <p> (iii) the partner with whom VENDING ON TRACK PTY LTD offered the Purchase Services to you has terminated
                its relationship with VENDING ON TRACK PTY LTD or ceased to offer the Purchase Services to you;</p>

            <p> (iv) VENDING ON TRACK PTY LTD is transitioning to no longer providing the Purchase Services to Users in
                the country in which you are resident or from which you use the service; or</p>

            <p> (v) the provision of the Purchase Services to you by VENDING ON TRACK PTY LTD is, in the opinion of
                VENDING ON TRACK PTY LTD, no longer commercially viable.</p>

            <p> (iv) Subject to local applicable laws, VENDING ON TRACK PTY LTD reserves the right to discontinue or
                cancel your membership to the Application at any time and may suspend or deny, in its sole discretion,
                your access to all or any portion of the Application or the Purchase Services without notice if you
                breach any provision of the Terms or any applicable law or if your conduct impacts VENDING ON TRACK PTY
                LTD's name or reputation or violates the rights of those of another party.</p>

            <p> (v) When the Terms come to an end, all of the legal rights, obligations and liabilities that you and
                VENDING ON TRACK PTY LTD have benefited from, been subject to (or which have accrued over time whilst
                the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by
                this cessation, and the provisions of this clause shall continue to apply to such rights, obligations
                and liabilities indefinitely.</p>

            <p> 14. Indemnity</p>

            <p> (a) You agree to indemnify VENDING ON TRACK PTY LTD, its affiliates, employees, agents, contributors,
                third party content providers and licensors from and against:</p>

            <p> (i) all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal
                fees on a full indemnity basis) incurred, suffered or arising out of or in connection with any Content
                you post through the</p>

            <p>Application;</p>

            <p> (ii) any direct or indirect consequences of you accessing, using or transacting on the Application or
                attempts to do so and any breach by you or your agents of these Terms; and/or</p>

            <p> (iii) any breach of the Terms.</p>

            <p> 15. Dispute Resolution</p>

            <p>15.1. Compulsory:</p>

            <p>If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court
                proceedings in relation to the dispute, unless the following clauses have been complied with (except
                where urgent interlocutory relief is sought).</p>

            <p>15.2. Notice:</p>

            <p>A party to the Terms claiming a dispute ('Dispute') has arisen under the Terms, must give written notice
                to the other party detailing the nature of the dispute, the desired outcome and the action required to
                settle the Dispute.</p>

            <p>15.3. Resolution:</p>

            <p>On receipt of that notice ('Notice') by that other party, the parties to the Terms ('Parties') must: (a)
                Within 14 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation
                or such other means upon which they may mutually agree;</p>

            <p> (b) If for any reason whatsoever, 14 days after the date of the Notice, the Dispute has not been
                resolved, the Parties must either agree upon selection of a mediator or request that an appropriate
                mediator be appointed by the President of the Conflict Resolution Service or his or her nominee;</p>

            <p> (c) The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of
                the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by
                the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs
                associated with the mediation;</p>

            <p> (d) The mediation will be held in Sydney, Australia.</p>

            <p> 15. 4. Confidential</p>

            <p>All communications concerning negotiations made by the Parties arising out of and in connection with this
                dispute resolution clause are confidential and to the extent possible, must be treated as &quot;without
                prejudice&quot; negotiations for the purpose of applicable laws of evidence.</p>

            <p>15.5. Termination of Mediation:</p>

            <p>If 14 days have elapsed after the start of a mediation of the Dispute and the Dispute has not been
                resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.</p>

            <p> 16. Venue and Jurisdiction</p>

            <p>The Purchase Services offered by VENDING ON TRACK PTY LTD is intended to be viewed by residents of
                Australia. In the event of any dispute arising out of or in relation to the Application, you agree that
                the exclusive venue for resolving any dispute shall be in the courts of New South Wales, Australia.</p>

            <p> 17. Governing Law</p>

            <p>The Terms are governed by the laws of New South Wales, Australia. Any dispute, controversy, proceeding or
                claim of whatever nature arising out of or in any way relating to the Terms and the rights created
                hereby shall be governed, interpreted and construed by, under and pursuant to the laws of New South
                Wales, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The
                validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the
                parties hereto and their successors and assigns.</p>

            <p> 18. Independent Legal Advice</p>

            <p>Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both
                parties having taken the opportunity to obtain independent legal advice and declare the Terms are not
                against public policy on the grounds of inequality or bargaining power or general grounds of restraint
                of trade.</p>

            <p> 19. Severance</p>

            <p>If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction,
                that part shall be severed and the rest of the Terms shall remain in force.</p>

        </div>

    </div>;
}
