import React from 'react'
import {IGlobalDataContext, withGlobalData} from "../global/global-data";

interface Props extends IGlobalDataContext {
    votCardNumber: string,
    index?: number
}

export default function extractCardNumberFromWiegandReading(value: string, facilityCodeLength: number, rawWiegandReader: boolean): string {
    const binaryValue = parseInt(value.replace(/^0+/, ''), 16)

    const binaryCardReadingString = binaryValue.toString(2)

    // padding left until len is 26
    const fullReadingBinaryString = binaryCardReadingString.padStart(26, "0")

    let rawReading = fullReadingBinaryString.substring(1, fullReadingBinaryString.length-1)
    if (rawWiegandReader) {
        rawReading = fullReadingBinaryString.substring(2)
    }


    return parseInt(rawReading.substring(facilityCodeLength), 2).toString(10)
}

export function extractFacilityCodeFromWiegandReading(value: string,facilityCodeLength: number, rawWiegandReader: boolean): string {
    const binaryValue = parseInt(value.replace(/^0+/, ''), 16)

    const binaryCardReadingString = binaryValue.toString(2)

    // padding left until len is 26
    let fullReadingBinaryString = binaryCardReadingString.padStart(26, "0")

    if (fullReadingBinaryString.length > 26) {
        fullReadingBinaryString = fullReadingBinaryString.substring(fullReadingBinaryString.length-26)
    }


    let rawReading = fullReadingBinaryString.substring(1, facilityCodeLength+1)
    if (rawWiegandReader) {
        rawReading = fullReadingBinaryString.substring(2, facilityCodeLength+2)
    }


    return parseInt(rawReading, 2).toString(10)
}

function _FromVotCardNumberToCardNumber(props: Props){
    return (
        (props.votCardNumber != null) && (props.votCardNumber != "") ?
        <span>
            {props.index != null && (`Card ${props.index}:`)}  <b>{props.votCardNumber} <br /> {props.hidInfo?.facilityCode && '(' + 'FC:' + extractFacilityCodeFromWiegandReading(props.votCardNumber,props.hidInfo.facilityCodeLength ?? 8, props.hidInfo?.rawWiegandReading || false) + ', ID:' + extractCardNumberFromWiegandReading(props.votCardNumber,props.hidInfo?.facilityCodeLength ?? 8, props.hidInfo?.rawWiegandReading ?? false) + ')'}</b>
        </span> :
        <span>
            -
        </span>
    )
}

export const FromVotCardNumberToCardNumber = withGlobalData(_FromVotCardNumberToCardNumber);
