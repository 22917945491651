import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAdvancedReporting } from "../global/api";
import { PpeReportTemplate } from "../gen";
import cronstrue from 'cronstrue';
import { IGlobalDataContext, withGlobalData } from '../global/global-data';

interface Props extends IGlobalDataContext {
    onSave: () => void;
}

const _NewScheduledReportForm = (props: Props) => {
    const history = useHistory();
    const location = useLocation();
    const { report } = location.state || {};

    const [name, setName] = useState<string>(report?.name || "");
    const [emailSubject, setEmailSubject] = useState(report?.email_subject || "");
    const [schedule, setSchedule] = useState<string>(report?.cron || "");
    const [recipients, setRecipients] = useState(report?.emails.join(', ') || "");
    const [cutoff, setCutoff] = useState<string>(report?.day ? "day" :
        report?.seven_days ? "seven_days" :
            report?.sunday_week ? "sunday_week" :
                report?.monday_week ? "monday_week" :
                    report?.last_28_days ? "last_28_days" :
                        report?.last_calendar_month ? "last_calendar_month" : "day");

    const [template, setTemplate] = useState<string>(report?.template_uuid || "");
    const [templates, setTemplates] = useState<PpeReportTemplate[]>([]);
    const [loading, setLoading] = useState(false);
    const [explain, setExplain] = useState<string>("");

    const loadTemplate = () => {
        setLoading(true);
        getAdvancedReporting().advancedReportingServiceListTemplates({}).then((response) => {
            setTemplates(response.data.templates ?? []);
        }).catch((e) => {
            window.alert(`Failed to load templates: ${e}`);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        loadTemplate();
    }, []);

    useEffect(() => {
        if (schedule) {
            try {
                setExplain(cronstrue.toString(schedule, { verbose: true }));
            } catch (e) {
                console.error(e);
                setExplain("Invalid format");
            }
        } else {
            setExplain("-");
        }
    }, [schedule]);

    const handleSave = () => {
        if (!template) {
            window.alert("Please select a template");
            return;
        }

        if (!name) {
            window.alert("Please enter a name");
            return;
        }

        if (!schedule) {
            window.alert("Please enter a schedule");
            return;
        }

        try {
            cronstrue.toString(schedule);
        } catch (e) {
            window.alert("Invalid schedule format");
            return;
        }


        if (!recipients) {
            window.alert("Please enter recipients");
            return;
        }

        const data = {
            name,
            email_subject: emailSubject,
            cron: schedule,
            emails: recipients.split(',').map((email: string) => email.trim()),
            template_uuid: template,
            timezone: props.timezone,
            day: cutoff === "day",
            seven_days: cutoff === "seven_days",
            sunday_week: cutoff === "sunday_week",
            monday_week: cutoff === "monday_week",
            last_28_days: cutoff === "last_28_days",
            last_calendar_month: cutoff === "last_calendar_month",
        };

        setLoading(true);

        if (report) {
            getAdvancedReporting().advancedReportingServiceUpdateScheduledReport({
                scheduled_report_uuid: report.uuid,
                ...data,
            }).then(() => {
                history.push("/scheduled-reports");
            }).catch(() => {
                window.alert("Failed to update report.");
            }).finally(() => {
                setLoading(false);
            });
        } else {
            getAdvancedReporting().advancedReportingServiceCreateScheduledReport(data).then(() => {
                history.push("/scheduled-reports");
            }).catch(() => {
                window.alert("Failed to create report.");
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">{report ? "Edit Scheduled Report" : "Create New Scheduled Report"}</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="reportName" className="form-label">Name:</label>
                                        <input
                                            type="text"
                                            id="reportName"
                                            className="form-control"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="emailSubject" className="form-label">Email Subject:</label>
                                        <input
                                            type="text"
                                            id="emailSubject"
                                            className="form-control"
                                            value={emailSubject}
                                            onChange={(e) => setEmailSubject(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="cronSchedule" className="form-label">Schedule:</label>
                                        <input
                                            type="text"
                                            id="cronSchedule"
                                            className="form-control"
                                            placeholder="Enter a cron schedule expression (e.g. 0 23 * * *)."
                                            value={schedule}
                                            onChange={(e) => setSchedule(e.target.value)}
                                            onBlur={() => {
                                                try {
                                                    setExplain(cronstrue.toString(schedule, { verbose: true }));
                                                } catch (e) {
                                                    setExplain("Invalid format");
                                                }
                                            }}
                                        />
                                        {explain && <div className="mt-2">Explanation: {explain}<br />Timezone: {report?.timezone || props.timezone}</div>}
                                    </div>
                                    <div className={"form-group"}>
                                        <label>Recipients</label>
                                        <input type="text" className="form-control" placeholder="Please separate recipients with ','." value={recipients} onChange={(e) => setRecipients(e.target.value)} />

                                        <div className="mt-2">e.g. test1@example.com, test2@example.com</div>
                                    </div>
                                    <div className="mb-3">
                                        <label>Template</label>
                                        <select className="form-control" value={template} onChange={(e) => setTemplate(e.target.value)}>
                                            <option value={""}>Please select template</option>
                                            {templates.map((template) => <option key={template.uuid} value={template.uuid}>{template.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="cutoffSelect" className="form-label">Cutoff:</label>
                                        <select
                                            id="cutoffSelect"
                                            className="form-control"
                                            value={cutoff}
                                            onChange={(e) => setCutoff(e.target.value)}
                                        >
                                            <option value="day">Last Day</option>
                                            <option value="seven_days">Seven Days</option>
                                            <option value="sunday_week">Sunday Week</option>
                                            <option value="monday_week">Monday Week</option>
                                            <option value="last_28_days">Last 28 Days</option>
                                            <option value="last_calendar_month">Last Calendar Month</option>
                                        </select>
                                    </div>

                                    <div className="d-flex gap-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary mr-2"
                                            onClick={handleSave}
                                            disabled={loading}
                                        >
                                            {loading ? "Saving..." : "Save"}
                                        </button>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => history.push("/scheduled-reports")}
                                        >
                                            Cancel
                                        </button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export const NewScheduledReportForm = withGlobalData(_NewScheduledReportForm);
