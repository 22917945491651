import React, {useState} from "react";
import {postAPI} from "../global/api";
import {PpeServiceNow} from "../gen";

interface Props {
    servicenow: PpeServiceNow | null
}

export function ServiceNowCard(props:Props) {
    const [verifySuccess, setVerifySuccess] = useState(false);

    const [verifyFailureMessage, setVerifyFailureMessage] = useState("");

    const [verifyInProgress, setVerifyInProgress] = useState(false);

    function verifyToken(e: any) {
        e.preventDefault();

        setVerifyInProgress(true);
        postAPI("/api/servicenow/verify", {}).then((response) => {
            if (response.data.success) {
                setVerifySuccess(true);
            } else {
                setVerifyFailureMessage(response.data.message);
            }
        }).finally(() => {
            setVerifyInProgress(false);
        })
    }

    return (
        <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">ServiceNow Integration</h4>
                </div>
            </div>
            <div className="iq-card-body">

                    <table className={"table table-bordered"}>
                        <thead>
                        <tr>
                            <th>Features</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <img
                                    src={"https://www.servicenow.com/content/dam/servicenow-assets/public/en-us/images/company-library/media/logo/sn-logo-color.jpg"}
                                    width={"309px"}
                                />
                            </td>
                            <td>
                                {
                                    props.servicenow?.api_key ?
                                        <div>
                                            <span>{props.servicenow.api_key} [{props.servicenow.username}]</span>
                                            <br/>
                                            {!verifyInProgress ?
                                                <button onClick={verifyToken}>
                                                    Verify Token
                                                </button>
                                                :
                                                <span>Verifying...</span>
                                            }
                                            {verifySuccess ?
                                                <div className="text text-success">Verified</div>
                                                :
                                                <div className="text text-danger">{verifyFailureMessage}</div>
                                            }
                                        </div>
                                        :
                                        <span>Not enabled</span>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    );
}
