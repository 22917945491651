import React, { CSSProperties, useState, useEffect, useMemo } from "react";
import { V1AdminTag } from "../gen/typescript-axios";
import { getAdminTagServiceAPI } from "../global/api";
import { Message } from "../global/models";

interface Props {
  selectedTags: V1AdminTag[];
  onSelectionChange: (selectedOptions: V1AdminTag[]) => void;
  pushFlashMessage(message: Message): void;
}

export const sortTagsByName = (tags: V1AdminTag[]): V1AdminTag[] => {
  return tags.slice().sort((a, b) => {
    const nameA = (a.name || '').toLowerCase();
    const nameB = (b.name || '').toLowerCase();
    return nameA.localeCompare(nameB, 'en', { numeric: true });
  });
};

export default function CustomSelectDropdown(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<V1AdminTag[]>(props.selectedTags.length > 0 ? props.selectedTags : []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allTags, setAllTags] = useState<V1AdminTag[]>([]);
  const [hasSelectedTags, setHasSelectedTags] = useState<boolean>(props.selectedTags.length > 0);
  const [hasFetchedTags, setHasFetchedTags] = useState<boolean>(false);

  useEffect(() => {
    if (!hasFetchedTags) {
      getAllTags();
    }
  }, [hasFetchedTags]);

  useEffect(() => {
    setHasSelectedTags(props.selectedTags.length > 0);
  }, [props.selectedTags]);

  const getAllTags = () => {
    setIsLoading(true);
    if (isLoading) return;

    getAdminTagServiceAPI().adminTagServiceListTags({}).then((response) => {
      if (response.data.success ?? false) {
        setAllTags(response.data.tags ?? []);
        setHasFetchedTags(true);
      } else {
        props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"));
      }
    }).catch(() => {
      props.pushFlashMessage(new Message("Server error", "error"));
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    handleApply();
  }, [selectedTags]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (tag: V1AdminTag) => {
    const index = selectedTags.findIndex(option => option.uuid === tag.uuid);
    if (index === -1) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter(option => option.uuid !== tag.uuid));
    }
  };

  const handleApply = () => {
    if (JSON.stringify(selectedTags) !== JSON.stringify(props.selectedTags)) {
      props.onSelectionChange(selectedTags);
    }
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const currentTarget = e.currentTarget as HTMLDivElement;
    if (currentTarget && !currentTarget.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  const optionsDropdownStyle: CSSProperties = {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    maxHeight: '200px',
    overflowY: 'auto',
    width: '100%',
    height: selectedTags.length > 0 ? 'auto' : 'auto',
  };

  const optionStyle: CSSProperties = {
    padding: '8px 12px',
    cursor: 'pointer'
  };

  const selectedOptionStyle: CSSProperties = {
    backgroundColor: '#e0e0e0'
  };

  const sortedAllTags = useMemo(() => sortTagsByName(allTags), [allTags]);
  const sortedSelectedTags = useMemo(() => sortTagsByName(props.selectedTags), [props.selectedTags]);


  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          border: '1px solid #ccc',
          padding: '8px 12px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...(isOpen ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : {})
        }}
        onClick={toggleDropdown}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', display: 'flex', flexWrap: 'wrap', maxHeight: '100px' }}>
            {hasSelectedTags
              ? sortedSelectedTags
                .map((tag, index, array) => (
                  <span
                    key={tag.uuid}
                    style={{
                      marginRight: '8px',
                      ...(selectedTags.some(option => option.uuid === tag.uuid) && { fontWeight: 'bold' })
                    }}
                    onClick={() => handleOptionClick(tag)}
                  >
                    {tag.name}
                    {index !== array.length - 1 && ','}
                  </span>
                ))
              : <span>Select one or more tags</span>}
          </div>
          <span style={{ marginLeft: '8px', transition: 'transform 0.3s ease', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>▼</span>
        </div>
      </div>
      {isOpen && (
        <div
          style={optionsDropdownStyle}
          onClick={handleOutsideClick}
          onMouseDown={e => e.preventDefault()}
        >
          {sortedAllTags.map(option => (
            <div
              key={option.uuid}
              style={{
                ...optionStyle,
                ...(selectedTags.some(tag => tag.uuid === option.uuid) ? selectedOptionStyle : {})
              }}
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
