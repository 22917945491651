import React, { Component } from 'react';
import {DispensingBarChart} from "./dispensing_bar_chart";
import {DispensingPieChart} from "./dispensing_pie_chart";
import {PpeTransactionsCount, PpeTransactionsCountByProduct} from "../gen";

interface Props {
    handleNumberOfDayChange(e:any):void
    numberOfDay:number
    isLoading: boolean
    transactionCountByDay: PpeTransactionsCount[]
    transactionCountByProduct: PpeTransactionsCountByProduct[]
}

interface State {
}

class DispensingStatsChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                        <h4 className="card-title">Dispensing Stats Chart</h4>
                    </div>
                    <div className={"row"}>
                        <div className={"col-2"}>
                            {this.props.isLoading && <i className="fa fa-spinner fa-spin" style={{marginRight:'10px'}}/>}
                        </div>
                        <div className={"col-10"}>
                            <select className={"form-control"} onChange={this.props.handleNumberOfDayChange.bind(this)} value={this.props.numberOfDay}>
                                <option value={7}>7 Days</option>
                                <option value={28}>28 Days</option>
                                <option value={90}>90 Days</option>
                                <option value={180}>180 Days</option>
                                <option value={365}>365 Days</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="iq-card-body row m-0 align-items-center pb-0">
                    <div className="col-md-6">
                        <DispensingBarChart transactionCountByDay={this.props.transactionCountByDay} isLoading={this.props.isLoading}/>
                    </div>
                    <div className="col-md-6">
                        <DispensingPieChart transactionCountByProduct={this.props.transactionCountByProduct} isLoading={this.props.isLoading}/>
                    </div>
                </div>
            </div>);
    }
}

export {DispensingStatsChart}
