import React, { Fragment, useContext, useState } from 'react';
import { Ppeemployerv1Transaction, V1CurrencyConfig } from "../gen/typescript-axios";
import { DatetimeDisplay } from "../global/datetime_display";
import { Link } from "react-router-dom";
import BusyIndicator, { LoadingSpanner } from "../busy_indicator";
import { getAdminJobServiceAPI, getAdminTransactionServiceApi } from "../global/api";
import { AsyncTypeahead, Highlighter, TypeaheadMenu } from "react-bootstrap-typeahead";
import { TransactionProductAdditionalInformation } from "./transaction_product_additional_information";
import { FromVotCardNumberToCardNumber } from '../employee_page/from_vot_card_number_to_card_number';
import { GlobalDataContext } from "../global/global-data";
import CurrencyFormatter from '../util/currencyFormatter';

interface TransactionRowProps {
    transaction: Ppeemployerv1Transaction,
    timezone: string,
    reload(): void,
    currency_config: V1CurrencyConfig | undefined,
}

function TransactionRow(props: TransactionRowProps) {
    const [isEditJobId, setIsEditJobId] = useState<boolean>(false);
    const [jobId, setJobId] = useState<string | undefined>(props.transaction.job_id);
    const [isSavingJobId, setIsSavingJobId] = useState<boolean>(false);
    const [jobIdOptions, setJobIdOptions] = useState<string[]>([]);
    const [isJobIdOptionsLoading, setIsJobIdOptionsLoading] = useState<boolean>(false);
    const [showProductAdditional, setShowProductAdditional] = useState<boolean>(false);
    const context = useContext(GlobalDataContext);

    const editJobId = (e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsEditJobId(true)
    }

    const cancelEditJobId = (e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setJobId(props.transaction.job_id)
        setIsEditJobId(false)
    }

    const onJobIdChange = (selected: string[]) => {
        console.log("selected", selected)
        if (selected.length > 0) {
            setJobId(selected[0])
        }
    }

    const onSearchJobId = (query: string) => {
        console.log("query", query)
        setIsJobIdOptionsLoading(true)
        setJobId(query)
        getAdminJobServiceAPI().adminJobServiceListJobs({
            job_code: query
        }).then((response) => {
            if (response.data.success) {
                const jobs = response.data.jobs ?? []
                const jobIds = jobs.map((job) => {
                    return job.job_code ?? ""
                })
                setJobIdOptions(jobIds)
            }
        }).catch(() => {
            window.alert("Server error")
        }).finally(() => {
            setIsJobIdOptionsLoading(false)
        })
    }

    const getProductAdditional = () => {
        setShowProductAdditional(!showProductAdditional);
    }

    const saveJobId = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (isSavingJobId) {
            return;
        }
        setIsSavingJobId(true)
        getAdminTransactionServiceApi().adminTransactionServiceUpdateTransaction(props.transaction.uuid ?? "", {
            job_id: jobId
        }).then((response) => {
            if (response.data.success) {
                props.reload();
                window.alert("Job ID Saved")
                setIsEditJobId(false)
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(() => {
            window.alert("Server error")
        }).finally(() => {
            setIsSavingJobId(false)
        })
    }
    const renderProductItemChildren = (option: string, props: any) => {
        return [
            <Highlighter key={"name"} search={props.text}>{option}</Highlighter>,
        ];
    };
    return (
        <>
            <tr key={props.transaction.uuid}>
                <td>{(props.transaction.uuid ?? "").substr(0, 4)}</td>
                <td>
                    <Link to={`/employees/${props.transaction.employee_uuid}/transactions`}>{props.transaction.employee_name}</Link>
                    {(props.transaction.employee_department && (props.transaction.employee_department !== "")) && <div>Department: {props.transaction.employee_department}</div>}
                </td>
                <td><FromVotCardNumberToCardNumber votCardNumber={props.transaction.key_card ?? ""} facilityCode={context.hidInfo.facilityCode ?? ''} facilityCodeLength={context.hidInfo.facilityCodeLength} /></td>
                <td>
                    {!props.transaction.trackable_item_session && <DatetimeDisplay timezone={props.timezone} timestamp={props.transaction.event_epoch ?? 0} />}
                    {
                        props.transaction.trackable_item_session &&
                        <Fragment>
                            <tr>
                                <td>
                                    Start
                                </td>
                                <td>
                                    <DatetimeDisplay timezone={props.timezone} timestamp={props.transaction.trackable_item_session.start ?? 0} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Finish
                                </td>
                                <td>
                                    {
                                        props.transaction.trackable_item_session.finish ?
                                            <DatetimeDisplay
                                                timezone={props.timezone}
                                                timestamp={props.transaction.trackable_item_session.finish ?? 0}
                                            /> : "-"
                                    }
                                </td>
                            </tr>
                        </Fragment>
                    }
                </td>
                <td>
                    <span style={{ cursor: "pointer", textDecoration: "dotted underline" }} onClick={getProductAdditional}>{props.transaction.product_name}</span>
                    {
                        props.transaction.trackable_item_session &&
                        <div title={
                            `${props.transaction.product_name} is a trackable item. Each trackable item is differentiated.`
                        }>
                            <i className={"la la-binoculars"}></i> Trackable
                        </div>
                    }
                    {props.transaction.selection && <span style={{ fontSize: "0.8em" }}>[selection: {props.transaction.selection}]</span>}
                </td>
                <td>{props.transaction.machine_name ?? "-"}</td>
                <td>{props.transaction.machine_site ?? "-"}</td>
                <td>
                    {!isEditJobId && (props.transaction.job_id ?? "-")}
                    {!isEditJobId && <i className="las la-edit" onClick={editJobId} />}
                    {
                        isEditJobId && <AsyncTypeahead
                            id={"typeahead"}
                            isLoading={isJobIdOptionsLoading}
                            onSearch={onSearchJobId}
                            onInputChange={onSearchJobId}
                            selected={jobId ? [jobId] : []}
                            onChange={onJobIdChange}
                            options={jobIdOptions}
                            clearButton
                            emptyLabel={""}
                            renderMenuItemChildren={renderProductItemChildren}
                            renderMenu={(results, menuProps) => {
                                // Hide the menu when there are no results.
                                if (!results.length) {
                                    return null;
                                }
                                // @ts-ignore
                                return <TypeaheadMenu {...menuProps} options={results} />;
                            }}
                        />
                    }
                    <br />
                    {isEditJobId && <BusyIndicator busy={false} busyIndicator={<LoadingSpanner />}>
                        <button className="btn btn-primary"
                            onClick={saveJobId}>Save
                        </button>
                    </BusyIndicator>}
                    &nbsp;
                    {isEditJobId && <button className="btn btn-warning" onClick={cancelEditJobId}>Cancel</button>}
                </td>
                <td>
                    <CurrencyFormatter priceInCents={props.transaction.product?.value_in_cents} currencyConfig={props.currency_config} /> </td>
            </tr>
            {
                showProductAdditional && <TransactionProductAdditionalInformation transaction={props.transaction.product} />
            }
        </>
    );
}

export default TransactionRow;
