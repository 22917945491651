/* tslint:disable */
/* eslint-disable */
/**
 * ppe.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject10
 */
export interface InlineObject10 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject11
 */
export interface InlineObject11 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject11
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject11
     */
    'per'?: number;
    /**
     * 
     * @type {PpeSessionType}
     * @memberof InlineObject11
     */
    'type'?: PpeSessionType;
}
/**
 * 
 * @export
 * @interface InlineObject12
 */
export interface InlineObject12 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    'job_id'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject13
 */
export interface InlineObject13 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject13
     */
    'new_password'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject14
 */
export interface InlineObject14 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject14
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject14
     */
    'site'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject14
     */
    'photo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject14
     */
    'remove_photo'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject15
 */
export interface InlineObject15 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject15
     */
    'mdb_code'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject15
     */
    'capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject15
     */
    'restock_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject15
     */
    'product_uuid'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject16
 */
export interface InlineObject16 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject16
     */
    'capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject16
     */
    'restock_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject16
     */
    'effect_timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject16
     */
    'product_uuid'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    'email'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject2
     */
    'key_cards'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    'employee_number'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject2
     */
    'secondary_emails'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject2
     */
    'access_codes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject3
     */
    'new_expiry_epoch'?: number;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {PpeEmployeeStatus}
     * @memberof InlineObject5
     */
    'status'?: PpeEmployeeStatus;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    'job_code'?: string;
    /**
     * 
     * @type {PpeJobStatus}
     * @memberof InlineObject6
     */
    'job_status'?: PpeJobStatus;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    'admin_note'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject7
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject7
     */
    'photo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject7
     */
    'remove_photo'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject8
     */
    'photo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject8
     */
    'trackable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InlineObject8
     */
    'inspection_interval'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject8
     */
    'remove_photo'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PpeAddAdminRequest
 */
export interface PpeAddAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeAddAdminRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAddAdminRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PpeAddAdminResponse
 */
export interface PpeAddAdminResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAddAdminResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeAddAdminResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeAddPartnerRequest
 */
export interface PpeAddPartnerRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeAddPartnerRequest
     */
    'parent_partner_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAddPartnerRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PpeAddPartnerResponse
 */
export interface PpeAddPartnerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAddPartnerResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeAddPartnerResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeAddPartnerUserResponse
 */
export interface PpeAddPartnerUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAddPartnerUserResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeAddPartnerUserResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeAddProductRequest
 */
export interface PpeAddProductRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeAddProductRequest
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAddProductRequest
     */
    'product_photo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeAddProductRequest
     */
    'is_trackable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PpeAddProductRequest
     */
    'inspection_interval'?: number;
}
/**
 * 
 * @export
 * @interface PpeAddProductResponse
 */
export interface PpeAddProductResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAddProductResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeAddProductResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAddProductResponse
     */
    'product_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeAddRestrictionRequest
 */
export interface PpeAddRestrictionRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeAddRestrictionRequest
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeAddRestrictionRequest
     */
    'inspection_period'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeAddRestrictionRequest
     */
    'max_quantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PpeAddRestrictionRequest
     */
    'daily_reset'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeAddRestrictionResponse
 */
export interface PpeAddRestrictionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAddRestrictionResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeAddRestrictionResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAddRestrictionResponse
     */
    'restriction_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeAdmin
 */
export interface PpeAdmin {
    /**
     * 
     * @type {string}
     * @memberof PpeAdmin
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAdmin
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAdmin
     */
    'timezone'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeAdmin
     */
    'created_epoch'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PpeAdmin
     */
    'is_activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeAdmin
     */
    'is_tfa_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeAdministratorForgotPasswordRequest
 */
export interface PpeAdministratorForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeAdministratorForgotPasswordRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface PpeAdministratorForgotPasswordResponse
 */
export interface PpeAdministratorForgotPasswordResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAdministratorForgotPasswordResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeAdministratorForgotPasswordResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeAdministratorLoginRequest
 */
export interface PpeAdministratorLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeAdministratorLoginRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAdministratorLoginRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAdministratorLoginRequest
     */
    'tfa_code'?: string;
}
/**
 * 
 * @export
 * @interface PpeAdministratorLoginResponse
 */
export interface PpeAdministratorLoginResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAdministratorLoginResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {PpeLoginResponseCode}
     * @memberof PpeAdministratorLoginResponse
     */
    'code'?: PpeLoginResponseCode;
    /**
     * 
     * @type {string}
     * @memberof PpeAdministratorLoginResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAdministratorLoginResponse
     */
    'auth_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAdministratorLoginResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface PpeAggregatedByEmployeeTransaction
 */
export interface PpeAggregatedByEmployeeTransaction {
    /**
     * 
     * @type {string}
     * @memberof PpeAggregatedByEmployeeTransaction
     */
    'employee_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAggregatedByEmployeeTransaction
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAggregatedByEmployeeTransaction
     */
    'count'?: string;
}
/**
 * 
 * @export
 * @interface PpeAggregatedByMachineTransaction
 */
export interface PpeAggregatedByMachineTransaction {
    /**
     * 
     * @type {string}
     * @memberof PpeAggregatedByMachineTransaction
     */
    'machine_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAggregatedByMachineTransaction
     */
    'machine_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAggregatedByMachineTransaction
     */
    'count'?: string;
    /**
     * 
     * @type {PpeTransactionType}
     * @memberof PpeAggregatedByMachineTransaction
     */
    'type'?: PpeTransactionType;
}
/**
 * 
 * @export
 * @interface PpeAggregatedByProductTransaction
 */
export interface PpeAggregatedByProductTransaction {
    /**
     * 
     * @type {string}
     * @memberof PpeAggregatedByProductTransaction
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAggregatedByProductTransaction
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAggregatedByProductTransaction
     */
    'count'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeAggregation = {
    Day: 'DAY',
    Month: 'MONTH',
    Week: 'WEEK'
} as const;

export type PpeAggregation = typeof PpeAggregation[keyof typeof PpeAggregation];


/**
 * 
 * @export
 * @interface PpeAllocatedKeyCardToEmployeeRequest
 */
export interface PpeAllocatedKeyCardToEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeAllocatedKeyCardToEmployeeRequest
     */
    'key_card_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAllocatedKeyCardToEmployeeRequest
     */
    'employee_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeAllocatedKeyCardToEmployeeResponse
 */
export interface PpeAllocatedKeyCardToEmployeeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAllocatedKeyCardToEmployeeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeAllocatedKeyCardToEmployeeResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeArchiveProductResponse
 */
export interface PpeArchiveProductResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeArchiveProductResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeArchiveProductResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeAssignAssetTagToTrackableItemRequest
 */
export interface PpeAssignAssetTagToTrackableItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeAssignAssetTagToTrackableItemRequest
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAssignAssetTagToTrackableItemRequest
     */
    'asset_tag'?: string;
}
/**
 * 
 * @export
 * @interface PpeAssignAssetTagToTrackableItemResponse
 */
export interface PpeAssignAssetTagToTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAssignAssetTagToTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeAssignAssetTagToTrackableItemResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeAssignTrackableItemToEmployeeResponse
 */
export interface PpeAssignTrackableItemToEmployeeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeAssignTrackableItemToEmployeeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeAssignTrackableItemToEmployeeResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeAuditLog
 */
export interface PpeAuditLog {
    /**
     * 
     * @type {number}
     * @memberof PpeAuditLog
     */
    'epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeAuditLog
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAuditLog
     */
    'admin_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAuditLog
     */
    'admin_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeAuditLog
     */
    'summary'?: string;
}
/**
 * 
 * @export
 * @interface PpeAvailableLockerCell
 */
export interface PpeAvailableLockerCell {
    /**
     * 
     * @type {PpeLockerInformation}
     * @memberof PpeAvailableLockerCell
     */
    'locker'?: PpeLockerInformation;
    /**
     * 
     * @type {PpeCellInformation}
     * @memberof PpeAvailableLockerCell
     */
    'cell'?: PpeCellInformation;
}
/**
 * 
 * @export
 * @interface PpeCapabilities
 */
export interface PpeCapabilities {
    /**
     * 
     * @type {boolean}
     * @memberof PpeCapabilities
     */
    'servicenow'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCapabilities
     */
    'trackable_item'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeCellInformation
 */
export interface PpeCellInformation {
    /**
     * 
     * @type {string}
     * @memberof PpeCellInformation
     */
    'uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeCellInformation
     */
    'row'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeCellInformation
     */
    'col'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCellInformation
     */
    'occupied'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeCellInformation
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCellInformation
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCellInformation
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCellInformation
     */
    'trackable_item_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCellInformation
     */
    'trackable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeCellInformation
     */
    'display_name'?: string;
}
/**
 * 
 * @export
 * @interface PpeClearLockerCellRequest
 */
export interface PpeClearLockerCellRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeClearLockerCellRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeClearLockerCellRequest
     */
    'cell_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeClearLockerCellResponse
 */
export interface PpeClearLockerCellResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeClearLockerCellResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeClearLockerCellResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeClearPendingInspectionRequest
 */
export interface PpeClearPendingInspectionRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeClearPendingInspectionRequest
     */
    'trackable_item_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeClearPendingInspectionResponse
 */
export interface PpeClearPendingInspectionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeClearPendingInspectionResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeClearPendingInspectionResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeCreateEmployeeRequest
 */
export interface PpeCreateEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeCreateEmployeeRequest
     */
    'email'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeCreateEmployeeRequest
     */
    'key_cards'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateEmployeeRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateEmployeeRequest
     */
    'employee_number'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeCreateEmployeeRequest
     */
    'secondary_emails'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeCreateEmployeeRequest
     */
    'access_codes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PpeCreateEmployeeResponse
 */
export interface PpeCreateEmployeeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateEmployeeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateEmployeeResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeCreateJobRequest
 */
export interface PpeCreateJobRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeCreateJobRequest
     */
    'job_code'?: string;
    /**
     * 
     * @type {PpeJobStatus}
     * @memberof PpeCreateJobRequest
     */
    'job_status'?: PpeJobStatus;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateJobRequest
     */
    'admin_note'?: string;
}
/**
 * 
 * @export
 * @interface PpeCreateJobResponse
 */
export interface PpeCreateJobResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateJobResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateJobResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeCreateProductMapResponse
 */
export interface PpeCreateProductMapResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateProductMapResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateProductMapResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeCreateScheduledReportRequest
 */
export interface PpeCreateScheduledReportRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeCreateScheduledReportRequest
     */
    'cron'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeCreateScheduledReportRequest
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateScheduledReportRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateScheduledReportRequest
     */
    'template_uuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateScheduledReportRequest
     */
    'transactions_csv'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateScheduledReportRequest
     */
    'email_subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateScheduledReportRequest
     */
    'timezone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateScheduledReportRequest
     */
    'day'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateScheduledReportRequest
     */
    'seven_days'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateScheduledReportRequest
     */
    'sunday_week'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateScheduledReportRequest
     */
    'monday_week'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateScheduledReportRequest
     */
    'last_28_days'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateScheduledReportRequest
     */
    'last_calendar_month'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeCreateScheduledReportResponse
 */
export interface PpeCreateScheduledReportResponse {
    /**
     * 
     * @type {string}
     * @memberof PpeCreateScheduledReportResponse
     */
    'scheduled_report_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeCreateTemplateRequest
 */
export interface PpeCreateTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeCreateTemplateRequest
     */
    'template_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateTemplateRequest
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateTemplateRequest
     */
    'content_bs64'?: string;
}
/**
 * 
 * @export
 * @interface PpeCreateTrackableItemRequest
 */
export interface PpeCreateTrackableItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeCreateTrackableItemRequest
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateTrackableItemRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateTrackableItemRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PpeCreateTrackableItemResponse
 */
export interface PpeCreateTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeCreateTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeCreateTrackableItemResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeTrackableItem}
     * @memberof PpeCreateTrackableItemResponse
     */
    'trackable_item'?: PpeTrackableItem;
}
/**
 * 
 * @export
 * @interface PpeDeleteTrackableItemRequest
 */
export interface PpeDeleteTrackableItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeDeleteTrackableItemRequest
     */
    'trackable_item_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeDeleteTrackableItemResponse
 */
export interface PpeDeleteTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeDeleteTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeDeleteTrackableItemResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmailPreferences
 */
export interface PpeEmailPreferences {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmailPreferences
     */
    'is_receive_daily_report'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmailPreferences
     */
    'is_receive_monthly_report'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmailPreferences
     */
    'is_receive_vend_failure_alert'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmailPreferences
     */
    'is_receive_pending_inspection_alert'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeEmployee
 */
export interface PpeEmployee {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployee
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployee
     */
    'full_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployee
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployee
     */
    'is_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployee
     */
    'employee_number'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployee
     */
    'expiry_at'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployee
     */
    'is_tmp_access'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeEmployee
     */
    'key_cards'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeEmployee
     */
    'secondary_emails'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeEmployee
     */
    'access_codes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PpeEmployeePagination
 */
export interface PpeEmployeePagination {
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeePagination
     */
    'current_page'?: number;
    /**
     * 
     * @type {Array<PpeEmployee>}
     * @memberof PpeEmployeePagination
     */
    'employees'?: Array<PpeEmployee>;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeePagination
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeePagination
     */
    'to'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeePagination
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeePagination
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeEmployeeStatus = {
    Enabled: 'ENABLED',
    Disabled: 'DISABLED'
} as const;

export type PpeEmployeeStatus = typeof PpeEmployeeStatus[keyof typeof PpeEmployeeStatus];


/**
 * 
 * @export
 * @interface PpeExchangeSecurityTokenWithGoogleTokenRequest
 */
export interface PpeExchangeSecurityTokenWithGoogleTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithGoogleTokenRequest
     */
    'google_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithGoogleTokenRequest
     */
    'google_access_token'?: string;
}
/**
 * 
 * @export
 * @interface PpeExchangeSecurityTokenWithGoogleTokenResponse
 */
export interface PpeExchangeSecurityTokenWithGoogleTokenResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeExchangeSecurityTokenWithGoogleTokenResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithGoogleTokenResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithGoogleTokenResponse
     */
    'security_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithGoogleTokenResponse
     */
    'email'?: string;
    /**
     * 
     * @type {PpeSsoResponseCode}
     * @memberof PpeExchangeSecurityTokenWithGoogleTokenResponse
     */
    'code'?: PpeSsoResponseCode;
}
/**
 * 
 * @export
 * @interface PpeExchangeSecurityTokenWithMicrosoftTokenRequest
 */
export interface PpeExchangeSecurityTokenWithMicrosoftTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithMicrosoftTokenRequest
     */
    'microsoft_token'?: string;
}
/**
 * 
 * @export
 * @interface PpeExchangeSecurityTokenWithMicrosoftTokenResponse
 */
export interface PpeExchangeSecurityTokenWithMicrosoftTokenResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeExchangeSecurityTokenWithMicrosoftTokenResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithMicrosoftTokenResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithMicrosoftTokenResponse
     */
    'security_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithMicrosoftTokenResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeExchangeSecurityTokenWithMicrosoftTokenResponse
     */
    'suggested_email'?: string;
    /**
     * 
     * @type {PpeSsoResponseCode}
     * @memberof PpeExchangeSecurityTokenWithMicrosoftTokenResponse
     */
    'code'?: PpeSsoResponseCode;
}
/**
 * 
 * @export
 * @interface PpeExtentTemporaryAccessResponse
 */
export interface PpeExtentTemporaryAccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeExtentTemporaryAccessResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeExtentTemporaryAccessResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeGetAggregatedTransactionRequest
 */
export interface PpeGetAggregatedTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'start_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'end_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'job_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'vending_machine_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {PpeTransactionAggregation}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'aggregate_by'?: PpeTransactionAggregation;
    /**
     * 
     * @type {PpeTransactionAggregationOrder}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'order_by'?: PpeTransactionAggregationOrder;
    /**
     * 
     * @type {number}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetAggregatedTransactionRequest
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface PpeGetAggregatedTransactionResponse
 */
export interface PpeGetAggregatedTransactionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetAggregatedTransactionResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetAggregatedTransactionResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeGetAggregatedTransactionResponse
     */
    'total_page'?: number;
    /**
     * 
     * @type {PpeTransactionAggregation}
     * @memberof PpeGetAggregatedTransactionResponse
     */
    'aggregate_by'?: PpeTransactionAggregation;
    /**
     * 
     * @type {Array<PpeAggregatedByEmployeeTransaction>}
     * @memberof PpeGetAggregatedTransactionResponse
     */
    'aggregated_by_employee_transaction'?: Array<PpeAggregatedByEmployeeTransaction>;
    /**
     * 
     * @type {Array<PpeAggregatedByProductTransaction>}
     * @memberof PpeGetAggregatedTransactionResponse
     */
    'aggregated_by_product_transaction'?: Array<PpeAggregatedByProductTransaction>;
    /**
     * 
     * @type {Array<PpeAggregatedByMachineTransaction>}
     * @memberof PpeGetAggregatedTransactionResponse
     */
    'aggregated_by_machine_transaction'?: Array<PpeAggregatedByMachineTransaction>;
}
/**
 * 
 * @export
 * @interface PpeGetAllEmployeeResponse
 */
export interface PpeGetAllEmployeeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetAllEmployeeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetAllEmployeeResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeePagination}
     * @memberof PpeGetAllEmployeeResponse
     */
    'data'?: PpeEmployeePagination;
}
/**
 * 
 * @export
 * @interface PpeGetEmployeeDetailsResponse
 */
export interface PpeGetEmployeeDetailsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetEmployeeDetailsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetEmployeeDetailsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployee}
     * @memberof PpeGetEmployeeDetailsResponse
     */
    'employee'?: PpeEmployee;
}
/**
 * 
 * @export
 * @interface PpeGetGoogleSsoConfigResponse
 */
export interface PpeGetGoogleSsoConfigResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetGoogleSsoConfigResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetGoogleSsoConfigResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeGoogleSsoConfig}
     * @memberof PpeGetGoogleSsoConfigResponse
     */
    'config'?: PpeGoogleSsoConfig;
}
/**
 * 
 * @export
 * @interface PpeGetJobDetailResponse
 */
export interface PpeGetJobDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetJobDetailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetJobDetailResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeJobDetail}
     * @memberof PpeGetJobDetailResponse
     */
    'job_detail'?: PpeJobDetail;
}
/**
 * 
 * @export
 * @interface PpeGetLockerInformationResponse
 */
export interface PpeGetLockerInformationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetLockerInformationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetLockerInformationResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeCellInformation>}
     * @memberof PpeGetLockerInformationResponse
     */
    'cells'?: Array<PpeCellInformation>;
}
/**
 * 
 * @export
 * @interface PpeGetMicrosoftSsoConfigResponse
 */
export interface PpeGetMicrosoftSsoConfigResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetMicrosoftSsoConfigResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetMicrosoftSsoConfigResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeMicrosoftSsoConfig}
     * @memberof PpeGetMicrosoftSsoConfigResponse
     */
    'config'?: PpeMicrosoftSsoConfig;
}
/**
 * 
 * @export
 * @interface PpeGetMyInfoResponse
 */
export interface PpeGetMyInfoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetMyInfoResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetMyInfoResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeMyInfo}
     * @memberof PpeGetMyInfoResponse
     */
    'my_info'?: PpeMyInfo;
}
/**
 * 
 * @export
 * @interface PpeGetOrganizationBasicStatisticsResponse
 */
export interface PpeGetOrganizationBasicStatisticsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetOrganizationBasicStatisticsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetOrganizationBasicStatisticsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeGetOrganizationBasicStatisticsResponse
     */
    'total_active_employees'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetOrganizationBasicStatisticsResponse
     */
    'total_employees'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetOrganizationBasicStatisticsResponse
     */
    'total_item_dispensed_in_last_seven_days'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetOrganizationBasicStatisticsResponse
     */
    'total_item_dispensed_today'?: number;
}
/**
 * 
 * @export
 * @interface PpeGetOrganizationDetailResponse
 */
export interface PpeGetOrganizationDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetOrganizationDetailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetOrganizationDetailResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeOrganization}
     * @memberof PpeGetOrganizationDetailResponse
     */
    'organization'?: PpeOrganization;
}
/**
 * 
 * @export
 * @interface PpeGetOrganizationLogoResponse
 */
export interface PpeGetOrganizationLogoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetOrganizationLogoResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetOrganizationLogoResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetOrganizationLogoResponse
     */
    'img'?: string;
}
/**
 * 
 * @export
 * @interface PpeGetProductResponse
 */
export interface PpeGetProductResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetProductResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetProductResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeProductInfo}
     * @memberof PpeGetProductResponse
     */
    'product'?: PpeProductInfo;
}
/**
 * 
 * @export
 * @interface PpeGetSelfServicingConfigResponse
 */
export interface PpeGetSelfServicingConfigResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetSelfServicingConfigResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetSelfServicingConfigResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PpeSelfServicingConfig}
     * @memberof PpeGetSelfServicingConfigResponse
     */
    'config'?: PpeSelfServicingConfig;
}
/**
 * 
 * @export
 * @interface PpeGetServiceNowUserSyncJobResponse
 */
export interface PpeGetServiceNowUserSyncJobResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetServiceNowUserSyncJobResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetServiceNowUserSyncJobResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PpeUserDelegationSyncJob}
     * @memberof PpeGetServiceNowUserSyncJobResponse
     */
    'job'?: PpeUserDelegationSyncJob;
}
/**
 * 
 * @export
 * @interface PpeGetTemporaryAccessUrlResponse
 */
export interface PpeGetTemporaryAccessUrlResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetTemporaryAccessUrlResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTemporaryAccessUrlResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTemporaryAccessUrlResponse
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface PpeGetTfaSetupDataResponse
 */
export interface PpeGetTfaSetupDataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetTfaSetupDataResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTfaSetupDataResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeTfaSetupData}
     * @memberof PpeGetTfaSetupDataResponse
     */
    'tfa_setup_data'?: PpeTfaSetupData;
}
/**
 * 
 * @export
 * @interface PpeGetTrackableItemResponse
 */
export interface PpeGetTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTrackableItemResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeTrackableItem}
     * @memberof PpeGetTrackableItemResponse
     */
    'trackable_item'?: PpeTrackableItem;
}
/**
 * 
 * @export
 * @interface PpeGetTrackableItemSessionResponse
 */
export interface PpeGetTrackableItemSessionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetTrackableItemSessionResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTrackableItemSessionResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<PpeTrackableItemSession>}
     * @memberof PpeGetTrackableItemSessionResponse
     */
    'sessions'?: Array<PpeTrackableItemSession>;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTrackableItemSessionResponse
     */
    'current_page'?: number;
}
/**
 * 
 * @export
 * @interface PpeGetTransactionsCountByProductRequest
 */
export interface PpeGetTransactionsCountByProductRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountByProductRequest
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountByProductRequest
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountByProductRequest
     */
    'vending_machine_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountByProductRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsCountByProductRequest
     */
    'start_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsCountByProductRequest
     */
    'end_epoch'?: number;
}
/**
 * 
 * @export
 * @interface PpeGetTransactionsCountByProductResponse
 */
export interface PpeGetTransactionsCountByProductResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetTransactionsCountByProductResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountByProductResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeTransactionsCountByProduct>}
     * @memberof PpeGetTransactionsCountByProductResponse
     */
    'transactions_count_by_product'?: Array<PpeTransactionsCountByProduct>;
}
/**
 * 
 * @export
 * @interface PpeGetTransactionsCountRequest
 */
export interface PpeGetTransactionsCountRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountRequest
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountRequest
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountRequest
     */
    'vending_machine_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsCountRequest
     */
    'start_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsCountRequest
     */
    'end_epoch'?: number;
    /**
     * 
     * @type {PpeAggregation}
     * @memberof PpeGetTransactionsCountRequest
     */
    'aggregation'?: PpeAggregation;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountRequest
     */
    'job_code'?: string;
}
/**
 * 
 * @export
 * @interface PpeGetTransactionsCountResponse
 */
export interface PpeGetTransactionsCountResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetTransactionsCountResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsCountResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeTransactionsCount>}
     * @memberof PpeGetTransactionsCountResponse
     */
    'transactions_count'?: Array<PpeTransactionsCount>;
}
/**
 * 
 * @export
 * @interface PpeGetTransactionsRequest
 */
export interface PpeGetTransactionsRequest {
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsRequest
     */
    'per'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsRequest
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsRequest
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsRequest
     */
    'vending_machine_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsRequest
     */
    'start_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsRequest
     */
    'end_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsRequest
     */
    'job_code'?: string;
}
/**
 * 
 * @export
 * @interface PpeGetTransactionsResponse
 */
export interface PpeGetTransactionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGetTransactionsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGetTransactionsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeTransaction>}
     * @memberof PpeGetTransactionsResponse
     */
    'transactions'?: Array<PpeTransaction>;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsResponse
     */
    'current_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeGetTransactionsResponse
     */
    'number_of_page'?: number;
}
/**
 * 
 * @export
 * @interface PpeGoogleSsoConfig
 */
export interface PpeGoogleSsoConfig {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGoogleSsoConfig
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGoogleSsoConfig
     */
    'client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGoogleSsoConfig
     */
    'redirect_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGoogleSsoConfig
     */
    'url_authorize'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGoogleSsoConfig
     */
    'url_access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeGoogleSsoConfig
     */
    'scopes'?: string;
}
/**
 * 
 * @export
 * @interface PpeGrantTemporaryAccessRequest
 */
export interface PpeGrantTemporaryAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeGrantTemporaryAccessRequest
     */
    'contact_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeGrantTemporaryAccessRequest
     */
    'expiry_epoch'?: number;
}
/**
 * 
 * @export
 * @interface PpeGrantTemporaryAccessResponse
 */
export interface PpeGrantTemporaryAccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeGrantTemporaryAccessResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeGrantTemporaryAccessResponse
     */
    'error_message'?: string;
}
/**
 * Location describes where an item can be. It could be vending machine, or text description. It\'s reserved for now.
 * @export
 * @interface PpeItemLocation
 */
export interface PpeItemLocation {
    /**
     * 
     * @type {string}
     * @memberof PpeItemLocation
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface PpeJob
 */
export interface PpeJob {
    /**
     * 
     * @type {string}
     * @memberof PpeJob
     */
    'uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeJob
     */
    'created_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeJob
     */
    'updated_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeJob
     */
    'job_code'?: string;
    /**
     * 
     * @type {PpeJobStatus}
     * @memberof PpeJob
     */
    'job_status'?: PpeJobStatus;
    /**
     * 
     * @type {string}
     * @memberof PpeJob
     */
    'admin_note'?: string;
}
/**
 * 
 * @export
 * @interface PpeJobDetail
 */
export interface PpeJobDetail {
    /**
     * 
     * @type {PpeJob}
     * @memberof PpeJobDetail
     */
    'job'?: PpeJob;
    /**
     * 
     * @type {Array<PpeTransaction>}
     * @memberof PpeJobDetail
     */
    'transactions'?: Array<PpeTransaction>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeJobIDStatus = {
    Disabled: 'JOB_ID_DISABLED',
    Enabled: 'JOB_ID_ENABLED',
    Enforced: 'JOB_ID_ENFORCED',
    StrictlyEnforced: 'JOB_ID_STRICTLY_ENFORCED'
} as const;

export type PpeJobIDStatus = typeof PpeJobIDStatus[keyof typeof PpeJobIDStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpeJobStatus = {
    Enabled: 'JOB_ENABLED',
    Disabled: 'JOB_DISABLED'
} as const;

export type PpeJobStatus = typeof PpeJobStatus[keyof typeof PpeJobStatus];


/**
 * 
 * @export
 * @interface PpeKeyCardTapEvent
 */
export interface PpeKeyCardTapEvent {
    /**
     * 
     * @type {string}
     * @memberof PpeKeyCardTapEvent
     */
    'key_card_number'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeKeyCardTapEvent
     */
    'epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeKeyCardTapEvent
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeKeyCardTapEvent
     */
    'vending_machine_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeKeyCardTapEvent
     */
    'vending_machine_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeKeyCardTapEvent
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeKeyCardTapEvent
     */
    'employee_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeKeyCardTapEvent
     */
    'is_key_card_allocated'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeKiosk
 */
export interface PpeKiosk {
    /**
     * 
     * @type {string}
     * @memberof PpeKiosk
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeKiosk
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PpeLinkMicrosoftAccountRequest
 */
export interface PpeLinkMicrosoftAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeLinkMicrosoftAccountRequest
     */
    'microsoft_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeLinkMicrosoftAccountRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeLinkMicrosoftAccountRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeLinkMicrosoftAccountRequest
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface PpeLinkMicrosoftAccountResponse
 */
export interface PpeLinkMicrosoftAccountResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeLinkMicrosoftAccountResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeLinkMicrosoftAccountResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeLoginResponseCode}
     * @memberof PpeLinkMicrosoftAccountResponse
     */
    'code'?: PpeLoginResponseCode;
    /**
     * 
     * @type {string}
     * @memberof PpeLinkMicrosoftAccountResponse
     */
    'security_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeLinkMicrosoftAccountResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface PpeListAdminsResponse
 */
export interface PpeListAdminsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListAdminsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListAdminsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeAdmin>}
     * @memberof PpeListAdminsResponse
     */
    'admins'?: Array<PpeAdmin>;
}
/**
 * 
 * @export
 * @interface PpeListAuditLogsRequest
 */
export interface PpeListAuditLogsRequest {
    /**
     * 
     * @type {number}
     * @memberof PpeListAuditLogsRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeListAuditLogsRequest
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface PpeListAuditLogsResponse
 */
export interface PpeListAuditLogsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListAuditLogsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListAuditLogsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeAuditLog>}
     * @memberof PpeListAuditLogsResponse
     */
    'audit_logs'?: Array<PpeAuditLog>;
    /**
     * 
     * @type {number}
     * @memberof PpeListAuditLogsResponse
     */
    'current_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeListAuditLogsResponse
     */
    'number_of_page'?: number;
}
/**
 * 
 * @export
 * @interface PpeListAvailableLockerCellsRequest
 */
export interface PpeListAvailableLockerCellsRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeListAvailableLockerCellsRequest
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeListAvailableLockerCellsRequest
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface PpeListAvailableLockerCellsResponse
 */
export interface PpeListAvailableLockerCellsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListAvailableLockerCellsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListAvailableLockerCellsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeAvailableLockerCell>}
     * @memberof PpeListAvailableLockerCellsResponse
     */
    'available_cells'?: Array<PpeAvailableLockerCell>;
}
/**
 * 
 * @export
 * @interface PpeListJobsRequest
 */
export interface PpeListJobsRequest {
    /**
     * 
     * @type {number}
     * @memberof PpeListJobsRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeListJobsRequest
     */
    'per'?: number;
    /**
     * 
     * @type {PpeJobStatus}
     * @memberof PpeListJobsRequest
     */
    'job_status'?: PpeJobStatus;
    /**
     * 
     * @type {string}
     * @memberof PpeListJobsRequest
     */
    'job_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeListJobsRequest
     */
    'admin_note'?: string;
}
/**
 * 
 * @export
 * @interface PpeListJobsResponse
 */
export interface PpeListJobsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListJobsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListJobsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeJob>}
     * @memberof PpeListJobsResponse
     */
    'jobs'?: Array<PpeJob>;
    /**
     * 
     * @type {number}
     * @memberof PpeListJobsResponse
     */
    'total_pages'?: number;
}
/**
 * 
 * @export
 * @interface PpeListKeyCardTapEventRequest
 */
export interface PpeListKeyCardTapEventRequest {
    /**
     * 
     * @type {number}
     * @memberof PpeListKeyCardTapEventRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeListKeyCardTapEventRequest
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeListKeyCardTapEventRequest
     */
    'vending_machine_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeListKeyCardTapEventResponse
 */
export interface PpeListKeyCardTapEventResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListKeyCardTapEventResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListKeyCardTapEventResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeKeyCardTapEvent>}
     * @memberof PpeListKeyCardTapEventResponse
     */
    'events'?: Array<PpeKeyCardTapEvent>;
    /**
     * 
     * @type {number}
     * @memberof PpeListKeyCardTapEventResponse
     */
    'current_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeListKeyCardTapEventResponse
     */
    'number_of_page'?: number;
}
/**
 * 
 * @export
 * @interface PpeListKiosksResponse
 */
export interface PpeListKiosksResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListKiosksResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListKiosksResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeKiosk>}
     * @memberof PpeListKiosksResponse
     */
    'kiosks'?: Array<PpeKiosk>;
}
/**
 * 
 * @export
 * @interface PpeListLockersResponse
 */
export interface PpeListLockersResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListLockersResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListLockersResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeLockerInformation>}
     * @memberof PpeListLockersResponse
     */
    'lockers'?: Array<PpeLockerInformation>;
}
/**
 * 
 * @export
 * @interface PpeListOrdersRequest
 */
export interface PpeListOrdersRequest {
    /**
     * 
     * @type {number}
     * @memberof PpeListOrdersRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeListOrdersRequest
     */
    'per'?: number;
    /**
     * 
     * @type {Array<PpeOrderState>}
     * @memberof PpeListOrdersRequest
     */
    'states'?: Array<PpeOrderState>;
}
/**
 * 
 * @export
 * @interface PpeListOrdersResponse
 */
export interface PpeListOrdersResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListOrdersResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListOrdersResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeOrderInfo>}
     * @memberof PpeListOrdersResponse
     */
    'orders'?: Array<PpeOrderInfo>;
    /**
     * 
     * @type {number}
     * @memberof PpeListOrdersResponse
     */
    'total_page'?: number;
}
/**
 * 
 * @export
 * @interface PpeListOrganizationsResponse
 */
export interface PpeListOrganizationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListOrganizationsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListOrganizationsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeOrganizationOption>}
     * @memberof PpeListOrganizationsResponse
     */
    'organization_options'?: Array<PpeOrganizationOption>;
}
/**
 * 
 * @export
 * @interface PpeListPartnerUsersResponse
 */
export interface PpeListPartnerUsersResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListPartnerUsersResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListPartnerUsersResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpePartnerUser>}
     * @memberof PpeListPartnerUsersResponse
     */
    'partner_users'?: Array<PpePartnerUser>;
}
/**
 * 
 * @export
 * @interface PpeListProductMapsResponse
 */
export interface PpeListProductMapsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListProductMapsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListProductMapsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeProductMapInfo>}
     * @memberof PpeListProductMapsResponse
     */
    'product_maps'?: Array<PpeProductMapInfo>;
}
/**
 * 
 * @export
 * @interface PpeListProductsRequest
 */
export interface PpeListProductsRequest {
    /**
     * 
     * @type {number}
     * @memberof PpeListProductsRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeListProductsRequest
     */
    'page'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PpeListProductsRequest
     */
    'only_current'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeListProductsResponse
 */
export interface PpeListProductsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListProductsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListProductsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeListProductsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeListProductsResponse
     */
    'total_page'?: number;
    /**
     * 
     * @type {Array<PpeProductInfo>}
     * @memberof PpeListProductsResponse
     */
    'products'?: Array<PpeProductInfo>;
}
/**
 * 
 * @export
 * @interface PpeListRestrictionsRequest
 */
export interface PpeListRestrictionsRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeListRestrictionsRequest
     */
    'product_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeListRestrictionsResponse
 */
export interface PpeListRestrictionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListRestrictionsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListRestrictionsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeRestriction>}
     * @memberof PpeListRestrictionsResponse
     */
    'restrictions'?: Array<PpeRestriction>;
}
/**
 * 
 * @export
 * @interface PpeListScheduledReportsResponse
 */
export interface PpeListScheduledReportsResponse {
    /**
     * 
     * @type {Array<PpeScheduledReport>}
     * @memberof PpeListScheduledReportsResponse
     */
    'reports'?: Array<PpeScheduledReport>;
}
/**
 * 
 * @export
 * @interface PpeListServiceNowGroupResponse
 */
export interface PpeListServiceNowGroupResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListServiceNowGroupResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListServiceNowGroupResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<PpeServiceNowUserGroup>}
     * @memberof PpeListServiceNowGroupResponse
     */
    'user_groups'?: Array<PpeServiceNowUserGroup>;
}
/**
 * 
 * @export
 * @interface PpeListServiceNowUserSyncJobsResponse
 */
export interface PpeListServiceNowUserSyncJobsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListServiceNowUserSyncJobsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListServiceNowUserSyncJobsResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PpeListTemplatesResponse
 */
export interface PpeListTemplatesResponse {
    /**
     * 
     * @type {Array<PpeReportTemplate>}
     * @memberof PpeListTemplatesResponse
     */
    'templates'?: Array<PpeReportTemplate>;
}
/**
 * 
 * @export
 * @interface PpeListTrackableItemsResponse
 */
export interface PpeListTrackableItemsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListTrackableItemsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListTrackableItemsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeListTrackableItemsResponse
     */
    'total_count'?: number;
    /**
     * 
     * @type {Array<PpeTrackableItem>}
     * @memberof PpeListTrackableItemsResponse
     */
    'items'?: Array<PpeTrackableItem>;
}
/**
 * 
 * @export
 * @interface PpeListVendingMachinesRequest
 */
export interface PpeListVendingMachinesRequest {
    /**
     * 
     * @type {number}
     * @memberof PpeListVendingMachinesRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeListVendingMachinesRequest
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface PpeListVendingMachinesResponse
 */
export interface PpeListVendingMachinesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeListVendingMachinesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeListVendingMachinesResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeVendingMachineInfo>}
     * @memberof PpeListVendingMachinesResponse
     */
    'vending_machines'?: Array<PpeVendingMachineInfo>;
    /**
     * 
     * @type {number}
     * @memberof PpeListVendingMachinesResponse
     */
    'total_page'?: number;
}
/**
 * 
 * @export
 * @interface PpeLockerInformation
 */
export interface PpeLockerInformation {
    /**
     * 
     * @type {string}
     * @memberof PpeLockerInformation
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeLockerInformation
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeLockerInformation
     */
    'rows'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeLockerInformation
     */
    'cols'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PpeLockerInformation
     */
    'is_online'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeLockerInformation
     */
    'photo_url'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeLoginResponseCode = {
    TfaRequired: 'TFA_REQUIRED',
    LoggedIn: 'LOGGED_IN',
    Error: 'ERROR'
} as const;

export type PpeLoginResponseCode = typeof PpeLoginResponseCode[keyof typeof PpeLoginResponseCode];


/**
 * 
 * @export
 * @interface PpeLoginToOrganizationRequest
 */
export interface PpeLoginToOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeLoginToOrganizationRequest
     */
    'organization_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeLoginToOrganizationResponse
 */
export interface PpeLoginToOrganizationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeLoginToOrganizationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeLoginToOrganizationResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeLoginToOrganizationResponse
     */
    'security_token'?: string;
}
/**
 * 
 * @export
 * @interface PpeManualExecuteScheduledReportRequest
 */
export interface PpeManualExecuteScheduledReportRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeManualExecuteScheduledReportRequest
     */
    'scheduled_report_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeManuallyUpdateCurrentUserRequest
 */
export interface PpeManuallyUpdateCurrentUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeManuallyUpdateCurrentUserRequest
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeManuallyUpdateCurrentUserRequest
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeManuallyUpdateCurrentUserRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PpeManuallyUpdateCurrentUserResponse
 */
export interface PpeManuallyUpdateCurrentUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeManuallyUpdateCurrentUserResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeManuallyUpdateCurrentUserResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeMarkPendingInspectionRequest
 */
export interface PpeMarkPendingInspectionRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeMarkPendingInspectionRequest
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMarkPendingInspectionRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PpeMarkPendingInspectionResponse
 */
export interface PpeMarkPendingInspectionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeMarkPendingInspectionResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeMarkPendingInspectionResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeMarkReadyRequest
 */
export interface PpeMarkReadyRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeMarkReadyRequest
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMarkReadyRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PpeMarkReadyResponse
 */
export interface PpeMarkReadyResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeMarkReadyResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeMarkReadyResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeMarkTrackableItemAsMissingRequest
 */
export interface PpeMarkTrackableItemAsMissingRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeMarkTrackableItemAsMissingRequest
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMarkTrackableItemAsMissingRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PpeMarkTrackableItemAsMissingResponse
 */
export interface PpeMarkTrackableItemAsMissingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeMarkTrackableItemAsMissingResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeMarkTrackableItemAsMissingResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeMicrosoftSsoConfig
 */
export interface PpeMicrosoftSsoConfig {
    /**
     * 
     * @type {boolean}
     * @memberof PpeMicrosoftSsoConfig
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeMicrosoftSsoConfig
     */
    'client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMicrosoftSsoConfig
     */
    'redirect_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMicrosoftSsoConfig
     */
    'url_authorize'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMicrosoftSsoConfig
     */
    'url_access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMicrosoftSsoConfig
     */
    'scopes'?: string;
}
/**
 * 
 * @export
 * @interface PpeMicrosoftSsoSetting
 */
export interface PpeMicrosoftSsoSetting {
    /**
     * 
     * @type {string}
     * @memberof PpeMicrosoftSsoSetting
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMicrosoftSsoSetting
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMicrosoftSsoSetting
     */
    'principal'?: string;
}
/**
 * 
 * @export
 * @interface PpeMoveItemToLockerRequest
 */
export interface PpeMoveItemToLockerRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeMoveItemToLockerRequest
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMoveItemToLockerRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeMoveItemToLockerRequest
     */
    'locker_cell_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeMoveItemToLockerResponse
 */
export interface PpeMoveItemToLockerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeMoveItemToLockerResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeMoveItemToLockerResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeMyInfo
 */
export interface PpeMyInfo {
    /**
     * 
     * @type {PpeAdmin}
     * @memberof PpeMyInfo
     */
    'admin'?: PpeAdmin;
    /**
     * 
     * @type {PpeEmailPreferences}
     * @memberof PpeMyInfo
     */
    'email_preferences'?: PpeEmailPreferences;
    /**
     * 
     * @type {PpeMicrosoftSsoSetting}
     * @memberof PpeMyInfo
     */
    'microsoft_sso_setting'?: PpeMicrosoftSsoSetting;
}
/**
 * 
 * @export
 * @interface PpeOpenLockerCellDoorRequest
 */
export interface PpeOpenLockerCellDoorRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeOpenLockerCellDoorRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeOpenLockerCellDoorRequest
     */
    'cell_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeOpenLockerCellDoorResponse
 */
export interface PpeOpenLockerCellDoorResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeOpenLockerCellDoorResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeOpenLockerCellDoorResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeOrderInfo
 */
export interface PpeOrderInfo {
    /**
     * 
     * @type {string}
     * @memberof PpeOrderInfo
     */
    'uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeOrderInfo
     */
    'created_epoch'?: number;
    /**
     * 
     * @type {PpeOrderState}
     * @memberof PpeOrderInfo
     */
    'state'?: PpeOrderState;
    /**
     * 
     * @type {string}
     * @memberof PpeOrderInfo
     */
    'txn_ref'?: string;
    /**
     * 
     * @type {PpeEmployee}
     * @memberof PpeOrderInfo
     */
    'employee'?: PpeEmployee;
    /**
     * 
     * @type {string}
     * @memberof PpeOrderInfo
     */
    'selection'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeOrderInfo
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeOrderInfo
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeOrderInfo
     */
    'vending_machine_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeOrderInfo
     */
    'vending_machine_uuid'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeOrderState = {
    PendingSelection: 'PENDING_SELECTION',
    PendingCustomerConfirm: 'PENDING_CUSTOMER_CONFIRM',
    Vending: 'VENDING',
    Failure: 'FAILURE',
    VendedSuccess: 'VENDED_SUCCESS',
    Aborted: 'ABORTED'
} as const;

export type PpeOrderState = typeof PpeOrderState[keyof typeof PpeOrderState];


/**
 * 
 * @export
 * @interface PpeOrganization
 */
export interface PpeOrganization {
    /**
     * 
     * @type {string}
     * @memberof PpeOrganization
     */
    'name'?: string;
    /**
     * 
     * @type {PpePhoneNumber}
     * @memberof PpeOrganization
     */
    'phone_number'?: PpePhoneNumber;
    /**
     * 
     * @type {PpePickupMethods}
     * @memberof PpeOrganization
     */
    'pickup_methods'?: PpePickupMethods;
    /**
     * 
     * @type {PpeCapabilities}
     * @memberof PpeOrganization
     */
    'capabilities'?: PpeCapabilities;
    /**
     * 
     * @type {PpeServiceNow}
     * @memberof PpeOrganization
     */
    'servicenow'?: PpeServiceNow;
    /**
     * 
     * @type {PpeJobIDStatus}
     * @memberof PpeOrganization
     */
    'job_id_status'?: PpeJobIDStatus;
    /**
     * 
     * @type {string}
     * @memberof PpeOrganization
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeOrganization
     */
    'default_wiegand_facility_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeOrganization
     */
    'facility_code_length'?: number;
    /**
     * if the desktop reader is using the \"raw wiegand\" protocol. In this mode, the wiegand is 24-bit rather than 26-bit. The prelude and trailing code is omited.
     * @type {boolean}
     * @memberof PpeOrganization
     */
    'raw_wiegand_reading'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeOrganizationOption
 */
export interface PpeOrganizationOption {
    /**
     * 
     * @type {string}
     * @memberof PpeOrganizationOption
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeOrganizationOption
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeOrganizationOption
     */
    'logo'?: string;
}
/**
 * 
 * @export
 * @interface PpePartner
 */
export interface PpePartner {
    /**
     * 
     * @type {string}
     * @memberof PpePartner
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartner
     */
    'parent_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartner
     */
    'parent_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpePartner
     */
    'created_at'?: number;
    /**
     * 
     * @type {Array<PpePartnerUserOrganization>}
     * @memberof PpePartner
     */
    'organizations'?: Array<PpePartnerUserOrganization>;
}
/**
 * 
 * @export
 * @interface PpePartnerUser
 */
export interface PpePartnerUser {
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUser
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUser
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PpePartnerUserListPartnersResponse
 */
export interface PpePartnerUserListPartnersResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpePartnerUserListPartnersResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserListPartnersResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpePartner>}
     * @memberof PpePartnerUserListPartnersResponse
     */
    'partners'?: Array<PpePartner>;
}
/**
 * 
 * @export
 * @interface PpePartnerUserLoginRequest
 */
export interface PpePartnerUserLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserLoginRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface PpePartnerUserLoginResponse
 */
export interface PpePartnerUserLoginResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpePartnerUserLoginResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserLoginResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PpePartnerUserLoginToOrganizationRequest
 */
export interface PpePartnerUserLoginToOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserLoginToOrganizationRequest
     */
    'partner_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserLoginToOrganizationRequest
     */
    'organization_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpePartnerUserLoginToOrganizationResponse
 */
export interface PpePartnerUserLoginToOrganizationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpePartnerUserLoginToOrganizationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserLoginToOrganizationResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserLoginToOrganizationResponse
     */
    'admin_token'?: string;
}
/**
 * 
 * @export
 * @interface PpePartnerUserOrganization
 */
export interface PpePartnerUserOrganization {
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserOrganization
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserOrganization
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserOrganization
     */
    'logo'?: string;
}
/**
 * 
 * @export
 * @interface PpePartnerUserVerifyLoginRequest
 */
export interface PpePartnerUserVerifyLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserVerifyLoginRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserVerifyLoginRequest
     */
    'auth_code'?: string;
}
/**
 * 
 * @export
 * @interface PpePartnerUserVerifyLoginResponse
 */
export interface PpePartnerUserVerifyLoginResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpePartnerUserVerifyLoginResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserVerifyLoginResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePartnerUserVerifyLoginResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface PpePhoneNumber
 */
export interface PpePhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof PpePhoneNumber
     */
    'contact_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpePhoneNumber
     */
    'country_code'?: string;
}
/**
 * 
 * @export
 * @interface PpePickupMethods
 */
export interface PpePickupMethods {
    /**
     * 
     * @type {boolean}
     * @memberof PpePickupMethods
     */
    'mifare_card'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpePickupMethods
     */
    'mobile_app'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeProductInfo
 */
export interface PpeProductInfo {
    /**
     * 
     * @type {string}
     * @memberof PpeProductInfo
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeProductInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeProductInfo
     */
    'organization_uuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeProductInfo
     */
    'is_placeholder'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeProductInfo
     */
    'servicenow_sysid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeProductInfo
     */
    'photo_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeProductInfo
     */
    'is_trackable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeProductInfo
     */
    'is_restricted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeProductInfo
     */
    'category_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeProductInfo
     */
    'inspection_interval'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PpeProductInfo
     */
    'is_current'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeProductMapInfo
 */
export interface PpeProductMapInfo {
    /**
     * 
     * @type {string}
     * @memberof PpeProductMapInfo
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeProductMapInfo
     */
    'vending_machine_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeProductMapInfo
     */
    'capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeProductMapInfo
     */
    'consumed'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeProductMapInfo
     */
    'restock_count'?: number;
    /**
     * 
     * @type {PpeProductInfo}
     * @memberof PpeProductMapInfo
     */
    'product'?: PpeProductInfo;
    /**
     * 
     * @type {number}
     * @memberof PpeProductMapInfo
     */
    'mdb_code'?: number;
}
/**
 * 
 * @export
 * @interface PpeRefillLockerCellRequest
 */
export interface PpeRefillLockerCellRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeRefillLockerCellRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeRefillLockerCellRequest
     */
    'cell_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeRefillLockerCellResponse
 */
export interface PpeRefillLockerCellResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRefillLockerCellResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRefillLockerCellResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRefillLockerRequest
 */
export interface PpeRefillLockerRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeRefillLockerRequest
     */
    'locker_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeRefillLockerResponse
 */
export interface PpeRefillLockerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRefillLockerResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRefillLockerResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRefillTrackableItemToCellRequest
 */
export interface PpeRefillTrackableItemToCellRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeRefillTrackableItemToCellRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeRefillTrackableItemToCellRequest
     */
    'cell_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeRefillTrackableItemToCellRequest
     */
    'trackable_item_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeRefillTrackableItemToCellResponse
 */
export interface PpeRefillTrackableItemToCellResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRefillTrackableItemToCellResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRefillTrackableItemToCellResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRefillVendingMachineResponse
 */
export interface PpeRefillVendingMachineResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRefillVendingMachineResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRefillVendingMachineResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRemovePartnerResponse
 */
export interface PpeRemovePartnerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRemovePartnerResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRemovePartnerResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRemoveProductMapResponse
 */
export interface PpeRemoveProductMapResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRemoveProductMapResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRemoveProductMapResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRemoveRestrictionRequest
 */
export interface PpeRemoveRestrictionRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeRemoveRestrictionRequest
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeRemoveRestrictionRequest
     */
    'restriction_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeRemoveRestrictionResponse
 */
export interface PpeRemoveRestrictionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRemoveRestrictionResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRemoveRestrictionResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRemoveScheduledReportRequest
 */
export interface PpeRemoveScheduledReportRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeRemoveScheduledReportRequest
     */
    'scheduled_report_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeRemoveTemplateRequest
 */
export interface PpeRemoveTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeRemoveTemplateRequest
     */
    'template_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeReplaceAssetTagRequest
 */
export interface PpeReplaceAssetTagRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeReplaceAssetTagRequest
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeReplaceAssetTagRequest
     */
    'asset_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeReplaceAssetTagRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PpeReplaceAssetTagResponse
 */
export interface PpeReplaceAssetTagResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeReplaceAssetTagResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeReplaceAssetTagResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeReportTemplate
 */
export interface PpeReportTemplate {
    /**
     * 
     * @type {string}
     * @memberof PpeReportTemplate
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeReportTemplate
     */
    'original_filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeReportTemplate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeReportTemplate
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeReportTemplate
     */
    'last_modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeReportTemplate
     */
    'created_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeReportTemplate
     */
    'template_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeReportTemplate
     */
    'valid'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeResetPasswordResponse
 */
export interface PpeResetPasswordResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeResetPasswordResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeResetPasswordResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRestriction
 */
export interface PpeRestriction {
    /**
     * 
     * @type {string}
     * @memberof PpeRestriction
     */
    'restriction_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeRestriction
     */
    'inspection_period'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeRestriction
     */
    'max_quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeRestriction
     */
    'added_at_epoch_second'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PpeRestriction
     */
    'daily_reset'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeRetireTrackableItemResponse
 */
export interface PpeRetireTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRetireTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRetireTrackableItemResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRevokeAdminRequest
 */
export interface PpeRevokeAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeRevokeAdminRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface PpeRevokeAdminResponse
 */
export interface PpeRevokeAdminResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRevokeAdminResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRevokeAdminResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRevokePartnerUserResponse
 */
export interface PpeRevokePartnerUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRevokePartnerUserResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRevokePartnerUserResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRevokeTemporaryAccessResponse
 */
export interface PpeRevokeTemporaryAccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeRevokeTemporaryAccessResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeRevokeTemporaryAccessResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeRunReportRequest
 */
export interface PpeRunReportRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeRunReportRequest
     */
    'template_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeRunReportRequest
     */
    'start_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeRunReportRequest
     */
    'end_date'?: string;
}
/**
 * 
 * @export
 * @interface PpeRunReportResponse
 */
export interface PpeRunReportResponse {
    /**
     * 
     * @type {string}
     * @memberof PpeRunReportResponse
     */
    'report_url'?: string;
}
/**
 * 
 * @export
 * @interface PpeScheduledReport
 */
export interface PpeScheduledReport {
    /**
     * 
     * @type {string}
     * @memberof PpeScheduledReport
     */
    'uuid'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeScheduledReport
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PpeScheduledReport
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeScheduledReport
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeScheduledReport
     */
    'last_running_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeScheduledReport
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeScheduledReport
     */
    'template_uuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeScheduledReport
     */
    'transactions_csv'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeScheduledReport
     */
    'email_subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeScheduledReport
     */
    'timezone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeScheduledReport
     */
    'day'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeScheduledReport
     */
    'seven_days'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeScheduledReport
     */
    'sunday_week'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeScheduledReport
     */
    'monday_week'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeScheduledReport
     */
    'last_28_days'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeScheduledReport
     */
    'last_calendar_month'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeScheduledReport
     */
    'cron'?: string;
}
/**
 * 
 * @export
 * @interface PpeSearchEmployeesRequest
 */
export interface PpeSearchEmployeesRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeSearchEmployeesRequest
     */
    'term'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeSearchEmployeesRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeSearchEmployeesRequest
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface PpeSearchEmployeesResponse
 */
export interface PpeSearchEmployeesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeSearchEmployeesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeSearchEmployeesResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeePagination}
     * @memberof PpeSearchEmployeesResponse
     */
    'data'?: PpeEmployeePagination;
}
/**
 * 
 * @export
 * @interface PpeSearchProductsRequest
 */
export interface PpeSearchProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeSearchProductsRequest
     */
    'term'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeSearchProductsRequest
     */
    'only_current'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PpeSearchProductsRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeSearchProductsRequest
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface PpeSearchProductsResponse
 */
export interface PpeSearchProductsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeSearchProductsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeSearchProductsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeSearchProductsResponse
     */
    'total_page'?: number;
    /**
     * 
     * @type {Array<PpeProductInfo>}
     * @memberof PpeSearchProductsResponse
     */
    'products'?: Array<PpeProductInfo>;
}
/**
 * 
 * @export
 * @interface PpeSelfServicingConfig
 */
export interface PpeSelfServicingConfig {
    /**
     * 
     * @type {boolean}
     * @memberof PpeSelfServicingConfig
     */
    'enable_self_servicing'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeSelfServicingConfig
     */
    'authorized_domains'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PpeSendTemporaryAccessUrlResponse
 */
export interface PpeSendTemporaryAccessUrlResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeSendTemporaryAccessUrlResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeSendTemporaryAccessUrlResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeServiceNow
 */
export interface PpeServiceNow {
    /**
     * 
     * @type {string}
     * @memberof PpeServiceNow
     */
    'api_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeServiceNow
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface PpeServiceNowUserGroup
 */
export interface PpeServiceNowUserGroup {
    /**
     * 
     * @type {string}
     * @memberof PpeServiceNowUserGroup
     */
    'sys_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeServiceNowUserGroup
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeServiceNowUserGroup
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeServiceNowUserGroup
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeServiceNowUserGroup
     */
    'included_for_users'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeServiceNowUserGroup
     */
    'included_for_admin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeServiceNowUserGroup
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeSessionRole = {
    Admin: 'ADMIN',
    Employee: 'EMPLOYEE',
    System: 'SYSTEM'
} as const;

export type PpeSessionRole = typeof PpeSessionRole[keyof typeof PpeSessionRole];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpeSessionType = {
    Use: 'USE',
    Inspection: 'INSPECTION'
} as const;

export type PpeSessionType = typeof PpeSessionType[keyof typeof PpeSessionType];


/**
 * 
 * @export
 * @interface PpeSetupTfaRequest
 */
export interface PpeSetupTfaRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeSetupTfaRequest
     */
    'one_time_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeSetupTfaRequest
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface PpeSetupTfaResponse
 */
export interface PpeSetupTfaResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeSetupTfaResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeSetupTfaResponse
     */
    'error_message'?: string;
}
/**
 * - NO_MATCH: the email does not match any account, unable to process the login request  - UNLINKED: the email is not exist in the system, user need to link the social account with a exist PPE account.
 * @export
 * @enum {string}
 */

export const PpeSsoResponseCode = {
    NoMatch: 'NO_MATCH',
    Unlinked: 'UNLINKED'
} as const;

export type PpeSsoResponseCode = typeof PpeSsoResponseCode[keyof typeof PpeSsoResponseCode];


/**
 * 
 * @export
 * @interface PpeStockTrackableItemRequest
 */
export interface PpeStockTrackableItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeStockTrackableItemRequest
     */
    'trackable_item_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeStockTrackableItemResponse
 */
export interface PpeStockTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeStockTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeStockTrackableItemResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeSyncServiceNowGroupResponse
 */
export interface PpeSyncServiceNowGroupResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeSyncServiceNowGroupResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeSyncServiceNowGroupResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeSyncServiceNowGroupResponse
     */
    'total_teams'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeSyncServiceNowGroupResponse
     */
    'added_teams'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeSyncServiceNowGroupResponse
     */
    'removed_teams'?: string;
}
/**
 * 
 * @export
 * @interface PpeTakeOutToInspectionRequest
 */
export interface PpeTakeOutToInspectionRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeTakeOutToInspectionRequest
     */
    'trackable_item_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeTakeOutToInspectionResponse
 */
export interface PpeTakeOutToInspectionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeTakeOutToInspectionResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeTakeOutToInspectionResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeTakeoutTrackableItemFromCellRequest
 */
export interface PpeTakeoutTrackableItemFromCellRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeTakeoutTrackableItemFromCellRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTakeoutTrackableItemFromCellRequest
     */
    'cell_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeTakeoutTrackableItemFromCellResponse
 */
export interface PpeTakeoutTrackableItemFromCellResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeTakeoutTrackableItemFromCellResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeTakeoutTrackableItemFromCellResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeTfaSetupData
 */
export interface PpeTfaSetupData {
    /**
     * 
     * @type {string}
     * @memberof PpeTfaSetupData
     */
    'qr_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTfaSetupData
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface PpeTrackableItem
 */
export interface PpeTrackableItem {
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'asset_tag'?: string;
    /**
     * 
     * @type {PpeTrackableItemState}
     * @memberof PpeTrackableItem
     */
    'state'?: PpeTrackableItemState;
    /**
     * 
     * @type {PpeTrackableItemState}
     * @memberof PpeTrackableItem
     */
    'prev_state'?: PpeTrackableItemState;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'last_state_change_epoch'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'last_attribute_change_epoch'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'created_at_epoch'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'note'?: string;
    /**
     * 
     * @type {PpeItemLocation}
     * @memberof PpeTrackableItem
     */
    'location'?: PpeItemLocation;
    /**
     * 
     * @type {boolean}
     * @memberof PpeTrackableItem
     */
    'require_attention'?: boolean;
    /**
     * 
     * @type {PpeTrackableItemInUseSession}
     * @memberof PpeTrackableItem
     */
    'current_in_use'?: PpeTrackableItemInUseSession;
    /**
     * 
     * @type {Array<PpeTrackableItemInUseSession>}
     * @memberof PpeTrackableItem
     */
    'five_recent_uses'?: Array<PpeTrackableItemInUseSession>;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItem
     */
    'attention_note'?: string;
}
/**
 * 
 * @export
 * @interface PpeTrackableItemInUseSession
 */
export interface PpeTrackableItemInUseSession {
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemInUseSession
     */
    'uuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeTrackableItemInUseSession
     */
    'in_use'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemInUseSession
     */
    'employee_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemInUseSession
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeTrackableItemInUseSession
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeTrackableItemInUseSession
     */
    'finish'?: number;
}
/**
 * 
 * @export
 * @interface PpeTrackableItemSession
 */
export interface PpeTrackableItemSession {
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemSession
     */
    'uuid'?: string;
    /**
     * 
     * @type {PpeSessionType}
     * @memberof PpeTrackableItemSession
     */
    'type'?: PpeSessionType;
    /**
     * 
     * @type {PpeSessionRole}
     * @memberof PpeTrackableItemSession
     */
    'start_role'?: PpeSessionRole;
    /**
     * 
     * @type {PpeSessionRole}
     * @memberof PpeTrackableItemSession
     */
    'end_role'?: PpeSessionRole;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemSession
     */
    'start_role_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemSession
     */
    'start_role_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemSession
     */
    'end_role_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemSession
     */
    'end_role_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemSession
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTrackableItemSession
     */
    'finish'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeTrackableItemState = {
    Draft: 'DRAFT',
    Inventory: 'INVENTORY',
    Ready: 'READY',
    InUse: 'IN_USE',
    Returned: 'RETURNED',
    PendingInspection: 'PENDING_INSPECTION',
    Lost: 'LOST',
    Retired: 'RETIRED',
    Deleted: 'DELETED'
} as const;

export type PpeTrackableItemState = typeof PpeTrackableItemState[keyof typeof PpeTrackableItemState];


/**
 * 
 * @export
 * @interface PpeTransaction
 */
export interface PpeTransaction {
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'uuid'?: string;
    /**
     * 
     * @type {PpeTransactionType}
     * @memberof PpeTransaction
     */
    'type'?: PpeTransactionType;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'machine_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'machine_site'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'vending_machine_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'selection'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'key_card'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeTransaction
     */
    'event_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'employee_name'?: string;
    /**
     * 
     * @type {PpeTrackableItemInUseSession}
     * @memberof PpeTransaction
     */
    'trackable_item_session'?: PpeTrackableItemInUseSession;
    /**
     * 
     * @type {string}
     * @memberof PpeTransaction
     */
    'job_id'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeTransactionAggregation = {
    Employee: 'AGGREGATED_BY_EMPLOYEE',
    Product: 'AGGREGATED_BY_PRODUCT',
    Machine: 'AGGREGATED_BY_MACHINE'
} as const;

export type PpeTransactionAggregation = typeof PpeTransactionAggregation[keyof typeof PpeTransactionAggregation];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpeTransactionAggregationOrder = {
    Name: 'NAME',
    NameDesc: 'NAME_DESC',
    Count: 'COUNT',
    CountDesc: 'COUNT_DESC'
} as const;

export type PpeTransactionAggregationOrder = typeof PpeTransactionAggregationOrder[keyof typeof PpeTransactionAggregationOrder];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpeTransactionType = {
    VendingMachine: 'VENDING_MACHINE',
    Locker: 'LOCKER',
    Neither: 'NEITHER'
} as const;

export type PpeTransactionType = typeof PpeTransactionType[keyof typeof PpeTransactionType];


/**
 * 
 * @export
 * @interface PpeTransactionsCount
 */
export interface PpeTransactionsCount {
    /**
     * 
     * @type {string}
     * @memberof PpeTransactionsCount
     */
    'key'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeTransactionsCount
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface PpeTransactionsCountByProduct
 */
export interface PpeTransactionsCountByProduct {
    /**
     * 
     * @type {string}
     * @memberof PpeTransactionsCountByProduct
     */
    'product'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeTransactionsCountByProduct
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeTransactionsCountByProduct
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface PpeUnassignAssetTagToTrackableItemRequest
 */
export interface PpeUnassignAssetTagToTrackableItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUnassignAssetTagToTrackableItemRequest
     */
    'trackable_item_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeUnassignAssetTagToTrackableItemResponse
 */
export interface PpeUnassignAssetTagToTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUnassignAssetTagToTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUnassignAssetTagToTrackableItemResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUnlinkMsSsoRequest
 */
export interface PpeUnlinkMsSsoRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUnlinkMsSsoRequest
     */
    'principal'?: string;
}
/**
 * 
 * @export
 * @interface PpeUnlinkMsSsoResponse
 */
export interface PpeUnlinkMsSsoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUnlinkMsSsoResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUnlinkMsSsoResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateEmailPreferencesRequest
 */
export interface PpeUpdateEmailPreferencesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateEmailPreferencesRequest
     */
    'is_receive_daily_report'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateEmailPreferencesRequest
     */
    'is_receive_monthly_report'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateEmailPreferencesRequest
     */
    'is_receive_vend_failure_alert'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateEmailPreferencesRequest
     */
    'is_receive_pending_inspection_alert'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeUpdateEmailPreferencesResponse
 */
export interface PpeUpdateEmailPreferencesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateEmailPreferencesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateEmailPreferencesResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateEmployeeResponse
 */
export interface PpeUpdateEmployeeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateEmployeeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateEmployeeResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateEmployeeStatusResponse
 */
export interface PpeUpdateEmployeeStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateEmployeeStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateEmployeeStatusResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateJobIdStatusRequest
 */
export interface PpeUpdateJobIdStatusRequest {
    /**
     * 
     * @type {PpeJobIDStatus}
     * @memberof PpeUpdateJobIdStatusRequest
     */
    'job_id_status'?: PpeJobIDStatus;
}
/**
 * 
 * @export
 * @interface PpeUpdateJobIdStatusResponse
 */
export interface PpeUpdateJobIdStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateJobIdStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateJobIdStatusResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateJobResponse
 */
export interface PpeUpdateJobResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateJobResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateJobResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateLockerResponse
 */
export interface PpeUpdateLockerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateLockerResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateLockerResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateOrganizationLogoRequest
 */
export interface PpeUpdateOrganizationLogoRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateOrganizationLogoRequest
     */
    'logo'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateOrganizationLogoResponse
 */
export interface PpeUpdateOrganizationLogoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateOrganizationLogoResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateOrganizationLogoResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateOrganizationTimezoneRequest
 */
export interface PpeUpdateOrganizationTimezoneRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateOrganizationTimezoneRequest
     */
    'timezone'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateOrganizationTimezoneResponse
 */
export interface PpeUpdateOrganizationTimezoneResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateOrganizationTimezoneResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateOrganizationTimezoneResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdatePartnerResponse
 */
export interface PpeUpdatePartnerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdatePartnerResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdatePartnerResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdatePasswordRequest
 */
export interface PpeUpdatePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdatePasswordRequest
     */
    'current_password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdatePasswordRequest
     */
    'new_password'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdatePasswordResponse
 */
export interface PpeUpdatePasswordResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdatePasswordResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdatePasswordResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdatePhoneNumberRequest
 */
export interface PpeUpdatePhoneNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdatePhoneNumberRequest
     */
    'country_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdatePhoneNumberRequest
     */
    'contact_number'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdatePhoneNumberResponse
 */
export interface PpeUpdatePhoneNumberResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdatePhoneNumberResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdatePhoneNumberResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdatePickupMethodsRequest
 */
export interface PpeUpdatePickupMethodsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdatePickupMethodsRequest
     */
    'is_mifare_card_allowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdatePickupMethodsRequest
     */
    'is_mobile_app_allowed'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeUpdatePickupMethodsResponse
 */
export interface PpeUpdatePickupMethodsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdatePickupMethodsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdatePickupMethodsResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateProductCellRequest
 */
export interface PpeUpdateProductCellRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateProductCellRequest
     */
    'locker_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateProductCellRequest
     */
    'cell_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateProductCellRequest
     */
    'product_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateProductCellResponse
 */
export interface PpeUpdateProductCellResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateProductCellResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateProductCellResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateProductMapResponse
 */
export interface PpeUpdateProductMapResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateProductMapResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateProductMapResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateProductResponse
 */
export interface PpeUpdateProductResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateProductResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateProductResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateScheduledReportRequest
 */
export interface PpeUpdateScheduledReportRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateScheduledReportRequest
     */
    'scheduled_report_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateScheduledReportRequest
     */
    'cron'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeUpdateScheduledReportRequest
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateScheduledReportRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateScheduledReportRequest
     */
    'template_uuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateScheduledReportRequest
     */
    'transactions_csv'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateScheduledReportRequest
     */
    'email_subject'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateSelfServicingRequest
 */
export interface PpeUpdateSelfServicingRequest {
    /**
     * 
     * @type {PpeSelfServicingConfig}
     * @memberof PpeUpdateSelfServicingRequest
     */
    'config'?: PpeSelfServicingConfig;
}
/**
 * 
 * @export
 * @interface PpeUpdateSelfServicingResponse
 */
export interface PpeUpdateSelfServicingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateSelfServicingResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateSelfServicingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PpeSelfServicingConfig}
     * @memberof PpeUpdateSelfServicingResponse
     */
    'config'?: PpeSelfServicingConfig;
}
/**
 * 
 * @export
 * @interface PpeUpdateTemplateRequest
 */
export interface PpeUpdateTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTemplateRequest
     */
    'template_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTemplateRequest
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTemplateRequest
     */
    'content_bs64'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateTimezoneRequest
 */
export interface PpeUpdateTimezoneRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTimezoneRequest
     */
    'timezone'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateTimezoneResponse
 */
export interface PpeUpdateTimezoneResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateTimezoneResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTimezoneResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateTrackableItemDetailRequest
 */
export interface PpeUpdateTrackableItemDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTrackableItemDetailRequest
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTrackableItemDetailRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTrackableItemDetailRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateTrackableItemDetailResponse
 */
export interface PpeUpdateTrackableItemDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateTrackableItemDetailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTrackableItemDetailResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateTrackableItemStateRequest
 */
export interface PpeUpdateTrackableItemStateRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTrackableItemStateRequest
     */
    'trackable_item_uuid'?: string;
    /**
     * 
     * @type {PpeTrackableItemState}
     * @memberof PpeUpdateTrackableItemStateRequest
     */
    'state'?: PpeTrackableItemState;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTrackableItemStateRequest
     */
    'location_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateTrackableItemStateResponse
 */
export interface PpeUpdateTrackableItemStateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateTrackableItemStateResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTrackableItemStateResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateTransactionResponse
 */
export interface PpeUpdateTransactionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateTransactionResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateTransactionResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUpdateUsersServiceNowDelegationRequest
 */
export interface PpeUpdateUsersServiceNowDelegationRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeUpdateUsersServiceNowDelegationRequest
     */
    'user_team_uuid'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeUpdateUsersServiceNowDelegationRequest
     */
    'admin_team_uuid'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PpeUpdateUsersServiceNowDelegationResponse
 */
export interface PpeUpdateUsersServiceNowDelegationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateUsersServiceNowDelegationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateUsersServiceNowDelegationResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PpeUserDelegationSyncJob}
     * @memberof PpeUpdateUsersServiceNowDelegationResponse
     */
    'sync_job'?: PpeUserDelegationSyncJob;
}
/**
 * 
 * @export
 * @interface PpeUpdateVendingMachineResponse
 */
export interface PpeUpdateVendingMachineResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeUpdateVendingMachineResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUpdateVendingMachineResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeUserDelegationSyncJob
 */
export interface PpeUserDelegationSyncJob {
    /**
     * 
     * @type {string}
     * @memberof PpeUserDelegationSyncJob
     */
    'uuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeUserDelegationSyncJob
     */
    'in_progress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeUserDelegationSyncJob
     */
    'total_users'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUserDelegationSyncJob
     */
    'added'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeUserDelegationSyncJob
     */
    'removed'?: string;
}
/**
 * 
 * @export
 * @interface PpeVendingMachineInfo
 */
export interface PpeVendingMachineInfo {
    /**
     * 
     * @type {string}
     * @memberof PpeVendingMachineInfo
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeVendingMachineInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeVendingMachineInfo
     */
    'site'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeVendingMachineInfo
     */
    'photo_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeVendingMachineInfo
     */
    'is_ready'?: boolean;
}
/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    'details'?: Array<ProtobufAny>;
}

/**
 * AdminAuditLogServiceApi - axios parameter creator
 * @export
 */
export const AdminAuditLogServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all audit logs
         * @param {PpeListAuditLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuditLogServiceListAuditLogs: async (body: PpeListAuditLogsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminAuditLogServiceListAuditLogs', 'body', body)
            const localVarPath = `/api/v2/audit-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAuditLogServiceApi - functional programming interface
 * @export
 */
export const AdminAuditLogServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAuditLogServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all audit logs
         * @param {PpeListAuditLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuditLogServiceListAuditLogs(body: PpeListAuditLogsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListAuditLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuditLogServiceListAuditLogs(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAuditLogServiceApi - factory interface
 * @export
 */
export const AdminAuditLogServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAuditLogServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary List all audit logs
         * @param {PpeListAuditLogsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuditLogServiceListAuditLogs(body: PpeListAuditLogsRequest, options?: any): AxiosPromise<PpeListAuditLogsResponse> {
            return localVarFp.adminAuditLogServiceListAuditLogs(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAuditLogServiceApi - object-oriented interface
 * @export
 * @class AdminAuditLogServiceApi
 * @extends {BaseAPI}
 */
export class AdminAuditLogServiceApi extends BaseAPI {
    /**
     * 
     * @summary List all audit logs
     * @param {PpeListAuditLogsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuditLogServiceApi
     */
    public adminAuditLogServiceListAuditLogs(body: PpeListAuditLogsRequest, options?: AxiosRequestConfig) {
        return AdminAuditLogServiceApiFp(this.configuration).adminAuditLogServiceListAuditLogs(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminAuthenticationServiceApi - axios parameter creator
 * @export
 */
export const AdminAuthenticationServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Administrator forgot password
         * @param {PpeAdministratorForgotPasswordRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthenticationServiceAdministratorForgotPassword: async (body: PpeAdministratorForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminAuthenticationServiceAdministratorForgotPassword', 'body', body)
            const localVarPath = `/api/v2/users/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administrator Login
         * @param {PpeAdministratorLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthenticationServiceAdministratorLogin: async (body: PpeAdministratorLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminAuthenticationServiceAdministratorLogin', 'body', body)
            const localVarPath = `/api/v2/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Administrator reset password
         * @param {string} resetPasswordToken 
         * @param {InlineObject13} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthenticationServiceResetPassword: async (resetPasswordToken: string, body: InlineObject13, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordToken' is not null or undefined
            assertParamExists('adminAuthenticationServiceResetPassword', 'resetPasswordToken', resetPasswordToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminAuthenticationServiceResetPassword', 'body', body)
            const localVarPath = `/api/v2/users/password-resets/{reset_password_token}`
                .replace(`{${"reset_password_token"}}`, encodeURIComponent(String(resetPasswordToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAuthenticationServiceApi - functional programming interface
 * @export
 */
export const AdminAuthenticationServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAuthenticationServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Administrator forgot password
         * @param {PpeAdministratorForgotPasswordRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthenticationServiceAdministratorForgotPassword(body: PpeAdministratorForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAdministratorForgotPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthenticationServiceAdministratorForgotPassword(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administrator Login
         * @param {PpeAdministratorLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthenticationServiceAdministratorLogin(body: PpeAdministratorLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAdministratorLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthenticationServiceAdministratorLogin(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Administrator reset password
         * @param {string} resetPasswordToken 
         * @param {InlineObject13} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthenticationServiceResetPassword(resetPasswordToken: string, body: InlineObject13, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeResetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthenticationServiceResetPassword(resetPasswordToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAuthenticationServiceApi - factory interface
 * @export
 */
export const AdminAuthenticationServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAuthenticationServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Administrator forgot password
         * @param {PpeAdministratorForgotPasswordRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthenticationServiceAdministratorForgotPassword(body: PpeAdministratorForgotPasswordRequest, options?: any): AxiosPromise<PpeAdministratorForgotPasswordResponse> {
            return localVarFp.adminAuthenticationServiceAdministratorForgotPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administrator Login
         * @param {PpeAdministratorLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthenticationServiceAdministratorLogin(body: PpeAdministratorLoginRequest, options?: any): AxiosPromise<PpeAdministratorLoginResponse> {
            return localVarFp.adminAuthenticationServiceAdministratorLogin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Administrator reset password
         * @param {string} resetPasswordToken 
         * @param {InlineObject13} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthenticationServiceResetPassword(resetPasswordToken: string, body: InlineObject13, options?: any): AxiosPromise<PpeResetPasswordResponse> {
            return localVarFp.adminAuthenticationServiceResetPassword(resetPasswordToken, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAuthenticationServiceApi - object-oriented interface
 * @export
 * @class AdminAuthenticationServiceApi
 * @extends {BaseAPI}
 */
export class AdminAuthenticationServiceApi extends BaseAPI {
    /**
     * 
     * @summary Administrator forgot password
     * @param {PpeAdministratorForgotPasswordRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthenticationServiceApi
     */
    public adminAuthenticationServiceAdministratorForgotPassword(body: PpeAdministratorForgotPasswordRequest, options?: AxiosRequestConfig) {
        return AdminAuthenticationServiceApiFp(this.configuration).adminAuthenticationServiceAdministratorForgotPassword(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administrator Login
     * @param {PpeAdministratorLoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthenticationServiceApi
     */
    public adminAuthenticationServiceAdministratorLogin(body: PpeAdministratorLoginRequest, options?: AxiosRequestConfig) {
        return AdminAuthenticationServiceApiFp(this.configuration).adminAuthenticationServiceAdministratorLogin(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Administrator reset password
     * @param {string} resetPasswordToken 
     * @param {InlineObject13} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthenticationServiceApi
     */
    public adminAuthenticationServiceResetPassword(resetPasswordToken: string, body: InlineObject13, options?: AxiosRequestConfig) {
        return AdminAuthenticationServiceApiFp(this.configuration).adminAuthenticationServiceResetPassword(resetPasswordToken, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCompanyInformationServiceApi - axios parameter creator
 * @export
 */
export const AdminCompanyInformationServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an employee
         * @param {PpeCreateEmployeeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceCreateEmployee: async (body: PpeCreateEmployeeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminCompanyInformationServiceCreateEmployee', 'body', body)
            const localVarPath = `/api/v2/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all employees
         * @param {'ENABLED' | 'DISABLED'} [status] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceGetAllEmployees: async (status?: 'ENABLED' | 'DISABLED', perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get employee Details
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceGetEmployeeDetails: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminCompanyInformationServiceGetEmployeeDetails', 'uuid', uuid)
            const localVarPath = `/api/v2/employees/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List kiosks in in the organization
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceListKiosks: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminCompanyInformationServiceListKiosks', 'body', body)
            const localVarPath = `/api/v2/organization-settings/kiosks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search all employees
         * @param {PpeSearchEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceSearchEmployees: async (body: PpeSearchEmployeesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminCompanyInformationServiceSearchEmployees', 'body', body)
            const localVarPath = `/api/v2/employees/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an employee
         * @param {string} uuid 
         * @param {InlineObject2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceUpdateEmployee: async (uuid: string, body: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminCompanyInformationServiceUpdateEmployee', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminCompanyInformationServiceUpdateEmployee', 'body', body)
            const localVarPath = `/api/v2/employees/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary enable or disable an employee
         * @param {string} uuid 
         * @param {InlineObject5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceUpdateEmployeeStatus: async (uuid: string, body: InlineObject5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminCompanyInformationServiceUpdateEmployeeStatus', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminCompanyInformationServiceUpdateEmployeeStatus', 'body', body)
            const localVarPath = `/api/v2/employees/{uuid}/update-status`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCompanyInformationServiceApi - functional programming interface
 * @export
 */
export const AdminCompanyInformationServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCompanyInformationServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an employee
         * @param {PpeCreateEmployeeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCompanyInformationServiceCreateEmployee(body: PpeCreateEmployeeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeCreateEmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyInformationServiceCreateEmployee(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all employees
         * @param {'ENABLED' | 'DISABLED'} [status] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCompanyInformationServiceGetAllEmployees(status?: 'ENABLED' | 'DISABLED', perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetAllEmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyInformationServiceGetAllEmployees(status, perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get employee Details
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCompanyInformationServiceGetEmployeeDetails(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetEmployeeDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyInformationServiceGetEmployeeDetails(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List kiosks in in the organization
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCompanyInformationServiceListKiosks(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListKiosksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyInformationServiceListKiosks(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search all employees
         * @param {PpeSearchEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCompanyInformationServiceSearchEmployees(body: PpeSearchEmployeesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeSearchEmployeesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyInformationServiceSearchEmployees(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an employee
         * @param {string} uuid 
         * @param {InlineObject2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCompanyInformationServiceUpdateEmployee(uuid: string, body: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateEmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyInformationServiceUpdateEmployee(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary enable or disable an employee
         * @param {string} uuid 
         * @param {InlineObject5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCompanyInformationServiceUpdateEmployeeStatus(uuid: string, body: InlineObject5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateEmployeeStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyInformationServiceUpdateEmployeeStatus(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCompanyInformationServiceApi - factory interface
 * @export
 */
export const AdminCompanyInformationServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCompanyInformationServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an employee
         * @param {PpeCreateEmployeeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceCreateEmployee(body: PpeCreateEmployeeRequest, options?: any): AxiosPromise<PpeCreateEmployeeResponse> {
            return localVarFp.adminCompanyInformationServiceCreateEmployee(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all employees
         * @param {'ENABLED' | 'DISABLED'} [status] 
         * @param {number} [perPage] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceGetAllEmployees(status?: 'ENABLED' | 'DISABLED', perPage?: number, page?: number, options?: any): AxiosPromise<PpeGetAllEmployeeResponse> {
            return localVarFp.adminCompanyInformationServiceGetAllEmployees(status, perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get employee Details
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceGetEmployeeDetails(uuid: string, options?: any): AxiosPromise<PpeGetEmployeeDetailsResponse> {
            return localVarFp.adminCompanyInformationServiceGetEmployeeDetails(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List kiosks in in the organization
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceListKiosks(body: object, options?: any): AxiosPromise<PpeListKiosksResponse> {
            return localVarFp.adminCompanyInformationServiceListKiosks(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search all employees
         * @param {PpeSearchEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceSearchEmployees(body: PpeSearchEmployeesRequest, options?: any): AxiosPromise<PpeSearchEmployeesResponse> {
            return localVarFp.adminCompanyInformationServiceSearchEmployees(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an employee
         * @param {string} uuid 
         * @param {InlineObject2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceUpdateEmployee(uuid: string, body: InlineObject2, options?: any): AxiosPromise<PpeUpdateEmployeeResponse> {
            return localVarFp.adminCompanyInformationServiceUpdateEmployee(uuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary enable or disable an employee
         * @param {string} uuid 
         * @param {InlineObject5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyInformationServiceUpdateEmployeeStatus(uuid: string, body: InlineObject5, options?: any): AxiosPromise<PpeUpdateEmployeeStatusResponse> {
            return localVarFp.adminCompanyInformationServiceUpdateEmployeeStatus(uuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCompanyInformationServiceApi - object-oriented interface
 * @export
 * @class AdminCompanyInformationServiceApi
 * @extends {BaseAPI}
 */
export class AdminCompanyInformationServiceApi extends BaseAPI {
    /**
     * 
     * @summary Create an employee
     * @param {PpeCreateEmployeeRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyInformationServiceApi
     */
    public adminCompanyInformationServiceCreateEmployee(body: PpeCreateEmployeeRequest, options?: AxiosRequestConfig) {
        return AdminCompanyInformationServiceApiFp(this.configuration).adminCompanyInformationServiceCreateEmployee(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all employees
     * @param {'ENABLED' | 'DISABLED'} [status] 
     * @param {number} [perPage] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyInformationServiceApi
     */
    public adminCompanyInformationServiceGetAllEmployees(status?: 'ENABLED' | 'DISABLED', perPage?: number, page?: number, options?: AxiosRequestConfig) {
        return AdminCompanyInformationServiceApiFp(this.configuration).adminCompanyInformationServiceGetAllEmployees(status, perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get employee Details
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyInformationServiceApi
     */
    public adminCompanyInformationServiceGetEmployeeDetails(uuid: string, options?: AxiosRequestConfig) {
        return AdminCompanyInformationServiceApiFp(this.configuration).adminCompanyInformationServiceGetEmployeeDetails(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List kiosks in in the organization
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyInformationServiceApi
     */
    public adminCompanyInformationServiceListKiosks(body: object, options?: AxiosRequestConfig) {
        return AdminCompanyInformationServiceApiFp(this.configuration).adminCompanyInformationServiceListKiosks(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search all employees
     * @param {PpeSearchEmployeesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyInformationServiceApi
     */
    public adminCompanyInformationServiceSearchEmployees(body: PpeSearchEmployeesRequest, options?: AxiosRequestConfig) {
        return AdminCompanyInformationServiceApiFp(this.configuration).adminCompanyInformationServiceSearchEmployees(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an employee
     * @param {string} uuid 
     * @param {InlineObject2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyInformationServiceApi
     */
    public adminCompanyInformationServiceUpdateEmployee(uuid: string, body: InlineObject2, options?: AxiosRequestConfig) {
        return AdminCompanyInformationServiceApiFp(this.configuration).adminCompanyInformationServiceUpdateEmployee(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary enable or disable an employee
     * @param {string} uuid 
     * @param {InlineObject5} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyInformationServiceApi
     */
    public adminCompanyInformationServiceUpdateEmployeeStatus(uuid: string, body: InlineObject5, options?: AxiosRequestConfig) {
        return AdminCompanyInformationServiceApiFp(this.configuration).adminCompanyInformationServiceUpdateEmployeeStatus(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminJobServiceApi - axios parameter creator
 * @export
 */
export const AdminJobServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a job
         * @param {PpeCreateJobRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobServiceCreateJob: async (body: PpeCreateJobRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminJobServiceCreateJob', 'body', body)
            const localVarPath = `/api/v2/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get job details
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobServiceGetJobDetail: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminJobServiceGetJobDetail', 'uuid', uuid)
            const localVarPath = `/api/v2/jobs/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all job
         * @param {PpeListJobsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobServiceListJobs: async (body: PpeListJobsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminJobServiceListJobs', 'body', body)
            const localVarPath = `/api/v2/list-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a job
         * @param {string} uuid 
         * @param {InlineObject6} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobServiceUpdateJob: async (uuid: string, body: InlineObject6, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminJobServiceUpdateJob', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminJobServiceUpdateJob', 'body', body)
            const localVarPath = `/api/v2/jobs/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminJobServiceApi - functional programming interface
 * @export
 */
export const AdminJobServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminJobServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a job
         * @param {PpeCreateJobRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminJobServiceCreateJob(body: PpeCreateJobRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeCreateJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminJobServiceCreateJob(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get job details
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminJobServiceGetJobDetail(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetJobDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminJobServiceGetJobDetail(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all job
         * @param {PpeListJobsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminJobServiceListJobs(body: PpeListJobsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListJobsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminJobServiceListJobs(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a job
         * @param {string} uuid 
         * @param {InlineObject6} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminJobServiceUpdateJob(uuid: string, body: InlineObject6, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminJobServiceUpdateJob(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminJobServiceApi - factory interface
 * @export
 */
export const AdminJobServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminJobServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a job
         * @param {PpeCreateJobRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobServiceCreateJob(body: PpeCreateJobRequest, options?: any): AxiosPromise<PpeCreateJobResponse> {
            return localVarFp.adminJobServiceCreateJob(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get job details
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobServiceGetJobDetail(uuid: string, options?: any): AxiosPromise<PpeGetJobDetailResponse> {
            return localVarFp.adminJobServiceGetJobDetail(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all job
         * @param {PpeListJobsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobServiceListJobs(body: PpeListJobsRequest, options?: any): AxiosPromise<PpeListJobsResponse> {
            return localVarFp.adminJobServiceListJobs(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a job
         * @param {string} uuid 
         * @param {InlineObject6} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminJobServiceUpdateJob(uuid: string, body: InlineObject6, options?: any): AxiosPromise<PpeUpdateJobResponse> {
            return localVarFp.adminJobServiceUpdateJob(uuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminJobServiceApi - object-oriented interface
 * @export
 * @class AdminJobServiceApi
 * @extends {BaseAPI}
 */
export class AdminJobServiceApi extends BaseAPI {
    /**
     * 
     * @summary Create a job
     * @param {PpeCreateJobRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminJobServiceApi
     */
    public adminJobServiceCreateJob(body: PpeCreateJobRequest, options?: AxiosRequestConfig) {
        return AdminJobServiceApiFp(this.configuration).adminJobServiceCreateJob(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get job details
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminJobServiceApi
     */
    public adminJobServiceGetJobDetail(uuid: string, options?: AxiosRequestConfig) {
        return AdminJobServiceApiFp(this.configuration).adminJobServiceGetJobDetail(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all job
     * @param {PpeListJobsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminJobServiceApi
     */
    public adminJobServiceListJobs(body: PpeListJobsRequest, options?: AxiosRequestConfig) {
        return AdminJobServiceApiFp(this.configuration).adminJobServiceListJobs(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a job
     * @param {string} uuid 
     * @param {InlineObject6} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminJobServiceApi
     */
    public adminJobServiceUpdateJob(uuid: string, body: InlineObject6, options?: AxiosRequestConfig) {
        return AdminJobServiceApiFp(this.configuration).adminJobServiceUpdateJob(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminKeyCardServiceApi - axios parameter creator
 * @export
 */
export const AdminKeyCardServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary allocate a key card to a employee
         * @param {PpeAllocatedKeyCardToEmployeeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKeyCardServiceAllocatedKeyCardToEmployee: async (body: PpeAllocatedKeyCardToEmployeeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminKeyCardServiceAllocatedKeyCardToEmployee', 'body', body)
            const localVarPath = `/api/v2/key-cards/allocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all key card tap events
         * @param {PpeListKeyCardTapEventRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKeyCardServiceListKeyCardTapEvent: async (body: PpeListKeyCardTapEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminKeyCardServiceListKeyCardTapEvent', 'body', body)
            const localVarPath = `/api/v2/key-cards/tap-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminKeyCardServiceApi - functional programming interface
 * @export
 */
export const AdminKeyCardServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminKeyCardServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary allocate a key card to a employee
         * @param {PpeAllocatedKeyCardToEmployeeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKeyCardServiceAllocatedKeyCardToEmployee(body: PpeAllocatedKeyCardToEmployeeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAllocatedKeyCardToEmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKeyCardServiceAllocatedKeyCardToEmployee(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all key card tap events
         * @param {PpeListKeyCardTapEventRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKeyCardServiceListKeyCardTapEvent(body: PpeListKeyCardTapEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListKeyCardTapEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKeyCardServiceListKeyCardTapEvent(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminKeyCardServiceApi - factory interface
 * @export
 */
export const AdminKeyCardServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminKeyCardServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary allocate a key card to a employee
         * @param {PpeAllocatedKeyCardToEmployeeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKeyCardServiceAllocatedKeyCardToEmployee(body: PpeAllocatedKeyCardToEmployeeRequest, options?: any): AxiosPromise<PpeAllocatedKeyCardToEmployeeResponse> {
            return localVarFp.adminKeyCardServiceAllocatedKeyCardToEmployee(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all key card tap events
         * @param {PpeListKeyCardTapEventRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKeyCardServiceListKeyCardTapEvent(body: PpeListKeyCardTapEventRequest, options?: any): AxiosPromise<PpeListKeyCardTapEventResponse> {
            return localVarFp.adminKeyCardServiceListKeyCardTapEvent(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminKeyCardServiceApi - object-oriented interface
 * @export
 * @class AdminKeyCardServiceApi
 * @extends {BaseAPI}
 */
export class AdminKeyCardServiceApi extends BaseAPI {
    /**
     * 
     * @summary allocate a key card to a employee
     * @param {PpeAllocatedKeyCardToEmployeeRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminKeyCardServiceApi
     */
    public adminKeyCardServiceAllocatedKeyCardToEmployee(body: PpeAllocatedKeyCardToEmployeeRequest, options?: AxiosRequestConfig) {
        return AdminKeyCardServiceApiFp(this.configuration).adminKeyCardServiceAllocatedKeyCardToEmployee(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all key card tap events
     * @param {PpeListKeyCardTapEventRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminKeyCardServiceApi
     */
    public adminKeyCardServiceListKeyCardTapEvent(body: PpeListKeyCardTapEventRequest, options?: AxiosRequestConfig) {
        return AdminKeyCardServiceApiFp(this.configuration).adminKeyCardServiceListKeyCardTapEvent(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminLockerServiceApi - axios parameter creator
 * @export
 */
export const AdminLockerServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clear a single locker cell
         * @param {PpeClearLockerCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceClearLockerCell: async (body: PpeClearLockerCellRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLockerServiceClearLockerCell', 'body', body)
            const localVarPath = `/api/v2/lockers/cell-clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get locker information
         * @param {string} lockerUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceGetLockerInformation: async (lockerUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lockerUuid' is not null or undefined
            assertParamExists('adminLockerServiceGetLockerInformation', 'lockerUuid', lockerUuid)
            const localVarPath = `/api/v2/lockers/information/{locker_uuid}`
                .replace(`{${"locker_uuid"}}`, encodeURIComponent(String(lockerUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find list of empty locker slots for the product
         * @param {PpeListAvailableLockerCellsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceListAvailableLockerCells: async (body: PpeListAvailableLockerCellsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLockerServiceListAvailableLockerCells', 'body', body)
            const localVarPath = `/api/v2/trackable-items/search-locker-cells`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all lockers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceListLockers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/lockers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Open a particular cell door of a locker
         * @param {PpeOpenLockerCellDoorRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceOpenLockerCellDoor: async (body: PpeOpenLockerCellDoorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLockerServiceOpenLockerCellDoor', 'body', body)
            const localVarPath = `/api/v2/lockers/door-open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refill a locker
         * @param {PpeRefillLockerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceRefillLocker: async (body: PpeRefillLockerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLockerServiceRefillLocker', 'body', body)
            const localVarPath = `/api/v2/lockers/refills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refill a single locker cell
         * @param {PpeRefillLockerCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceRefillLockerCell: async (body: PpeRefillLockerCellRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLockerServiceRefillLockerCell', 'body', body)
            const localVarPath = `/api/v2/lockers/cell-refills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refill a cell with trackable item
         * @param {PpeRefillTrackableItemToCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceRefillTrackableItemToCell: async (body: PpeRefillTrackableItemToCellRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLockerServiceRefillTrackableItemToCell', 'body', body)
            const localVarPath = `/api/v2/lockers/trackable-refills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary take out a trackable item from cell
         * @param {PpeTakeoutTrackableItemFromCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceTakeoutTrackableItemFromCell: async (body: PpeTakeoutTrackableItemFromCellRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLockerServiceTakeoutTrackableItemFromCell', 'body', body)
            const localVarPath = `/api/v2/lockers/trackable-takeouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Locker Information
         * @param {string} lockerUuid 
         * @param {InlineObject7} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceUpdateLocker: async (lockerUuid: string, body: InlineObject7, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lockerUuid' is not null or undefined
            assertParamExists('adminLockerServiceUpdateLocker', 'lockerUuid', lockerUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLockerServiceUpdateLocker', 'body', body)
            const localVarPath = `/api/v2/lockers/information/{locker_uuid}`
                .replace(`{${"locker_uuid"}}`, encodeURIComponent(String(lockerUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a cell for product allocation
         * @param {PpeUpdateProductCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceUpdateProductCell: async (body: PpeUpdateProductCellRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLockerServiceUpdateProductCell', 'body', body)
            const localVarPath = `/api/v2/lockers/product-allocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminLockerServiceApi - functional programming interface
 * @export
 */
export const AdminLockerServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminLockerServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clear a single locker cell
         * @param {PpeClearLockerCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceClearLockerCell(body: PpeClearLockerCellRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeClearLockerCellResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceClearLockerCell(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get locker information
         * @param {string} lockerUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceGetLockerInformation(lockerUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetLockerInformationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceGetLockerInformation(lockerUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find list of empty locker slots for the product
         * @param {PpeListAvailableLockerCellsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceListAvailableLockerCells(body: PpeListAvailableLockerCellsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListAvailableLockerCellsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceListAvailableLockerCells(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all lockers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceListLockers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListLockersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceListLockers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Open a particular cell door of a locker
         * @param {PpeOpenLockerCellDoorRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceOpenLockerCellDoor(body: PpeOpenLockerCellDoorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeOpenLockerCellDoorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceOpenLockerCellDoor(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refill a locker
         * @param {PpeRefillLockerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceRefillLocker(body: PpeRefillLockerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRefillLockerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceRefillLocker(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refill a single locker cell
         * @param {PpeRefillLockerCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceRefillLockerCell(body: PpeRefillLockerCellRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRefillLockerCellResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceRefillLockerCell(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refill a cell with trackable item
         * @param {PpeRefillTrackableItemToCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceRefillTrackableItemToCell(body: PpeRefillTrackableItemToCellRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRefillTrackableItemToCellResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceRefillTrackableItemToCell(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary take out a trackable item from cell
         * @param {PpeTakeoutTrackableItemFromCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceTakeoutTrackableItemFromCell(body: PpeTakeoutTrackableItemFromCellRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeTakeoutTrackableItemFromCellResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceTakeoutTrackableItemFromCell(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Locker Information
         * @param {string} lockerUuid 
         * @param {InlineObject7} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceUpdateLocker(lockerUuid: string, body: InlineObject7, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateLockerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceUpdateLocker(lockerUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a cell for product allocation
         * @param {PpeUpdateProductCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLockerServiceUpdateProductCell(body: PpeUpdateProductCellRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateProductCellResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLockerServiceUpdateProductCell(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminLockerServiceApi - factory interface
 * @export
 */
export const AdminLockerServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminLockerServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Clear a single locker cell
         * @param {PpeClearLockerCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceClearLockerCell(body: PpeClearLockerCellRequest, options?: any): AxiosPromise<PpeClearLockerCellResponse> {
            return localVarFp.adminLockerServiceClearLockerCell(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get locker information
         * @param {string} lockerUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceGetLockerInformation(lockerUuid: string, options?: any): AxiosPromise<PpeGetLockerInformationResponse> {
            return localVarFp.adminLockerServiceGetLockerInformation(lockerUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find list of empty locker slots for the product
         * @param {PpeListAvailableLockerCellsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceListAvailableLockerCells(body: PpeListAvailableLockerCellsRequest, options?: any): AxiosPromise<PpeListAvailableLockerCellsResponse> {
            return localVarFp.adminLockerServiceListAvailableLockerCells(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all lockers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceListLockers(options?: any): AxiosPromise<PpeListLockersResponse> {
            return localVarFp.adminLockerServiceListLockers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Open a particular cell door of a locker
         * @param {PpeOpenLockerCellDoorRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceOpenLockerCellDoor(body: PpeOpenLockerCellDoorRequest, options?: any): AxiosPromise<PpeOpenLockerCellDoorResponse> {
            return localVarFp.adminLockerServiceOpenLockerCellDoor(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refill a locker
         * @param {PpeRefillLockerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceRefillLocker(body: PpeRefillLockerRequest, options?: any): AxiosPromise<PpeRefillLockerResponse> {
            return localVarFp.adminLockerServiceRefillLocker(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refill a single locker cell
         * @param {PpeRefillLockerCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceRefillLockerCell(body: PpeRefillLockerCellRequest, options?: any): AxiosPromise<PpeRefillLockerCellResponse> {
            return localVarFp.adminLockerServiceRefillLockerCell(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refill a cell with trackable item
         * @param {PpeRefillTrackableItemToCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceRefillTrackableItemToCell(body: PpeRefillTrackableItemToCellRequest, options?: any): AxiosPromise<PpeRefillTrackableItemToCellResponse> {
            return localVarFp.adminLockerServiceRefillTrackableItemToCell(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary take out a trackable item from cell
         * @param {PpeTakeoutTrackableItemFromCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceTakeoutTrackableItemFromCell(body: PpeTakeoutTrackableItemFromCellRequest, options?: any): AxiosPromise<PpeTakeoutTrackableItemFromCellResponse> {
            return localVarFp.adminLockerServiceTakeoutTrackableItemFromCell(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Locker Information
         * @param {string} lockerUuid 
         * @param {InlineObject7} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceUpdateLocker(lockerUuid: string, body: InlineObject7, options?: any): AxiosPromise<PpeUpdateLockerResponse> {
            return localVarFp.adminLockerServiceUpdateLocker(lockerUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a cell for product allocation
         * @param {PpeUpdateProductCellRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLockerServiceUpdateProductCell(body: PpeUpdateProductCellRequest, options?: any): AxiosPromise<PpeUpdateProductCellResponse> {
            return localVarFp.adminLockerServiceUpdateProductCell(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminLockerServiceApi - object-oriented interface
 * @export
 * @class AdminLockerServiceApi
 * @extends {BaseAPI}
 */
export class AdminLockerServiceApi extends BaseAPI {
    /**
     * 
     * @summary Clear a single locker cell
     * @param {PpeClearLockerCellRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceClearLockerCell(body: PpeClearLockerCellRequest, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceClearLockerCell(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get locker information
     * @param {string} lockerUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceGetLockerInformation(lockerUuid: string, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceGetLockerInformation(lockerUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find list of empty locker slots for the product
     * @param {PpeListAvailableLockerCellsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceListAvailableLockerCells(body: PpeListAvailableLockerCellsRequest, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceListAvailableLockerCells(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all lockers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceListLockers(options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceListLockers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Open a particular cell door of a locker
     * @param {PpeOpenLockerCellDoorRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceOpenLockerCellDoor(body: PpeOpenLockerCellDoorRequest, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceOpenLockerCellDoor(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refill a locker
     * @param {PpeRefillLockerRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceRefillLocker(body: PpeRefillLockerRequest, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceRefillLocker(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refill a single locker cell
     * @param {PpeRefillLockerCellRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceRefillLockerCell(body: PpeRefillLockerCellRequest, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceRefillLockerCell(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refill a cell with trackable item
     * @param {PpeRefillTrackableItemToCellRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceRefillTrackableItemToCell(body: PpeRefillTrackableItemToCellRequest, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceRefillTrackableItemToCell(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary take out a trackable item from cell
     * @param {PpeTakeoutTrackableItemFromCellRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceTakeoutTrackableItemFromCell(body: PpeTakeoutTrackableItemFromCellRequest, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceTakeoutTrackableItemFromCell(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Locker Information
     * @param {string} lockerUuid 
     * @param {InlineObject7} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceUpdateLocker(lockerUuid: string, body: InlineObject7, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceUpdateLocker(lockerUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a cell for product allocation
     * @param {PpeUpdateProductCellRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLockerServiceApi
     */
    public adminLockerServiceUpdateProductCell(body: PpeUpdateProductCellRequest, options?: AxiosRequestConfig) {
        return AdminLockerServiceApiFp(this.configuration).adminLockerServiceUpdateProductCell(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrderServiceApi - axios parameter creator
 * @export
 */
export const AdminOrderServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get a list of orders
         * @param {PpeListOrdersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderServiceListOrders: async (body: PpeListOrdersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminOrderServiceListOrders', 'body', body)
            const localVarPath = `/api/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrderServiceApi - functional programming interface
 * @export
 */
export const AdminOrderServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrderServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get a list of orders
         * @param {PpeListOrdersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderServiceListOrders(body: PpeListOrdersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderServiceListOrders(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrderServiceApi - factory interface
 * @export
 */
export const AdminOrderServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrderServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary get a list of orders
         * @param {PpeListOrdersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderServiceListOrders(body: PpeListOrdersRequest, options?: any): AxiosPromise<PpeListOrdersResponse> {
            return localVarFp.adminOrderServiceListOrders(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrderServiceApi - object-oriented interface
 * @export
 * @class AdminOrderServiceApi
 * @extends {BaseAPI}
 */
export class AdminOrderServiceApi extends BaseAPI {
    /**
     * 
     * @summary get a list of orders
     * @param {PpeListOrdersRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderServiceApi
     */
    public adminOrderServiceListOrders(body: PpeListOrdersRequest, options?: AxiosRequestConfig) {
        return AdminOrderServiceApiFp(this.configuration).adminOrderServiceListOrders(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrganizationServiceApi - axios parameter creator
 * @export
 */
export const AdminOrganizationServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get organization\'s basic statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceGetOrganizationBasicStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/organizations/basic-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get my organization details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceGetOrganizationDetail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get organization logo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceGetOrganizationLogo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/organizations/logos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update job id status
         * @param {PpeUpdateJobIdStatusRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdateJobIdStatus: async (body: PpeUpdateJobIdStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminOrganizationServiceUpdateJobIdStatus', 'body', body)
            const localVarPath = `/api/v2/organizations/job-id-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization logos
         * @param {PpeUpdateOrganizationLogoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdateOrganizationLogo: async (body: PpeUpdateOrganizationLogoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminOrganizationServiceUpdateOrganizationLogo', 'body', body)
            const localVarPath = `/api/v2/organizations/logos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization timezone
         * @param {PpeUpdateOrganizationTimezoneRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdateOrganizationTimezone: async (body: PpeUpdateOrganizationTimezoneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminOrganizationServiceUpdateOrganizationTimezone', 'body', body)
            const localVarPath = `/api/v2/organizations/timezone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization contact number
         * @param {PpeUpdatePhoneNumberRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdatePhoneNumber: async (body: PpeUpdatePhoneNumberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminOrganizationServiceUpdatePhoneNumber', 'body', body)
            const localVarPath = `/api/v2/organizations/phone-numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization PPE pickup methods
         * @param {PpeUpdatePickupMethodsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdatePickupMethods: async (body: PpeUpdatePickupMethodsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminOrganizationServiceUpdatePickupMethods', 'body', body)
            const localVarPath = `/api/v2/organizations/pickup-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrganizationServiceApi - functional programming interface
 * @export
 */
export const AdminOrganizationServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrganizationServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get organization\'s basic statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationServiceGetOrganizationBasicStatistics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetOrganizationBasicStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationServiceGetOrganizationBasicStatistics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get my organization details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationServiceGetOrganizationDetail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetOrganizationDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationServiceGetOrganizationDetail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get organization logo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationServiceGetOrganizationLogo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetOrganizationLogoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationServiceGetOrganizationLogo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update job id status
         * @param {PpeUpdateJobIdStatusRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationServiceUpdateJobIdStatus(body: PpeUpdateJobIdStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateJobIdStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationServiceUpdateJobIdStatus(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update organization logos
         * @param {PpeUpdateOrganizationLogoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationServiceUpdateOrganizationLogo(body: PpeUpdateOrganizationLogoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateOrganizationLogoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationServiceUpdateOrganizationLogo(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update organization timezone
         * @param {PpeUpdateOrganizationTimezoneRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationServiceUpdateOrganizationTimezone(body: PpeUpdateOrganizationTimezoneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateOrganizationTimezoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationServiceUpdateOrganizationTimezone(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update organization contact number
         * @param {PpeUpdatePhoneNumberRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationServiceUpdatePhoneNumber(body: PpeUpdatePhoneNumberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdatePhoneNumberResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationServiceUpdatePhoneNumber(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update organization PPE pickup methods
         * @param {PpeUpdatePickupMethodsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrganizationServiceUpdatePickupMethods(body: PpeUpdatePickupMethodsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdatePickupMethodsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrganizationServiceUpdatePickupMethods(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrganizationServiceApi - factory interface
 * @export
 */
export const AdminOrganizationServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrganizationServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get organization\'s basic statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceGetOrganizationBasicStatistics(options?: any): AxiosPromise<PpeGetOrganizationBasicStatisticsResponse> {
            return localVarFp.adminOrganizationServiceGetOrganizationBasicStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get my organization details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceGetOrganizationDetail(options?: any): AxiosPromise<PpeGetOrganizationDetailResponse> {
            return localVarFp.adminOrganizationServiceGetOrganizationDetail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get organization logo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceGetOrganizationLogo(options?: any): AxiosPromise<PpeGetOrganizationLogoResponse> {
            return localVarFp.adminOrganizationServiceGetOrganizationLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update job id status
         * @param {PpeUpdateJobIdStatusRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdateJobIdStatus(body: PpeUpdateJobIdStatusRequest, options?: any): AxiosPromise<PpeUpdateJobIdStatusResponse> {
            return localVarFp.adminOrganizationServiceUpdateJobIdStatus(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update organization logos
         * @param {PpeUpdateOrganizationLogoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdateOrganizationLogo(body: PpeUpdateOrganizationLogoRequest, options?: any): AxiosPromise<PpeUpdateOrganizationLogoResponse> {
            return localVarFp.adminOrganizationServiceUpdateOrganizationLogo(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update organization timezone
         * @param {PpeUpdateOrganizationTimezoneRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdateOrganizationTimezone(body: PpeUpdateOrganizationTimezoneRequest, options?: any): AxiosPromise<PpeUpdateOrganizationTimezoneResponse> {
            return localVarFp.adminOrganizationServiceUpdateOrganizationTimezone(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update organization contact number
         * @param {PpeUpdatePhoneNumberRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdatePhoneNumber(body: PpeUpdatePhoneNumberRequest, options?: any): AxiosPromise<PpeUpdatePhoneNumberResponse> {
            return localVarFp.adminOrganizationServiceUpdatePhoneNumber(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update organization PPE pickup methods
         * @param {PpeUpdatePickupMethodsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationServiceUpdatePickupMethods(body: PpeUpdatePickupMethodsRequest, options?: any): AxiosPromise<PpeUpdatePickupMethodsResponse> {
            return localVarFp.adminOrganizationServiceUpdatePickupMethods(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrganizationServiceApi - object-oriented interface
 * @export
 * @class AdminOrganizationServiceApi
 * @extends {BaseAPI}
 */
export class AdminOrganizationServiceApi extends BaseAPI {
    /**
     * 
     * @summary Get organization\'s basic statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationServiceApi
     */
    public adminOrganizationServiceGetOrganizationBasicStatistics(options?: AxiosRequestConfig) {
        return AdminOrganizationServiceApiFp(this.configuration).adminOrganizationServiceGetOrganizationBasicStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get my organization details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationServiceApi
     */
    public adminOrganizationServiceGetOrganizationDetail(options?: AxiosRequestConfig) {
        return AdminOrganizationServiceApiFp(this.configuration).adminOrganizationServiceGetOrganizationDetail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get organization logo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationServiceApi
     */
    public adminOrganizationServiceGetOrganizationLogo(options?: AxiosRequestConfig) {
        return AdminOrganizationServiceApiFp(this.configuration).adminOrganizationServiceGetOrganizationLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update job id status
     * @param {PpeUpdateJobIdStatusRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationServiceApi
     */
    public adminOrganizationServiceUpdateJobIdStatus(body: PpeUpdateJobIdStatusRequest, options?: AxiosRequestConfig) {
        return AdminOrganizationServiceApiFp(this.configuration).adminOrganizationServiceUpdateJobIdStatus(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update organization logos
     * @param {PpeUpdateOrganizationLogoRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationServiceApi
     */
    public adminOrganizationServiceUpdateOrganizationLogo(body: PpeUpdateOrganizationLogoRequest, options?: AxiosRequestConfig) {
        return AdminOrganizationServiceApiFp(this.configuration).adminOrganizationServiceUpdateOrganizationLogo(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update organization timezone
     * @param {PpeUpdateOrganizationTimezoneRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationServiceApi
     */
    public adminOrganizationServiceUpdateOrganizationTimezone(body: PpeUpdateOrganizationTimezoneRequest, options?: AxiosRequestConfig) {
        return AdminOrganizationServiceApiFp(this.configuration).adminOrganizationServiceUpdateOrganizationTimezone(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update organization contact number
     * @param {PpeUpdatePhoneNumberRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationServiceApi
     */
    public adminOrganizationServiceUpdatePhoneNumber(body: PpeUpdatePhoneNumberRequest, options?: AxiosRequestConfig) {
        return AdminOrganizationServiceApiFp(this.configuration).adminOrganizationServiceUpdatePhoneNumber(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update organization PPE pickup methods
     * @param {PpeUpdatePickupMethodsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationServiceApi
     */
    public adminOrganizationServiceUpdatePickupMethods(body: PpeUpdatePickupMethodsRequest, options?: AxiosRequestConfig) {
        return AdminOrganizationServiceApiFp(this.configuration).adminOrganizationServiceUpdatePickupMethods(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductServiceApi - axios parameter creator
 * @export
 */
export const AdminProductServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add a product
         * @param {PpeAddProductRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceAddProduct: async (body: PpeAddProductRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminProductServiceAddProduct', 'body', body)
            const localVarPath = `/api/v2/products/adding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add restriction to a product
         * @param {PpeAddRestrictionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceAddRestriction: async (body: PpeAddRestrictionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminProductServiceAddRestriction', 'body', body)
            const localVarPath = `/api/v2/add-restriction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary archive a product
         * @param {string} productUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceArchiveProduct: async (productUuid: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUuid' is not null or undefined
            assertParamExists('adminProductServiceArchiveProduct', 'productUuid', productUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminProductServiceArchiveProduct', 'body', body)
            const localVarPath = `/api/v2/products/{product_uuid}/archive`
                .replace(`{${"product_uuid"}}`, encodeURIComponent(String(productUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a product
         * @param {string} productUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceGetProduct: async (productUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUuid' is not null or undefined
            assertParamExists('adminProductServiceGetProduct', 'productUuid', productUuid)
            const localVarPath = `/api/v2/products/{product_uuid}`
                .replace(`{${"product_uuid"}}`, encodeURIComponent(String(productUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all products
         * @param {PpeListProductsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceListProducts: async (body: PpeListProductsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminProductServiceListProducts', 'body', body)
            const localVarPath = `/api/v2/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List restrictions posed on a product
         * @param {PpeListRestrictionsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceListRestrictions: async (body: PpeListRestrictionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminProductServiceListRestrictions', 'body', body)
            const localVarPath = `/api/v2/product-restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removerestriction on a product
         * @param {PpeRemoveRestrictionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceRemoveRestriction: async (body: PpeRemoveRestrictionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminProductServiceRemoveRestriction', 'body', body)
            const localVarPath = `/api/v2/remove-restriction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary search a product by partial name or uuid
         * @param {PpeSearchProductsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceSearchProducts: async (body: PpeSearchProductsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminProductServiceSearchProducts', 'body', body)
            const localVarPath = `/api/v2/products/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a product
         * @param {string} uuid 
         * @param {InlineObject8} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceUpdateProduct: async (uuid: string, body: InlineObject8, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminProductServiceUpdateProduct', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminProductServiceUpdateProduct', 'body', body)
            const localVarPath = `/api/v2/products/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductServiceApi - functional programming interface
 * @export
 */
export const AdminProductServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add a product
         * @param {PpeAddProductRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductServiceAddProduct(body: PpeAddProductRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAddProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductServiceAddProduct(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add restriction to a product
         * @param {PpeAddRestrictionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductServiceAddRestriction(body: PpeAddRestrictionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAddRestrictionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductServiceAddRestriction(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary archive a product
         * @param {string} productUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductServiceArchiveProduct(productUuid: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeArchiveProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductServiceArchiveProduct(productUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a product
         * @param {string} productUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductServiceGetProduct(productUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductServiceGetProduct(productUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all products
         * @param {PpeListProductsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductServiceListProducts(body: PpeListProductsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductServiceListProducts(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List restrictions posed on a product
         * @param {PpeListRestrictionsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductServiceListRestrictions(body: PpeListRestrictionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListRestrictionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductServiceListRestrictions(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removerestriction on a product
         * @param {PpeRemoveRestrictionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductServiceRemoveRestriction(body: PpeRemoveRestrictionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRemoveRestrictionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductServiceRemoveRestriction(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary search a product by partial name or uuid
         * @param {PpeSearchProductsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductServiceSearchProducts(body: PpeSearchProductsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeSearchProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductServiceSearchProducts(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a product
         * @param {string} uuid 
         * @param {InlineObject8} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductServiceUpdateProduct(uuid: string, body: InlineObject8, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductServiceUpdateProduct(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductServiceApi - factory interface
 * @export
 */
export const AdminProductServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary add a product
         * @param {PpeAddProductRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceAddProduct(body: PpeAddProductRequest, options?: any): AxiosPromise<PpeAddProductResponse> {
            return localVarFp.adminProductServiceAddProduct(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add restriction to a product
         * @param {PpeAddRestrictionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceAddRestriction(body: PpeAddRestrictionRequest, options?: any): AxiosPromise<PpeAddRestrictionResponse> {
            return localVarFp.adminProductServiceAddRestriction(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary archive a product
         * @param {string} productUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceArchiveProduct(productUuid: string, body: object, options?: any): AxiosPromise<PpeArchiveProductResponse> {
            return localVarFp.adminProductServiceArchiveProduct(productUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a product
         * @param {string} productUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceGetProduct(productUuid: string, options?: any): AxiosPromise<PpeGetProductResponse> {
            return localVarFp.adminProductServiceGetProduct(productUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all products
         * @param {PpeListProductsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceListProducts(body: PpeListProductsRequest, options?: any): AxiosPromise<PpeListProductsResponse> {
            return localVarFp.adminProductServiceListProducts(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List restrictions posed on a product
         * @param {PpeListRestrictionsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceListRestrictions(body: PpeListRestrictionsRequest, options?: any): AxiosPromise<PpeListRestrictionsResponse> {
            return localVarFp.adminProductServiceListRestrictions(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removerestriction on a product
         * @param {PpeRemoveRestrictionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceRemoveRestriction(body: PpeRemoveRestrictionRequest, options?: any): AxiosPromise<PpeRemoveRestrictionResponse> {
            return localVarFp.adminProductServiceRemoveRestriction(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary search a product by partial name or uuid
         * @param {PpeSearchProductsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceSearchProducts(body: PpeSearchProductsRequest, options?: any): AxiosPromise<PpeSearchProductsResponse> {
            return localVarFp.adminProductServiceSearchProducts(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a product
         * @param {string} uuid 
         * @param {InlineObject8} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductServiceUpdateProduct(uuid: string, body: InlineObject8, options?: any): AxiosPromise<PpeUpdateProductResponse> {
            return localVarFp.adminProductServiceUpdateProduct(uuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminProductServiceApi - object-oriented interface
 * @export
 * @class AdminProductServiceApi
 * @extends {BaseAPI}
 */
export class AdminProductServiceApi extends BaseAPI {
    /**
     * 
     * @summary add a product
     * @param {PpeAddProductRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductServiceApi
     */
    public adminProductServiceAddProduct(body: PpeAddProductRequest, options?: AxiosRequestConfig) {
        return AdminProductServiceApiFp(this.configuration).adminProductServiceAddProduct(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add restriction to a product
     * @param {PpeAddRestrictionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductServiceApi
     */
    public adminProductServiceAddRestriction(body: PpeAddRestrictionRequest, options?: AxiosRequestConfig) {
        return AdminProductServiceApiFp(this.configuration).adminProductServiceAddRestriction(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary archive a product
     * @param {string} productUuid 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductServiceApi
     */
    public adminProductServiceArchiveProduct(productUuid: string, body: object, options?: AxiosRequestConfig) {
        return AdminProductServiceApiFp(this.configuration).adminProductServiceArchiveProduct(productUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a product
     * @param {string} productUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductServiceApi
     */
    public adminProductServiceGetProduct(productUuid: string, options?: AxiosRequestConfig) {
        return AdminProductServiceApiFp(this.configuration).adminProductServiceGetProduct(productUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all products
     * @param {PpeListProductsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductServiceApi
     */
    public adminProductServiceListProducts(body: PpeListProductsRequest, options?: AxiosRequestConfig) {
        return AdminProductServiceApiFp(this.configuration).adminProductServiceListProducts(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List restrictions posed on a product
     * @param {PpeListRestrictionsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductServiceApi
     */
    public adminProductServiceListRestrictions(body: PpeListRestrictionsRequest, options?: AxiosRequestConfig) {
        return AdminProductServiceApiFp(this.configuration).adminProductServiceListRestrictions(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removerestriction on a product
     * @param {PpeRemoveRestrictionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductServiceApi
     */
    public adminProductServiceRemoveRestriction(body: PpeRemoveRestrictionRequest, options?: AxiosRequestConfig) {
        return AdminProductServiceApiFp(this.configuration).adminProductServiceRemoveRestriction(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary search a product by partial name or uuid
     * @param {PpeSearchProductsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductServiceApi
     */
    public adminProductServiceSearchProducts(body: PpeSearchProductsRequest, options?: AxiosRequestConfig) {
        return AdminProductServiceApiFp(this.configuration).adminProductServiceSearchProducts(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a product
     * @param {string} uuid 
     * @param {InlineObject8} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductServiceApi
     */
    public adminProductServiceUpdateProduct(uuid: string, body: InlineObject8, options?: AxiosRequestConfig) {
        return AdminProductServiceApiFp(this.configuration).adminProductServiceUpdateProduct(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSelfServiceServiceApi - axios parameter creator
 * @export
 */
export const AdminSelfServiceServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve self servicing config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSelfServiceServiceGetSelfServicingConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/organization-settings/self-servicing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List service now groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSelfServiceServiceListServiceNowGroup: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/servicenow/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync service now groups
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSelfServiceServiceSyncServiceNowGroup: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminSelfServiceServiceSyncServiceNowGroup', 'body', body)
            const localVarPath = `/api/v2/servicenow/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization self-servicing config
         * @param {PpeUpdateSelfServicingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSelfServiceServiceUpdateSelfServicing: async (body: PpeUpdateSelfServicingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminSelfServiceServiceUpdateSelfServicing', 'body', body)
            const localVarPath = `/api/v2/organization-settings/self-servicing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSelfServiceServiceApi - functional programming interface
 * @export
 */
export const AdminSelfServiceServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSelfServiceServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve self servicing config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSelfServiceServiceGetSelfServicingConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetSelfServicingConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSelfServiceServiceGetSelfServicingConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List service now groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSelfServiceServiceListServiceNowGroup(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListServiceNowGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSelfServiceServiceListServiceNowGroup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync service now groups
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSelfServiceServiceSyncServiceNowGroup(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeSyncServiceNowGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSelfServiceServiceSyncServiceNowGroup(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update organization self-servicing config
         * @param {PpeUpdateSelfServicingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSelfServiceServiceUpdateSelfServicing(body: PpeUpdateSelfServicingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateSelfServicingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSelfServiceServiceUpdateSelfServicing(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSelfServiceServiceApi - factory interface
 * @export
 */
export const AdminSelfServiceServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSelfServiceServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve self servicing config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSelfServiceServiceGetSelfServicingConfig(options?: any): AxiosPromise<PpeGetSelfServicingConfigResponse> {
            return localVarFp.adminSelfServiceServiceGetSelfServicingConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List service now groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSelfServiceServiceListServiceNowGroup(options?: any): AxiosPromise<PpeListServiceNowGroupResponse> {
            return localVarFp.adminSelfServiceServiceListServiceNowGroup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync service now groups
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSelfServiceServiceSyncServiceNowGroup(body: object, options?: any): AxiosPromise<PpeSyncServiceNowGroupResponse> {
            return localVarFp.adminSelfServiceServiceSyncServiceNowGroup(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update organization self-servicing config
         * @param {PpeUpdateSelfServicingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSelfServiceServiceUpdateSelfServicing(body: PpeUpdateSelfServicingRequest, options?: any): AxiosPromise<PpeUpdateSelfServicingResponse> {
            return localVarFp.adminSelfServiceServiceUpdateSelfServicing(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSelfServiceServiceApi - object-oriented interface
 * @export
 * @class AdminSelfServiceServiceApi
 * @extends {BaseAPI}
 */
export class AdminSelfServiceServiceApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve self servicing config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSelfServiceServiceApi
     */
    public adminSelfServiceServiceGetSelfServicingConfig(options?: AxiosRequestConfig) {
        return AdminSelfServiceServiceApiFp(this.configuration).adminSelfServiceServiceGetSelfServicingConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List service now groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSelfServiceServiceApi
     */
    public adminSelfServiceServiceListServiceNowGroup(options?: AxiosRequestConfig) {
        return AdminSelfServiceServiceApiFp(this.configuration).adminSelfServiceServiceListServiceNowGroup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync service now groups
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSelfServiceServiceApi
     */
    public adminSelfServiceServiceSyncServiceNowGroup(body: object, options?: AxiosRequestConfig) {
        return AdminSelfServiceServiceApiFp(this.configuration).adminSelfServiceServiceSyncServiceNowGroup(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update organization self-servicing config
     * @param {PpeUpdateSelfServicingRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSelfServiceServiceApi
     */
    public adminSelfServiceServiceUpdateSelfServicing(body: PpeUpdateSelfServicingRequest, options?: AxiosRequestConfig) {
        return AdminSelfServiceServiceApiFp(this.configuration).adminSelfServiceServiceUpdateSelfServicing(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminServiceNowServiceApi - axios parameter creator
 * @export
 */
export const AdminServiceNowServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check sync progress
         * @param {string} jobId job uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminServiceNowServiceGetServiceNowUserSyncJob: async (jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('adminServiceNowServiceGetServiceNowUserSyncJob', 'jobId', jobId)
            const localVarPath = `/api/v2/servicnow/delegations/sync-jobs/{job_id}`
                .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List user sync jobs
         * @param {string} [page] 
         * @param {string} [per] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminServiceNowServiceListServiceNowUserSyncJobs: async (page?: string, per?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/servicenow/user-sync-jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (per !== undefined) {
                localVarQueryParameter['per'] = per;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delegate settings. And sync
         * @param {PpeUpdateUsersServiceNowDelegationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminServiceNowServiceUpdateUsersServiceNowDelegation: async (body: PpeUpdateUsersServiceNowDelegationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminServiceNowServiceUpdateUsersServiceNowDelegation', 'body', body)
            const localVarPath = `/api/v2/servicenow/delegations/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminServiceNowServiceApi - functional programming interface
 * @export
 */
export const AdminServiceNowServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminServiceNowServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check sync progress
         * @param {string} jobId job uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminServiceNowServiceGetServiceNowUserSyncJob(jobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetServiceNowUserSyncJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminServiceNowServiceGetServiceNowUserSyncJob(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List user sync jobs
         * @param {string} [page] 
         * @param {string} [per] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminServiceNowServiceListServiceNowUserSyncJobs(page?: string, per?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListServiceNowUserSyncJobsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminServiceNowServiceListServiceNowUserSyncJobs(page, per, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delegate settings. And sync
         * @param {PpeUpdateUsersServiceNowDelegationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminServiceNowServiceUpdateUsersServiceNowDelegation(body: PpeUpdateUsersServiceNowDelegationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateUsersServiceNowDelegationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminServiceNowServiceUpdateUsersServiceNowDelegation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminServiceNowServiceApi - factory interface
 * @export
 */
export const AdminServiceNowServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminServiceNowServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Check sync progress
         * @param {string} jobId job uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminServiceNowServiceGetServiceNowUserSyncJob(jobId: string, options?: any): AxiosPromise<PpeGetServiceNowUserSyncJobResponse> {
            return localVarFp.adminServiceNowServiceGetServiceNowUserSyncJob(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List user sync jobs
         * @param {string} [page] 
         * @param {string} [per] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminServiceNowServiceListServiceNowUserSyncJobs(page?: string, per?: string, options?: any): AxiosPromise<PpeListServiceNowUserSyncJobsResponse> {
            return localVarFp.adminServiceNowServiceListServiceNowUserSyncJobs(page, per, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delegate settings. And sync
         * @param {PpeUpdateUsersServiceNowDelegationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminServiceNowServiceUpdateUsersServiceNowDelegation(body: PpeUpdateUsersServiceNowDelegationRequest, options?: any): AxiosPromise<PpeUpdateUsersServiceNowDelegationResponse> {
            return localVarFp.adminServiceNowServiceUpdateUsersServiceNowDelegation(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminServiceNowServiceApi - object-oriented interface
 * @export
 * @class AdminServiceNowServiceApi
 * @extends {BaseAPI}
 */
export class AdminServiceNowServiceApi extends BaseAPI {
    /**
     * 
     * @summary Check sync progress
     * @param {string} jobId job uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceNowServiceApi
     */
    public adminServiceNowServiceGetServiceNowUserSyncJob(jobId: string, options?: AxiosRequestConfig) {
        return AdminServiceNowServiceApiFp(this.configuration).adminServiceNowServiceGetServiceNowUserSyncJob(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List user sync jobs
     * @param {string} [page] 
     * @param {string} [per] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceNowServiceApi
     */
    public adminServiceNowServiceListServiceNowUserSyncJobs(page?: string, per?: string, options?: AxiosRequestConfig) {
        return AdminServiceNowServiceApiFp(this.configuration).adminServiceNowServiceListServiceNowUserSyncJobs(page, per, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delegate settings. And sync
     * @param {PpeUpdateUsersServiceNowDelegationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminServiceNowServiceApi
     */
    public adminServiceNowServiceUpdateUsersServiceNowDelegation(body: PpeUpdateUsersServiceNowDelegationRequest, options?: AxiosRequestConfig) {
        return AdminServiceNowServiceApiFp(this.configuration).adminServiceNowServiceUpdateUsersServiceNowDelegation(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSsoServiceApi - axios parameter creator
 * @export
 */
export const AdminSsoServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exchange security token with google token
         * @param {PpeExchangeSecurityTokenWithGoogleTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceExchangeSecurityTokenWithGoogleToken: async (body: PpeExchangeSecurityTokenWithGoogleTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminSsoServiceExchangeSecurityTokenWithGoogleToken', 'body', body)
            const localVarPath = `/api/v2/sso/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exchange security token with microsoft token
         * @param {PpeExchangeSecurityTokenWithMicrosoftTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceExchangeSecurityTokenWithMicrosoftToken: async (body: PpeExchangeSecurityTokenWithMicrosoftTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminSsoServiceExchangeSecurityTokenWithMicrosoftToken', 'body', body)
            const localVarPath = `/api/v2/sso/microsoft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Google SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceGetGoogleSsoConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/sso/google/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Microsoft SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceGetMicrosoftSsoConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/sso/microsoft/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link microsoft account to vot account
         * @param {PpeLinkMicrosoftAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceLinkMicrosoftAccount: async (body: PpeLinkMicrosoftAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminSsoServiceLinkMicrosoftAccount', 'body', body)
            const localVarPath = `/api/v2/sso/microsoft-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSsoServiceApi - functional programming interface
 * @export
 */
export const AdminSsoServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSsoServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exchange security token with google token
         * @param {PpeExchangeSecurityTokenWithGoogleTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSsoServiceExchangeSecurityTokenWithGoogleToken(body: PpeExchangeSecurityTokenWithGoogleTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeExchangeSecurityTokenWithGoogleTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSsoServiceExchangeSecurityTokenWithGoogleToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exchange security token with microsoft token
         * @param {PpeExchangeSecurityTokenWithMicrosoftTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSsoServiceExchangeSecurityTokenWithMicrosoftToken(body: PpeExchangeSecurityTokenWithMicrosoftTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeExchangeSecurityTokenWithMicrosoftTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSsoServiceExchangeSecurityTokenWithMicrosoftToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Google SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSsoServiceGetGoogleSsoConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetGoogleSsoConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSsoServiceGetGoogleSsoConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Microsoft SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSsoServiceGetMicrosoftSsoConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetMicrosoftSsoConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSsoServiceGetMicrosoftSsoConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Link microsoft account to vot account
         * @param {PpeLinkMicrosoftAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSsoServiceLinkMicrosoftAccount(body: PpeLinkMicrosoftAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeLinkMicrosoftAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSsoServiceLinkMicrosoftAccount(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSsoServiceApi - factory interface
 * @export
 */
export const AdminSsoServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSsoServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Exchange security token with google token
         * @param {PpeExchangeSecurityTokenWithGoogleTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceExchangeSecurityTokenWithGoogleToken(body: PpeExchangeSecurityTokenWithGoogleTokenRequest, options?: any): AxiosPromise<PpeExchangeSecurityTokenWithGoogleTokenResponse> {
            return localVarFp.adminSsoServiceExchangeSecurityTokenWithGoogleToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exchange security token with microsoft token
         * @param {PpeExchangeSecurityTokenWithMicrosoftTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceExchangeSecurityTokenWithMicrosoftToken(body: PpeExchangeSecurityTokenWithMicrosoftTokenRequest, options?: any): AxiosPromise<PpeExchangeSecurityTokenWithMicrosoftTokenResponse> {
            return localVarFp.adminSsoServiceExchangeSecurityTokenWithMicrosoftToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Google SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceGetGoogleSsoConfig(options?: any): AxiosPromise<PpeGetGoogleSsoConfigResponse> {
            return localVarFp.adminSsoServiceGetGoogleSsoConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Microsoft SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceGetMicrosoftSsoConfig(options?: any): AxiosPromise<PpeGetMicrosoftSsoConfigResponse> {
            return localVarFp.adminSsoServiceGetMicrosoftSsoConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link microsoft account to vot account
         * @param {PpeLinkMicrosoftAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSsoServiceLinkMicrosoftAccount(body: PpeLinkMicrosoftAccountRequest, options?: any): AxiosPromise<PpeLinkMicrosoftAccountResponse> {
            return localVarFp.adminSsoServiceLinkMicrosoftAccount(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSsoServiceApi - object-oriented interface
 * @export
 * @class AdminSsoServiceApi
 * @extends {BaseAPI}
 */
export class AdminSsoServiceApi extends BaseAPI {
    /**
     * 
     * @summary Exchange security token with google token
     * @param {PpeExchangeSecurityTokenWithGoogleTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSsoServiceApi
     */
    public adminSsoServiceExchangeSecurityTokenWithGoogleToken(body: PpeExchangeSecurityTokenWithGoogleTokenRequest, options?: AxiosRequestConfig) {
        return AdminSsoServiceApiFp(this.configuration).adminSsoServiceExchangeSecurityTokenWithGoogleToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exchange security token with microsoft token
     * @param {PpeExchangeSecurityTokenWithMicrosoftTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSsoServiceApi
     */
    public adminSsoServiceExchangeSecurityTokenWithMicrosoftToken(body: PpeExchangeSecurityTokenWithMicrosoftTokenRequest, options?: AxiosRequestConfig) {
        return AdminSsoServiceApiFp(this.configuration).adminSsoServiceExchangeSecurityTokenWithMicrosoftToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Google SSO config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSsoServiceApi
     */
    public adminSsoServiceGetGoogleSsoConfig(options?: AxiosRequestConfig) {
        return AdminSsoServiceApiFp(this.configuration).adminSsoServiceGetGoogleSsoConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Microsoft SSO config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSsoServiceApi
     */
    public adminSsoServiceGetMicrosoftSsoConfig(options?: AxiosRequestConfig) {
        return AdminSsoServiceApiFp(this.configuration).adminSsoServiceGetMicrosoftSsoConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link microsoft account to vot account
     * @param {PpeLinkMicrosoftAccountRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSsoServiceApi
     */
    public adminSsoServiceLinkMicrosoftAccount(body: PpeLinkMicrosoftAccountRequest, options?: AxiosRequestConfig) {
        return AdminSsoServiceApiFp(this.configuration).adminSsoServiceLinkMicrosoftAccount(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTemporaryAccessServiceApi - axios parameter creator
 * @export
 */
export const AdminTemporaryAccessServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary extent someone\'s temporary access
         * @param {string} uuid 
         * @param {InlineObject3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceExtentTemporaryAccess: async (uuid: string, body: InlineObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminTemporaryAccessServiceExtentTemporaryAccess', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTemporaryAccessServiceExtentTemporaryAccess', 'body', body)
            const localVarPath = `/api/v2/employees/{uuid}/temporary-access-extension`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get url for temporary access
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceGetTemporaryAccessUrl: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminTemporaryAccessServiceGetTemporaryAccessUrl', 'uuid', uuid)
            const localVarPath = `/api/v2/employees/{uuid}/temporary-access-url`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary grant a temporary access to someone
         * @param {PpeGrantTemporaryAccessRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceGrantTemporaryAccess: async (body: PpeGrantTemporaryAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTemporaryAccessServiceGrantTemporaryAccess', 'body', body)
            const localVarPath = `/api/v2/employees/temporary-access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary revoke someone\'s temporary access
         * @param {string} uuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceRevokeTemporaryAccess: async (uuid: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminTemporaryAccessServiceRevokeTemporaryAccess', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTemporaryAccessServiceRevokeTemporaryAccess', 'body', body)
            const localVarPath = `/api/v2/employees/{uuid}/temporary-access-revocations`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sent temporary access url to nominated email
         * @param {string} uuid 
         * @param {InlineObject4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceSendTemporaryAccessUrl: async (uuid: string, body: InlineObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminTemporaryAccessServiceSendTemporaryAccessUrl', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTemporaryAccessServiceSendTemporaryAccessUrl', 'body', body)
            const localVarPath = `/api/v2/employees/{uuid}/temporary-access-url/emails`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTemporaryAccessServiceApi - functional programming interface
 * @export
 */
export const AdminTemporaryAccessServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTemporaryAccessServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary extent someone\'s temporary access
         * @param {string} uuid 
         * @param {InlineObject3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTemporaryAccessServiceExtentTemporaryAccess(uuid: string, body: InlineObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeExtentTemporaryAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTemporaryAccessServiceExtentTemporaryAccess(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get url for temporary access
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTemporaryAccessServiceGetTemporaryAccessUrl(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetTemporaryAccessUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTemporaryAccessServiceGetTemporaryAccessUrl(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary grant a temporary access to someone
         * @param {PpeGrantTemporaryAccessRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTemporaryAccessServiceGrantTemporaryAccess(body: PpeGrantTemporaryAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGrantTemporaryAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTemporaryAccessServiceGrantTemporaryAccess(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary revoke someone\'s temporary access
         * @param {string} uuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTemporaryAccessServiceRevokeTemporaryAccess(uuid: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRevokeTemporaryAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTemporaryAccessServiceRevokeTemporaryAccess(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sent temporary access url to nominated email
         * @param {string} uuid 
         * @param {InlineObject4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTemporaryAccessServiceSendTemporaryAccessUrl(uuid: string, body: InlineObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeSendTemporaryAccessUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTemporaryAccessServiceSendTemporaryAccessUrl(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTemporaryAccessServiceApi - factory interface
 * @export
 */
export const AdminTemporaryAccessServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTemporaryAccessServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary extent someone\'s temporary access
         * @param {string} uuid 
         * @param {InlineObject3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceExtentTemporaryAccess(uuid: string, body: InlineObject3, options?: any): AxiosPromise<PpeExtentTemporaryAccessResponse> {
            return localVarFp.adminTemporaryAccessServiceExtentTemporaryAccess(uuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get url for temporary access
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceGetTemporaryAccessUrl(uuid: string, options?: any): AxiosPromise<PpeGetTemporaryAccessUrlResponse> {
            return localVarFp.adminTemporaryAccessServiceGetTemporaryAccessUrl(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary grant a temporary access to someone
         * @param {PpeGrantTemporaryAccessRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceGrantTemporaryAccess(body: PpeGrantTemporaryAccessRequest, options?: any): AxiosPromise<PpeGrantTemporaryAccessResponse> {
            return localVarFp.adminTemporaryAccessServiceGrantTemporaryAccess(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary revoke someone\'s temporary access
         * @param {string} uuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceRevokeTemporaryAccess(uuid: string, body: object, options?: any): AxiosPromise<PpeRevokeTemporaryAccessResponse> {
            return localVarFp.adminTemporaryAccessServiceRevokeTemporaryAccess(uuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sent temporary access url to nominated email
         * @param {string} uuid 
         * @param {InlineObject4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTemporaryAccessServiceSendTemporaryAccessUrl(uuid: string, body: InlineObject4, options?: any): AxiosPromise<PpeSendTemporaryAccessUrlResponse> {
            return localVarFp.adminTemporaryAccessServiceSendTemporaryAccessUrl(uuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTemporaryAccessServiceApi - object-oriented interface
 * @export
 * @class AdminTemporaryAccessServiceApi
 * @extends {BaseAPI}
 */
export class AdminTemporaryAccessServiceApi extends BaseAPI {
    /**
     * 
     * @summary extent someone\'s temporary access
     * @param {string} uuid 
     * @param {InlineObject3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemporaryAccessServiceApi
     */
    public adminTemporaryAccessServiceExtentTemporaryAccess(uuid: string, body: InlineObject3, options?: AxiosRequestConfig) {
        return AdminTemporaryAccessServiceApiFp(this.configuration).adminTemporaryAccessServiceExtentTemporaryAccess(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get url for temporary access
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemporaryAccessServiceApi
     */
    public adminTemporaryAccessServiceGetTemporaryAccessUrl(uuid: string, options?: AxiosRequestConfig) {
        return AdminTemporaryAccessServiceApiFp(this.configuration).adminTemporaryAccessServiceGetTemporaryAccessUrl(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary grant a temporary access to someone
     * @param {PpeGrantTemporaryAccessRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemporaryAccessServiceApi
     */
    public adminTemporaryAccessServiceGrantTemporaryAccess(body: PpeGrantTemporaryAccessRequest, options?: AxiosRequestConfig) {
        return AdminTemporaryAccessServiceApiFp(this.configuration).adminTemporaryAccessServiceGrantTemporaryAccess(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary revoke someone\'s temporary access
     * @param {string} uuid 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemporaryAccessServiceApi
     */
    public adminTemporaryAccessServiceRevokeTemporaryAccess(uuid: string, body: object, options?: AxiosRequestConfig) {
        return AdminTemporaryAccessServiceApiFp(this.configuration).adminTemporaryAccessServiceRevokeTemporaryAccess(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sent temporary access url to nominated email
     * @param {string} uuid 
     * @param {InlineObject4} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemporaryAccessServiceApi
     */
    public adminTemporaryAccessServiceSendTemporaryAccessUrl(uuid: string, body: InlineObject4, options?: AxiosRequestConfig) {
        return AdminTemporaryAccessServiceApiFp(this.configuration).adminTemporaryAccessServiceSendTemporaryAccessUrl(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTrackableItemServiceApi - axios parameter creator
 * @export
 */
export const AdminTrackableItemServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign an asset tag to a trackable item
         * @param {PpeAssignAssetTagToTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceAssignAssetTagToTrackableItem: async (body: PpeAssignAssetTagToTrackableItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceAssignAssetTagToTrackableItem', 'body', body)
            const localVarPath = `/api/v2/trackable-items/assign-asset-tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary assignment a trackable to a employee
         * @param {string} trackableItemUuid 
         * @param {InlineObject9} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceAssignTrackableItemToEmployee: async (trackableItemUuid: string, body: InlineObject9, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackableItemUuid' is not null or undefined
            assertParamExists('adminTrackableItemServiceAssignTrackableItemToEmployee', 'trackableItemUuid', trackableItemUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceAssignTrackableItemToEmployee', 'body', body)
            const localVarPath = `/api/v2/trackable-items/{trackable_item_uuid}/assignment`
                .replace(`{${"trackable_item_uuid"}}`, encodeURIComponent(String(trackableItemUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manually clear pending-inspection on item
         * @param {PpeClearPendingInspectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceClearPendingInspection: async (body: PpeClearPendingInspectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceClearPendingInspection', 'body', body)
            const localVarPath = `/api/v2/trackable-items/clear-pending-inspection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a trackable item
         * @param {PpeCreateTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceCreateTrackableItem: async (body: PpeCreateTrackableItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceCreateTrackableItem', 'body', body)
            const localVarPath = `/api/v2/trackable-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a trackable item
         * @param {PpeDeleteTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceDeleteTrackableItem: async (body: PpeDeleteTrackableItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceDeleteTrackableItem', 'body', body)
            const localVarPath = `/api/v2/trackable-items/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trackable item details
         * @param {string} trackableItemUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceGetTrackableItem: async (trackableItemUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackableItemUuid' is not null or undefined
            assertParamExists('adminTrackableItemServiceGetTrackableItem', 'trackableItemUuid', trackableItemUuid)
            const localVarPath = `/api/v2/trackable-items/{trackable_item_uuid}`
                .replace(`{${"trackable_item_uuid"}}`, encodeURIComponent(String(trackableItemUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all sessions of a trackable item
         * @param {string} trackableItemUuid 
         * @param {InlineObject11} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceGetTrackableItemSession: async (trackableItemUuid: string, body: InlineObject11, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackableItemUuid' is not null or undefined
            assertParamExists('adminTrackableItemServiceGetTrackableItemSession', 'trackableItemUuid', trackableItemUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceGetTrackableItemSession', 'body', body)
            const localVarPath = `/api/v2/trackable-items/{trackable_item_uuid}/sessions`
                .replace(`{${"trackable_item_uuid"}}`, encodeURIComponent(String(trackableItemUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List trackable items
         * @param {number} [page] 
         * @param {number} [per] 
         * @param {'DRAFT' | 'INVENTORY' | 'READY' | 'IN_USE' | 'RETURNED' | 'PENDING_INSPECTION' | 'LOST' | 'RETIRED' | 'DELETED'} [state] if not specified, it will get trackable item in all states
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceListTrackableItems: async (page?: number, per?: number, state?: 'DRAFT' | 'INVENTORY' | 'READY' | 'IN_USE' | 'RETURNED' | 'PENDING_INSPECTION' | 'LOST' | 'RETIRED' | 'DELETED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/trackable-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (per !== undefined) {
                localVarQueryParameter['per'] = per;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manually mark user as the current user
         * @param {PpeManuallyUpdateCurrentUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceManuallyUpdateCurrentUser: async (body: PpeManuallyUpdateCurrentUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceManuallyUpdateCurrentUser', 'body', body)
            const localVarPath = `/api/v2/trackable-items/manual-assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manually add pending-inspection to item
         * @param {PpeMarkPendingInspectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceMarkPendingInspection: async (body: PpeMarkPendingInspectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceMarkPendingInspection', 'body', body)
            const localVarPath = `/api/v2/trackable-items/pending-inspection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manually mark the item as ready
         * @param {PpeMarkReadyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceMarkReady: async (body: PpeMarkReadyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceMarkReady', 'body', body)
            const localVarPath = `/api/v2/trackable-items/ready`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a trackable item as missing
         * @param {PpeMarkTrackableItemAsMissingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceMarkTrackableItemAsMissing: async (body: PpeMarkTrackableItemAsMissingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceMarkTrackableItemAsMissing', 'body', body)
            const localVarPath = `/api/v2/trackable-items/mark-missing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark an item ready to use
         * @param {PpeMoveItemToLockerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceMoveItemToLocker: async (body: PpeMoveItemToLockerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceMoveItemToLocker', 'body', body)
            const localVarPath = `/api/v2/trackable-items/move`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace an asset tag on a trackable item
         * @param {PpeReplaceAssetTagRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceReplaceAssetTag: async (body: PpeReplaceAssetTagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceReplaceAssetTag', 'body', body)
            const localVarPath = `/api/v2/trackable-items/replace-asset-tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retire a trackable item
         * @param {string} trackableItemUuid 
         * @param {InlineObject10} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceRetireTrackableItem: async (trackableItemUuid: string, body: InlineObject10, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackableItemUuid' is not null or undefined
            assertParamExists('adminTrackableItemServiceRetireTrackableItem', 'trackableItemUuid', trackableItemUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceRetireTrackableItem', 'body', body)
            const localVarPath = `/api/v2/trackable-items/{trackable_item_uuid}/retirement`
                .replace(`{${"trackable_item_uuid"}}`, encodeURIComponent(String(trackableItemUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark an item ready to use
         * @param {PpeStockTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceStockTrackableItem: async (body: PpeStockTrackableItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceStockTrackableItem', 'body', body)
            const localVarPath = `/api/v2/trackable-items/stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Take an item out of circulation and move to pending inspection
         * @param {PpeTakeOutToInspectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceTakeOutToInspection: async (body: PpeTakeOutToInspectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceTakeOutToInspection', 'body', body)
            const localVarPath = `/api/v2/trackable-items/inspection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unassign an asset tag to a trackable item
         * @param {PpeUnassignAssetTagToTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceUnassignAssetTagToTrackableItem: async (body: PpeUnassignAssetTagToTrackableItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceUnassignAssetTagToTrackableItem', 'body', body)
            const localVarPath = `/api/v2/trackable-items/unassign-asset-tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update detail of trackable item
         * @param {PpeUpdateTrackableItemDetailRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceUpdateTrackableItemDetail: async (body: PpeUpdateTrackableItemDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceUpdateTrackableItemDetail', 'body', body)
            const localVarPath = `/api/v2/trackable-items/update-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign an asset tag to a trackable item
         * @param {PpeUpdateTrackableItemStateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceUpdateTrackableItemState: async (body: PpeUpdateTrackableItemStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTrackableItemServiceUpdateTrackableItemState', 'body', body)
            const localVarPath = `/api/v2/trackable-items/update-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTrackableItemServiceApi - functional programming interface
 * @export
 */
export const AdminTrackableItemServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTrackableItemServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign an asset tag to a trackable item
         * @param {PpeAssignAssetTagToTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceAssignAssetTagToTrackableItem(body: PpeAssignAssetTagToTrackableItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAssignAssetTagToTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceAssignAssetTagToTrackableItem(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary assignment a trackable to a employee
         * @param {string} trackableItemUuid 
         * @param {InlineObject9} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceAssignTrackableItemToEmployee(trackableItemUuid: string, body: InlineObject9, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAssignTrackableItemToEmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceAssignTrackableItemToEmployee(trackableItemUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manually clear pending-inspection on item
         * @param {PpeClearPendingInspectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceClearPendingInspection(body: PpeClearPendingInspectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeClearPendingInspectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceClearPendingInspection(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a trackable item
         * @param {PpeCreateTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceCreateTrackableItem(body: PpeCreateTrackableItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeCreateTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceCreateTrackableItem(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a trackable item
         * @param {PpeDeleteTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceDeleteTrackableItem(body: PpeDeleteTrackableItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeDeleteTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceDeleteTrackableItem(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get trackable item details
         * @param {string} trackableItemUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceGetTrackableItem(trackableItemUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceGetTrackableItem(trackableItemUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all sessions of a trackable item
         * @param {string} trackableItemUuid 
         * @param {InlineObject11} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceGetTrackableItemSession(trackableItemUuid: string, body: InlineObject11, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetTrackableItemSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceGetTrackableItemSession(trackableItemUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List trackable items
         * @param {number} [page] 
         * @param {number} [per] 
         * @param {'DRAFT' | 'INVENTORY' | 'READY' | 'IN_USE' | 'RETURNED' | 'PENDING_INSPECTION' | 'LOST' | 'RETIRED' | 'DELETED'} [state] if not specified, it will get trackable item in all states
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceListTrackableItems(page?: number, per?: number, state?: 'DRAFT' | 'INVENTORY' | 'READY' | 'IN_USE' | 'RETURNED' | 'PENDING_INSPECTION' | 'LOST' | 'RETIRED' | 'DELETED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListTrackableItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceListTrackableItems(page, per, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manually mark user as the current user
         * @param {PpeManuallyUpdateCurrentUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceManuallyUpdateCurrentUser(body: PpeManuallyUpdateCurrentUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeManuallyUpdateCurrentUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceManuallyUpdateCurrentUser(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manually add pending-inspection to item
         * @param {PpeMarkPendingInspectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceMarkPendingInspection(body: PpeMarkPendingInspectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeMarkPendingInspectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceMarkPendingInspection(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manually mark the item as ready
         * @param {PpeMarkReadyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceMarkReady(body: PpeMarkReadyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeMarkReadyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceMarkReady(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a trackable item as missing
         * @param {PpeMarkTrackableItemAsMissingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceMarkTrackableItemAsMissing(body: PpeMarkTrackableItemAsMissingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeMarkTrackableItemAsMissingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceMarkTrackableItemAsMissing(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark an item ready to use
         * @param {PpeMoveItemToLockerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceMoveItemToLocker(body: PpeMoveItemToLockerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeMoveItemToLockerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceMoveItemToLocker(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Replace an asset tag on a trackable item
         * @param {PpeReplaceAssetTagRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceReplaceAssetTag(body: PpeReplaceAssetTagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeReplaceAssetTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceReplaceAssetTag(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retire a trackable item
         * @param {string} trackableItemUuid 
         * @param {InlineObject10} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceRetireTrackableItem(trackableItemUuid: string, body: InlineObject10, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRetireTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceRetireTrackableItem(trackableItemUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark an item ready to use
         * @param {PpeStockTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceStockTrackableItem(body: PpeStockTrackableItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeStockTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceStockTrackableItem(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Take an item out of circulation and move to pending inspection
         * @param {PpeTakeOutToInspectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceTakeOutToInspection(body: PpeTakeOutToInspectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeTakeOutToInspectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceTakeOutToInspection(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unassign an asset tag to a trackable item
         * @param {PpeUnassignAssetTagToTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceUnassignAssetTagToTrackableItem(body: PpeUnassignAssetTagToTrackableItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUnassignAssetTagToTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceUnassignAssetTagToTrackableItem(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update detail of trackable item
         * @param {PpeUpdateTrackableItemDetailRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceUpdateTrackableItemDetail(body: PpeUpdateTrackableItemDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateTrackableItemDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceUpdateTrackableItemDetail(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign an asset tag to a trackable item
         * @param {PpeUpdateTrackableItemStateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackableItemServiceUpdateTrackableItemState(body: PpeUpdateTrackableItemStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateTrackableItemStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackableItemServiceUpdateTrackableItemState(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTrackableItemServiceApi - factory interface
 * @export
 */
export const AdminTrackableItemServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTrackableItemServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign an asset tag to a trackable item
         * @param {PpeAssignAssetTagToTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceAssignAssetTagToTrackableItem(body: PpeAssignAssetTagToTrackableItemRequest, options?: any): AxiosPromise<PpeAssignAssetTagToTrackableItemResponse> {
            return localVarFp.adminTrackableItemServiceAssignAssetTagToTrackableItem(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary assignment a trackable to a employee
         * @param {string} trackableItemUuid 
         * @param {InlineObject9} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceAssignTrackableItemToEmployee(trackableItemUuid: string, body: InlineObject9, options?: any): AxiosPromise<PpeAssignTrackableItemToEmployeeResponse> {
            return localVarFp.adminTrackableItemServiceAssignTrackableItemToEmployee(trackableItemUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manually clear pending-inspection on item
         * @param {PpeClearPendingInspectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceClearPendingInspection(body: PpeClearPendingInspectionRequest, options?: any): AxiosPromise<PpeClearPendingInspectionResponse> {
            return localVarFp.adminTrackableItemServiceClearPendingInspection(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a trackable item
         * @param {PpeCreateTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceCreateTrackableItem(body: PpeCreateTrackableItemRequest, options?: any): AxiosPromise<PpeCreateTrackableItemResponse> {
            return localVarFp.adminTrackableItemServiceCreateTrackableItem(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a trackable item
         * @param {PpeDeleteTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceDeleteTrackableItem(body: PpeDeleteTrackableItemRequest, options?: any): AxiosPromise<PpeDeleteTrackableItemResponse> {
            return localVarFp.adminTrackableItemServiceDeleteTrackableItem(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trackable item details
         * @param {string} trackableItemUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceGetTrackableItem(trackableItemUuid: string, options?: any): AxiosPromise<PpeGetTrackableItemResponse> {
            return localVarFp.adminTrackableItemServiceGetTrackableItem(trackableItemUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all sessions of a trackable item
         * @param {string} trackableItemUuid 
         * @param {InlineObject11} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceGetTrackableItemSession(trackableItemUuid: string, body: InlineObject11, options?: any): AxiosPromise<PpeGetTrackableItemSessionResponse> {
            return localVarFp.adminTrackableItemServiceGetTrackableItemSession(trackableItemUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List trackable items
         * @param {number} [page] 
         * @param {number} [per] 
         * @param {'DRAFT' | 'INVENTORY' | 'READY' | 'IN_USE' | 'RETURNED' | 'PENDING_INSPECTION' | 'LOST' | 'RETIRED' | 'DELETED'} [state] if not specified, it will get trackable item in all states
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceListTrackableItems(page?: number, per?: number, state?: 'DRAFT' | 'INVENTORY' | 'READY' | 'IN_USE' | 'RETURNED' | 'PENDING_INSPECTION' | 'LOST' | 'RETIRED' | 'DELETED', options?: any): AxiosPromise<PpeListTrackableItemsResponse> {
            return localVarFp.adminTrackableItemServiceListTrackableItems(page, per, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manually mark user as the current user
         * @param {PpeManuallyUpdateCurrentUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceManuallyUpdateCurrentUser(body: PpeManuallyUpdateCurrentUserRequest, options?: any): AxiosPromise<PpeManuallyUpdateCurrentUserResponse> {
            return localVarFp.adminTrackableItemServiceManuallyUpdateCurrentUser(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manually add pending-inspection to item
         * @param {PpeMarkPendingInspectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceMarkPendingInspection(body: PpeMarkPendingInspectionRequest, options?: any): AxiosPromise<PpeMarkPendingInspectionResponse> {
            return localVarFp.adminTrackableItemServiceMarkPendingInspection(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manually mark the item as ready
         * @param {PpeMarkReadyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceMarkReady(body: PpeMarkReadyRequest, options?: any): AxiosPromise<PpeMarkReadyResponse> {
            return localVarFp.adminTrackableItemServiceMarkReady(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a trackable item as missing
         * @param {PpeMarkTrackableItemAsMissingRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceMarkTrackableItemAsMissing(body: PpeMarkTrackableItemAsMissingRequest, options?: any): AxiosPromise<PpeMarkTrackableItemAsMissingResponse> {
            return localVarFp.adminTrackableItemServiceMarkTrackableItemAsMissing(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark an item ready to use
         * @param {PpeMoveItemToLockerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceMoveItemToLocker(body: PpeMoveItemToLockerRequest, options?: any): AxiosPromise<PpeMoveItemToLockerResponse> {
            return localVarFp.adminTrackableItemServiceMoveItemToLocker(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace an asset tag on a trackable item
         * @param {PpeReplaceAssetTagRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceReplaceAssetTag(body: PpeReplaceAssetTagRequest, options?: any): AxiosPromise<PpeReplaceAssetTagResponse> {
            return localVarFp.adminTrackableItemServiceReplaceAssetTag(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retire a trackable item
         * @param {string} trackableItemUuid 
         * @param {InlineObject10} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceRetireTrackableItem(trackableItemUuid: string, body: InlineObject10, options?: any): AxiosPromise<PpeRetireTrackableItemResponse> {
            return localVarFp.adminTrackableItemServiceRetireTrackableItem(trackableItemUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark an item ready to use
         * @param {PpeStockTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceStockTrackableItem(body: PpeStockTrackableItemRequest, options?: any): AxiosPromise<PpeStockTrackableItemResponse> {
            return localVarFp.adminTrackableItemServiceStockTrackableItem(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Take an item out of circulation and move to pending inspection
         * @param {PpeTakeOutToInspectionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceTakeOutToInspection(body: PpeTakeOutToInspectionRequest, options?: any): AxiosPromise<PpeTakeOutToInspectionResponse> {
            return localVarFp.adminTrackableItemServiceTakeOutToInspection(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unassign an asset tag to a trackable item
         * @param {PpeUnassignAssetTagToTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceUnassignAssetTagToTrackableItem(body: PpeUnassignAssetTagToTrackableItemRequest, options?: any): AxiosPromise<PpeUnassignAssetTagToTrackableItemResponse> {
            return localVarFp.adminTrackableItemServiceUnassignAssetTagToTrackableItem(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update detail of trackable item
         * @param {PpeUpdateTrackableItemDetailRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceUpdateTrackableItemDetail(body: PpeUpdateTrackableItemDetailRequest, options?: any): AxiosPromise<PpeUpdateTrackableItemDetailResponse> {
            return localVarFp.adminTrackableItemServiceUpdateTrackableItemDetail(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign an asset tag to a trackable item
         * @param {PpeUpdateTrackableItemStateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackableItemServiceUpdateTrackableItemState(body: PpeUpdateTrackableItemStateRequest, options?: any): AxiosPromise<PpeUpdateTrackableItemStateResponse> {
            return localVarFp.adminTrackableItemServiceUpdateTrackableItemState(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTrackableItemServiceApi - object-oriented interface
 * @export
 * @class AdminTrackableItemServiceApi
 * @extends {BaseAPI}
 */
export class AdminTrackableItemServiceApi extends BaseAPI {
    /**
     * 
     * @summary Assign an asset tag to a trackable item
     * @param {PpeAssignAssetTagToTrackableItemRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceAssignAssetTagToTrackableItem(body: PpeAssignAssetTagToTrackableItemRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceAssignAssetTagToTrackableItem(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary assignment a trackable to a employee
     * @param {string} trackableItemUuid 
     * @param {InlineObject9} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceAssignTrackableItemToEmployee(trackableItemUuid: string, body: InlineObject9, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceAssignTrackableItemToEmployee(trackableItemUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manually clear pending-inspection on item
     * @param {PpeClearPendingInspectionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceClearPendingInspection(body: PpeClearPendingInspectionRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceClearPendingInspection(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a trackable item
     * @param {PpeCreateTrackableItemRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceCreateTrackableItem(body: PpeCreateTrackableItemRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceCreateTrackableItem(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a trackable item
     * @param {PpeDeleteTrackableItemRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceDeleteTrackableItem(body: PpeDeleteTrackableItemRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceDeleteTrackableItem(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trackable item details
     * @param {string} trackableItemUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceGetTrackableItem(trackableItemUuid: string, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceGetTrackableItem(trackableItemUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all sessions of a trackable item
     * @param {string} trackableItemUuid 
     * @param {InlineObject11} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceGetTrackableItemSession(trackableItemUuid: string, body: InlineObject11, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceGetTrackableItemSession(trackableItemUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List trackable items
     * @param {number} [page] 
     * @param {number} [per] 
     * @param {'DRAFT' | 'INVENTORY' | 'READY' | 'IN_USE' | 'RETURNED' | 'PENDING_INSPECTION' | 'LOST' | 'RETIRED' | 'DELETED'} [state] if not specified, it will get trackable item in all states
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceListTrackableItems(page?: number, per?: number, state?: 'DRAFT' | 'INVENTORY' | 'READY' | 'IN_USE' | 'RETURNED' | 'PENDING_INSPECTION' | 'LOST' | 'RETIRED' | 'DELETED', options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceListTrackableItems(page, per, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manually mark user as the current user
     * @param {PpeManuallyUpdateCurrentUserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceManuallyUpdateCurrentUser(body: PpeManuallyUpdateCurrentUserRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceManuallyUpdateCurrentUser(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manually add pending-inspection to item
     * @param {PpeMarkPendingInspectionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceMarkPendingInspection(body: PpeMarkPendingInspectionRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceMarkPendingInspection(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manually mark the item as ready
     * @param {PpeMarkReadyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceMarkReady(body: PpeMarkReadyRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceMarkReady(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a trackable item as missing
     * @param {PpeMarkTrackableItemAsMissingRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceMarkTrackableItemAsMissing(body: PpeMarkTrackableItemAsMissingRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceMarkTrackableItemAsMissing(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark an item ready to use
     * @param {PpeMoveItemToLockerRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceMoveItemToLocker(body: PpeMoveItemToLockerRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceMoveItemToLocker(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace an asset tag on a trackable item
     * @param {PpeReplaceAssetTagRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceReplaceAssetTag(body: PpeReplaceAssetTagRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceReplaceAssetTag(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retire a trackable item
     * @param {string} trackableItemUuid 
     * @param {InlineObject10} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceRetireTrackableItem(trackableItemUuid: string, body: InlineObject10, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceRetireTrackableItem(trackableItemUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark an item ready to use
     * @param {PpeStockTrackableItemRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceStockTrackableItem(body: PpeStockTrackableItemRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceStockTrackableItem(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Take an item out of circulation and move to pending inspection
     * @param {PpeTakeOutToInspectionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceTakeOutToInspection(body: PpeTakeOutToInspectionRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceTakeOutToInspection(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unassign an asset tag to a trackable item
     * @param {PpeUnassignAssetTagToTrackableItemRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceUnassignAssetTagToTrackableItem(body: PpeUnassignAssetTagToTrackableItemRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceUnassignAssetTagToTrackableItem(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update detail of trackable item
     * @param {PpeUpdateTrackableItemDetailRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceUpdateTrackableItemDetail(body: PpeUpdateTrackableItemDetailRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceUpdateTrackableItemDetail(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign an asset tag to a trackable item
     * @param {PpeUpdateTrackableItemStateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTrackableItemServiceApi
     */
    public adminTrackableItemServiceUpdateTrackableItemState(body: PpeUpdateTrackableItemStateRequest, options?: AxiosRequestConfig) {
        return AdminTrackableItemServiceApiFp(this.configuration).adminTrackableItemServiceUpdateTrackableItemState(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTransactionServiceApi - axios parameter creator
 * @export
 */
export const AdminTransactionServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary aggregate transaction by employee, product and machines
         * @param {PpeGetAggregatedTransactionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceGetAggregatedTransaction: async (body: PpeGetAggregatedTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTransactionServiceGetAggregatedTransaction', 'body', body)
            const localVarPath = `/api/v2/transactions-aggregation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of transactions
         * @param {PpeGetTransactionsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceGetTransactions: async (body: PpeGetTransactionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTransactionServiceGetTransactions', 'body', body)
            const localVarPath = `/api/v2/list-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get number of transaction by day/week/month
         * @param {PpeGetTransactionsCountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceGetTransactionsCount: async (body: PpeGetTransactionsCountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTransactionServiceGetTransactionsCount', 'body', body)
            const localVarPath = `/api/v2/count-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get number of transaction by product
         * @param {PpeGetTransactionsCountByProductRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceGetTransactionsCountByProduct: async (body: PpeGetTransactionsCountByProductRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTransactionServiceGetTransactionsCountByProduct', 'body', body)
            const localVarPath = `/api/v2/transactions-count-by-product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a transaction manually
         * @param {string} uuid 
         * @param {InlineObject12} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceUpdateTransaction: async (uuid: string, body: InlineObject12, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminTransactionServiceUpdateTransaction', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTransactionServiceUpdateTransaction', 'body', body)
            const localVarPath = `/api/v2/transactions/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTransactionServiceApi - functional programming interface
 * @export
 */
export const AdminTransactionServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTransactionServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary aggregate transaction by employee, product and machines
         * @param {PpeGetAggregatedTransactionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTransactionServiceGetAggregatedTransaction(body: PpeGetAggregatedTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetAggregatedTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTransactionServiceGetAggregatedTransaction(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of transactions
         * @param {PpeGetTransactionsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTransactionServiceGetTransactions(body: PpeGetTransactionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetTransactionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTransactionServiceGetTransactions(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get number of transaction by day/week/month
         * @param {PpeGetTransactionsCountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTransactionServiceGetTransactionsCount(body: PpeGetTransactionsCountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetTransactionsCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTransactionServiceGetTransactionsCount(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get number of transaction by product
         * @param {PpeGetTransactionsCountByProductRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTransactionServiceGetTransactionsCountByProduct(body: PpeGetTransactionsCountByProductRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetTransactionsCountByProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTransactionServiceGetTransactionsCountByProduct(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a transaction manually
         * @param {string} uuid 
         * @param {InlineObject12} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTransactionServiceUpdateTransaction(uuid: string, body: InlineObject12, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTransactionServiceUpdateTransaction(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTransactionServiceApi - factory interface
 * @export
 */
export const AdminTransactionServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTransactionServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary aggregate transaction by employee, product and machines
         * @param {PpeGetAggregatedTransactionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceGetAggregatedTransaction(body: PpeGetAggregatedTransactionRequest, options?: any): AxiosPromise<PpeGetAggregatedTransactionResponse> {
            return localVarFp.adminTransactionServiceGetAggregatedTransaction(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of transactions
         * @param {PpeGetTransactionsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceGetTransactions(body: PpeGetTransactionsRequest, options?: any): AxiosPromise<PpeGetTransactionsResponse> {
            return localVarFp.adminTransactionServiceGetTransactions(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get number of transaction by day/week/month
         * @param {PpeGetTransactionsCountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceGetTransactionsCount(body: PpeGetTransactionsCountRequest, options?: any): AxiosPromise<PpeGetTransactionsCountResponse> {
            return localVarFp.adminTransactionServiceGetTransactionsCount(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get number of transaction by product
         * @param {PpeGetTransactionsCountByProductRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceGetTransactionsCountByProduct(body: PpeGetTransactionsCountByProductRequest, options?: any): AxiosPromise<PpeGetTransactionsCountByProductResponse> {
            return localVarFp.adminTransactionServiceGetTransactionsCountByProduct(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a transaction manually
         * @param {string} uuid 
         * @param {InlineObject12} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTransactionServiceUpdateTransaction(uuid: string, body: InlineObject12, options?: any): AxiosPromise<PpeUpdateTransactionResponse> {
            return localVarFp.adminTransactionServiceUpdateTransaction(uuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTransactionServiceApi - object-oriented interface
 * @export
 * @class AdminTransactionServiceApi
 * @extends {BaseAPI}
 */
export class AdminTransactionServiceApi extends BaseAPI {
    /**
     * 
     * @summary aggregate transaction by employee, product and machines
     * @param {PpeGetAggregatedTransactionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTransactionServiceApi
     */
    public adminTransactionServiceGetAggregatedTransaction(body: PpeGetAggregatedTransactionRequest, options?: AxiosRequestConfig) {
        return AdminTransactionServiceApiFp(this.configuration).adminTransactionServiceGetAggregatedTransaction(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of transactions
     * @param {PpeGetTransactionsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTransactionServiceApi
     */
    public adminTransactionServiceGetTransactions(body: PpeGetTransactionsRequest, options?: AxiosRequestConfig) {
        return AdminTransactionServiceApiFp(this.configuration).adminTransactionServiceGetTransactions(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get number of transaction by day/week/month
     * @param {PpeGetTransactionsCountRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTransactionServiceApi
     */
    public adminTransactionServiceGetTransactionsCount(body: PpeGetTransactionsCountRequest, options?: AxiosRequestConfig) {
        return AdminTransactionServiceApiFp(this.configuration).adminTransactionServiceGetTransactionsCount(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get number of transaction by product
     * @param {PpeGetTransactionsCountByProductRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTransactionServiceApi
     */
    public adminTransactionServiceGetTransactionsCountByProduct(body: PpeGetTransactionsCountByProductRequest, options?: AxiosRequestConfig) {
        return AdminTransactionServiceApiFp(this.configuration).adminTransactionServiceGetTransactionsCountByProduct(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a transaction manually
     * @param {string} uuid 
     * @param {InlineObject12} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTransactionServiceApi
     */
    public adminTransactionServiceUpdateTransaction(uuid: string, body: InlineObject12, options?: AxiosRequestConfig) {
        return AdminTransactionServiceApiFp(this.configuration).adminTransactionServiceUpdateTransaction(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminUserServiceApi - axios parameter creator
 * @export
 */
export const AdminUserServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Invite admin onboard by email address
         * @param {PpeAddAdminRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceAddAdmin: async (body: PpeAddAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminUserServiceAddAdmin', 'body', body)
            const localVarPath = `/api/v2/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get my information and configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceGetMyInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/admins/my-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tfa setup data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceGetTfaSetupData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/admins/tfa-setup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of company admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceListAdmins: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of organization that the admin has right to manage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceListOrganizations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/admins/organization-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login to an organization
         * @param {PpeLoginToOrganizationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceLoginToOrganization: async (body: PpeLoginToOrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminUserServiceLoginToOrganization', 'body', body)
            const localVarPath = `/api/v2/admins/organization-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary revoke admin
         * @param {PpeRevokeAdminRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceRevokeAdmin: async (body: PpeRevokeAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminUserServiceRevokeAdmin', 'body', body)
            const localVarPath = `/api/v2/admins/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setup two factors authentication
         * @param {PpeSetupTfaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceSetupTfa: async (body: PpeSetupTfaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminUserServiceSetupTfa', 'body', body)
            const localVarPath = `/api/v2/admins/tfa-setup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink Microsoft 365 account
         * @param {PpeUnlinkMsSsoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceUnlinkMsSso: async (body: PpeUnlinkMsSsoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminUserServiceUnlinkMsSso', 'body', body)
            const localVarPath = `/api/v2/admins/ms365/unlink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update summary email preferences
         * @param {PpeUpdateEmailPreferencesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceUpdateEmailPreferences: async (body: PpeUpdateEmailPreferencesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminUserServiceUpdateEmailPreferences', 'body', body)
            const localVarPath = `/api/v2/admins/email-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update my password
         * @param {PpeUpdatePasswordRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceUpdatePassword: async (body: PpeUpdatePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminUserServiceUpdatePassword', 'body', body)
            const localVarPath = `/api/v2/admins/password-updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user timezone
         * @param {PpeUpdateTimezoneRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceUpdateTimezone: async (body: PpeUpdateTimezoneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminUserServiceUpdateTimezone', 'body', body)
            const localVarPath = `/api/v2/admins/timezone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserServiceApi - functional programming interface
 * @export
 */
export const AdminUserServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Invite admin onboard by email address
         * @param {PpeAddAdminRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceAddAdmin(body: PpeAddAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAddAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceAddAdmin(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get my information and configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceGetMyInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetMyInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceGetMyInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tfa setup data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceGetTfaSetupData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeGetTfaSetupDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceGetTfaSetupData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of company admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceListAdmins(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListAdminsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceListAdmins(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of organization that the admin has right to manage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceListOrganizations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListOrganizationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceListOrganizations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary login to an organization
         * @param {PpeLoginToOrganizationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceLoginToOrganization(body: PpeLoginToOrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeLoginToOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceLoginToOrganization(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary revoke admin
         * @param {PpeRevokeAdminRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceRevokeAdmin(body: PpeRevokeAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRevokeAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceRevokeAdmin(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setup two factors authentication
         * @param {PpeSetupTfaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceSetupTfa(body: PpeSetupTfaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeSetupTfaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceSetupTfa(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlink Microsoft 365 account
         * @param {PpeUnlinkMsSsoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceUnlinkMsSso(body: PpeUnlinkMsSsoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUnlinkMsSsoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceUnlinkMsSso(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update summary email preferences
         * @param {PpeUpdateEmailPreferencesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceUpdateEmailPreferences(body: PpeUpdateEmailPreferencesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateEmailPreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceUpdateEmailPreferences(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update my password
         * @param {PpeUpdatePasswordRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceUpdatePassword(body: PpeUpdatePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdatePasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceUpdatePassword(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user timezone
         * @param {PpeUpdateTimezoneRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserServiceUpdateTimezone(body: PpeUpdateTimezoneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateTimezoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserServiceUpdateTimezone(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUserServiceApi - factory interface
 * @export
 */
export const AdminUserServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Invite admin onboard by email address
         * @param {PpeAddAdminRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceAddAdmin(body: PpeAddAdminRequest, options?: any): AxiosPromise<PpeAddAdminResponse> {
            return localVarFp.adminUserServiceAddAdmin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get my information and configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceGetMyInfo(options?: any): AxiosPromise<PpeGetMyInfoResponse> {
            return localVarFp.adminUserServiceGetMyInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tfa setup data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceGetTfaSetupData(options?: any): AxiosPromise<PpeGetTfaSetupDataResponse> {
            return localVarFp.adminUserServiceGetTfaSetupData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of company admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceListAdmins(options?: any): AxiosPromise<PpeListAdminsResponse> {
            return localVarFp.adminUserServiceListAdmins(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of organization that the admin has right to manage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceListOrganizations(options?: any): AxiosPromise<PpeListOrganizationsResponse> {
            return localVarFp.adminUserServiceListOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary login to an organization
         * @param {PpeLoginToOrganizationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceLoginToOrganization(body: PpeLoginToOrganizationRequest, options?: any): AxiosPromise<PpeLoginToOrganizationResponse> {
            return localVarFp.adminUserServiceLoginToOrganization(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary revoke admin
         * @param {PpeRevokeAdminRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceRevokeAdmin(body: PpeRevokeAdminRequest, options?: any): AxiosPromise<PpeRevokeAdminResponse> {
            return localVarFp.adminUserServiceRevokeAdmin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setup two factors authentication
         * @param {PpeSetupTfaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceSetupTfa(body: PpeSetupTfaRequest, options?: any): AxiosPromise<PpeSetupTfaResponse> {
            return localVarFp.adminUserServiceSetupTfa(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink Microsoft 365 account
         * @param {PpeUnlinkMsSsoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceUnlinkMsSso(body: PpeUnlinkMsSsoRequest, options?: any): AxiosPromise<PpeUnlinkMsSsoResponse> {
            return localVarFp.adminUserServiceUnlinkMsSso(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update summary email preferences
         * @param {PpeUpdateEmailPreferencesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceUpdateEmailPreferences(body: PpeUpdateEmailPreferencesRequest, options?: any): AxiosPromise<PpeUpdateEmailPreferencesResponse> {
            return localVarFp.adminUserServiceUpdateEmailPreferences(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update my password
         * @param {PpeUpdatePasswordRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceUpdatePassword(body: PpeUpdatePasswordRequest, options?: any): AxiosPromise<PpeUpdatePasswordResponse> {
            return localVarFp.adminUserServiceUpdatePassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user timezone
         * @param {PpeUpdateTimezoneRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserServiceUpdateTimezone(body: PpeUpdateTimezoneRequest, options?: any): AxiosPromise<PpeUpdateTimezoneResponse> {
            return localVarFp.adminUserServiceUpdateTimezone(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserServiceApi - object-oriented interface
 * @export
 * @class AdminUserServiceApi
 * @extends {BaseAPI}
 */
export class AdminUserServiceApi extends BaseAPI {
    /**
     * 
     * @summary Invite admin onboard by email address
     * @param {PpeAddAdminRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceAddAdmin(body: PpeAddAdminRequest, options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceAddAdmin(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get my information and configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceGetMyInfo(options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceGetMyInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tfa setup data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceGetTfaSetupData(options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceGetTfaSetupData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of company admins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceListAdmins(options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceListAdmins(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of organization that the admin has right to manage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceListOrganizations(options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceListOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary login to an organization
     * @param {PpeLoginToOrganizationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceLoginToOrganization(body: PpeLoginToOrganizationRequest, options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceLoginToOrganization(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary revoke admin
     * @param {PpeRevokeAdminRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceRevokeAdmin(body: PpeRevokeAdminRequest, options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceRevokeAdmin(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setup two factors authentication
     * @param {PpeSetupTfaRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceSetupTfa(body: PpeSetupTfaRequest, options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceSetupTfa(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink Microsoft 365 account
     * @param {PpeUnlinkMsSsoRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceUnlinkMsSso(body: PpeUnlinkMsSsoRequest, options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceUnlinkMsSso(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update summary email preferences
     * @param {PpeUpdateEmailPreferencesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceUpdateEmailPreferences(body: PpeUpdateEmailPreferencesRequest, options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceUpdateEmailPreferences(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update my password
     * @param {PpeUpdatePasswordRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceUpdatePassword(body: PpeUpdatePasswordRequest, options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceUpdatePassword(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user timezone
     * @param {PpeUpdateTimezoneRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserServiceApi
     */
    public adminUserServiceUpdateTimezone(body: PpeUpdateTimezoneRequest, options?: AxiosRequestConfig) {
        return AdminUserServiceApiFp(this.configuration).adminUserServiceUpdateTimezone(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminVendingMachineServiceApi - axios parameter creator
 * @export
 */
export const AdminVendingMachineServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new product map for the vending machine
         * @param {string} vendingMachineUuid 
         * @param {InlineObject15} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceCreateProductMap: async (vendingMachineUuid: string, body: InlineObject15, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendingMachineUuid' is not null or undefined
            assertParamExists('adminVendingMachineServiceCreateProductMap', 'vendingMachineUuid', vendingMachineUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminVendingMachineServiceCreateProductMap', 'body', body)
            const localVarPath = `/api/v2/vending-machines/{vending_machine_uuid}/product-maps`
                .replace(`{${"vending_machine_uuid"}}`, encodeURIComponent(String(vendingMachineUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all product maps of that belongs to the vending machine
         * @param {string} vendingMachineUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceListProductMaps: async (vendingMachineUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendingMachineUuid' is not null or undefined
            assertParamExists('adminVendingMachineServiceListProductMaps', 'vendingMachineUuid', vendingMachineUuid)
            const localVarPath = `/api/v2/vending-machines/{vending_machine_uuid}/product-maps`
                .replace(`{${"vending_machine_uuid"}}`, encodeURIComponent(String(vendingMachineUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all vending machines
         * @param {PpeListVendingMachinesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceListVendingMachines: async (body: PpeListVendingMachinesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminVendingMachineServiceListVendingMachines', 'body', body)
            const localVarPath = `/api/v2/vending-machines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refill all coils of a vending machine
         * @param {string} vendingMachineUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceRefillVendingMachine: async (vendingMachineUuid: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendingMachineUuid' is not null or undefined
            assertParamExists('adminVendingMachineServiceRefillVendingMachine', 'vendingMachineUuid', vendingMachineUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminVendingMachineServiceRefillVendingMachine', 'body', body)
            const localVarPath = `/api/v2/vending-machines/{vending_machine_uuid}/refills`
                .replace(`{${"vending_machine_uuid"}}`, encodeURIComponent(String(vendingMachineUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove a product map from vending machine
         * @param {string} vendingMachineUuid 
         * @param {string} productMapUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceRemoveProductMap: async (vendingMachineUuid: string, productMapUuid: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendingMachineUuid' is not null or undefined
            assertParamExists('adminVendingMachineServiceRemoveProductMap', 'vendingMachineUuid', vendingMachineUuid)
            // verify required parameter 'productMapUuid' is not null or undefined
            assertParamExists('adminVendingMachineServiceRemoveProductMap', 'productMapUuid', productMapUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminVendingMachineServiceRemoveProductMap', 'body', body)
            const localVarPath = `/api/v2/vending-machines/{vending_machine_uuid}/product-maps/{product_map_uuid}/remove`
                .replace(`{${"vending_machine_uuid"}}`, encodeURIComponent(String(vendingMachineUuid)))
                .replace(`{${"product_map_uuid"}}`, encodeURIComponent(String(productMapUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a product map
         * @param {string} vendingMachineUuid 
         * @param {string} productMapUuid 
         * @param {InlineObject16} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceUpdateProductMap: async (vendingMachineUuid: string, productMapUuid: string, body: InlineObject16, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendingMachineUuid' is not null or undefined
            assertParamExists('adminVendingMachineServiceUpdateProductMap', 'vendingMachineUuid', vendingMachineUuid)
            // verify required parameter 'productMapUuid' is not null or undefined
            assertParamExists('adminVendingMachineServiceUpdateProductMap', 'productMapUuid', productMapUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminVendingMachineServiceUpdateProductMap', 'body', body)
            const localVarPath = `/api/v2/vending-machines/{vending_machine_uuid}/product-maps/{product_map_uuid}`
                .replace(`{${"vending_machine_uuid"}}`, encodeURIComponent(String(vendingMachineUuid)))
                .replace(`{${"product_map_uuid"}}`, encodeURIComponent(String(productMapUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vending machine
         * @param {string} vendingMachineUuid 
         * @param {InlineObject14} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceUpdateVendingMachine: async (vendingMachineUuid: string, body: InlineObject14, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendingMachineUuid' is not null or undefined
            assertParamExists('adminVendingMachineServiceUpdateVendingMachine', 'vendingMachineUuid', vendingMachineUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminVendingMachineServiceUpdateVendingMachine', 'body', body)
            const localVarPath = `/api/v2/vending-machines/{vending_machine_uuid}`
                .replace(`{${"vending_machine_uuid"}}`, encodeURIComponent(String(vendingMachineUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVendingMachineServiceApi - functional programming interface
 * @export
 */
export const AdminVendingMachineServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVendingMachineServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new product map for the vending machine
         * @param {string} vendingMachineUuid 
         * @param {InlineObject15} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVendingMachineServiceCreateProductMap(vendingMachineUuid: string, body: InlineObject15, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeCreateProductMapResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVendingMachineServiceCreateProductMap(vendingMachineUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all product maps of that belongs to the vending machine
         * @param {string} vendingMachineUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVendingMachineServiceListProductMaps(vendingMachineUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListProductMapsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVendingMachineServiceListProductMaps(vendingMachineUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all vending machines
         * @param {PpeListVendingMachinesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVendingMachineServiceListVendingMachines(body: PpeListVendingMachinesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListVendingMachinesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVendingMachineServiceListVendingMachines(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary refill all coils of a vending machine
         * @param {string} vendingMachineUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVendingMachineServiceRefillVendingMachine(vendingMachineUuid: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRefillVendingMachineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVendingMachineServiceRefillVendingMachine(vendingMachineUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary remove a product map from vending machine
         * @param {string} vendingMachineUuid 
         * @param {string} productMapUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVendingMachineServiceRemoveProductMap(vendingMachineUuid: string, productMapUuid: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRemoveProductMapResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVendingMachineServiceRemoveProductMap(vendingMachineUuid, productMapUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a product map
         * @param {string} vendingMachineUuid 
         * @param {string} productMapUuid 
         * @param {InlineObject16} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVendingMachineServiceUpdateProductMap(vendingMachineUuid: string, productMapUuid: string, body: InlineObject16, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateProductMapResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVendingMachineServiceUpdateProductMap(vendingMachineUuid, productMapUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update vending machine
         * @param {string} vendingMachineUuid 
         * @param {InlineObject14} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVendingMachineServiceUpdateVendingMachine(vendingMachineUuid: string, body: InlineObject14, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdateVendingMachineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVendingMachineServiceUpdateVendingMachine(vendingMachineUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminVendingMachineServiceApi - factory interface
 * @export
 */
export const AdminVendingMachineServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVendingMachineServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new product map for the vending machine
         * @param {string} vendingMachineUuid 
         * @param {InlineObject15} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceCreateProductMap(vendingMachineUuid: string, body: InlineObject15, options?: any): AxiosPromise<PpeCreateProductMapResponse> {
            return localVarFp.adminVendingMachineServiceCreateProductMap(vendingMachineUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all product maps of that belongs to the vending machine
         * @param {string} vendingMachineUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceListProductMaps(vendingMachineUuid: string, options?: any): AxiosPromise<PpeListProductMapsResponse> {
            return localVarFp.adminVendingMachineServiceListProductMaps(vendingMachineUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all vending machines
         * @param {PpeListVendingMachinesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceListVendingMachines(body: PpeListVendingMachinesRequest, options?: any): AxiosPromise<PpeListVendingMachinesResponse> {
            return localVarFp.adminVendingMachineServiceListVendingMachines(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refill all coils of a vending machine
         * @param {string} vendingMachineUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceRefillVendingMachine(vendingMachineUuid: string, body: object, options?: any): AxiosPromise<PpeRefillVendingMachineResponse> {
            return localVarFp.adminVendingMachineServiceRefillVendingMachine(vendingMachineUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove a product map from vending machine
         * @param {string} vendingMachineUuid 
         * @param {string} productMapUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceRemoveProductMap(vendingMachineUuid: string, productMapUuid: string, body: object, options?: any): AxiosPromise<PpeRemoveProductMapResponse> {
            return localVarFp.adminVendingMachineServiceRemoveProductMap(vendingMachineUuid, productMapUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a product map
         * @param {string} vendingMachineUuid 
         * @param {string} productMapUuid 
         * @param {InlineObject16} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceUpdateProductMap(vendingMachineUuid: string, productMapUuid: string, body: InlineObject16, options?: any): AxiosPromise<PpeUpdateProductMapResponse> {
            return localVarFp.adminVendingMachineServiceUpdateProductMap(vendingMachineUuid, productMapUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vending machine
         * @param {string} vendingMachineUuid 
         * @param {InlineObject14} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVendingMachineServiceUpdateVendingMachine(vendingMachineUuid: string, body: InlineObject14, options?: any): AxiosPromise<PpeUpdateVendingMachineResponse> {
            return localVarFp.adminVendingMachineServiceUpdateVendingMachine(vendingMachineUuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminVendingMachineServiceApi - object-oriented interface
 * @export
 * @class AdminVendingMachineServiceApi
 * @extends {BaseAPI}
 */
export class AdminVendingMachineServiceApi extends BaseAPI {
    /**
     * 
     * @summary Create a new product map for the vending machine
     * @param {string} vendingMachineUuid 
     * @param {InlineObject15} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVendingMachineServiceApi
     */
    public adminVendingMachineServiceCreateProductMap(vendingMachineUuid: string, body: InlineObject15, options?: AxiosRequestConfig) {
        return AdminVendingMachineServiceApiFp(this.configuration).adminVendingMachineServiceCreateProductMap(vendingMachineUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all product maps of that belongs to the vending machine
     * @param {string} vendingMachineUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVendingMachineServiceApi
     */
    public adminVendingMachineServiceListProductMaps(vendingMachineUuid: string, options?: AxiosRequestConfig) {
        return AdminVendingMachineServiceApiFp(this.configuration).adminVendingMachineServiceListProductMaps(vendingMachineUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all vending machines
     * @param {PpeListVendingMachinesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVendingMachineServiceApi
     */
    public adminVendingMachineServiceListVendingMachines(body: PpeListVendingMachinesRequest, options?: AxiosRequestConfig) {
        return AdminVendingMachineServiceApiFp(this.configuration).adminVendingMachineServiceListVendingMachines(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary refill all coils of a vending machine
     * @param {string} vendingMachineUuid 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVendingMachineServiceApi
     */
    public adminVendingMachineServiceRefillVendingMachine(vendingMachineUuid: string, body: object, options?: AxiosRequestConfig) {
        return AdminVendingMachineServiceApiFp(this.configuration).adminVendingMachineServiceRefillVendingMachine(vendingMachineUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove a product map from vending machine
     * @param {string} vendingMachineUuid 
     * @param {string} productMapUuid 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVendingMachineServiceApi
     */
    public adminVendingMachineServiceRemoveProductMap(vendingMachineUuid: string, productMapUuid: string, body: object, options?: AxiosRequestConfig) {
        return AdminVendingMachineServiceApiFp(this.configuration).adminVendingMachineServiceRemoveProductMap(vendingMachineUuid, productMapUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a product map
     * @param {string} vendingMachineUuid 
     * @param {string} productMapUuid 
     * @param {InlineObject16} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVendingMachineServiceApi
     */
    public adminVendingMachineServiceUpdateProductMap(vendingMachineUuid: string, productMapUuid: string, body: InlineObject16, options?: AxiosRequestConfig) {
        return AdminVendingMachineServiceApiFp(this.configuration).adminVendingMachineServiceUpdateProductMap(vendingMachineUuid, productMapUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vending machine
     * @param {string} vendingMachineUuid 
     * @param {InlineObject14} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVendingMachineServiceApi
     */
    public adminVendingMachineServiceUpdateVendingMachine(vendingMachineUuid: string, body: InlineObject14, options?: AxiosRequestConfig) {
        return AdminVendingMachineServiceApiFp(this.configuration).adminVendingMachineServiceUpdateVendingMachine(vendingMachineUuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvancedReportingServiceApi - axios parameter creator
 * @export
 */
export const AdvancedReportingServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a scheduled report
         * @param {PpeCreateScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceCreateScheduledReport: async (body: PpeCreateScheduledReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceCreateScheduledReport', 'body', body)
            const localVarPath = `/api/v2/create-scheduled-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create templates
         * @param {PpeCreateTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceCreateTemplate: async (body: PpeCreateTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceCreateTemplate', 'body', body)
            const localVarPath = `/api/v2/create-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Scheduled Reports
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceListScheduledReports: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceListScheduledReports', 'body', body)
            const localVarPath = `/api/v2/list-scheduled-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all templates
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceListTemplates: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceListTemplates', 'body', body)
            const localVarPath = `/api/v2/list-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run a scheduled report
         * @param {PpeManualExecuteScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceManualExecuteScheduledReport: async (body: PpeManualExecuteScheduledReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceManualExecuteScheduledReport', 'body', body)
            const localVarPath = `/api/v2/run-scheduled-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a scheduled report
         * @param {PpeRemoveScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceRemoveScheduledReport: async (body: PpeRemoveScheduledReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceRemoveScheduledReport', 'body', body)
            const localVarPath = `/api/v2/delete-scheduled-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a template
         * @param {PpeRemoveTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceRemoveTemplate: async (body: PpeRemoveTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceRemoveTemplate', 'body', body)
            const localVarPath = `/api/v2/remove-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run report with a given template
         * @param {PpeRunReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceRunReport: async (body: PpeRunReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceRunReport', 'body', body)
            const localVarPath = `/api/v2/run-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a scheduled report
         * @param {PpeUpdateScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceUpdateScheduledReport: async (body: PpeUpdateScheduledReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceUpdateScheduledReport', 'body', body)
            const localVarPath = `/api/v2/update-scheduled-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a template
         * @param {PpeUpdateTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceUpdateTemplate: async (body: PpeUpdateTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('advancedReportingServiceUpdateTemplate', 'body', body)
            const localVarPath = `/api/v2/update-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvancedReportingServiceApi - functional programming interface
 * @export
 */
export const AdvancedReportingServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvancedReportingServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a scheduled report
         * @param {PpeCreateScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceCreateScheduledReport(body: PpeCreateScheduledReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeCreateScheduledReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceCreateScheduledReport(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create templates
         * @param {PpeCreateTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceCreateTemplate(body: PpeCreateTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceCreateTemplate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Scheduled Reports
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceListScheduledReports(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListScheduledReportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceListScheduledReports(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all templates
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceListTemplates(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListTemplatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceListTemplates(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Run a scheduled report
         * @param {PpeManualExecuteScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceManualExecuteScheduledReport(body: PpeManualExecuteScheduledReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceManualExecuteScheduledReport(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a scheduled report
         * @param {PpeRemoveScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceRemoveScheduledReport(body: PpeRemoveScheduledReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceRemoveScheduledReport(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a template
         * @param {PpeRemoveTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceRemoveTemplate(body: PpeRemoveTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceRemoveTemplate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Run report with a given template
         * @param {PpeRunReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceRunReport(body: PpeRunReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRunReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceRunReport(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a scheduled report
         * @param {PpeUpdateScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceUpdateScheduledReport(body: PpeUpdateScheduledReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceUpdateScheduledReport(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a template
         * @param {PpeUpdateTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedReportingServiceUpdateTemplate(body: PpeUpdateTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedReportingServiceUpdateTemplate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvancedReportingServiceApi - factory interface
 * @export
 */
export const AdvancedReportingServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvancedReportingServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a scheduled report
         * @param {PpeCreateScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceCreateScheduledReport(body: PpeCreateScheduledReportRequest, options?: any): AxiosPromise<PpeCreateScheduledReportResponse> {
            return localVarFp.advancedReportingServiceCreateScheduledReport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create templates
         * @param {PpeCreateTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceCreateTemplate(body: PpeCreateTemplateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.advancedReportingServiceCreateTemplate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Scheduled Reports
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceListScheduledReports(body: object, options?: any): AxiosPromise<PpeListScheduledReportsResponse> {
            return localVarFp.advancedReportingServiceListScheduledReports(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all templates
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceListTemplates(body: object, options?: any): AxiosPromise<PpeListTemplatesResponse> {
            return localVarFp.advancedReportingServiceListTemplates(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run a scheduled report
         * @param {PpeManualExecuteScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceManualExecuteScheduledReport(body: PpeManualExecuteScheduledReportRequest, options?: any): AxiosPromise<object> {
            return localVarFp.advancedReportingServiceManualExecuteScheduledReport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a scheduled report
         * @param {PpeRemoveScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceRemoveScheduledReport(body: PpeRemoveScheduledReportRequest, options?: any): AxiosPromise<object> {
            return localVarFp.advancedReportingServiceRemoveScheduledReport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a template
         * @param {PpeRemoveTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceRemoveTemplate(body: PpeRemoveTemplateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.advancedReportingServiceRemoveTemplate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run report with a given template
         * @param {PpeRunReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceRunReport(body: PpeRunReportRequest, options?: any): AxiosPromise<PpeRunReportResponse> {
            return localVarFp.advancedReportingServiceRunReport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a scheduled report
         * @param {PpeUpdateScheduledReportRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceUpdateScheduledReport(body: PpeUpdateScheduledReportRequest, options?: any): AxiosPromise<object> {
            return localVarFp.advancedReportingServiceUpdateScheduledReport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a template
         * @param {PpeUpdateTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedReportingServiceUpdateTemplate(body: PpeUpdateTemplateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.advancedReportingServiceUpdateTemplate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvancedReportingServiceApi - object-oriented interface
 * @export
 * @class AdvancedReportingServiceApi
 * @extends {BaseAPI}
 */
export class AdvancedReportingServiceApi extends BaseAPI {
    /**
     * 
     * @summary Create a scheduled report
     * @param {PpeCreateScheduledReportRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceCreateScheduledReport(body: PpeCreateScheduledReportRequest, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceCreateScheduledReport(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create templates
     * @param {PpeCreateTemplateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceCreateTemplate(body: PpeCreateTemplateRequest, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceCreateTemplate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Scheduled Reports
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceListScheduledReports(body: object, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceListScheduledReports(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all templates
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceListTemplates(body: object, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceListTemplates(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run a scheduled report
     * @param {PpeManualExecuteScheduledReportRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceManualExecuteScheduledReport(body: PpeManualExecuteScheduledReportRequest, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceManualExecuteScheduledReport(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a scheduled report
     * @param {PpeRemoveScheduledReportRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceRemoveScheduledReport(body: PpeRemoveScheduledReportRequest, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceRemoveScheduledReport(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a template
     * @param {PpeRemoveTemplateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceRemoveTemplate(body: PpeRemoveTemplateRequest, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceRemoveTemplate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run report with a given template
     * @param {PpeRunReportRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceRunReport(body: PpeRunReportRequest, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceRunReport(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a scheduled report
     * @param {PpeUpdateScheduledReportRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceUpdateScheduledReport(body: PpeUpdateScheduledReportRequest, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceUpdateScheduledReport(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a template
     * @param {PpeUpdateTemplateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedReportingServiceApi
     */
    public advancedReportingServiceUpdateTemplate(body: PpeUpdateTemplateRequest, options?: AxiosRequestConfig) {
        return AdvancedReportingServiceApiFp(this.configuration).advancedReportingServiceUpdateTemplate(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerUserAuthenticationServiceApi - axios parameter creator
 * @export
 */
export const PartnerUserAuthenticationServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary request login auth code for partner user
         * @param {PpePartnerUserLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserAuthenticationServicePartnerUserLogin: async (body: PpePartnerUserLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerUserAuthenticationServicePartnerUserLogin', 'body', body)
            const localVarPath = `/api-pu/v1/partner-users/authentications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partner admin login to admin portal (get admin token)
         * @param {PpePartnerUserLoginToOrganizationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserAuthenticationServicePartnerUserLoginToOrganization: async (body: PpePartnerUserLoginToOrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerUserAuthenticationServicePartnerUserLoginToOrganization', 'body', body)
            const localVarPath = `/api-pu/v1/partner-users/login-as-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verify the auth code and get partner admin token
         * @param {PpePartnerUserVerifyLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserAuthenticationServicePartnerUserVerifyLogin: async (body: PpePartnerUserVerifyLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerUserAuthenticationServicePartnerUserVerifyLogin', 'body', body)
            const localVarPath = `/api-pu/v1/partner-users/verifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerUserAuthenticationServiceApi - functional programming interface
 * @export
 */
export const PartnerUserAuthenticationServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerUserAuthenticationServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary request login auth code for partner user
         * @param {PpePartnerUserLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserAuthenticationServicePartnerUserLogin(body: PpePartnerUserLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpePartnerUserLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserAuthenticationServicePartnerUserLogin(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary partner admin login to admin portal (get admin token)
         * @param {PpePartnerUserLoginToOrganizationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserAuthenticationServicePartnerUserLoginToOrganization(body: PpePartnerUserLoginToOrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpePartnerUserLoginToOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserAuthenticationServicePartnerUserLoginToOrganization(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary verify the auth code and get partner admin token
         * @param {PpePartnerUserVerifyLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserAuthenticationServicePartnerUserVerifyLogin(body: PpePartnerUserVerifyLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpePartnerUserVerifyLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserAuthenticationServicePartnerUserVerifyLogin(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerUserAuthenticationServiceApi - factory interface
 * @export
 */
export const PartnerUserAuthenticationServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerUserAuthenticationServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary request login auth code for partner user
         * @param {PpePartnerUserLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserAuthenticationServicePartnerUserLogin(body: PpePartnerUserLoginRequest, options?: any): AxiosPromise<PpePartnerUserLoginResponse> {
            return localVarFp.partnerUserAuthenticationServicePartnerUserLogin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary partner admin login to admin portal (get admin token)
         * @param {PpePartnerUserLoginToOrganizationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserAuthenticationServicePartnerUserLoginToOrganization(body: PpePartnerUserLoginToOrganizationRequest, options?: any): AxiosPromise<PpePartnerUserLoginToOrganizationResponse> {
            return localVarFp.partnerUserAuthenticationServicePartnerUserLoginToOrganization(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary verify the auth code and get partner admin token
         * @param {PpePartnerUserVerifyLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserAuthenticationServicePartnerUserVerifyLogin(body: PpePartnerUserVerifyLoginRequest, options?: any): AxiosPromise<PpePartnerUserVerifyLoginResponse> {
            return localVarFp.partnerUserAuthenticationServicePartnerUserVerifyLogin(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerUserAuthenticationServiceApi - object-oriented interface
 * @export
 * @class PartnerUserAuthenticationServiceApi
 * @extends {BaseAPI}
 */
export class PartnerUserAuthenticationServiceApi extends BaseAPI {
    /**
     * 
     * @summary request login auth code for partner user
     * @param {PpePartnerUserLoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserAuthenticationServiceApi
     */
    public partnerUserAuthenticationServicePartnerUserLogin(body: PpePartnerUserLoginRequest, options?: AxiosRequestConfig) {
        return PartnerUserAuthenticationServiceApiFp(this.configuration).partnerUserAuthenticationServicePartnerUserLogin(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary partner admin login to admin portal (get admin token)
     * @param {PpePartnerUserLoginToOrganizationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserAuthenticationServiceApi
     */
    public partnerUserAuthenticationServicePartnerUserLoginToOrganization(body: PpePartnerUserLoginToOrganizationRequest, options?: AxiosRequestConfig) {
        return PartnerUserAuthenticationServiceApiFp(this.configuration).partnerUserAuthenticationServicePartnerUserLoginToOrganization(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary verify the auth code and get partner admin token
     * @param {PpePartnerUserVerifyLoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserAuthenticationServiceApi
     */
    public partnerUserAuthenticationServicePartnerUserVerifyLogin(body: PpePartnerUserVerifyLoginRequest, options?: AxiosRequestConfig) {
        return PartnerUserAuthenticationServiceApiFp(this.configuration).partnerUserAuthenticationServicePartnerUserVerifyLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerUserPartnerServiceApi - axios parameter creator
 * @export
 */
export const PartnerUserPartnerServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new partner
         * @param {PpeAddPartnerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerServiceAddPartner: async (body: PpeAddPartnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerUserPartnerServiceAddPartner', 'body', body)
            const localVarPath = `/api-pu/v1/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of partners that the partner user has right to manage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerServicePartnerUserListPartners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-pu/v1/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a partner
         * @param {string} uuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerServiceRemovePartner: async (uuid: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('partnerUserPartnerServiceRemovePartner', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerUserPartnerServiceRemovePartner', 'body', body)
            const localVarPath = `/api-pu/v1/partners/{uuid}/remove`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit a partner
         * @param {string} uuid 
         * @param {InlineObject1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerServiceUpdatePartner: async (uuid: string, body: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('partnerUserPartnerServiceUpdatePartner', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerUserPartnerServiceUpdatePartner', 'body', body)
            const localVarPath = `/api-pu/v1/partners/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerUserPartnerServiceApi - functional programming interface
 * @export
 */
export const PartnerUserPartnerServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerUserPartnerServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new partner
         * @param {PpeAddPartnerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserPartnerServiceAddPartner(body: PpeAddPartnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAddPartnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserPartnerServiceAddPartner(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of partners that the partner user has right to manage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserPartnerServicePartnerUserListPartners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpePartnerUserListPartnersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserPartnerServicePartnerUserListPartners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a partner
         * @param {string} uuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserPartnerServiceRemovePartner(uuid: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRemovePartnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserPartnerServiceRemovePartner(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit a partner
         * @param {string} uuid 
         * @param {InlineObject1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserPartnerServiceUpdatePartner(uuid: string, body: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeUpdatePartnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserPartnerServiceUpdatePartner(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerUserPartnerServiceApi - factory interface
 * @export
 */
export const PartnerUserPartnerServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerUserPartnerServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new partner
         * @param {PpeAddPartnerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerServiceAddPartner(body: PpeAddPartnerRequest, options?: any): AxiosPromise<PpeAddPartnerResponse> {
            return localVarFp.partnerUserPartnerServiceAddPartner(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of partners that the partner user has right to manage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerServicePartnerUserListPartners(options?: any): AxiosPromise<PpePartnerUserListPartnersResponse> {
            return localVarFp.partnerUserPartnerServicePartnerUserListPartners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a partner
         * @param {string} uuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerServiceRemovePartner(uuid: string, body: object, options?: any): AxiosPromise<PpeRemovePartnerResponse> {
            return localVarFp.partnerUserPartnerServiceRemovePartner(uuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit a partner
         * @param {string} uuid 
         * @param {InlineObject1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerServiceUpdatePartner(uuid: string, body: InlineObject1, options?: any): AxiosPromise<PpeUpdatePartnerResponse> {
            return localVarFp.partnerUserPartnerServiceUpdatePartner(uuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerUserPartnerServiceApi - object-oriented interface
 * @export
 * @class PartnerUserPartnerServiceApi
 * @extends {BaseAPI}
 */
export class PartnerUserPartnerServiceApi extends BaseAPI {
    /**
     * 
     * @summary Add a new partner
     * @param {PpeAddPartnerRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserPartnerServiceApi
     */
    public partnerUserPartnerServiceAddPartner(body: PpeAddPartnerRequest, options?: AxiosRequestConfig) {
        return PartnerUserPartnerServiceApiFp(this.configuration).partnerUserPartnerServiceAddPartner(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of partners that the partner user has right to manage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserPartnerServiceApi
     */
    public partnerUserPartnerServicePartnerUserListPartners(options?: AxiosRequestConfig) {
        return PartnerUserPartnerServiceApiFp(this.configuration).partnerUserPartnerServicePartnerUserListPartners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a partner
     * @param {string} uuid 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserPartnerServiceApi
     */
    public partnerUserPartnerServiceRemovePartner(uuid: string, body: object, options?: AxiosRequestConfig) {
        return PartnerUserPartnerServiceApiFp(this.configuration).partnerUserPartnerServiceRemovePartner(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit a partner
     * @param {string} uuid 
     * @param {InlineObject1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserPartnerServiceApi
     */
    public partnerUserPartnerServiceUpdatePartner(uuid: string, body: InlineObject1, options?: AxiosRequestConfig) {
        return PartnerUserPartnerServiceApiFp(this.configuration).partnerUserPartnerServiceUpdatePartner(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerUserPartnerUserServiceApi - axios parameter creator
 * @export
 */
export const PartnerUserPartnerUserServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add a partner user to a partner
         * @param {string} partnerUuid 
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerUserServiceAddPartnerUser: async (partnerUuid: string, body: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerUuid' is not null or undefined
            assertParamExists('partnerUserPartnerUserServiceAddPartnerUser', 'partnerUuid', partnerUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerUserPartnerUserServiceAddPartnerUser', 'body', body)
            const localVarPath = `/api-pu/v1/partners/{partner_uuid}/partner-users`
                .replace(`{${"partner_uuid"}}`, encodeURIComponent(String(partnerUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of partner-users of a particular partner
         * @param {string} partnerUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerUserServiceListPartnerUsers: async (partnerUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerUuid' is not null or undefined
            assertParamExists('partnerUserPartnerUserServiceListPartnerUsers', 'partnerUuid', partnerUuid)
            const localVarPath = `/api-pu/v1/partners/{partner_uuid}/partner-users`
                .replace(`{${"partner_uuid"}}`, encodeURIComponent(String(partnerUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary revoke a partner user\'s access
         * @param {string} partnerUuid 
         * @param {string} partnerUserUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerUserServiceRevokePartnerUser: async (partnerUuid: string, partnerUserUuid: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerUuid' is not null or undefined
            assertParamExists('partnerUserPartnerUserServiceRevokePartnerUser', 'partnerUuid', partnerUuid)
            // verify required parameter 'partnerUserUuid' is not null or undefined
            assertParamExists('partnerUserPartnerUserServiceRevokePartnerUser', 'partnerUserUuid', partnerUserUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerUserPartnerUserServiceRevokePartnerUser', 'body', body)
            const localVarPath = `/api-pu/v1/partners/{partner_uuid}/partner-users/{partner_user_uuid}/revoke`
                .replace(`{${"partner_uuid"}}`, encodeURIComponent(String(partnerUuid)))
                .replace(`{${"partner_user_uuid"}}`, encodeURIComponent(String(partnerUserUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerUserPartnerUserServiceApi - functional programming interface
 * @export
 */
export const PartnerUserPartnerUserServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerUserPartnerUserServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add a partner user to a partner
         * @param {string} partnerUuid 
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserPartnerUserServiceAddPartnerUser(partnerUuid: string, body: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeAddPartnerUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserPartnerUserServiceAddPartnerUser(partnerUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of partner-users of a particular partner
         * @param {string} partnerUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserPartnerUserServiceListPartnerUsers(partnerUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeListPartnerUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserPartnerUserServiceListPartnerUsers(partnerUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary revoke a partner user\'s access
         * @param {string} partnerUuid 
         * @param {string} partnerUserUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerUserPartnerUserServiceRevokePartnerUser(partnerUuid: string, partnerUserUuid: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeRevokePartnerUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerUserPartnerUserServiceRevokePartnerUser(partnerUuid, partnerUserUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerUserPartnerUserServiceApi - factory interface
 * @export
 */
export const PartnerUserPartnerUserServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerUserPartnerUserServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary add a partner user to a partner
         * @param {string} partnerUuid 
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerUserServiceAddPartnerUser(partnerUuid: string, body: InlineObject, options?: any): AxiosPromise<PpeAddPartnerUserResponse> {
            return localVarFp.partnerUserPartnerUserServiceAddPartnerUser(partnerUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of partner-users of a particular partner
         * @param {string} partnerUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerUserServiceListPartnerUsers(partnerUuid: string, options?: any): AxiosPromise<PpeListPartnerUsersResponse> {
            return localVarFp.partnerUserPartnerUserServiceListPartnerUsers(partnerUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary revoke a partner user\'s access
         * @param {string} partnerUuid 
         * @param {string} partnerUserUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerUserPartnerUserServiceRevokePartnerUser(partnerUuid: string, partnerUserUuid: string, body: object, options?: any): AxiosPromise<PpeRevokePartnerUserResponse> {
            return localVarFp.partnerUserPartnerUserServiceRevokePartnerUser(partnerUuid, partnerUserUuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerUserPartnerUserServiceApi - object-oriented interface
 * @export
 * @class PartnerUserPartnerUserServiceApi
 * @extends {BaseAPI}
 */
export class PartnerUserPartnerUserServiceApi extends BaseAPI {
    /**
     * 
     * @summary add a partner user to a partner
     * @param {string} partnerUuid 
     * @param {InlineObject} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserPartnerUserServiceApi
     */
    public partnerUserPartnerUserServiceAddPartnerUser(partnerUuid: string, body: InlineObject, options?: AxiosRequestConfig) {
        return PartnerUserPartnerUserServiceApiFp(this.configuration).partnerUserPartnerUserServiceAddPartnerUser(partnerUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of partner-users of a particular partner
     * @param {string} partnerUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserPartnerUserServiceApi
     */
    public partnerUserPartnerUserServiceListPartnerUsers(partnerUuid: string, options?: AxiosRequestConfig) {
        return PartnerUserPartnerUserServiceApiFp(this.configuration).partnerUserPartnerUserServiceListPartnerUsers(partnerUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary revoke a partner user\'s access
     * @param {string} partnerUuid 
     * @param {string} partnerUserUuid 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserPartnerUserServiceApi
     */
    public partnerUserPartnerUserServiceRevokePartnerUser(partnerUuid: string, partnerUserUuid: string, body: object, options?: AxiosRequestConfig) {
        return PartnerUserPartnerUserServiceApiFp(this.configuration).partnerUserPartnerUserServiceRevokePartnerUser(partnerUuid, partnerUserUuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


