import React, {Component} from 'react';
import Modal from "react-modal";
import {getAdminTemporaryAccessServiceAPI} from "../global/api";
import {Message} from "../global/models";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import BusyIndicator, {LoadingSpanner} from "../busy_indicator";

interface Props {
    isOpen: boolean

    closeAddEmployeeModal(): void

    pushFlashMessage(message: Message): void;

    refreshEmployeeOnAdd(): void

    timezone:string
}

interface State {
    newEmployeeName: string
    expiryAtDate: Date | null
    isLoading: boolean
}

class GrantTmpAccessModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newEmployeeName: "",
            expiryAtDate: moment().add(1, 'days').toDate(),
            isLoading: false
        };
    }

    handleOpenModel(){
        this.setState({
            newEmployeeName: "",
            expiryAtDate: moment().add(1, 'days').toDate(),
            isLoading: false
        })
    }

    handleCloseModal() {
        this.setState({
            newEmployeeName: "",
            expiryAtDate: moment().add(1, 'days').toDate()
        })
        this.props.closeAddEmployeeModal()
    }

    handleEmployeeNameChange(event: any) {
        this.setState({newEmployeeName: event.target.value})
    }

    saveEmployee(event:any) {
        event.stopPropagation()
        event.preventDefault()
        if (this.state.isLoading){
            return;
        }
        this.setState({
            isLoading: true
        })
        getAdminTemporaryAccessServiceAPI().adminTemporaryAccessServiceGrantTemporaryAccess({
            contact_name: this.state.newEmployeeName,
            expiry_epoch: moment(this.state.expiryAtDate).tz(this.props.timezone, true).unix()
        }).then(response => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("temporary access granted", "success"))
                this.handleCloseModal()
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch((reason)=>{
            this.props.pushFlashMessage(new Message("Server error, please try again later.", "error"))
        }).finally(()=>{
            this.props.refreshEmployeeOnAdd()
            this.setState({
                isLoading: false
            })
        })
    }

    handleExpiryAtChange(date:Date | null){
        this.setState({expiryAtDate: date}, ()=>{console.log(moment(this.state.expiryAtDate).tz(this.props.timezone, true).unix())});
    }

    render() {
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%'
            }
        };
        Modal.setAppElement('#root');
        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.handleCloseModal.bind(this)} onAfterOpen={this.handleOpenModel.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Grant temporary access</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please enter the detail of the temporary access</p>

                <form>
                    <div className="form-group">
                        <label htmlFor="name">Contact Name:*</label>
                        <input type="text" onChange={this.handleEmployeeNameChange.bind(this)} className="form-control"
                               value={this.state.newEmployeeName} autoFocus={true} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="startDate">Expiry - (Timezone: {this.props.timezone}):</label>
                        <DatePicker className={"form-control"}
                                    selected={this.state.expiryAtDate}
                                    onChange={this.handleExpiryAtChange.bind(this)}
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    wrapperClassName={"datePicker"}
                                    showTimeSelect={true}
                                    isClearable
                        />
                    </div>
                    <div className={"form-group"}>
                        <BusyIndicator busy={this.state.isLoading} busyIndicator={<LoadingSpanner/>}>
                            <button onClick={this.saveEmployee.bind(this)} className={"btn btn-primary"}>Submit</button>
                        </BusyIndicator>
                        <button type="button" className="btn iq-bg-danger"
                                onClick={this.handleCloseModal.bind(this)}>Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>;
    }
}

export {GrantTmpAccessModal}
