import React, {useState} from 'react';
import {getAdminUserServiceAPI} from "../global/api";
import {Message} from "../global/models";
import {PpeMicrosoftSsoSetting} from "../gen";


function Microsoft365SsoLinkRow(props: { sso: PpeMicrosoftSsoSetting | null, pushFlashMessage(message: Message): void, reloadSSO(): void }) {
    const {sso} = props;
    const [saving, setSaving] = useState(false);

    function unbindingSso(e: any) {
        e.preventDefault();

        if (window.confirm(`Do you want to remove ${sso?.principal}? You won't be able to login using that Microsoft 365 anymore.`)) {
            setSaving(true);
            getAdminUserServiceAPI().adminUserServiceUnlinkMsSso({
                principal: sso?.principal
            }).then((response)=>{
                if (response.data.success) {
                    props.pushFlashMessage({message: "Microsoft365 account removed.", cls: "success"})
                    props.reloadSSO();
                } else {
                    props.pushFlashMessage({message: response.data.error_message??"", cls: "error"})
                }
            }).catch((response)=>{
                console.log(response.response);
                props.pushFlashMessage({message: "Server error. Please contact support", cls: "error"})
            }).finally(()=>{
                setSaving(false);
            });
        }
    }

    return (<tr>
            <td>Microsoft 365</td>
            <td>
                {sso?.principal ?
                    <div className={"microsoft-sso-information"}>
                        <div>Microsoft365 Display Name: {sso.display_name}</div>
                        <div>Microsoft365 Email: {sso.email}</div>
                        <div>Microsoft365 Principal: {sso.principal} </div>
                    </div>
                    :
                    <div>Not linked to any Microsoft 365 account</div>
                }
            </td>
            <td>
                {sso?.principal &&
                <form onSubmit={unbindingSso}>
                    {saving ?
                        <div>saving...</div>
                        :
                        <button className={"btn btn-danger"}>
                            Remove this {sso.propertyIsEnumerable} Microsoft365 account
                        </button>
                    }
                </form>
                }
            </td>
        </tr>
    );

}

function SsoTab(props:
                    {
                        pushFlashMessage: (message: Message) => void,
                        reload(): void;
                        microsoftSsoSetting: PpeMicrosoftSsoSetting | null
                    }
) {
    return (<div className="tab-pane fade" id="sso" role="tabpanel">
        <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Single Sign On (SSO)</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Service</th>
                        <th>Authorized Identity</th>
                        <th>Operation</th>
                    </tr>
                    </thead>

                    <tbody>

                    <Microsoft365SsoLinkRow sso={props.microsoftSsoSetting} pushFlashMessage={props.pushFlashMessage}
                                            reloadSSO={props.reload}/>


                    </tbody>
                </table>
            </div>
        </div>
    </div>);

}

export
{
    SsoTab
}
