import React, { Component } from 'react';
import {getAdminUserServiceAPI} from "../global/api";
import {Message} from "../global/models";
import {PpeAdmin, PpeEmailPreferences} from "../gen";
import moment from "moment-timezone";
import {Typeahead} from "react-bootstrap-typeahead";


interface Props {
    pushFlashMessage(message: Message): void;
    reload(): void;
    admin: PpeAdmin | null
    emailPreferences: PpeEmailPreferences | null
}

interface State {
    isEmailDailyReport:boolean
    isEmailMonthlyReport:boolean
    isReceiveVendFailureAlert: boolean
    isReceivePendingInspectionAlert: boolean
    isSaving: boolean
    timezone: string
}

class PersonalInformationTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEmailDailyReport: this.props.emailPreferences?.is_receive_daily_report ?? false,
            isEmailMonthlyReport: this.props.emailPreferences?.is_receive_monthly_report ?? false,
            isReceiveVendFailureAlert: this.props.emailPreferences?.is_receive_vend_failure_alert ?? false,
            isReceivePendingInspectionAlert: this.props.emailPreferences?.is_receive_pending_inspection_alert ?? false,
            isSaving: false,
            timezone: this.props.admin?.timezone ?? ""
        };
    }



    handleDailyEmailPreferenceChange(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.setState({isEmailDailyReport:event.target.value === "true"}, ()=>{console.log(this.state)})
    }

    handleMonthlyEmailPreferenceChange(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.setState({isEmailMonthlyReport:event.target.value === "true"}, ()=>{console.log(this.state)})
    }

    handleVendFailureAlertPreferenceChange(event:React.ChangeEvent<HTMLSelectElement>){
        event.preventDefault();
        event.stopPropagation();
        this.setState({isReceiveVendFailureAlert:event.target.value === "true"}, ()=>{console.log(this.state)})
    }

    handlePendingInspectionAlertPreferenceChange(event:React.ChangeEvent<HTMLSelectElement>){
        event.preventDefault();
        event.stopPropagation();
        this.setState({isReceivePendingInspectionAlert:event.target.value === "true"}, ()=>{console.log(this.state)})
    }

    savePreference(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            isSaving: true
        })
        Promise.all([
            getAdminUserServiceAPI().adminUserServiceUpdateEmailPreferences({
                is_receive_daily_report: this.state.isEmailDailyReport,
                is_receive_monthly_report: this.state.isEmailMonthlyReport,
                is_receive_vend_failure_alert: this.state.isReceiveVendFailureAlert,
                is_receive_pending_inspection_alert: this.state.isReceivePendingInspectionAlert
            }).then((response)=>{
                if (!(response.data.success ?? false)){
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch(()=>{
                this.props.pushFlashMessage(new Message("Server error", "error"))
            }),
            getAdminUserServiceAPI().adminUserServiceUpdateTimezone({
                timezone: this.state.timezone
            }).then((response)=>{
                if (response.data.success ?? false){
                    this.props.reload()
                    this.props.pushFlashMessage(new Message("Saved", "success"))
                    window.location.reload(); // force reload when update the timezone
                } else {
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch(()=>{
                this.props.pushFlashMessage(new Message("Server error", "error"))
            })
        ]).finally(()=>{
            this.setState({
                isSaving: false
            })
        })
    }

    onTimezoneChange(selected: Array<string>){
        if (selected.length > 0){
            this.setState({timezone:selected[0]})
        } else {
            this.setState({timezone:""})
        }
    }

    render() {
        let timeZones = moment.tz.names();
        return <div className="tab-pane fade active show" id="personal-information" role="tabpanel">
            <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                        <h4 className="card-title">Personal Information</h4>
                    </div>
                </div>
                <div className="iq-card-body">
                    <form method="POST">
                        <div className=" row align-items-center">
                            <div className="form-group col-sm-12">
                                <label htmlFor="fullName">Full Name:</label>
                                <input type="text" className="form-control" id="fullName"
                                       value={this.props.admin?.name} disabled/>
                            </div>
                            <div className="form-group col-sm-12">
                                <label htmlFor="email">Email Address:</label>
                                <input type="email" className="form-control" id="email"
                                       value={this.props.admin?.email} disabled/>
                            </div>
                            <div className="form-group col-sm-12">
                                <label htmlFor="email">Timezone:</label>
                                <Typeahead
                                    id={"timezone"}
                                    options={timeZones}
                                    defaultSelected={[this.state.timezone]}
                                    onChange={this.onTimezoneChange.bind(this)}
                                />
                            </div>
                            <div className="form-group col-sm-12">
                                <label htmlFor="isEmailDailyReport">Receive Daily Email
                                    Report:</label>
                                <select name="isEmailDailyReport" id="isEmailDailyReport"
                                        className="form-control" value={this.state.isEmailDailyReport ? "true" : "false"}
                                        onChange={this.handleDailyEmailPreferenceChange.bind(this)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                            <div className="form-group col-sm-12">
                                <label htmlFor="isEmailMonthlyReport">Receive Monthly Email
                                    Report:</label>
                                <select name="isEmailMonthlyReport" id="isEmailMonthlyReport"
                                        className="form-control" value={this.state.isEmailMonthlyReport ? "true" : "false"}
                                        onChange={this.handleMonthlyEmailPreferenceChange.bind(this)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                            <div className="form-group col-sm-12">
                                <label htmlFor="receiveVendFailureAlert">Receive Vend Failure Alert:</label>
                                <select name="receiveVendFailureAlert" id="receiveVendFailureAlert"
                                        className="form-control" value={this.state.isReceiveVendFailureAlert ? "true" : "false"}
                                        onChange={this.handleVendFailureAlertPreferenceChange.bind(this)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                            <div className="form-group col-sm-12">
                                <label htmlFor="receivePendingInspectionAlert">Receive Pending Inspection Alert (Trackable Item):</label>
                                <select name="receivePendingInspectionAlert" id="receivePendingInspectionAlert"
                                        className="form-control" value={this.state.isReceivePendingInspectionAlert ? "true" : "false"}
                                        onChange={this.handlePendingInspectionAlertPreferenceChange.bind(this)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </div>
                        <button className="btn btn-primary mr-2" onClick={this.savePreference.bind(this)}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    }
}

export {PersonalInformationTab}
