import React, {useEffect, useState} from 'react';
import {getAdminUserServiceAPI} from "./global/api";
import {PpeOrganizationOption} from "./gen";
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {UserType} from "./global/models";

interface Props extends IAuthenticationContext {

}

function _OrganizationSelection(props:Props) {
    const [organizations, setOrganizations] = useState<Array<PpeOrganizationOption>>([]);
    const [isOrganizationsLoading, setIsOrganizationsLoading] = useState<boolean>(false);

    const [wip, setWip] = useState<boolean>(false);

    const ListOrganizationOptions = ()=>{
        setIsOrganizationsLoading(true)
        getAdminUserServiceAPI().adminUserServiceListOrganizations().then((response)=>{
            if (response.data.success){
                setOrganizations(response.data.organization_options ?? [])
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(()=>{
            window.alert("Server error")
        }).finally(()=>{
            setIsOrganizationsLoading(false);
        })
    }

    const loginToOrganization = (organizationUUid: string)=>{
        setWip(true);
        getAdminUserServiceAPI().adminUserServiceLoginToOrganization({
            organization_uuid: organizationUUid
        }).then((response)=>{
            if (response.data.success){
                props.login(response.data.security_token as string, "", UserType.ADMIN);
                window.location.reload();
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(()=>{
            window.alert("Server error")
        }).finally(()=>{
            setWip(false);
        });
    }

    useEffect(ListOrganizationOptions, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const companyCards = organizations.map((option)=>{
        return <div className="col-lg-6" key={option.uuid}>
            <div className="card iq-mb-3 bg-light">
                <div className="row no-gutters">
                    <div className="col-md-4 my-auto">
                        <img src={`data:image/jpeg;base64,${option.logo}`} className="card-img" alt=""/>
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h4 className="card-title text-dark">{option.name}</h4>
                            {wip && <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>}
                            {!wip && 
                            <button type="submit" className="btn btn-dark mb-3" onClick={(e)=>{
                                e.preventDefault();
                                loginToOrganization(option.uuid ?? "");
                            }}>Log into {option.name}</button>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    })

    return (
        <div className={"content-page"}>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-sm-12 col-lg-6"}>
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    {!isOrganizationsLoading && organizations.length !== 0 && <h4 className="card-title">Select An Organization</h4>}
                                    {!isOrganizationsLoading && organizations.length === 0 && <h4 className="card-title">You are not belong to any organizations</h4>}
                                </div>
                            </div>
                            {!isOrganizationsLoading && organizations.length !== 0 && <div className="iq-card-body">
                                <p>Please select an organization that you want to login</p>
                                {companyCards}
                            </div>}
                            {!isOrganizationsLoading && organizations.length === 0 && <div className="iq-card-body">
                                <p className="card-title">Please contact your organization administrator</p>
                                <button type="button" className="btn btn-dark mb-3" onClick={()=>{props.logout()}}>Logout</button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const OrganizationSelection = withAuthentication(_OrganizationSelection)

