import ClientOAuth2 from "client-oauth2";
import {getAdminSsoServiceAPI, getEmployeeSsoServiceApi} from "../global/api";
import {UserType} from "../global/models";

export const getMicrosoftAuth = async (userType: UserType) => {
    if (userType === UserType.ADMIN){
        return getAdminSsoServiceAPI().adminSsoServiceGetMicrosoftSsoConfig().then((response)=>{
          if (response.data.success){
              return new ClientOAuth2({
                  clientId: response.data.config?.client_id ?? "",
                  authorizationUri: response.data.config?.url_authorize ?? "",
                  accessTokenUri: response.data.config?.url_access_token ?? "",
                  redirectUri: response.data.config?.redirect_uri ?? "",
                  scopes: ["user.read", "openid", "profile"]
              })
          } else {
              return null
          }
        })
    }

    if (userType === UserType.EMPLOYEE){
        return getEmployeeSsoServiceApi().ssoServiceGetMicrosoftSsoConfig().then((response)=>{
            if (response.data.success){
                return new ClientOAuth2({
                    clientId: response.data.client_id ?? "",
                    authorizationUri: response.data.url_authorize ?? "",
                    accessTokenUri: response.data.url_access_token ?? "",
                    redirectUri: response.data.redirect_uri_for_employee ?? "",
                    scopes: ["user.read", "openid", "profile"]
                })
            } else {
                return null
            }
        })
    }
}
