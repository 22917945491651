import React, {useEffect, useState} from "react";
import {getMicrosoftAuth} from "./auth/microsoft";
import {getAdminSsoServiceAPI, getEmployeeSsoServiceApi} from "./global/api";
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {UserType} from "./global/models";
import {ShowAdminLinkAccountForm} from "./auth/employer_ms_link_form";
import {PpeSsoResponseCode} from "./gen";


interface Props extends IAuthenticationContext {
    userType: UserType
}

const _MicrosoftLoginCallbackPage = (props: Props) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [suggestedEmail, setSuggestedEmail] = useState("");

    const [showLinkAccountForm, setShowLinkAccountForm] = useState(false);

    function loginWithMicrosoft() {
        getMicrosoftAuth(props.userType).then((auth) => {
            if (auth) {
                auth.token.getToken(window.location)
                    .then(function (token) {
                        const accessToken = token.accessToken;
                        setAccessToken(accessToken);

                        if (props.userType === UserType.ADMIN) {
                            getAdminSsoServiceAPI().adminSsoServiceExchangeSecurityTokenWithMicrosoftToken({
                                microsoft_token: accessToken
                            }).then((result)=>{
                                console.log(result.data);
                                if (result.data.success) {
                                    props.login(result.data.security_token ?? "", result.data.email ?? "", props.userType);

                                    // @ts-ignore
                                    window.location = "/"
                                } else {
                                    if (result.data.code === PpeSsoResponseCode.Unlinked) {
                                        setShowLinkAccountForm(true);
                                        setSuggestedEmail(result.data.suggested_email ?? "");
                                    } else {
                                        setErrorMessage(result.data.error_message ?? "");
                                    }
                                }
                            })
                        }

                        if (props.userType === UserType.EMPLOYEE) {
                            getEmployeeSsoServiceApi().ssoServiceExchangeSessionTokenWithMicrosoftAccessToken({
                                access_token: accessToken
                            }).then((response)=>{
                                console.log(response.data);
                                if (response.data.success) {
                                    props.login(response.data.session_token ?? "", "", props.userType);
                                    // @ts-ignore
                                    window.location = "/"
                                } else {
                                    setErrorMessage(response.data.error_message ?? "");
                                }
                            }).catch((e) => {
                                console.log(e);
                                setErrorMessage("Server error.");
                            })
                        }
                    }).catch((error) => {
                    console.log(error);
                    setErrorMessage("Failed to validate authentication. Please refresh and try again later.");
                });
            }
        })
    }

    useEffect(() => {
        loginWithMicrosoft();
    }, []);

    if (showLinkAccountForm) {
        return (<ShowAdminLinkAccountForm suggestedEmail={suggestedEmail} accessToken={accessToken}/>);
    }

    return <div className="sign-in-page">
        <div className="container p-0">
            {errorMessage ?
                <div>
                    <div className="alert alert-danger">
                        <b>Error: </b> {errorMessage}
                    </div>
                    <a className={"btn btn-info btn-lg"} href={"/"}>Retry</a>
                </div>
                :
                <div className="alert alert-info">
                    Processing...
                </div>
            }
        </div>
    </div>;
};


export const MicrosoftLoginCallbackPage = withAuthentication(_MicrosoftLoginCallbackPage);
