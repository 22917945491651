import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from "../authentication";
import {Link, Redirect} from 'react-router-dom';
import {getAdminAuthServiceAPI, getEmployeeUserServiceApi} from "../global/api";
import {UserType} from "../global/models";

import votLogo from '../VOT-logo.jpg';
import {GoogleLoginBox} from "./googleLoginBox";
import {LoginWithMS365Box} from "./loginWithMS365Box";

import googlePlayStore from '../google-play-store.png';
import appleAppStore from '../apple-app-store.svg';
import {PpeLoginResponseCode} from "../gen";


interface Props extends IAuthenticationContext {
    location: any
    match: any
    history: any
}

interface State {
    password: string
    email: string
    validating: boolean
    error_message: string | null
    userType: UserType
    isEmployeeOnetimeCodeRequested: boolean
    verificationId: string
    onetimeCode: string
    require2fa: boolean
    twoFactorAuthenticationCode: string
    busy: boolean
}

class _Login extends Component<Props, State> {
    qs = require('qs');
    params = this.qs.parse(this.props.location.search.slice(1));

    constructor(props: Props) {
        super(props);
        this.state = {
            busy: false,
            email: "",
            password: "",
            validating: false,
            error_message: null,
            userType: this.params['user_type'] !== "" ? this.params['user_type'] !== undefined ? this.params['user_type'] === UserType.EMPLOYEE ? UserType.EMPLOYEE : UserType.ADMIN : UserType.ADMIN : UserType.ADMIN,
            isEmployeeOnetimeCodeRequested: false,
            verificationId: "",
            onetimeCode: "",
            require2fa: false,
            twoFactorAuthenticationCode: "",
        };
    }

    componentDidUpdate(prevProps
                           :
                           any, prevState
                           :
                           any, snapshot
                           :
                           any
    ) {
        if (prevProps.location.search.slice(1) !== this.props.location.search.slice(1)) {
            this.getParamsFromUrl();
        }
    }

    getParamsFromUrl() {
        const params = this.qs.parse(this.props.location.search.slice(1));
        this.setState({
            userType: params['user_type'] !== "" ? params['user_type'] !== undefined ? params['user_type'] === UserType.EMPLOYEE ? UserType.EMPLOYEE : UserType.ADMIN : UserType.ADMIN : UserType.ADMIN,
        })
    }

    handleParamsChange(currentParam
                           :
                           string, newValue
                           :
                           string
    ) {
        const qs = require('qs');
        let params = qs.parse(this.props.location.search.slice(1));
        params[currentParam] = newValue;
        const keys = Object.keys(params);
        let paramsStr = "?";
        keys.forEach((key) => {
            paramsStr = paramsStr + key + "=" + params[key] + "&"
        })
        this.props.history.push(paramsStr);
    }

    handleEmailChange(event
                          :
                          any
    ) {
        this.setState({
            email: event.target.value,
            require2fa: false
        });
        if (this.state.userType === UserType.EMPLOYEE) {
            this.setState({isEmployeeOnetimeCodeRequested: false});
        }

    }

    handlePasswordChange(event
                             :
                             any
    ) {
        this.setState({
            require2fa: false,
            password: event.target.value
        });
    }

    handleSubmit(event
                     :
                     any
    ) {
        event.preventDefault();
        this.setState({validating: true, error_message: ""});


        getAdminAuthServiceAPI().adminAuthenticationServiceAdministratorLogin({
            email: this.state.email,
            password: this.state.password,
            tfa_code: this.state.twoFactorAuthenticationCode
        }).then((response) => {
            return response.data
        }).then((response) => {
            if (response.success) {
                if (response.code === PpeLoginResponseCode.LoggedIn){
                    this.props.login(response.auth_token as string, response.email as string, this.state.userType);
                }
                if (response.code === PpeLoginResponseCode.TfaRequired) {
                    this.setState({
                        error_message: response.error_message as string,
                        require2fa: true,
                    })
                }
            } else {
                this.setState({error_message: response.error_message as string});
            }
        }).finally(() => {
            this.setState({validating: false});
        });
    }

    changeToAdminLogin(event
                           :
                           any
    ) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({userType: UserType.ADMIN, error_message: "", isEmployeeOnetimeCodeRequested: false}, () => {
            this.handleParamsChange("user_type", this.state.userType)
        })
    }

    changeToEmployeeLogin(event
                              :
                              any
    ) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
                userType: UserType.EMPLOYEE,
                error_message: "",
                isEmployeeOnetimeCodeRequested: false
            }, () => {
                this.handleParamsChange("user_type", this.state.userType)
            }
        )
    }

    requestEmployeeLoginCode(event
                                 :
                                 any
    ) {
        event.preventDefault();
        event.stopPropagation();
        getEmployeeUserServiceApi().userServicePostAuthentication({
            email_address:this.state.email
        }).then((result)=>{
            if (result.data.success){
                this.setState({
                    isEmployeeOnetimeCodeRequested: true,
                    error_message: "Auth code has been sent to your email address",
                    verificationId: result.data.verification_id ?? ""
                })
            } else {
                this.setState({error_message: result.data.error_message ?? ""});
            }
        }).catch((result)=>{
            this.setState({error_message: "Server error, please try again later."});
        }).finally(()=>{

        })
    }

    handleOnetimeCodeChange(event
                                :
                                any
    ) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({onetimeCode: event.target.value})
    }

    handle2faChange(e
                        :
                        any
    ) {
        e.preventDefault();
        this.setState({twoFactorAuthenticationCode: e.target.value})
    }

    handleEmployeeLogin(event
                            :
                            any
    ) {
        this.setState({busy: true})
        event.preventDefault();
        event.stopPropagation();
        getEmployeeUserServiceApi().userServicePostVerification({
            auth_code : this.state.onetimeCode,
            verification_id: this.state.verificationId
        }).then((result)=>{
            if (result.data.success) {
                this.props.login(result.data.token ?? "", this.state.email, UserType.EMPLOYEE)
            } else {
                this.setState({error_message: result.data.error_message ?? ""});
            }
        }).catch((result)=>{
            this.setState({error_message: "Server error, please try again later."});
        }).finally(()=>{
            this.setState({busy: false})
        })
    }

    render() {
        if (this.props.authentication.authenticated) {
            return <Redirect to="/"/>;
        }

        return (
            <div className="sign-in-page">
                <div className="container p-0">
                    <div className="row no-gutters height-self-center">
                        <div className="col-sm-12 align-self-center rounded">
                            <div className="row m-0 justify-content-md-center">
                                <div className="col-md-5 bg-white sign-in-page-data">
                                    <div className="sign-in-from">
                                        <div className={"mb-5"}>
                                            {this.state.userType === UserType.ADMIN &&
                                            <button className="float-right btn btn-link"
                                                    onClick={this.changeToEmployeeLogin.bind(this)}>Switch To Employee
                                                Login</button>}
                                            {this.state.userType === UserType.EMPLOYEE &&
                                            <button className="float-right btn btn-link"
                                                    onClick={this.changeToAdminLogin.bind(this)}>Admin
                                                Login</button>}
                                        </div>
                                        <h1 className="mb-0 text-center">{this.state.busy ? "..." : "Sign in"}</h1>
                                        {this.state.userType === UserType.ADMIN &&
                                        <p className="text-center text-dark">Enter your email address and password
                                            to
                                            access PPE admin panel.</p>}

                                        {this.state.error_message &&
                                        <div className="alert alert-danger">{this.state.error_message}</div>
                                        }

                                        {this.state.userType === UserType.ADMIN &&
                                        <>
                                            <form className="mt-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Email</label>
                                                    <input type="email" className="form-control mb-0"
                                                           id="exampleInputEmail1" placeholder="Enter email"
                                                           onChange={this.handleEmailChange.bind(this)}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Password</label>
                                                    <input type="password" className="form-control mb-0"
                                                           id="exampleInputPassword1" placeholder="Password"
                                                           onChange={this.handlePasswordChange.bind(this)}/>
                                                    <Link to={"/forgot_password"} className="float-right">Forgot
                                                        password</Link>
                                                </div>

                                                {this.state.require2fa &&
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Two-Factor Authentication
                                                        Code</label>
                                                    <input type="text" className="form-control mb-0"
                                                           id="exampleInputPassword1" placeholder="Authentication Code"
                                                           onChange={this.handle2faChange.bind(this)}/>
                                                </div>
                                                }

                                                <div className="sign-info text-center">
                                                    <button onClick={this.handleSubmit.bind(this)}
                                                            className="btn btn-primary d-block w-100 mb-2">Sign in
                                                    </button>
                                                </div>
                                            </form>

                                            <hr/>
                                            <LoginWithMS365Box userType={UserType.ADMIN}/>
                                            <br/>
                                            <GoogleLoginBox role={UserType.ADMIN}/>
                                        </>
                                        }
                                        {this.state.userType === UserType.EMPLOYEE &&
                                        <div>
                                            <form className="mt-4">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="exampleInputEmail1">Email</label> {this.state.isEmployeeOnetimeCodeRequested &&
                                                <button onClick={this.requestEmployeeLoginCode.bind(this)}
                                                        className={"btn btn-link"}>Resend code</button>}
                                                    <input type="email" className="form-control mb-0"
                                                           id="exampleInputEmail1" placeholder="Enter email"
                                                           onChange={this.handleEmailChange.bind(this)}/>
                                                </div>
                                                {
                                                    this.state.isEmployeeOnetimeCodeRequested &&
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Auth Code</label>
                                                        <input type="password" className="form-control mb-0"
                                                               placeholder="Code"
                                                               onChange={this.handleOnetimeCodeChange.bind(this)}
                                                               value={this.state.onetimeCode}/>
                                                    </div>
                                                }
                                                {
                                                    this.state.isEmployeeOnetimeCodeRequested &&
                                                    <div className="sign-info text-center">
                                                        <button onClick={this.handleEmployeeLogin.bind(this)}
                                                                className="btn btn-primary d-block w-100 mb-2">Login
                                                        </button>
                                                    </div>
                                                }
                                                {!this.state.isEmployeeOnetimeCodeRequested &&
                                                <div className="sign-info text-center">
                                                    <button onClick={this.requestEmployeeLoginCode.bind(this)}
                                                            className="btn btn-primary d-block w-100 mb-2">Get auth code
                                                    </button>
                                                </div>}
                                            </form>

                                            <hr/>

                                            <LoginWithMS365Box userType={UserType.EMPLOYEE}/>
                                            <br/>
                                            <GoogleLoginBox role={UserType.EMPLOYEE}/>

                                            <br/>

                                            <hr/>
                                            <p>
                                                Mobile Clients available on Apple App Store and Google Play
                                                <br />
                                                <a href="https://play.google.com/store/apps/details?id=com.vendingontrack.ppe">
                                                    <img width={"70%"} src={googlePlayStore}
                                                         alt={"Available on Google Play Store"}/>
                                                </a>
                                                <br/>
                                                <a href="https://apps.apple.com/us/app/ppe-vending/id1565763590">
                                                    <img style={{paddingLeft: "10px"}} width="70%" src={appleAppStore}
                                                         alt={"Available on Apple App Store"}/>
                                                </a>
                                            </p>
                                        </div>
                                        }
                                        <div>
                                            <hr/>
                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    <p><Link to={"/help"}>Support & Help</Link></p>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    <p>Powered by:</p>
                                                </div>
                                                <div className={"col-8 mb-3"}>
                                                    <a href="https://www.vendingontrack.com">
                                                        <img
                                                            src={votLogo} className=""
                                                            style={{maxWidth: "60%", marginTop: "2em"}} alt="logo"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


const
    Login = withAuthentication(_Login);

export {
    Login
};

