import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {V1DedicatedLocker, V1DedicatedLockerCell, V1DedicatedLockerCellDoorOpenEvent} from "../gen/typescript-axios";
import {
    getAdminCompanyInformationServiceAPI,
    getAdminDedicatedLockerServiceAPI,
    getAdminUserServiceAPI
} from "../global/api";
import {Message} from "../global/models";
import {PpeAdmin, PpeEmployee} from "../gen";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import {DatetimeDisplay} from "../global/datetime_display";
import ReactPaginate from "react-paginate";
import { DownloadDedicatedLockersEventsCsv } from './download_dedicated_lockers_events_csv';


interface Props extends IGlobalDataContext{

}

function _DedicatedLockerCellDoorOpenEvents(props: Props) {
    const [lockers, setLockers] = useState<V1DedicatedLocker[]>([])
    const [cells, setCells] = useState<V1DedicatedLockerCell[]>([])
    const [admins, setAdmins] = useState<PpeAdmin[]>([])

    const [isEmployeeOptionsLoading, setIsEmployeeOptionsLoading] = useState<boolean>(false);
    const [employeeOptions, setEmployeeOptions] = useState<PpeEmployee[]>([])

    const [selectedLockerUuid, setSelectedLockerUuid] = useState<string>("");
    const [selectedCellUuid, setSelectedCellUuid] = useState<string>("");
    const [selectedEmployee, setSelectedEmployee] = useState<PpeEmployee | null>(null)
    const [selectedAdminEmail, setSelectedAdminEmail] = useState<string>("");

    const [events, setEvents] = useState<V1DedicatedLockerCellDoorOpenEvent[]>([])

    const [pageCount, setPageCount] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(0)

    const reloadLockers = () => {
        getAdminDedicatedLockerServiceAPI().adminDedicatedLockerServiceListDedicatedLockers().then(resp => resp.data).then((resp) => {
            if (!resp.success) {
                props.pushFlashMessage(new Message(resp.error_message!, "error"));
            } else {
                setLockers(resp.dedicated_lockers ?? []);
            }
        })
    }

    const reloadAdmins = () => {
        getAdminUserServiceAPI().adminUserServiceListAdmins().then((response) => {
            if (!response.data.success){
                props.pushFlashMessage(new Message(response.data.error_message!, "error"));
            } else {
                setAdmins(response.data.admins ?? [])
            }
        })
    }

    const reloadCells = () => {
        if (selectedLockerUuid === ""){
            return
        }
        getAdminDedicatedLockerServiceAPI().adminDedicatedLockerServiceGetDedicatedLockerDetails(selectedLockerUuid).then(resp => resp.data).then((resp) => {
            if (!resp.success) {
                props.pushFlashMessage(new Message(resp.error_message!, "error"));
            } else {
                setCells(resp.dedicated_locker?.cells ?? []);
            }
        })
    }

    const reloadDoorOpenEvents = () => {
        getAdminDedicatedLockerServiceAPI().adminDedicatedLockerServiceListDedicatedLockerCellDoorOpenEvents({
            locker_uuid: selectedLockerUuid === "" ? undefined : selectedLockerUuid,
            cell_uuid: selectedCellUuid === "" ? undefined : selectedCellUuid,
            employee_uuid: selectedEmployee === null ? undefined : selectedEmployee.uuid,
            admin_email: selectedAdminEmail === "" ? undefined : selectedAdminEmail,
            per_page: 50,
            page: currentPage + 1
        }).then((response) => {
            if (response.data.success){
                setEvents(response.data.events ?? [])
                setPageCount(response.data.total_pages ?? 1)
            } else {
                props.pushFlashMessage(new Message(response.data.error_message!, "error"));
            }
        })
    }

    const searchEmployee = (query:string) => {
        getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceSearchEmployees({
            term:query,
            per_page:10000,
            page:1
        }).then((response)=>{
            if (response.data.success){
                const employees = response.data.data?.employees?.filter((employee)=>{
                    return employee.is_enabled === true && employee.is_tmp_access === false;
                })
                setEmployeeOptions(employees ?? [])
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(reason => {
            window.alert("Server error")
        }).finally(()=>{
            setIsEmployeeOptionsLoading(false)
        })
    }

    const handleLockerChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value === ""){
            setCells([])
            setSelectedCellUuid("")
        }
        setCurrentPage(0)
        setSelectedLockerUuid(event.target.value)
    }

    const handleCellChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setCurrentPage(0)
        setSelectedCellUuid(event.target.value)
    }

    const handleAdminChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setCurrentPage(0)
        setSelectedAdminEmail(event.target.value)
    }

    const onEmployeeChange = (employees: Array<PpeEmployee>) => {
        if (employees.length > 0) {
            setSelectedEmployee(employees[0])
        } else {
            setSelectedEmployee(null)
        }
        setCurrentPage(0)
    }

    const handlePageChange = (selectedItem: { selected: number }) => {
        setCurrentPage(selectedItem.selected);
    }

    const init = () => {
        reloadLockers();
        reloadAdmins();
    }

    useEffect(reloadDoorOpenEvents, [selectedLockerUuid, selectedCellUuid, selectedAdminEmail, selectedEmployee, currentPage])

    useEffect(init, [])

    useEffect(reloadCells, [selectedLockerUuid])

    const lockerOptions = lockers.map((locker)=>{
        return <option key={locker.uuid} value={locker.uuid}>{locker.name}</option>
    })

    const adminOptions = admins.map((admin)=>{
        return <option key={admin.email} value={admin.email}>{`${admin.name}[${admin.email}]`}</option>
    })

    const cellOptions = cells.map((cell)=>{
        return <option key={cell.uuid} value={cell.uuid}>{`${cell.display_name}[${cell.row}, ${cell.col}]`}</option>
    })

    const renderEmployeeOptions = (option: PpeEmployee, props:any) => {
        return [
            <Highlighter key={"name"} search={props.text}>{option.full_name + "(" + (option.employee_number ?? 'N/A') + ")"}</Highlighter>,
        ];
    };

    const _selectedEmployee = selectedEmployee ? [selectedEmployee] : [];

    const rows = events.map((event) => {
        return <tr key={event.uuid}>
            <td>
                <DatetimeDisplay timezone={props.timezone} timestamp={event.open_request_epoch ?? 0}/>
            </td>
            <td>{event.key_card_number ?? "-"}</td>
            <td>
                Locker: {event.locker?.name}
                <br/>
                Cell: {`${event.cell?.display_name}[${event.cell?.row}, ${event.cell?.col}]`}
            </td>
            <td>
                {event.employee !== null ? `${event.employee?.full_name} [${event.employee?.email}]`: "-"}
                <br/>
                {event.reference !== null && <div style={{whiteSpace:"pre-wrap"}}>
                    <b>Reference</b>:
                    <br/>
                    {event.reference}
                </div>}
            </td>
            <td>{event.admin !== null ? `${event.admin?.name} [${event.admin?.email}]` : "-"}</td>
        </tr>
    })

    return (
        <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Dedicated Locker Events - " + props.company + " - " + props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Dedicated Locker Events</h4>
                                </div>
                                <DownloadDedicatedLockersEventsCsv 
                                    lockerUuid={selectedLockerUuid === "" ? undefined : selectedLockerUuid}
                                    cellUuid={selectedCellUuid === "" ? undefined : selectedCellUuid}
                                    employeeUuid={selectedEmployee === null ? undefined : selectedEmployee.uuid}
                                    adminEmail={selectedAdminEmail === "" ? undefined : selectedAdminEmail}
                                    perPage={50}
                                    page={currentPage + 1}
                                />
                            </div>
                            <div className="iq-card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-row">
                                            <div className="col-md-3 mb-3 form-group">
                                                <label htmlFor="lockers">Locker:</label>
                                                <select name="lockers" id="lockers"
                                                        className="custom-select" value={selectedLockerUuid} onChange={handleLockerChange}>
                                                    <option value="">All</option>
                                                    {lockerOptions}
                                                </select>
                                            </div>
                                            <div className="col-md-3 mb-3 form-group">
                                                <label htmlFor="cells">Cell:</label>
                                                <select name="cells" id="cells"
                                                        className="custom-select" value={selectedCellUuid} onChange={handleCellChange}>
                                                    <option value="">All</option>
                                                    {cellOptions}
                                                </select>
                                            </div>
                                            <div className="col-md-3 mb-3 form-group">
                                                <label>Employee</label>
                                                <AsyncTypeahead
                                                    id={"typeahead"}
                                                    isLoading={isEmployeeOptionsLoading}
                                                    labelKey={(item)=>{
                                                        return item.full_name ?? ""
                                                    }}
                                                    onSearch={searchEmployee}
                                                    selected={_selectedEmployee}
                                                    onChange={onEmployeeChange}
                                                    options={employeeOptions}
                                                    renderMenuItemChildren={renderEmployeeOptions}
                                                    clearButton={true}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3 form-group">
                                                <label htmlFor="admins">Admin:</label>
                                                <select name="admins" id="admins"
                                                        className="custom-select" value={selectedAdminEmail} onChange={handleAdminChange}>
                                                    <option value="">All</option>
                                                    {adminOptions}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <table
                                        id="datatable"
                                        className="table table-striped table-bordered mt-4"
                                    >
                                        <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Key Card Number</th>
                                            <th>Locker & Cell</th>
                                            <th>Employee</th>
                                            <th>Admin</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ReactPaginate
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    initialPage={currentPage}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination mb-0"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item page-link"}
                    nextClassName={"page-item page-link"}
                    activeClassName={"active"}
                />
            </div>
        </div>
    );
}

export const DedicatedLockerCellDoorOpenEvents = withGlobalData(_DedicatedLockerCellDoorOpenEvents)
