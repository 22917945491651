import moment from "moment";

// date string format yyyy-mm-dd e.g. 2022-03-18
export const getStartOfDay = (dateString : string)=>{
    let startEpoch = 0;
    if (dateString !== ""){
        startEpoch = moment(dateString, "YYYY-MM-DD").unix();
    }
    return startEpoch;
}

// date string format yyyy-mm-dd e.g. 2022-03-18
export const getEndOfDay = (dateString : string)=>{
    let endEpoch = moment().unix();
    if (dateString !== ""){
        endEpoch = moment(dateString, "YYYY-MM-DD").unix() + 86399;
    }
    return endEpoch;
}
