import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {getAdminSelfServiceApi} from "../global/api";
import {PpeSelfServicingConfig} from "../gen";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";


interface Props extends IGlobalDataContext {
    config: PpeSelfServicingConfig

    reloaded(): void
}

function _selfServicingConfig(props: Props) {
    const [saving, setSaving] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [authorizedDomains, setAuthorizedDomains] = useState<Array<string>>([]);
    const [changed, setChanged] = useState(false);

    let state = <span className={"text text-danger"}>Disabled</span>;
    if (enabled) {
        state = <span className={"text text-success"}>Enabled</span>;
    }


    useEffect(() => {
        setAuthorizedDomains(props.config.authorized_domains as Array<string>);
        setChanged(false);
        setEnabled(!!props.config.enable_self_servicing);
    }, [props.config]);

    function remove(domain: string) {
        const newList = authorizedDomains.filter((d) => {
            return !(domain === d)
        });

        setAuthorizedDomains(newList);
        setChanged(true);
    }

    function toggleState() {
        setChanged(true);
        setEnabled(!enabled);
    }

    const rows = authorizedDomains.map((domain, idx) => {
        return <tr key={idx}>
            <td>{domain}</td>
            <td>
                <button onClick={() => {
                    remove(domain);
                }}>
                    Remove
                </button>
            </td>
        </tr>
    })


    const [newDomainName, setNewDomainName] = useState("");

    function handleDomainUpdate(e: any) {
        e.preventDefault()
        setNewDomainName(e.target.value);
        return false;
    }


    function addNewDomain(e: any) {
        e.preventDefault();
        setAuthorizedDomains([...authorizedDomains, newDomainName]);
        setNewDomainName("");
        setChanged(true);
    }

    function saveForm(e: any) {
        e.preventDefault();
        setSaving(true);
        const selfServiceAPI = getAdminSelfServiceApi();
        selfServiceAPI.adminSelfServiceServiceUpdateSelfServicing({
            config: {
                enable_self_servicing: enabled,
                authorized_domains: authorizedDomains
            }
        }).then((response) => {
            return response.data
        }).then((result) => {
            if (result.success) {
                setSaving(false);
                props.reloaded();
            } else {
                props.pushFlashMessage({
                    cls: 'error',
                    message: "" + result.message
                })
            }
        }).catch((e) => {
            props.pushFlashMessage({
                cls: 'error',
                message: "Server error, please retry"
            })
        }).finally(() => {
            setSaving(false);
        })
    }


    return <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title">
                Self-Servicing Onboarding Status
            </div>
        </div>

        <div className="iq-card-body">
            {saving ?
                <p>Saving...</p>
                :
                <div>
                    {changed &&
                    <form onSubmit={saveForm}><input type={"submit"} className={"btn btn-primary"} value={"Save"}/>
                    </form>}
                </div>
            }

            <div>
                State: {state}
                <br/>
                <button onClick={toggleState}>Toggle State</button>
            </div>
            <div>
                <b>Authorized Domains</b>
                <table className={"table"}>
                    {rows}
                </table>
                <hr/>

                <form className={"form"} onSubmit={addNewDomain}>
                    <input className={"form-control"} value={newDomainName} onChange={handleDomainUpdate}
                           type={"text"}
                           placeholder={"New authorized domain"}/>
			<br />
                    <input type={"submit"} className={"btn btn-info"} value={"Add new domain"}/>
                </form>
            </div>

        </div>
    </div>
}

const SelfServicingConfig = withGlobalData(_selfServicingConfig);

export function SelfServicingPage() {
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState<PpeSelfServicingConfig | null>(null);
    const [error, setError] = useState<string | null>(null);

    function loadSelfServicingConfig() {
        setLoading(true);
        setError("");
        getAdminSelfServiceApi().adminSelfServiceServiceGetSelfServicingConfig().then((response) => {
            return response.data
        }).then((result) => {
            if (result.success) {
                if (result.config) {
                    setConfig(result.config);
                } else {
                    setConfig(null);
                }
            }
        }).catch((e) => {
            setError("Server error, please try again later.");
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        loadSelfServicingConfig();
    }, []);

    return <div id="content-page" className="content-page">
        <Helmet>
            <title>SelfServicing Onboarding</title>
        </Helmet>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <h1>Self-Servicing Onboarding Configuration</h1>
                    {loading ? <p>Loading</p> :
                        <div>
                            {error && <div className={"alert alert-danger"}>{error}</div>}
                            {config && <SelfServicingConfig config={config} reloaded={loadSelfServicingConfig}/>}
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>;
}
