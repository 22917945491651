import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {ServiceNowCard} from "./company_profile_page/service_now_card";
import {ServiceNowSyncProductCard} from "./servicenow/service_now_sync_product_card";
import {ServiceNowSyncUsersCard} from "./servicenow/service_now_sync_users_card";
import {getAdminOrganizationServiceAPI} from "./global/api";
import {PpeServiceNow} from "./gen";

interface Props {

}

export function ServiceNowPage(props: Props) {
    const [serviceNowEnabled, setServiceNowEnabled] = useState(false);
    const [busy, setBusy] = useState(true);
    const [servicenow, setServicenow] = useState<PpeServiceNow | null>(null);

    useEffect(() => {
        setBusy(true);
        setServiceNowEnabled(false);

        getAdminOrganizationServiceAPI().adminOrganizationServiceGetOrganizationDetail().then((response)=>{
            if (response.data.success ?? false){
                if (response.data.organization?.capabilities?.servicenow){
                    setServiceNowEnabled(true);
                    setServicenow(response.data.organization.servicenow ?? null);
                } else {
                    setServiceNowEnabled(false);
                }
            } else {
                setServiceNowEnabled(false);
                window.alert(response.data.error_message ?? "")
            }
        }).catch(()=>{
            window.alert("Server error")
        }).finally(()=>{
            setBusy(false);
        })

    }, []);

    if (busy) {
        return <div id="content-page" className="content-page">
            <div className="row">
                <div className="col-sm-12">
                    <h3>Loading...</h3>
                </div>
            </div>
        </div>
    }

    if (!serviceNowEnabled) {
        return (<div id="content-page" className="content-page">
            <Helmet>
                <title>ServiceNow Integration - Disabled</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>ServiceNow Integration - <span className={"text text-danger"}>DISABLED</span></h3>

                        <div className={"alert alert-info"}>
                            <b>ServiceNow integration disabled.</b>
                            &nbsp;
                            For more information, please contact Vending on Track enterprise support
                            (support+enterprise@vendingontrack.com).
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    return (<div id="content-page" className="content-page">
        <Helmet>
            <title>ServiceNow Integration</title>
        </Helmet>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <ServiceNowCard servicenow={servicenow}/>

                    <ServiceNowSyncProductCard/>

                    <ServiceNowSyncUsersCard/>
                </div>
            </div>
        </div>
    </div>);
}
