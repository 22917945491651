import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {PpeTrackableItem, PpeTrackableItemState} from "../gen";
import {getAdminOrganizationServiceAPI, getTrackableItemsServiceAPI} from "../global/api";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {Message} from "../global/models";
import DataTable, {ExpanderComponentProps, TableColumn} from 'react-data-table-component';
import {AddTrackableItemCard} from "./_add_trackable_item_card";
import moment from "moment";
import {SearchSlots} from "./search_slots";
import {Link} from "react-router-dom";
import AssignToEmployee from "./assign_to_employee";
import {DatetimeDisplay} from "../global/datetime_display";

function AssignAssetTag(props: { item: PpeTrackableItem, reload(): void }) {
    function assign(e: any) {
        e.preventDefault();
        console.log('saving');

        if (assetTag) {
            setError("");
            setSaving(true);

            getTrackableItemsServiceAPI().adminTrackableItemServiceAssignAssetTagToTrackableItem({
                trackable_item_uuid: props.item.uuid,
                asset_tag: assetTag
            }, {}).then(resp => resp.data).then((resp) => {
                if (resp.success) {
                    props.reload()
                } else {
                    setError(resp.error_message || "Unknown error");
                }
            }).catch((e) => {
                console.log(e);
                setError("Unknown server error");
            }).finally(() => {
                setSaving(false);
            })
        }
    }

    const [assetTag, setAssetTag] = useState("");
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");

    return (<div className={"well"}>
        <h2>Assign Asset Tag to Item</h2>

        <form onSubmit={assign}>
            <div className={"form-group"}>
                <label>Asset Tag:</label>
                <input type={"text"} value={assetTag} onChange={(e) => {
                    setAssetTag(e.target.value);
                }} className={"form-control"}/>
            </div>

            {error && <div className={"text text-danger"}>{error}</div>}

            {!saving && <input type={"submit"} className={"btn btn-info"} value={"Save"}/>}
        </form>
    </div>);
}

interface DetailViewProps extends ExpanderComponentProps<PpeTrackableItem> {
    reload?(): void
}

function DetailDropdownView(props: DetailViewProps) {
    const [assignAssetTag, setAssignAssetTag] = useState(false);

    const [assignToEmployee, setAssignToEmployee] = useState(false);

    const [unassigning, setUnassigning] = useState(false);

    const [searchSlots, setSearchSlots] = useState(false);

    function pendingInspection(e: any) {
        e.preventDefault();

        getTrackableItemsServiceAPI().adminTrackableItemServiceMarkPendingInspection({
            note: "Admin manually add pending inspection",
            trackable_item_uuid: props.data.uuid
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                if (props.reload) {
                    props.reload()
                }
            } else {
                window.alert(resp.error_message);
            }
        }).catch((e) => {
            console.log(e)
            window.alert("Unknown error, please retry.");
        }).finally(() => {
            // FIXME
        })
    }

    function markAsInventory(e: any) {
        e.preventDefault();

        getTrackableItemsServiceAPI().adminTrackableItemServiceClearPendingInspection({
            trackable_item_uuid: props.data.uuid
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                if (props.reload) {
                    props.reload()
                }
            } else {
                window.alert(resp.success);
            }
        }).finally(() => {
            // fixme
        })
    }

    function markAsReady(e: any) {
        e.preventDefault();

        getTrackableItemsServiceAPI().adminTrackableItemServiceMarkReady({
            trackable_item_uuid: props.data.uuid
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                if (props.reload) {
                    props.reload()
                }
            } else {
                window.alert(resp.success);
            }
        }).finally(() => {
            // fixme
        })
    }

    function markAsRetired(e: any) {
        e.preventDefault();

        getTrackableItemsServiceAPI().adminTrackableItemServiceRetireTrackableItem(props.data.uuid ?? "", {
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                if (props.reload) {
                    props.reload()
                }
            } else {
                window.alert(resp.error_message);
            }
        })
    }

    function toggleSlotSearch(e: any) {
        e.preventDefault();

        setSearchSlots(!searchSlots);
    }

    function unassign() {
        setUnassigning(true);

        getTrackableItemsServiceAPI().adminTrackableItemServiceUnassignAssetTagToTrackableItem({
            trackable_item_uuid: props.data.uuid
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                if (props.reload !== undefined) {
                    props.reload()
                }
            } else {
                window.alert(resp.error_message);
            }
        }).catch((e) => {
            console.log(e);
            window.alert("Unknown error");
        }).finally(() => {
            setUnassigning(false);
        })
    }

    return (<div className={"detail-dropdown-view"}>
        <ul className={"trackable-item-actions"}>
            <li>
                <button disabled={!!props.data.asset_tag} className={"btn btn-default"} onClick={(e) => {
                    e.preventDefault();
                    setAssignAssetTag(!assignAssetTag);
                }}>
                    <i className={"las la-tag"}></i> Assign Asset Tag
                </button>
            </li>

            <li>
                <button className={"btn btn-default"} disabled={!props.data.asset_tag || unassigning} onClick={(e) => {
                    e.preventDefault();
                    if (window.confirm(`Remove tag ${props.data.asset_tag}?`)) {
                        unassign();
                    }
                }}>
                    <i className={"fa fa-close"}></i> Unassign Asset Tag

                </button>
            </li>

            <li>
                <button disabled={props.data.state !== 'INVENTORY'} className={"btn btn-default"}
                        onClick={toggleSlotSearch}>
                    <i className={"las la-binoculars "}></i> Find available locker
                </button>
            </li>


            <li>
                <button disabled={props.data.state === "PENDING_INSPECTION"} className={"btn btn-default"} onClick={pendingInspection}>
                    <i className={"las la-bell "}></i> Pending Inspection
                </button>
            </li>

            <li>
                <button disabled={props.data.state !== "PENDING_INSPECTION"} className={"btn btn-default"} onClick={markAsInventory}>
                    <i className={"las la-check"}></i> Pass Inspection
                </button>
            </li>

            <li>
                <button disabled={props.data.state !== "INVENTORY"} className={"btn btn-default"} onClick={markAsReady}>
                    <i className={"las la-check"}></i> Mark as Ready
                </button>
            </li>

            <li>
                <button disabled={props.data.state !== PpeTrackableItemState.Ready} className={"btn btn-default"} onClick={(e) => {
                    e.preventDefault();
                    setAssignToEmployee(!assignToEmployee);
                }}>
                    <i className={"las la-user "}></i> Assign to Employee
                </button>
            </li>

            <li>
                <button disabled={props.data.state !== PpeTrackableItemState.PendingInspection} className={"btn btn-default"} onClick={markAsRetired}>
                    <i className={"las la-trash"}></i> Retire
                </button>
            </li>

            <li>
                <Link to={`/trackable_items/${props.data.uuid}/sessions`} className="btn btn-default">
                    <i className="las la-list-ul"></i> List Sessions
                </Link>
            </li>
        </ul>
        <hr/>
        {assignAssetTag && <AssignAssetTag item={props.data} reload={props.reload!}/>}

        {assignToEmployee && <AssignToEmployee item={props.data} reload={props.reload!}/>}

        {searchSlots && <SearchSlots item={props.data} reload={props.reload!}/>}

    </div>);
}

function _TrackableItemsPage(props: IGlobalDataContext) {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState<Array<PpeTrackableItem>>([]);
    const [adding, setAdding] = useState(false);

    const [trackableItemsCapable, setTrackableItemsCapable] = useState(true);

    const [inUseTrackableItemOnly, setInUseTrackableItemOnly] = useState(false);

    function loadTrackableItems() {
        setLoading(true);
        const _inUseTrackableItemOnly = inUseTrackableItemOnly;
        getTrackableItemsServiceAPI().adminTrackableItemServiceListTrackableItems(0, 100, inUseTrackableItemOnly ? PpeTrackableItemState.InUse : undefined).then((resp) => {
            return resp.data
        }).then((data) => {
            if (data.success) {
                if (inUseTrackableItemOnly === _inUseTrackableItemOnly){
                    setItems(data.items!);
                }
            } else {
                props.pushFlashMessage(new Message(data.error_message || "Unknown error", "error"));
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    function checkCapability() {
        getAdminOrganizationServiceAPI().adminOrganizationServiceGetOrganizationDetail().then((response)=>{
            if (response.data.success) {
                if (response.data.organization?.capabilities?.trackable_item ?? false) {
                    setTrackableItemsCapable(true);
                } else {
                    setTrackableItemsCapable(false);
                }
            } else {
                setTrackableItemsCapable(false);
                window.alert(response.data.error_message ?? "");
            }
        }).catch(()=>{
            setTrackableItemsCapable(false);
            window.alert("Server error. Please refresh page");
        })
    }

    function onInUseItemOnlyChange(e: any) {
        console.log("Checked: " + e.target.checked);
        setInUseTrackableItemOnly(e.target.checked);
    }

    useEffect(() => {
        checkCapability();
        loadTrackableItems();
    }, [inUseTrackableItemOnly])

    if (!trackableItemsCapable) {
        return (<div id="content-page" className="content-page">
            <Helmet>
                <title>Trackable Items</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h3 className="card-title">Trackable Items</h3>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <p>Trackable Items are where you could create, update, delete, or list all the trackable
                                    items in the organization.</p>

                                <div className={"alert alert-danger"}>
                                    Sorry, this feature is not available. Please contact Vending on Track support.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    const trackable_items_columns: TableColumn<PpeTrackableItem>[] = [
        {
            name: "Product",
            selector: (item: PpeTrackableItem) => {
                return item.product_name!
            },
            sortable: true,
            wrap: true,
        },
        {
            name: "Name",
            selector: (item: PpeTrackableItem) => {
                return item.name!
            },
            sortable: true,
            wrap: true,
        },
        {
            name: "State",
            selector: (item: PpeTrackableItem) => {
                return item.state || "-"
            },
            sortable: true,
            compact: true,
            wrap: true,
        },
        {
            name: "Asset Tag",
            selector: (item: PpeTrackableItem) => {
                return item.asset_tag || "-"
            },
            wrap: true,
        },
        {
            name: "Created At",
            selector: (item: PpeTrackableItem) => {
                return moment.unix(parseInt(item.created_at_epoch || "0") || 0).toString()
            },
            cell: (item: PpeTrackableItem)=>{
                return <DatetimeDisplay timestamp={parseInt(item.created_at_epoch || "0")} timezone={props.timezone}/>
            },
            wrap: true,
            sortable: true,
            compact: true,
        },
        {
            name: "Current in-use by",
            sortable: true,
            selector: (item: PpeTrackableItem) => {
                return item.current_in_use?.employee_name || "-"
            },
            wrap: true,
            compact: true,
        },
        {
            name: "Location",
            sortable: true,
            selector: (item: PpeTrackableItem) => {
                return item.location?.description ?? "-"
            },
            wrap: true,
            compact: true,
        }
    ]

    return <div id="content-page" className="content-page">
        <Helmet>
            <title>Trackable Items</title>
        </Helmet>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h3 className="card-title">Trackable Items</h3>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <p>Trackable Items are where you could create, update, delete, or list all the trackable
                                items in the organization.</p>

                            <button className="btn btn-primary" onClick={() => {
                                setAdding(!adding)
                            }}>Add
                            </button>
                        </div>
                    </div>

                    {adding &&
                    <AddTrackableItemCard onSaved={() => {
                        setAdding(false);
                        loadTrackableItems();
                    }}/>
                    }

                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">
                                    All Trackable Items
                                    <small>
                                        <button className={"btn btn-default"} onClick={(e) => {
                                            e.preventDefault();
                                            loadTrackableItems();
                                        }}>
                                            <i className={"fa fa-refresh" + (loading ? " fa-spin" : "")}></i>
                                        </button>
                                    </small>
                                </h4>

                            </div>
                            <div>
                                <label>Show in-use item only
                                    &nbsp;&nbsp;
                                    <input type="checkbox" checked={inUseTrackableItemOnly}
                                           onChange={onInUseItemOnlyChange}/>
                                </label>
                            </div>
                        </div>

                        <div className="iq-card-body">
                            <DataTable title="" columns={trackable_items_columns} data={items}
                                       highlightOnHover
                                       expandableRows expandableRowsComponent={DetailDropdownView}
                                       expandableRowsComponentProps={{reload: loadTrackableItems} }
                                       progressPending={loading}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const TrackableItemsPage = withGlobalData(_TrackableItemsPage)

