import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import {PpeTransactionsCount} from "../gen";
import {V1Aggregation} from "../gen/typescript-axios";


interface Props {
    chartData:PpeTransactionsCount[]
    isLoading: boolean
    handleDateClick(date:any):void
    handleDateAggChange(e:any):void
    aggregation: V1Aggregation
}

interface State {
}

class TransactionChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    handleDateClick(date: any){
        console.log(date)
    }
    render() {
        let data  : (string | Date | number)[][] = [];
        if (this.props.chartData.length > 0){
            data = [['Date', 'Number of PPE Dispensed']]
            this.props.chartData.forEach((_chartData)=>{
                data.push([_chartData.key ?? "", _chartData.count??0])
            })
        }
        return <div className={"row"}>
            <div className={"col-sm-9 col-md-10 col-lg-11"}>
                {!this.props.isLoading && <Chart
                    height={430}
                    chartType="ColumnChart"
                    data={data}
                    chartEvents={[
                        {
                            eventName: 'select',
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart()
                                const selection = chart.getSelection()
                                if (selection.length === 1) {
                                    const [selectedItem] = selection
                                    const dt = chartWrapper.getDataTable()
                                    const { row } = selectedItem
                                    if (row != null){
                                        if (dt!= null){
                                            this.props.handleDateClick(dt.getValue(row, 0))
                                        }
                                    }
                                }
                            },
                        },
                    ]}
                    options={{
                        legend:{
                            position: 'bottom',
                        },
                        hAxis: {
                            title: 'Date',
                        },
                        vAxis: {
                            title: 'Number of PPE Dispensed',
                            format: '0'
                        },
                        chartArea: {
                            left:100,
                            top: 20,
                            width: '100%',
                        }
                    }}
                />}
                {
                    this.props.isLoading && <div><span>loading transaction chart</span></div>
                }
            </div>
            <div className={'col-sm-3 col-md-2 col-lg-1'}>
                <select className={"form-control"} onChange={this.props.handleDateAggChange.bind(this)} value={this.props.aggregation}>
                    <option value={V1Aggregation.Day}>Day</option>
                    <option value={V1Aggregation.Week}>Week</option>
                    <option value={V1Aggregation.Month}>Month</option>
                </select>
            </div>
        </div>;
    }
}

export {TransactionChart}
