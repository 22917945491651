import React, { Component } from 'react';
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {VendingMachineRow} from "./vending_machine_row";
import {Helmet} from "react-helmet";


interface Props extends IGlobalDataContext{
}

interface State {
}

class _VendingMachinePage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }
    render() {
        let vendingMachineRows = this.props.allVendingMachines.map((vendingMachine)=>{
            return <VendingMachineRow pushFlashMessage={this.props.pushFlashMessage.bind(this)} key={vendingMachine.uuid} reloadAllVendingMachines={this.props.reloadAllVendingMachines.bind(this)} vendingMachine={vendingMachine}/>
        })
        return (
            <div id="content-page" className="content-page">
                <Helmet>
                    <title>{"Vending Machines - " + this.props.company + " - " + this.props.globalTitle}</title>
                </Helmet>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Vending Machines</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <div>
                                        <p>For adding or removing machines, please contact Vending on Track support: support+ppe@vendingontrack.com</p>
                                        <table
                                            id="datatable"
                                            className="table table-striped table-bordered text-center"
                                        >
                                            <thead>
                                            <tr>
                                                <th className="">UUID</th>
                                                <th className="">Vending Machine</th>
                                                <th className="">Location</th>
                                                <th className="">Status</th>
                                                <th className="">PIN Code</th>
                                                <th className="">Timezone</th>
                                                <th className="">Picture</th>
                                                <th className="">Tags</th>
                                                <th className="">Operation</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {vendingMachineRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export const VendingMachinePage = withGlobalData(_VendingMachinePage);
