import React, {useState} from "react";

export function convertHidPostfixToVot(postfix: string, facilityCode: string, facilityCodeLength: number, rawWiegandReader: boolean): string {
    if (facilityCodeLength !== 8) {
        window.alert("Facility code is not 8. Please contact VoT support.");
        return "";
    }
    const card_id: number = parseInt(postfix);
    const fc_byte: number = parseInt(facilityCode);

    console.log("Card id:", card_id);

    let payload: number = (fc_byte << 16) | card_id;

    if (rawWiegandReader) {
        // FIXME: TVN4 reader, 00020d04f5
        return (payload ).toString(16).toUpperCase().padStart(10, "0");
    }

    const high_12: number = payload >> 12;
    const low_12: number = payload & 0xFFF;
    let e_p: number = 0;
    if (parity(high_12)) {
        e_p = 1;
    }
    let o_p: number = 0;
    if (!parity(low_12)) {
        o_p = 1;
    }
    const vot_card_number: number = (e_p << 25) | (payload << 1) | o_p;

    console.log("vot_card_number", vot_card_number.toString(16).toUpperCase().padStart(8, "0"));
    return vot_card_number.toString(16).toUpperCase().padStart(8, "0");
}

function parity(n: number): boolean {
    let parity: boolean = false;
    while (n) {
        parity = !parity;
        n = n & (n - 1);
    }
    return parity;
}

export function HidCardInput(props: {
    onSubmit: OmitThisParameter<(cardNumber: string) => void>,
    hidSettings: HidSettings
}) {
    const [hidPostfix, setHidRawPostfix] = useState("");
    const [hidVotCardNumber, setHidVotCardNumber] = useState("");

    const setHidPostfix = (value: string) => {
        setHidRawPostfix(value);
        setHidVotCardNumber(convertHidPostfixToVot(value, props.hidSettings.facilityCode, props.hidSettings.facilityCodeLength, props.hidSettings.rawWiegandReading));
    }
    const onChange = (event: any) => {
        event.stopPropagation()
        event.preventDefault();

        setHidPostfix(event.target.value);
    }

    const submit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        props.onSubmit(hidVotCardNumber);

        setHidPostfix("")
    }

    return <div className={"well"} style={{border: "1px solid", padding: "5px"}}>
        HID Facility Code: {props.hidSettings.facilityCode}, Length: {props.hidSettings.facilityCodeLength}
        <input className={"form-control"} type={"text"} placeholder={"Enter HID Card Number"} onChange={onChange}
               value={hidPostfix}/>
        <button onClick={submit}>Add Card - HID: {hidVotCardNumber}</button>
    </div>
}

export interface HidSettings {
    // hexidecimal
    facilityCode: string
    facilityCodeLength: number
    rawWiegandReading: boolean
}
