import React, {Component} from 'react';
import ReactPaginate from "react-paginate";
import {getEmployeeTransactionServiceApi} from "../global/api";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {Helmet} from "react-helmet";
import {DatetimeDisplay} from "../global/datetime_display"
import {DownloadTransactions} from "./download_transaction";
import {PpeEmployeeTransaction} from "../gen-employee";



interface Props extends IGlobalDataContext{
    location:any
    match:any
    history:any
}

interface State {
    currentPage:number
    pageCount:number
    allTransactions:PpeEmployeeTransaction[]
}

class _EmployeeDashboard extends Component<Props, State> {
    qs = require('qs');
    params = this.qs.parse(this.props.location.search.slice(1));
    constructor(props: Props) {
        super(props);

        this.state = {
            currentPage:this.params['page'] !== "" ? this.params['page'] !== undefined ? parseInt(this.params['page'])-1 : 0 : 0,
            pageCount:0,
            allTransactions:[],
        };
    }

    componentDidMount() {
        this.reloadEmployeeTransaction();
    }

    componentDidUpdate(prevProps:any, prevState:any, snapshot:any) {
        if (prevProps.location.search.slice(1) !== this.props.location.search.slice(1)) {
            this.getParamsFromUrl();
        }
    }

    getParamsFromUrl(){
        const params = this.qs.parse(this.props.location.search.slice(1));
        this.setState({
            currentPage:params['page'] !== "" ? params['page'] !== undefined ? parseInt(params['page'])-1 : 0 : 0,
        }, ()=>{this.reloadEmployeeTransaction()})
    }

    handleParamsChange(currentParam: string, newValue:string){
        const qs = require('qs');
        let params = qs.parse(this.props.location.search.slice(1));
        params[currentParam] = newValue;
        const keys = Object.keys(params);
        let paramsStr = "?";
        keys.forEach((key)=>{
            paramsStr = paramsStr + key + "=" + params[key] + "&"
        })
        this.props.history.push(paramsStr);
    }

    reloadEmployeeTransaction(){
        getEmployeeTransactionServiceApi().transactionServiceGetTransactions(undefined, this.state.currentPage+1).then((response)=>{
            if (response.data.success){
                this.setState({
                    allTransactions:response.data.data?.transactions ?? [],
                    pageCount:response.data.data?.to ?? 0
                })
            } else {
                window.alert(response.data.error_message)
            }
        }).catch((result)=>{
            window.alert("Server error, please try again later.")
        }).finally(()=>{

        })
    }

    handlePageChange(selectedItem: { selected: number }): void {
        this.setState({currentPage: selectedItem.selected}, () => {
            this.reloadEmployeeTransaction();
            this.handleParamsChange("page", (selectedItem.selected + 1).toString())
        });
    }


    render() {
        const rows = this.state.allTransactions.map((transaction, index)=>{
            return <tr key={index}>
                <td>{transaction.uuid ?? "".slice(0, 4)}</td>
                <td>{this.props.userName}</td>
                <td>{transaction.key_card_number}</td>
                <td>
                    <DatetimeDisplay timestamp={transaction.timestamp ?? 0}/>
                </td>
                <td>{transaction.selection_name}</td>
                <td>{transaction.vending_machine_name}</td>
                <td>{transaction.vending_machine_site}</td>
            </tr>
        })

        return <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Transactions - " + this.props.company + " - " + this.props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">My Transaction History</h4>
                                </div>
                                <div>
                                    <DownloadTransactions/>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div>
                                    <table
                                        id="datatable"
                                        className="table table-striped table-bordered"
                                    >
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">UUID</th>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Key Card</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Vending Machine</th>
                                            <th scope="col">Location</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    pageCount={this.state.pageCount}
                                    initialPage={this.state.currentPage}
                                    onPageChange={this.handlePageChange.bind(this)}
                                    containerClassName={"pagination mb-0"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item page-link"}
                                    nextClassName={"page-item page-link"}
                                    activeClassName={"active"} forcePage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export const EmployeeDashboard = withGlobalData(_EmployeeDashboard);
