import React, { Component } from 'react';
import {getAdminAuditLogServiceAPI} from "../global/api";
import ReactPaginate from "react-paginate";
import {Helmet} from "react-helmet";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {DatetimeDisplay} from "../global/datetime_display";
import {PpeAuditLog} from "../gen";


interface Props extends IGlobalDataContext{
}

interface State {
    currentPage:number
    pageCount:number
    auditLogs: PpeAuditLog[]
    isLoading: boolean
}

class _AuditLogPage extends Component<Props, State>{
    constructor(props:Props) {
        super(props);

        this.state = {
            currentPage:0,
            pageCount:0,
            auditLogs:[],
            isLoading: false
        };
    }

    componentDidMount() {
        this.reloadAuditLog();
    }

    reloadAuditLog(){
        this.setState({
            isLoading: true
        });

        getAdminAuditLogServiceAPI().adminAuditLogServiceListAuditLogs({
            page:this.state.currentPage+1,
            per_page:50
        }).then((response)=>{
            if (response.data.success ?? false){
                this.setState({
                    auditLogs: response.data.audit_logs ?? [],
                    pageCount:response.data.number_of_page ?? 1
                })
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(()=>{
            window.alert("Server error")
        }).finally(()=>{
            this.setState({
                isLoading: false
            });
        })
    }

    handlePageChange(selectedItem: { selected: number }): void {
        this.setState({currentPage: selectedItem.selected}, () => {
            this.reloadAuditLog();
        });
    }
    render() {
        const rows = this.state.auditLogs.map((auditLog, index)=>{
            return <tr key={index}>
                <td>
                    <DatetimeDisplay timezone={this.props.timezone} timestamp={auditLog.epoch ?? 0}/>
                </td>
                <td>{auditLog.action}</td>
                <td>{auditLog.admin_name}[{auditLog.admin_email}]</td>
                <td>{auditLog.summary}</td>
            </tr>
        })
        return <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Audits - " + this.props.company + " - " + this.props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Audit Logs</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div>
                                    <table
                                        id="datatable"
                                        className="table table-striped table-bordered mt-4"
                                    >
                                        <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Action</th>
                                            <th>Admin</th>
                                            <th>Summary</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </table>
                                </div>
                                <ReactPaginate
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    pageCount={this.state.pageCount}
                                    initialPage={this.state.currentPage}
                                    onPageChange={this.handlePageChange.bind(this)}
                                    containerClassName={"pagination mb-0"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item page-link"}
                                    nextClassName={"page-item page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export const AuditLogPage = withGlobalData(_AuditLogPage)
