import React, { useEffect, useState } from 'react';
import { IGlobalDataContext, withGlobalData } from "../global/global-data";
import { getAdminProductServiceAPI } from "../global/api";
import { AddProductModal } from "./add_product_modal";
import { ProductRow } from "./product_row";
import { Helmet } from "react-helmet";
import { PpeProductInfo } from "../gen";
import ReactPaginate from "react-paginate";
import { DownloadProductCsv } from './download_product_csv';
import { UploadProductCsv } from './upload_product_csv';

interface Props extends IGlobalDataContext {
}


function _ProductPage(props: Props) {
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);

    const [adding, setAdding] = useState(false);

    const [products, setProducts] = useState<Array<PpeProductInfo>>([]);

    const [loading, setLoading] = useState(true);

    const [isShowAchievedProduct, setIsShowAchievedProduct] = useState(false);

    const handlePageChange = (selectedItem: { selected: number }) => {
        setCurrentPage(selectedItem.selected)
    }

    const toggleShowAchievedProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("Checked: " + event.target.checked);
        setIsShowAchievedProduct(event.target.checked);
    }

    function loadProducts() {
        console.log('load...');
        setLoading(true);

        getAdminProductServiceAPI().adminProductServiceListProducts({
            page: currentPage + 1,
            per_page: 100,
            only_current: !isShowAchievedProduct
        }).then(resp => resp.data).then((resp) => {
            if (resp.success) {
                setPageCount(resp.total_page ?? 1)
                setProducts(resp.products!);
            } else {
                props.pushFlashMessage({
                    cls: "error",
                    message: resp.error_message!
                });
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        loadProducts();
    }, [currentPage, isShowAchievedProduct]);

    const reload = loadProducts;

    const rows = products.map((product) => {
        return <ProductRow key={product.uuid}
            reloadAllProducts={reload}
            productInfo={product}
            pushFlashMessage={props.pushFlashMessage}
            currency_config={props.organization?.currency_config} />
    })


    return <div id="content-page" className="content-page">
        <Helmet>
            <title>{"Products - " + props.company + " - " + props.globalTitle}</title>
        </Helmet>
        <AddProductModal currency_config={props.organization?.currency_config} isOpen={adding}
            closeAddProductModal={() => {
                setAdding(false)
            }}
            reloadProduct={reload}
            pushFlashMessage={props.pushFlashMessage} />
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Products</h4>
                            </div>
                            <div className='row'>
                                <label>Show Archived Products
                                    &nbsp;&nbsp;
                                    <input type="checkbox" checked={isShowAchievedProduct}
                                        onChange={toggleShowAchievedProduct} />
                                </label>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                <span className="btn btn-primary btn-sm"
                                    onClick={() => {
                                        setAdding(true);
                                    }}>
                                    Add Product
                                </span>
                                &nbsp;&nbsp;
                                <DownloadProductCsv currency_config={props.organization?.currency_config} allProduct={products} />
                                &nbsp;&nbsp;
                                <UploadProductCsv currency_config={props.organization?.currency_config} reloadProduct={reload}
                                    pushFlashMessage={props.pushFlashMessage} />
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <div>

                                {loading ?
                                    <h2>Loading...</h2>
                                    :
                                    <table
                                        id="datatable"
                                        className="table table-striped table-bordered mt-4"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="w-25">Name</th>
                                                <th className={"w-25"}>Picture</th>
                                                {/*<th className="w-25">Access Group</th>*/}
                                                <th className={"w-25"}>Restrictions</th>
                                                <th className={"w-25"}>Tags</th>
                                                <th className={"w-25"}>Value</th>
                                                <th className="w-25">Operation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows}
                                        </tbody>
                                    </table>
                                }
                            </div>

                            <ReactPaginate
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                initialPage={currentPage}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination mb-0"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item page-link"}
                                nextClassName={"page-item page-link"}
                                activeClassName={"active"} forcePage={currentPage}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

}


export const ProductPage = withGlobalData(_ProductPage)
