import React, { Component } from 'react';
import { Message } from "../global/models";
import { getAdminProductServiceAPI } from "../global/api";
import { EditProductModal } from "./edit_product_modal";
import serviceNowIcon from "../servicenow-icon.png";
import { Link } from "react-router-dom";
import { Ppeemployerv1ProductInfo, V1CurrencyConfig } from "../gen/typescript-axios";

import plur from 'plur';
import { sortTagsByName } from '../custom_select_dropdown/custom_select_dropdown';
import CurrencyFormatter from '../util/currencyFormatter';

interface Props {
    productInfo: Ppeemployerv1ProductInfo

    reloadAllProducts(): void

    pushFlashMessage(message: Message): void;

    currency_config: V1CurrencyConfig | undefined
}

interface State {
    isEditModalOpen: boolean
}

class ProductRow extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditModalOpen: false
        };
    }

    handleCloseModal() {
        this.setState({ isEditModalOpen: false })
    }

    handleOpenModal() {
        this.setState({ isEditModalOpen: true })
    }

    removeProduct(e: React.MouseEvent<HTMLButtonElement>, uuid: string, name: string) {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm(`Are you sure to delete product ${name}`)) {
            getAdminProductServiceAPI().adminProductServiceArchiveProduct(uuid, {}).then(response => {
                if (response.data.success) {
                    this.props.reloadAllProducts();
                    this.props.pushFlashMessage(new Message("Product Archived", "success"))
                } else {
                    this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch(() => {
                this.props.pushFlashMessage(new Message("Server error", "error"))
            })
        }
    }

    render() {
        const inspectionInterval = this.props.productInfo.inspection_interval ?? 0

        return <tr key={this.props.productInfo.uuid} className={this.props.productInfo.is_current ? "" : "table-danger"}>
            <EditProductModal currency_config={this.props.currency_config} isOpen={this.state.isEditModalOpen}
                closeEditProductModal={this.handleCloseModal.bind(this)}
                productInfo={this.props.productInfo}
                reloadAllProducts={this.props.reloadAllProducts.bind(this)}
                pushFlashMessage={this.props.pushFlashMessage.bind(this)} />
            <td className="col-3">
                {this.props.productInfo.servicenow_sysid &&
                    <span>
                        <img src={serviceNowIcon} width={"30px"} title={"ServiceNow product"} alt="ServiceNow icon" />
                        &nbsp;
                    </span>}
                {this.props.productInfo.name}

                {this.props.productInfo.is_trackable &&
                    <div title={
                        `${this.props.productInfo.name} is a trackable item. Each trackable item is differentiated.`
                    }>
                        <i className={"la la-binoculars"}></i> Trackable
                    </div>
                }
                {this.props.productInfo.is_trackable && (this.props.productInfo.inspection_interval ?? 0) > 0 &&
                    <div>
                        <i className="las la-tools"></i> Inspection
                        after {inspectionInterval} {plur('use', inspectionInterval)}
                    </div>
                }
                <br />
                {this.props.productInfo.manufacturer !== '' && <>
                    manufacturer: {this.props.productInfo.manufacturer} <br />
                </>}
                {this.props.productInfo.manufacturer_code !== '' && <>
                    manufacturerCode: {this.props.productInfo.manufacturer_code} <br />
                </>}
                {this.props.productInfo.supplier !== '' && <>
                    supplier: {this.props.productInfo.supplier} <br />
                </>}
                {this.props.productInfo.supplier_code !== '' && <>
                    supplierCode: {this.props.productInfo.supplier_code} <br />
                </>}
            </td>
            <td className="col-2">
                {this.props.productInfo.photo_url != null &&
                    <img src={this.props.productInfo.photo_url} alt="" width={"80"} />}
            </td>
            <td className="col-2">
                <Link title={"Add/Remove restrictions on this product"}
                    to={`/products/${this.props.productInfo.uuid}/restrictions`}>{this.props.productInfo.is_restricted ?
                        "Restricted" :
                        <span><i className="las la-edit"></i> No Restrictions</span>}
                </Link>
            </td>
            <td className="col-2">
                {
                    this.props.productInfo.tags && this.props.productInfo.tags.length > 0
                        ? sortTagsByName(this.props.productInfo.tags).map(tag => tag.name).join(', ')
                        : ''
                }
            </td>
            <td className="col-2">
                <>
                    <CurrencyFormatter priceInCents={this.props.productInfo.value_in_cents} currencyConfig={this.props.currency_config} />
                </>
            </td>
            <td>
                <div className="col-3" style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                    <button className="btn btn-warning" onClick={this.handleOpenModal.bind(this)}>Edit</button>
                    {this.props.productInfo.is_current && (
                        <button
                            className="btn btn-danger"
                            onClick={(e) => {
                                this.removeProduct(e, this.props.productInfo.uuid ?? "", this.props.productInfo.name ?? "");
                            }}
                        >
                            Archive
                        </button>
                    )}
                </div>
            </td>
        </tr>
    }
}

export { ProductRow }
