import React, {useEffect, useState} from "react";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {getAdminSelfServiceApi, getAdminServiceNowServiceApi} from "../global/api";
import {PpeServiceNowUserGroup, PpeUserDelegationSyncJob} from "../gen";
import Select from "react-select";

interface Props extends IGlobalDataContext {
}

interface GroupSelection {
    label: string
    value: string
}

function SyncJobProgress(props: { syncJob: PpeUserDelegationSyncJob }) {
    useEffect(() => {
        const timer = window.setInterval(function () {
        }, 1000)

        return () => {
            window.clearInterval(timer);
        };
    }, [props.syncJob.uuid]);

    return <div>OK</div>
}

function _serviceNowSyncUsersCard(props: Props) {
    const [loading, setLoading] = useState(true);

    const [serviceNowGroups, setServiceNowGroups] = useState<Array<PpeServiceNowUserGroup>>([]);

    const [pullingServiceNowUserGroups, setPullingServiceNowUserGroups] = useState(false);

    const [userGroups, setUserGroups] = useState<Array<GroupSelection>>([]);
    const [adminGroups, setAdminGroups] = useState<Array<GroupSelection>>([]);

    const [currentSyncJob, setCurrentSyncJob] = useState<PpeUserDelegationSyncJob | null>(null);

    function reload() {
        getAdminSelfServiceApi().adminSelfServiceServiceListServiceNowGroup().then((response) => {
            return response.data;
        }).then((response) => {
            if (response.success) {
                // @ts-ignore
                setServiceNowGroups(response.user_groups);

                // @ts-ignore
                const userGroups = response.user_groups.filter((entry) => {
                    return entry.included_for_users && entry.uuid
                }).map((entry) => {
                    return {
                        label: entry.name as string,
                        value: entry.uuid as string
                    }
                })
                setUserGroups(userGroups);

                // @ts-ignore
                setAdminGroups(response.user_groups.filter((entry) => {
                    return entry.included_for_admin && entry.uuid
                }).map((entry) => {
                    return {
                        label: entry.name as string,
                        value: entry.uuid as string
                    }
                }));
            } else {
                setServiceNowGroups([]);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        reload();
    }, []);

    const allGroupOptions = serviceNowGroups.map((group) => {
        return {
            value: group.uuid as string,
            label: group.name as string
        }
    })

    function refreshServiceNowUserGroups() {
        setPullingServiceNowUserGroups(true);

        getAdminSelfServiceApi().adminSelfServiceServiceSyncServiceNowGroup({}).then((response) => {
            return response.data
        }).then((response) => {
            if (response.success) {
                reload();
            } else {
                window.alert(response.message);
            }
        }).finally(() => {
            setPullingServiceNowUserGroups(false);
        })
    }

    const [saveAndSyncInProgress, setSaveAndSyncInProgress] = useState(false);

    function saveSerivceNowUserGroupAssociation(e: any) {
        e.preventDefault();

        getAdminServiceNowServiceApi().adminServiceNowServiceUpdateUsersServiceNowDelegation({
            user_team_uuid: userGroups.map(ug => ug.value),
            admin_team_uuid: adminGroups.map(ug => ug.value)
        }).then((response) => {
            return response.data
        }).then((response) => {
            if (response.success) {
                setCurrentSyncJob(response.sync_job as PpeUserDelegationSyncJob);
            } else {
                window.alert(response.message);
            }
        }).finally(() => {
            setSaveAndSyncInProgress(false);
        })
    }

    function changeUserGroups(options: any) {
        // debugger;
        const selected = [] as Array<GroupSelection>;

        const uuids = allGroupOptions.map(entry => entry.value);

        // @ts-ignore
        options.forEach((option) => {
            console.log(option);

            if (uuids.indexOf(option.value) !== -1) {
                selected.push(option);
            }
        })
        // debugger;

        setUserGroups(selected);
    }

    function changeAdminGroups(options: any) {
        const selected = [] as Array<GroupSelection>;

        const uuids = allGroupOptions.map(entry => entry.value);
        // @ts-ignore
        options.forEach((option) => {
            if (uuids.indexOf(option.value) !== -1) {
                selected.push(option);
            }
        })

        setAdminGroups(selected);
    }

    return (
        <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">ServiceNow Integration - Users Sync</h4>
                </div>
            </div>
            <div className="iq-card-body">
                {pullingServiceNowUserGroups ?
                    <span>Loading...</span>
                    :
                    <button onClick={refreshServiceNowUserGroups} className={"btn btn-info"}>
                        Refresh ServiceNow user groups
                    </button>
                }

                <hr/>

                {loading ?
                    "loading..." :
                    <div>
                        <div>
                            <h5>Users Groups</h5>

                            <Select value={userGroups} options={allGroupOptions} isMulti={true}
                                    onChange={changeUserGroups}
                                    closeMenuOnSelect={false}/>

                            <h5>Administrator Groups</h5>
                            <Select value={adminGroups} options={allGroupOptions} isMulti={true}
                                    onChange={changeAdminGroups}
                                    closeMenuOnSelect={false}/>
                            <hr/>

                            <form onSubmit={saveSerivceNowUserGroupAssociation}>
                                {saveAndSyncInProgress ?
                                    <span>Saving...</span>
                                    :
                                    <input type={"submit"} className={"btn btn-info"} value={"Save and Sync"}/>
                                }
                            </form>

                            {currentSyncJob &&
                            <SyncJobProgress syncJob={currentSyncJob}/>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}


export const ServiceNowSyncUsersCard = withGlobalData(_serviceNowSyncUsersCard);
