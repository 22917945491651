import React, { useState } from 'react';
import Modal from "react-modal";
import { V1AdminTag } from "../gen/typescript-axios";
import { getAdminTagServiceAPI } from "../global/api";
import { IGlobalDataContext, withGlobalData } from "../global/global-data";
import BusyIndicator, { LoadingSpanner } from "../busy_indicator";
import { Message } from '../global/models';

interface Props extends IGlobalDataContext {
    isOpen: boolean
    tag: V1AdminTag | null
    closeModal(): void
    reloadTags(): void
}


function _CreateOrEditTagModal(props: Props) {
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [adminNote, setAdminNote] = useState<string>(props.tag ? props.tag.admin_note ?? "-" : "");
    const [name, setName] = useState<string>(props.tag ? props.tag.name ?? '-' : "");


    const handleCodeChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setName(e.currentTarget.value);
    }

    const onModelClose = () => {
        setName("");
        setAdminNote("");
        props.closeModal();
    }

    const handleAdminNoteChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setAdminNote(e.currentTarget.value);
    }

    const createTag = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (isSaving) {
            return;
        }
        setIsSaving(true);
        getAdminTagServiceAPI().adminTagServiceCreateTag({
            name: name,
            admin_note: adminNote,
        }).then((response) => {
            if (response.data.success ?? false) {
                props.reloadTags();
                onModelClose();
                props.pushFlashMessage(new Message("Tag created", "success"))
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            props.pushFlashMessage(new Message("Server error", "error"))
        }).finally(() => {
            setIsSaving(false);
        })
    }

    const editTage = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (isSaving) {
            return;
        }
        setIsSaving(true);
        getAdminTagServiceAPI().adminTagServiceUpdateTag(props.tag?.uuid ?? "", {
            name: name,
            admin_note: adminNote,
        }).then((response) => {
            if (response.data.success ?? false) {
                props.reloadTags();
                onModelClose();
                props.pushFlashMessage(new Message("Tag Updated", "success"))
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            props.pushFlashMessage(new Message("Server error", "error"))
        }).finally(() => {
            setIsSaving(false);
        })
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%'
        }
    };

    Modal.setAppElement('#root');
    return (
        <Modal isOpen={props.isOpen}
            style={customStyles}
            onRequestClose={onModelClose}
        >
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">{props.tag ? 'Edit Tag' : 'Create Tag'}</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>{props.tag ? 'Please edit the name of the new Tag ' + props.tag.name : 'Please enter the Name of the new Tag'}</p>
                <form>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" onChange={handleCodeChange} className="form-control"
                            value={name} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Admin Note:</label>
                        <textarea onChange={handleAdminNoteChange} className="form-control" value={adminNote}>

                        </textarea>
                    </div>
                    <BusyIndicator busy={isSaving} busyIndicator={<LoadingSpanner />}>
                        <button onClick={props.tag ? editTage : createTag} className="btn btn-primary">{props.tag ? "Update" : "Save"}</button>
                    </BusyIndicator> &nbsp;
                    <button type="button" className="btn iq-bg-danger"
                        onClick={onModelClose}>Cancel
                    </button>
                </form>
            </div>

        </Modal>
    );
}

export const CreateOrEditTagModal = withGlobalData(_CreateOrEditTagModal);
