import React, {useState} from 'react';
import Modal from "react-modal";
// import {PpeJobStatus} from "../gen";
import {V1JobStatus} from "../gen/typescript-axios";
import {getAdminJobServiceAPI} from "../global/api";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {Message} from "../global/models";
import BusyIndicator, {LoadingSpanner} from "../busy_indicator";

interface Props extends IGlobalDataContext{
    isOpen: boolean
    closeModal(): void
    reloadJobs():void
}


function _CreateJobModal(props:Props) {
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [jobCode, setJobCode] = useState<string>("");
    const [jobStatus, setJobStatus] = useState<V1JobStatus>(V1JobStatus.JobEnabled);
    const [adminNote, setAdminNote] = useState<string>("");

    const handleCodeChange = (e:React.SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setJobCode(e.currentTarget.value);
    }

    const handleJobStatusChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setJobStatus(e.currentTarget.value as V1JobStatus)
    }

    const handleAdminNoteChange = (e:React.SyntheticEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setAdminNote(e.currentTarget.value);
    }

    const onModelClose = () => {
        setJobCode("");
        setJobStatus(V1JobStatus.JobEnabled);
        setAdminNote("");
        props.closeModal();
    }

    const createJob = (e:React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (isSaving){
            return;
        }
        setIsSaving(true);
        getAdminJobServiceAPI().adminJobServiceCreateJob({
            job_code: jobCode,
            job_status: jobStatus,
            admin_note: adminNote
        }).then((response)=>{
            if (response.data.success ?? false){
                props.reloadJobs();
                onModelClose();
                props.pushFlashMessage(new Message("Job created", "success"))
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(()=>{
            props.pushFlashMessage(new Message("Server error", "error"))
        }).finally(()=>{
            setIsSaving(false);
        })
    }



    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%'
        }
    };
    Modal.setAppElement('#root');
    return (
        <Modal isOpen={props.isOpen}
               style={customStyles}
               onRequestClose={onModelClose}
        >
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">New Job</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <p>Please enter the detail of new job</p>
                <form>
                    <div className="form-group">
                        <label htmlFor="name">Job Code:</label>
                        <input type="text" onChange={handleCodeChange} className="form-control"
                               value={jobCode}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Job Status:</label>
                        <select className="form-control"
                                value={jobStatus}
                                onChange={handleJobStatusChange}>
                            <option value={V1JobStatus.JobDisabled}>Disable</option>
                            <option value={V1JobStatus.JobEnabled}>Enable</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Admin Note:</label>
                        <textarea onChange={handleAdminNoteChange} className="form-control"
                               value={adminNote}/>
                    </div>

                    <BusyIndicator busy={isSaving} busyIndicator={<LoadingSpanner/>}>
                        <button onClick={createJob} className="btn btn-primary">Save</button>
                    </BusyIndicator> &nbsp;
                    <button type="button" className="btn iq-bg-danger"
                            onClick={onModelClose}>Cancel
                    </button>
                </form>
            </div>

        </Modal>
    );
}

export const CreateJobModal = withGlobalData(_CreateJobModal);
