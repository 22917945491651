import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import {PpeTransactionsCountByProduct} from "../gen";

interface Props {
    transactionCountByProduct: PpeTransactionsCountByProduct[]
    isLoading: boolean
}

interface State {
}

class DispensingPieChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        let data : (string | number)[][] = [['Item', 'Count']]
        this.props.transactionCountByProduct.forEach((_productStats)=>{
            data.push([_productStats.product ?? "", _productStats.count ?? ""])
        })
        if (this.props.isLoading){
            return <div>loading</div>
        }
        return (
            <Chart
                width={'500px'}
                height={'350px'}
                chartType="PieChart"
                data={data}
            />
        );
    }
}

export {DispensingPieChart}
