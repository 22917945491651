import cronstrue from 'cronstrue';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { getAdvancedReporting } from "../global/api";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { PpeScheduledReport, PpeReportTemplate } from "../gen";
import { withGlobalData } from '../global/global-data';

function ReportRow(props: { deleteReport: (uuid: string) => void, report: PpeScheduledReport, templates: PpeReportTemplate[] }) {
    const report = props.report;
    const templates = props.templates;

    const [running, setRunning] = useState(false);
    let explain = "-";

    const template = templates.find((template) => template.uuid === report.template_uuid)?.name ?? "-";

    try {
        explain = cronstrue.toString(report.cron ?? "", { verbose: true });
    } catch (e) {
        console.error(e);
        explain = "Invalid format";
    }

    function runScheduledReport() {
        setRunning(true);

        getAdvancedReporting().advancedReportingServiceManualExecuteScheduledReport({
            scheduled_report_uuid: report.uuid ?? ""
        }).then(() => {
            window.alert("Report is scheduled successfully")
        }).catch((e) => {
            window.alert(`Failed to run report: ${e}`);
        }).finally(() => {
            setRunning(false);
        });
    }

    return <tr key={report.uuid}>
        <td>{report.name}</td>
        <td>{report.email_subject}<br />{template}</td>
        <td><pre>{report.cron}</pre><br />Explanation: {explain}<br />Timezone: {report.timezone}</td>
        <td>{(report.emails ?? []).join(', ')}</td>
        <td>{(moment(report.created_at).toISOString())}</td>
        <td>-</td>
        <td>
            {
                report?.day ? "Last Day" :
                    report?.seven_days ? "Last 7 Days" :
                        report?.sunday_week ? "Sunday Week" :
                            report?.monday_week ? "Monday Week" :
                                report?.last_28_days ? "Last 28 Days" :
                                    report?.last_calendar_month ? "Last Calendar Month" :
                                        "N/A"
            }
        </td>
        <td>
            <button onClick={(e) => {
                e.preventDefault();
                if (!window.confirm("Are you sure?")) {
                    return;
                }
                props.deleteReport(report.uuid ?? "");
            }} className="btn btn-danger">Delete</button>
            |
            <Link to={{
                pathname: `/scheduled-reports/edit/${report.uuid}`,
                state: { report }
            }} className="btn btn-warning">Edit</Link>
            |
            <button disabled={running} onClick={(e) => {
                e.preventDefault();
                runScheduledReport();
            }} className="btn btn-primary">Run</button>
        </td>
    </tr>
}

function _ScheduledReports() {
    const [busy, setBusy] = useState<boolean>(false);
    const [reports, setReports] = useState<PpeScheduledReport[]>([]);
    const [templates, setTemplates] = useState<PpeReportTemplate[]>([]);

    const refresh = () => {
        setBusy(true);
        getAdvancedReporting().advancedReportingServiceListScheduledReports({}).then((response) => {
            setReports(response.data.reports ?? []);
        }).catch((e) => {
            window.alert(`Failed to load scheduled reports: ${e}`);
        }).finally(() => {
            setBusy(false);
        });

        getAdvancedReporting().advancedReportingServiceListTemplates({}).then((response) => {
            setTemplates(response.data.templates ?? []);
        }).catch((e) => {
            window.alert(`Failed to load templates: ${e}`);
        }).finally(() => { });
    };

    useEffect(() => {
        refresh();
    }, []);

    const deleteReport = (uuid: string) => {
        getAdvancedReporting().advancedReportingServiceRemoveScheduledReport({
            scheduled_report_uuid: uuid
        }).then(() => {
            refresh();
        }).catch((e) => {
            window.alert(`Failed to delete report: ${e}`);
        });
    };

    const rows = reports.map((report) => {
        return <ReportRow key={report.uuid} report={report} deleteReport={deleteReport} templates={templates} />
    });

    if (busy) {
        return <div id="content-page" className="content-page"><h1>Loading</h1></div>;
    }

    return <div id="content-page" className="content-page">
        <Helmet>
            <title>Scheduled Reports</title>
        </Helmet>
        <div className="container-fluid">
            <div className={"iq-card"}>
                <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                        <h4 className="card-title">Scheduled Reports</h4>
                    </div>
                    <div className="iq-card-header-toolbar d-flex align-items-center">
                        <Link to={"/scheduled-reports/templates"} className="btn btn-info mr-2">Manage Report Templates</Link>
                        <button onClick={() => { window.location.href = "/scheduled-reports/new"; }} className="btn btn-primary">New Report</button>
                    </div>
                </div>
                <div className="iq-card-body">
                    <div className={"alert alert-danger"}>
                        <b>Note &nbsp;&nbsp;</b>
                        Advanced reporting feature is currently available for feedback and evaluation. It's still in Work in Progress stage, and it's subject to changes without notice. It will be available to all organizations in Advanced Plan after the evaluation period.
                    </div>
                    {rows.length > 0 ? (
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email Subject</th>
                                    <th>Schedule</th>
                                    <th>Recipients</th>
                                    <th>Created Time</th>
                                    <th>Last Running Time</th>
                                    <th>Cutoff</th>
                                    <th>Operation</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    ) : (
                        <p>No scheduled reports available.</p>
                    )}
                </div>
            </div>
        </div>
    </div>;
}

export const ScheduledReports = withGlobalData(_ScheduledReports);
