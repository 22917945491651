import React, { Component } from 'react';
import Modal from "react-modal";
import {
    getAdminProductServiceAPI,
    getAdminVendingMachineServiceAPI,
} from "../../global/api";
import {Message} from "../../global/models";
import {AsyncTypeahead, Highlighter, Typeahead} from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {PpeProductInfo, PpeTrackableItem} from "../../gen";
import {Ppeemployerv1ProductMapInfo} from "../../gen/typescript-axios";
import moment from "moment-timezone";
import {IGlobalDataContext, withGlobalData} from "../../global/global-data";

interface Props extends IGlobalDataContext{
    isOpen: boolean
    closeEditProductMapModal():void
    reloadProductMap():void
    productMap:Ppeemployerv1ProductMapInfo
    pushFlashMessage(message: Message): void;
    allTrackableItems:PpeTrackableItem[]|undefined
}

interface State {
    productUuid : string | null;
    mdbCode : number
    lowStockAlert:number|undefined
    capacity : number
    restockCount : number
    effectFrom:Date,
    saving: boolean
    selectedProduct: PpeProductInfo | null
    productOptions: PpeProductInfo[]
    isproductOptionsLoading: boolean
    selectedTrackableItem:PpeTrackableItem|null
}

class _EditProductMapModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            productUuid : this.props.productMap.product?.uuid || null,
            mdbCode: this.props.productMap.mdb_code as number,
            lowStockAlert:this.props.productMap.low_stock_alert,
            capacity: this.props.productMap.capacity as number,
            restockCount: this.props.productMap.restock_count as number,
            effectFrom:new Date(),
            saving: false,
            selectedProduct: null,
            productOptions: [],
            isproductOptionsLoading: false,
            selectedTrackableItem:this.props.productMap.trackable_item as PpeTrackableItem || null,
        };
    }


    componentDidMount() {
        this.getProductByUuid();
    }

    closeModal(){
        this.setState({
            productUuid : this.props.productMap.product?.uuid || null,
            mdbCode: this.props.productMap.mdb_code as number,
            lowStockAlert:this.props.productMap.low_stock_alert,
            capacity: this.props.productMap.capacity as number,
            restockCount: this.props.productMap.restock_count as number,
            selectedTrackableItem:this.props.productMap.trackable_item as PpeTrackableItem || null,
        })
        this.props.closeEditProductMapModal()
    }

    getProductByUuid(){
        if (this.state.productUuid !== "" && this.state.productUuid !== null){
            getAdminProductServiceAPI().adminProductServiceGetProduct(this.state.productUuid).then((response)=>{
                if (response.data.success){
                    this.setState({
                        selectedProduct:response.data.product ?? null
                    })
                } else {
                    window.alert(response.data.error_message)
                }
            }).catch(() => {
                window.alert("Server error")
            })
        }
    }


    updateProductMap(e:any){
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            saving:true
        })
        getAdminVendingMachineServiceAPI().adminVendingMachineServiceUpdateProductMap(this.props.productMap.vending_machine_uuid as string, this.props.productMap.uuid as string, {
            product_uuid:this.state.productUuid ?? "",
            capacity:this.state.capacity ?? 0,
            restock_count:this.state.restockCount ?? 0,
            low_stock_alert:this.state.lowStockAlert ?? 0,
            // effectFrom is a js data object which returned from datepicker, it does not will use the browser's timezone.
            // if the browser's timezone differs to the admin's timezone, we force the system to use admin's timezone
            effect_timestamp :  moment(this.state.effectFrom).tz(this.props.timezone, true).unix(),
            trackable_item_uuid:this.state.selectedTrackableItem?.uuid
        }).then(response => {
            if (response.data.success) {
                this.props.closeEditProductMapModal();
                this.props.reloadProductMap();
                this.props.pushFlashMessage(new Message("Product Map Updated", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Server Error", "error"))
        }).finally(()=>{
            this.setState({
                saving:false
            })
        })
    }

    onProductChange(products: Array<PpeProductInfo>) {
        if (products.length > 0) {
            this.setState({productUuid: products[0].uuid ?? null, selectedProduct:products[0]});
        } else {
            this.setState({productUuid:null, selectedProduct: null})
        }
        this.setState({ selectedTrackableItem: null})
    }

    onMdbCodeChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({mdbCode:parseInt(e.target.value)})
    }

    onLowStockAlertChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({lowStockAlert:parseInt(e.target.value)})
    }

    onCapacityChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({capacity:parseInt(e.target.value)})
    }

    onRestockCountChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({restockCount:parseInt(e.target.value)})
    }

    handleDateChange(date:Date){
        this.setState({effectFrom:date})
    }

    onSearchProduct(query:string){
        this.setState({isproductOptionsLoading: true});
        getAdminProductServiceAPI().adminProductServiceSearchProducts({
            only_current:true,
            term: query,
            per_page:10000,
            page: 1
        }).then((response)=>{
            if (response.data.success){
                this.setState({
                    productOptions:response.data.products ?? []
                })
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(() => {
            window.alert("Server error")
        }).finally(()=>{
            this.setState({
                isproductOptionsLoading:false,
            })
        })
    }

    handleTrackableItemChange(selectTrackableItems:Array<PpeTrackableItem>){
        if (selectTrackableItems.length > 0) {
            this.setState({ selectedTrackableItem:selectTrackableItems[0]});
        } else {
            this.setState({ selectedTrackableItem: null})
        }
    }

    render() {
        const customStyles = {
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)',
                width:'50%'
            }
        };
        Modal.setAppElement('#root');

        const renderProductItemChildren = (option: PpeProductInfo, props:any) => {
            return [
                <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
            ];
        };

        const selectedProduct = this.state.selectedProduct ? [this.state.selectedProduct] : [];
        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.closeModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Edit Product Map</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <form>
                    <div className="form-group">
                        <label>Product Name:*</label>
                        <AsyncTypeahead
                            id={"typeahead"}
                            isLoading={this.state.isproductOptionsLoading}
                            labelKey={(option)=>{
                                return option.name ?? ""
                            }}
                            onSearch={this.onSearchProduct.bind(this)}
                            selected={selectedProduct}
                            onChange={this.onProductChange.bind(this)}
                            options={this.state.productOptions}
                            renderMenuItemChildren={renderProductItemChildren}
                            clearButton
                        />
                    </div>

                    {this.state.selectedProduct?.is_trackable && this.props.allTrackableItems && <div className="form-group" >
                        <label>Trackable Item:*</label>
                        <Typeahead id={"trackable item"}
                                   onChange={this.handleTrackableItemChange.bind(this)}
                                   defaultInputValue={this.props.productMap.trackable_item ? this.props.productMap.trackable_item?.name as string + " [" +this.props.productMap.trackable_item?.state + "]" : ""}
                                   labelKey={(trackableItem)=>{
                                       if(trackableItem !== undefined){
                                           return trackableItem.name as string + " [" + trackableItem.state + "]"
                                       }else{
                                           return ""
                                       }
                                   }}
                                   options={this.props.allTrackableItems}
                                   filterBy={(trackableItem) => {
                                       return trackableItem.product_uuid === this.state.productUuid
                                   }}
                        />
                    </div>}


                    <div className="form-group">
                        <label>MDB Code:*</label>
                        <input type="number" className={"form-control"} value={this.state.mdbCode} onChange={this.onMdbCodeChange.bind(this)} disabled={true}/>
                    </div>
                    <div className="form-group">
                        <label>Low Stock Alert:*</label>
                        <input type="number" className={"form-control"} value={this.state.lowStockAlert} onChange={this.onLowStockAlertChange.bind(this)} />
                    </div>
                    <div className="form-group">
                        <label>Capacity:*</label>
                        <input type="number" className={"form-control"} value={this.state.capacity} onChange={this.onCapacityChange.bind(this)}/>
                    </div>
                    <div className="form-group">
                        <label>Restock Count:*</label>
                        <input type="number" className={"form-control"} value={this.state.restockCount} onChange={this.onRestockCountChange.bind(this)}/>
                    </div>
                    <div className="form-group">
                        <label>Effect From - (Timezone: {this.props.timezone}):</label>
                        <div style={{display:"block"}}>
                            <DatePicker className={"form-control"}
                                        selected={this.state.effectFrom}
                                        onChange={this.handleDateChange.bind(this)}
                                        dateFormat="dd/MM/yyyy H:mm"
                                        showTimeInput
                                        wrapperClassName={"datePicker"}
                                        maxDate={new Date()}
                            />
                        </div>
                    </div>
                    <button onClick={this.updateProductMap.bind(this)} className="btn btn-primary">Save</button>
                    <button type="button" className="btn iq-bg-danger"
                            onClick={this.closeModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export const EditProductMapModal = withGlobalData(_EditProductMapModal)
