import React, {Component} from 'react';
import Modal from "react-modal";
import {Tag, WithContext as ReactTags} from 'react-tag-input';
import {getAdminCompanyInformationServiceAPI} from "../global/api";
import {Message} from "../global/models";
import {V1Employee, V1AdminTag} from "../gen/typescript-axios";
import {HidCardInput, HidSettings} from "../hid";
import {hasSpecialCharacters} from '../has_special_characters';
import CustomSelectDropdown from '../custom_select_dropdown/custom_select_dropdown';

interface Props {
    isOpen: boolean

    hidSettings: HidSettings

    closeEditEmployeeModal(): void

    employee: V1Employee

    pushFlashMessage(message: Message): void;

    reloadAllEmployee(): void

}

interface State {
    newEmployeeName: string
    newEmployeeNumber: string
    newDepartment: string
    newKeycards: Tag[],
    newSecondaryEmails: Tag[]
    newAccessCodes: Tag[],
    newEmployeeEmail: string,
    selectedTags: V1AdminTag[],
    isSaving: boolean
}

class EditEmployeeModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newDepartment: this.props.employee.department ?? "",
            newEmployeeName: this.props.employee.full_name ?? "",
            newEmployeeNumber: this.props.employee.employee_number ? this.props.employee.employee_number : "",
            newKeycards: this.props.employee.key_cards!.map((keycard) => {
                return {id: keycard, text: keycard}
            }),
            newSecondaryEmails: this.props.employee.secondary_emails!.map((secondaryEmail) => {
                return {id: secondaryEmail, text: secondaryEmail}
            }),
            newEmployeeEmail: this.props.employee.email ? this.props.employee.email : "",
            newAccessCodes: (this.props.employee.access_codes ?? []).map((code) => {
                return {id: code, text: code}
            }),
            selectedTags: this.props.employee.tags ?? [],
            isSaving: false
        };
    }


    handleAccessCodeAddition(tag: Tag) {
        const rawAccessCode = tag['id'];
        const tmp = rawAccessCode.split(":");
        let _tag = {id: tmp[0], text: tmp[0]}
        this.setState({newAccessCodes: [...this.state.newAccessCodes, _tag]}, () => {
            console.log(this.state.newAccessCodes)
        })
    }

    handleAccessCodeDelete(i: number) {
        const newAccessCodes = this.state.newAccessCodes;
        this.setState({
            newAccessCodes: newAccessCodes.filter((tag, index) => index !== i),
        });
    }

    handleCloseModal() {
        this.setState({
            newEmployeeName: this.props.employee.full_name ?? "",
            newEmployeeNumber: this.props.employee.employee_number ? this.props.employee.employee_number : "",
            newKeycards: this.props.employee.key_cards!.map((keycard) => {
                return {id: keycard, text: keycard}
            }),
            newEmployeeEmail: this.props.employee.email ? this.props.employee.email : "",
            selectedTags: this.props.employee.tags ?? [],
        })
        this.props.closeEditEmployeeModal()
    }

    handleEmployeeNameChange(event: any) {
        this.setState({newEmployeeName: event.target.value})
    }

    handleEmployeeNumberChange(event: any) {
        this.setState({newEmployeeNumber: event.target.value})
    }

    handleDepartmentChange(event: any) {
        this.setState({newDepartment: event.target.value})
    }

    handleEmployeeEmailChange(event: any) {
        this.setState({newEmployeeEmail: event.target.value})
    }

    handleHidAdd(cardNumber: string) {
        this.setState({newKeycards: [...this.state.newKeycards, {id: cardNumber, text: cardNumber}]});
    }

    handleAddition(tag: Tag) {
        const rawCardNumber = tag['id'];
        const tmp = rawCardNumber.split(":");
        let _tag = null;
        if (tmp.length === 1) {
            _tag = {id: tmp[0], text: tmp[0]}
        } else {
            _tag = {id: tmp[1].slice(0, 6), text: tmp[1].slice(0, 6)}
        }

        const uppercaseTag = {
            id: _tag.id,
            text: _tag.text.toUpperCase(),
        }

        this.setState({newKeycards: [...this.state.newKeycards, uppercaseTag]}, () => {console.log(this.state.newKeycards)})
    }

    handleSecondaryEmailAddition(tag: Tag) {
        const rawEmail = tag['id'];
        const tmp = rawEmail.split(":");
        let _tag = {id: tmp[0], text: tmp[0]}
        this.setState({newSecondaryEmails: [...this.state.newSecondaryEmails, _tag]}, () => {console.log(this.state.newSecondaryEmails)})
    }

    handleDelete(i: number) {
        const newKeycards = this.state.newKeycards;
        this.setState({
            newKeycards: newKeycards.filter((tag, index) => index !== i),
        });
    }

    handleSecondaryEmailDelete(i: number) {
        const newSecondaryEmails = this.state.newSecondaryEmails;
        this.setState({
            newSecondaryEmails: newSecondaryEmails.filter((tag, index) => index !== i),
        });
    }

    updateEmployee(event: any) {
        event.preventDefault();
        event.stopPropagation();

        if (hasSpecialCharacters(this.state.newEmployeeName, this.state.newEmployeeNumber)) {
            this.props.pushFlashMessage(new Message("Special characters are not allowed", "error"));
            return;
        }

        const keycards = this.state.newKeycards.map((tag) => {
            return tag["id"]
        })
        const secondaryEmails = this.state.newSecondaryEmails.map((tag) => {
            return tag["id"]
        })
        this.setState({
            isSaving: true
        })
        getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceUpdateEmployee(this.props.employee.uuid ?? "", {
            name: this.state.newEmployeeName,
            employee_number: this.state.newEmployeeNumber,
            email: this.state.newEmployeeEmail,
            secondary_emails: secondaryEmails,
            key_cards: keycards,
            access_codes: this.state.newAccessCodes.map((tag) => tag.id),
            department: this.state.newDepartment,
            tag_uuids: this.state.selectedTags.length > 0
                ? this.state.selectedTags.map((tag) => tag.uuid).filter(uuid => uuid !== undefined) as string[]
                : []
        }).then(response => {
            if (response.data.success) {
                this.props.closeEditEmployeeModal()
                this.props.reloadAllEmployee();
                this.props.pushFlashMessage(new Message("Employee Updated", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
            }
        }).catch(reason => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
        }).finally(() => {
            this.setState({
                isSaving: false
            })
        })
    }

    handleSelectionChange(newSelectedTags: V1AdminTag[]) {
        if (newSelectedTags !== this.state.selectedTags) {
            this.setState({selectedTags: [...newSelectedTags]});
        }
    }

    render() {
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%'
            }
        };
        Modal.setAppElement('#root');
        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.handleCloseModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Edit Employee</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <form>
                    <div className="form-group">
                        <label htmlFor="name">Employee Name:*</label>
                        <input type="text" onChange={this.handleEmployeeNameChange.bind(this)} className="form-control"
                               value={this.state.newEmployeeName} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="employee_number">Employee Number:</label>
                        <input type="text" onChange={this.handleEmployeeNumberChange.bind(this)}
                               className="form-control" value={this.state.newEmployeeNumber}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="department">Department:</label>
                        <input type="text" onChange={this.handleDepartmentChange.bind(this)}
                               className="form-control" value={this.state.newDepartment}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="key_card_number">Key Card Number:</label>
                        {
                            this.props.hidSettings.facilityCodeLength > 0 ?
                                (<HidCardInput onSubmit={this.handleHidAdd.bind(this)}
                                               hidSettings={this.props.hidSettings}/>)
                                :
                                (<div/>)
                        }
                        <ReactTags tags={this.state.newKeycards}
                                   handleDelete={this.handleDelete.bind(this)}
                                   handleAddition={this.handleAddition.bind(this)}
                                   classNames={{tagInputField: "form-control"}}
                                   placeholder={"Press 'Enter' to add a new key card number"}
                                   autofocus={false}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" onChange={this.handleEmployeeEmailChange.bind(this)}
                               className="form-control" id="email" name="email"
                               value={this.state.newEmployeeEmail}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="secondaryEmail">Secondary Emails:</label>
                        <ReactTags tags={this.state.newSecondaryEmails}
                                   handleDelete={this.handleSecondaryEmailDelete.bind(this)}
                                   handleAddition={this.handleSecondaryEmailAddition.bind(this)}
                                   classNames={{tagInputField: "form-control"}} placeholder={"Press 'Enter' to add a new secondary email"}
                                   autofocus={false}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="accessCode">Access Code:</label>
                        <ReactTags tags={this.state.newAccessCodes}
                                   handleDelete={this.handleAccessCodeDelete.bind(this)}
                                   handleAddition={this.handleAccessCodeAddition.bind(this)}
                                   classNames={{tagInputField: "form-control"}}
                                   placeholder={"Press 'Enter' to add a new access code"}
                                   autofocus={false}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="tag">Tags:</label>
                        <CustomSelectDropdown selectedTags={this.state.selectedTags} onSelectionChange={this.handleSelectionChange.bind(this)}
                                              pushFlashMessage={this.props.pushFlashMessage}/>
                    </div>

                    <button onClick={this.updateEmployee.bind(this)} className="btn btn-primary">Save</button>
                    &nbsp;
                    <button type="button" className="btn iq-bg-danger"
                            onClick={this.handleCloseModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export {EditEmployeeModal}
