import React from 'react';
import { V1CurrencyConfig } from '../gen/typescript-axios';

const CurrencyFormatter: React.FC<{ priceInCents?: number, currencyConfig?: V1CurrencyConfig }> = ({ priceInCents: value, currencyConfig: currencyConfig }) => {

    const decimalPlaces = currencyConfig?.currency_decimal_point ?? 0;
    const divisor = Math.pow(10, decimalPlaces);
    const roundedValue = Number(((value ?? 0) / divisor).toFixed(decimalPlaces));

    const formattedCurrency = `${currencyConfig?.currency_symbol ?? '$'}${roundedValue.toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })}`;

    return <span>{formattedCurrency}</span>;
};




export default CurrencyFormatter;


export const formatPriceWithoutSymbol = (priceInCents?: number, currencyConfig?: V1CurrencyConfig): number => {
    const decimalPlaces = currencyConfig?.currency_decimal_point ?? 0;
    const divisor = Math.pow(10, decimalPlaces);
    return Number(((priceInCents ?? 0) / divisor).toFixed(decimalPlaces));
};

export const parseCurrencyToCents = (formattedPrice: string, currencyConfig?: V1CurrencyConfig): number => {
    const decimalPlaces = currencyConfig?.currency_decimal_point ?? 0;
    const divisor = Math.pow(10, decimalPlaces);
    

    const cleanedValue = formattedPrice.replace(/[^\d.-]/g, '');

    const parts = cleanedValue.split('.');
    if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2); 
    }

    const normalizedValue = parts.join('.');

    const numericValue = parseFloat(normalizedValue);

    return isNaN(numericValue) ? 0 : Math.round(numericValue * divisor);
};
