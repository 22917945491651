import React, {useState} from 'react';
import {getAdminCompanyInformationServiceAPI, getTrackableItemsServiceAPI} from "../global/api";
import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import {PpeEmployee, PpeTrackableItem} from "../gen";

interface Props{
    item: PpeTrackableItem,
    reload(): void
}

function AssignToEmployee(props:Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [employeeOptions, setEmployeeOptions] = useState<Array<PpeEmployee>>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<Array<PpeEmployee>>([]);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");

    const assign = (e:any)=>{
        e.preventDefault();
        setSaving(true)
        getTrackableItemsServiceAPI().adminTrackableItemServiceAssignTrackableItemToEmployee(props.item.uuid ?? "", {
            employee_uuid : selectedEmployee[0].uuid
        }).then((response)=>{
            if (response.data.success){
                if (props.reload != null){
                    props.reload();
                }
            } else {
                setError(response.data.error_message ?? "")
            }
        }).catch(()=>{
            setError("Server error")
        }).finally(()=>{
            setSaving(false)
        })
    }

    const onEmployeeChange = (employees: Array<PpeEmployee>) => {
        if (employees.length > 0) {
            setSelectedEmployee([employees[0]]);
        } else {
            setSelectedEmployee([]);
        }
    }

    const renderEmployeeItemChildren = (option: PpeEmployee, _props:any) => {
        const display = `${option.full_name} [${option.email}]`;
        return [
            <Highlighter key={"name"} search={_props.text}>{display}</Highlighter>,
        ];
    };

    return (
        <div className={"well"}>
            <h2>Assign to employee</h2>

            <form onSubmit={assign}>
                <div className={"form-group"}>
                    <label>Employee: (search by name)</label>
                    <AsyncTypeahead
                        id={"typeahead"}
                        isLoading={isLoading}
                        labelKey={(option)=>{
                            return `${option.full_name} [${option.email}]`
                        }}
                        onSearch={(query) => {
                            setIsLoading(true);
                            getAdminCompanyInformationServiceAPI().adminCompanyInformationServiceSearchEmployees({
                                term: query,
                                per_page:10000,
                                page:1
                            }).then((response)=>{
                                if (response.data.success){
                                    const employees = response.data.data?.employees?.filter((employee)=>{
                                        return employee.is_enabled === true;
                                    })
                                    setEmployeeOptions(employees ?? [])
                                } else {
                                    window.alert(response.data.error_message)
                                }
                            }).catch(()=>{
                                window.alert("Server error")
                            }).finally(()=>{
                                setIsLoading(false);
                            })
                        }}
                        selected={selectedEmployee}
                        onChange={onEmployeeChange}
                        options={employeeOptions}
                        renderMenuItemChildren={renderEmployeeItemChildren}
                        clearButton
                    />
                </div>

                {error && <div className={"text text-danger"}>{error}</div>}

                {!saving && <input type={"submit"} className={"btn btn-info"} value={"Save"}/>}
            </form>
        </div>
    );
}

export default AssignToEmployee;
