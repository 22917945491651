import React, {Fragment, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {CreateJobModal} from "./create_job_modal";
import {getAdminJobServiceAPI, getAdminOrganizationServiceAPI} from "../global/api";
import {Message} from "../global/models";
// import {PpeJob} from "../gen";
import {V1JobStatus,Ppeemployerv1Job} from "../gen/typescript-axios";
import DataTable, {ExpanderComponentProps, TableColumn} from "react-data-table-component";
import moment from "moment";
import {DatetimeDisplay} from "../global/datetime_display";
import ReactPaginate from "react-paginate";
import {Link, useHistory, useLocation} from "react-router-dom";
import BusyIndicator, {LoadingSpanner} from "../busy_indicator";

import {Ppeemployerv1JobIDStatus} from "../gen/typescript-axios";

interface Props extends IGlobalDataContext{

}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface JobOperationProps extends ExpanderComponentProps<Ppeemployerv1Job> {
    reload?():void
}

function JobOperation(props:JobOperationProps) {
    const [isEditJob, setIsEditJob] = useState<boolean>(false);
    return (
        <div className={"detail-dropdown-view"}>
            <ul className={"trackable-item-actions"}>
                <li>
                    <button className={"btn btn-default"} onClick={(e) => {
                        setIsEditJob(true)
                        e.preventDefault();
                    }}>
                        <i className={"las la-edit"}></i> Update Job
                    </button>
                </li>
                <li>
                    <Link to={`/jobs/${props.data.uuid}`} className="btn btn-default">
                        <i className={"las la-info-circle"}></i> Show Job Details
                    </Link>
                </li>
            </ul>
            <hr/>
            {isEditJob && <EditJob job={props.data} reload={props.reload} close={()=>{setIsEditJob(false)}}/>}
        </div>
    );
}

interface EditJobProps {
    job: Ppeemployerv1Job,
    reload?():void
    close():void
}

function EditJob(props: EditJobProps){
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [jobCode, setJobCode] = useState<string>(props.job.job_code ?? "");
    const [jobStatus, setJobStatus] = useState<V1JobStatus>(props.job.job_status ?? V1JobStatus.JobEnabled);
    const [adminNote, setAdminNote] = useState<string>(props.job.admin_note ?? "");
    const [error, setError] = useState("");

    const updateJob = (e:React.SyntheticEvent<HTMLFormElement>)=>{
        e.preventDefault();
        e.stopPropagation();
        setIsSaving(true)
        getAdminJobServiceAPI().adminJobServiceUpdateJob(props.job.uuid ?? "", {
            job_code: jobCode,
            job_status: jobStatus,
            admin_note: adminNote
        }).then((response)=>{
            if (response.data.success ?? false){
                if (props.reload) {
                    props.reload()
                }
            } else {
                setError(response.data.error_message ?? "")
            }
        }).catch(()=>{
            setError("Server error")
        }).finally(()=>{
            setIsSaving(false)
        })
    }

    return(
        <div className={"well"}>
            <h2>Update Job</h2>

            <form onSubmit={updateJob}>
                <div className="form-group">
                    <label htmlFor="name">Job Code:</label>
                    <input type="text" onChange={(e) => {
                        setJobCode(e.target.value);
                    }} className="form-control"
                           value={jobCode}/>
                </div>

                <div className="form-group">
                    <label htmlFor="name">Job Status:</label>
                    <select className="form-control"
                            value={jobStatus}
                            onChange={(e)=>{
                                setJobStatus(e.currentTarget.value as V1JobStatus)
                            }}>
                        <option value={V1JobStatus.JobDisabled}>Disable</option>
                        <option value={V1JobStatus.JobEnabled}>Enable</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="name">Admin Note:</label>
                    <textarea onChange={(e)=>{
                        setAdminNote(e.currentTarget.value);
                    }} className="form-control"
                              value={adminNote}/>
                </div>

                {error && <div className={"text text-danger"}>{error}</div>}

                <BusyIndicator busy={isSaving} busyIndicator={<LoadingSpanner/>}>
                    <input type={"submit"} className={"btn btn-info"} value={"Save"}/>
                </BusyIndicator>

            </form>
        </div>
    );
}

function _JobPage(props:Props) {
    let query = useQuery();
    const history = useHistory()
    const [isAddJobModalOpen, setIsAddJobModalOpen] = useState<boolean>(false);
    const [isJobIdFeatureEnabled, setIsJobIdFeatureEnabled] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [jobs, setJobs] = useState<Ppeemployerv1Job[]>([]);
    const [page, setPage] = useState<number>(parseInt(query.get("page") ?? "1") - 1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [jobStatusFilter, setJobStatusFilter] = useState<V1JobStatus | "ALL">("ALL")
    const [jobCodeFilter, setJobCodeFilter] = useState<string>("")
    const [adminNoteFilter, setAdminNoteFilter] = useState<string>("")

    const [disableJobStatusFilter, setDisableJobStatusFilter] = useState<boolean>(false)
    const [disablejobCodeFilter, setDisableJobCodeFilter] = useState<boolean>(false)
    const [disableadminNoteFilter, setDisableAdminNoteFilter] = useState<boolean>(false)

    useEffect(()=>{
        reloadJobs();
    }, [page, jobStatusFilter, jobCodeFilter, adminNoteFilter, history.location]);

    useEffect(()=>{
        reloadCompanySetting();
    }, []);

    const reloadCompanySetting = ()=>{
        getAdminOrganizationServiceAPI().adminOrganizationServiceGetOrganizationDetail().then((response)=>{
            if (response.data.success){
                if (response.data.organization?.job_id_status === Ppeemployerv1JobIDStatus.Disabled){
                    setIsJobIdFeatureEnabled(false)
                } else {
                    setIsJobIdFeatureEnabled(true);
                }
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"));
            }
        }).catch(()=>{
            props.pushFlashMessage(new Message("Server error", "error"));
        })
    }

    const reloadJobs = () => {
        setPage(parseInt(query.get("page") ?? "1") - 1)
        setIsLoading(true);

        const code = jobCodeFilter;
        const status = jobStatusFilter;
        const note = adminNoteFilter;
        getAdminJobServiceAPI().adminJobServiceListJobs({
            page: page + 1,
            per: 50,
            job_code: jobCodeFilter === "" ? undefined : jobCodeFilter,
            job_status: jobStatusFilter === "ALL" ? undefined : jobStatusFilter,
            admin_note: adminNoteFilter === "" ? undefined : adminNoteFilter,
        }).then((response)=>{
            if (response.data.success ?? false){
                if (code === jobCodeFilter && status === jobStatusFilter && note === adminNoteFilter){
                    setJobs(response.data.jobs ?? []);
                    setTotalPage(response.data.total_pages ?? 1);
                }
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"));
            }
        }).catch(()=>{
            props.pushFlashMessage(new Message("Server error", "error"));
        }).finally(()=>{
            setIsLoading(false);
        })
    }

    const closeAddJobModal = ()=>{
        setIsAddJobModalOpen(false)
    }

    const openAddJobModal = ()=>{
        setIsAddJobModalOpen(true)
    }

    const handlePageChange = (selectedItem: { selected: number })=>{
        setPage(selectedItem.selected)
        history.push({
            pathname: window.location.pathname,
            search: '?page=' + (selectedItem.selected+1).toString()
        })
    }

    const jobs_columns: TableColumn<Ppeemployerv1Job>[] = [
        {
            name: "Job Code",
            selector: (job: Ppeemployerv1Job) => {
                return job.job_code ?? "-"
            },
            sortable: true,
            wrap: true,
        },
        {
            name: "Created At",
            selector: (job: Ppeemployerv1Job) => {
                return moment.unix(job.created_epoch ?? 0).toString()
            },
            cell: (job: Ppeemployerv1Job)=>{
                return <DatetimeDisplay timestamp={job.created_epoch ?? 0} timezone={props.timezone}/>
            },
            wrap: true,
            sortable: true,
            compact: true,
        },
        {
            name: "Updated At",
            selector: (job: Ppeemployerv1Job) => {
                return moment.unix(job.updated_epoch ?? 0).toString()
            },
            cell: (job: Ppeemployerv1Job)=>{
                return <DatetimeDisplay timestamp={job.updated_epoch ?? 0} timezone={props.timezone}/>
            },
            wrap: true,
            sortable: true,
            compact: true,
        },
        {
            name: "Job Status",
            selector: (job: Ppeemployerv1Job) => {
                return job.job_status ?? "-"
            },
            sortable: true,
            wrap: true,
        },
        {
            name: "Admin Note",
            selector: (job: Ppeemployerv1Job) => {
                return job.admin_note ?? "-"
            },
            sortable: true,
            wrap: true,
        },
    ]

    const handleJobStatusFilterChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.value === "ALL"){
            setDisableJobCodeFilter(false)
            setDisableJobStatusFilter(false)
            setDisableAdminNoteFilter(false)
        } else {
            setDisableJobCodeFilter(true)
            setDisableAdminNoteFilter(true)
        }
        setJobStatusFilter(e.currentTarget.value as V1JobStatus)
    }

    const handleNameFilterChange = (e:React.SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.value === ""){
            setDisableJobCodeFilter(false)
            setDisableJobStatusFilter(false)
            setDisableAdminNoteFilter(false)
        } else {
            setDisableJobCodeFilter(true)
            setDisableJobStatusFilter(true)
            setDisableAdminNoteFilter(true)
        }
    }

    const handleCodeFilterChange = (e:React.SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.value === ""){
            setDisableJobCodeFilter(false)
            setDisableJobStatusFilter(false)
            setDisableAdminNoteFilter(false)
        } else {
            setDisableJobStatusFilter(true)
            setDisableAdminNoteFilter(true)
        }
        setJobCodeFilter(e.currentTarget.value);
    }

    const handleAdminNoteFilterChange = (e:React.SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.value === ""){
            setDisableJobCodeFilter(false)
            setDisableJobStatusFilter(false)
            setDisableAdminNoteFilter(false)
        } else {
            setDisableJobCodeFilter(true)
            setDisableJobStatusFilter(true)
        }
        setAdminNoteFilter(e.currentTarget.value);
    }

    return (
        <Fragment>
            <Helmet>
                <title>{"Jobs - " + props.company + " - " + props.globalTitle}</title>
            </Helmet>
            {isJobIdFeatureEnabled === false && <div id="content-page" className="content-page">
                <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Job ID - <span className={"text text-danger"}>DISABLED</span></h3>

                        <div className={"alert alert-info"}>
                            <b>Job ID disabled.</b>
                            &nbsp;
                            You can enable it in the &nbsp;
                            <Link to={"/company"}> organization setting</Link>
                        </div>
                    </div>
                </div>
            </div>
            </div>}
            {isJobIdFeatureEnabled &&
                <Fragment>
                    <CreateJobModal isOpen={isAddJobModalOpen} closeModal={closeAddJobModal} reloadJobs={reloadJobs}/>
                    <div id="content-page" className="content-page">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="iq-card">
                                        <div className="iq-card-header d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">Jobs Management</h4>
                                            </div>
                                            <div>
                                        <span className="btn btn-primary btn-sm" onClick={openAddJobModal}>
                                              New Job
                                        </span>
                                            </div>
                                        </div>
                                        <div className="iq-card-body">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="col-md-3 mb-3">
                                                                <label htmlFor="startDate">Job Status: </label>
                                                                <select className="form-control" disabled={disableJobStatusFilter}
                                                                        value={jobStatusFilter}
                                                                        onChange={handleJobStatusFilterChange}>
                                                                    <option value={"ALL"}>All</option>
                                                                    <option value={V1JobStatus.JobDisabled}>Disable</option>
                                                                    <option value={V1JobStatus.JobEnabled}>Enable</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-3 mb-3">
                                                                <label htmlFor="startDate">Search By Job Code: </label>
                                                                <input className="form-control" value={jobCodeFilter} onChange={handleCodeFilterChange} type="text" disabled = {disablejobCodeFilter}/>
                                                            </div>
                                                            <div className="col-md-3 mb-3">
                                                                <label htmlFor="startDate">Search By Admin Note: </label>
                                                                <input className="form-control" value={adminNoteFilter} onChange={handleAdminNoteFilterChange} type="text" disabled = {disableadminNoteFilter}/>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="iq-card-body">
                                                <DataTable title=""
                                                           columns={jobs_columns}
                                                           data={jobs}
                                                           expandableRows={true}
                                                           expandableRowsComponent={JobOperation}
                                                           expandableRowsComponentProps={{reload: reloadJobs}}
                                                           highlightOnHover
                                                           progressPending={isLoading}/>
                                            </div>
                                            <ReactPaginate
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={2}
                                                pageCount={totalPage}
                                                initialPage={page}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination mb-0"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item page-link"}
                                                nextClassName={"page-item page-link"}
                                                activeClassName={"active"} forcePage={page}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    );
}

export const JobPage = withGlobalData(_JobPage);
