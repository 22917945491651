import React, {Component} from 'react';
import Modal from "react-modal";
import {Ppeemployerv1MotorCoupling} from "../../gen/typescript-axios";
import {Message} from "../../global/models";
import "react-datepicker/dist/react-datepicker.css";
import {Ppeemployerv1ProductMapInfo} from "../../gen/typescript-axios";
import {getAdminVendingMachineServiceAPI} from "../../global/api";

interface Props {
    isOpen: boolean
    closeAddMotorCouplingModal():void
    reloadProductMap():void
    pushFlashMessage(message: Message): void;
    vendingMachineUuid:string
    allProductMaps:Ppeemployerv1ProductMapInfo[]
    motorCouplings:Ppeemployerv1MotorCoupling[]
    vendingMachineUUID: string
}

interface State {
    mainMdbCode : number | undefined
    coupleMdbCode : number | undefined
    isSaving: boolean,
    isproductOptionsLoading: boolean
}

class AddMotorCoupling extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            mainMdbCode: undefined,
            coupleMdbCode: undefined,
            isSaving: false,
            isproductOptionsLoading: false,
        };
    }

    closeModal(){
        this.setState({
            mainMdbCode: undefined,
            coupleMdbCode: undefined,
        })
        this.props.closeAddMotorCouplingModal()
    }

    validateCoupledMdbCode = (mainMdbCode : string,coupleCode : string) => {
        // 1. CoupleCode cannot be mdb_code that exists in productMappings
        const isExistingProductMapping = this.props.allProductMaps.some(
            (mapping) => parseInt(mapping.mdb_code?.toString() ?? '', 10).toString() === coupleCode
        );
        

        // 2. CoupleCode cannot be mainMdbItemNumber or coupledMotorMdbItemNumber in motorCouplings
        const isExistingMotorCoupling = this.props.motorCouplings.some(
            (coupling) =>
                coupling.main_mdb_item_number !== undefined &&
                parseInt(coupling.main_mdb_item_number.toString(), 10).toString() === coupleCode &&
                coupling.coupled_mdb_item_number !== undefined &&
                parseInt(coupling.coupled_mdb_item_number?.toString() ?? '', 10).toString() === coupleCode
        );

        // 3. coupleCode and mainMdbCode cannot be the same
        const isCoupleCodeSameAsMainMdbCode = coupleCode === mainMdbCode;

        // 4. Neither coupleCode nor mainMdbCode can be 9999
        const isSpecialNumber = (mainMdbCode === '9999' || coupleCode === '9999');

        // 5. The length of coupleCode and mainMdbCode must be the same
        const isValidLength = mainMdbCode.length === coupleCode.length;

        // 6. The first three digits of coupleCode and mainMdbCode must be the same
        const isValidateSamePrefix = this.validateSamePrefix(
            mainMdbCode,
            coupleCode,
        );

        // 7.Find the positions of mainMdbCode and coupleCode in the mdbCode in productMappings and compare them.
        const isValidMainMdbCodeWithProductMappings = this.validateMainMdbCodeWithProductMappings(
            mainMdbCode,
            coupleCode,
            this.props.allProductMaps
        );

        return {
            isExistingProductMapping,
            isExistingMotorCoupling,
            isCoupleCodeSameAsMainMdbCode: isCoupleCodeSameAsMainMdbCode,
            isValidLength,
            isValidMainMdbCodeWithProductMappings,
            isSpecialNumber: isSpecialNumber,
            isValidateSamePrefix: isValidateSamePrefix
        };
    };

    validateSamePrefix = (mainMdbCode : string,coupleCode : string) => {
        if (coupleCode.length === 1 && mainMdbCode.length === 1) {
            return true;
        }

        const prefixLength = Math.min(coupleCode.length - 1, mainMdbCode.length - 1);
        const prefix1 = coupleCode.slice(0, prefixLength);
        const prefix2 = mainMdbCode.slice(0, prefixLength);
        return prefix1 === prefix2;
    };



    validateMainMdbCodeWithProductMappings = (mainMdbCode : string, coupleCode : string, productMappings : Ppeemployerv1ProductMapInfo[]) => {
        const currentIndex = productMappings.findIndex(mapping => {
            return this.getNumericMdbCode(mapping.mdb_code?.toString() ?? '0') === this.getNumericMdbCode(mainMdbCode);
        });

        // 7.1.It won’t work if mainMdbCode is not found.
        if (currentIndex === -1) return false;

        // 7.2 If the first mainMdbCode is found, then the coupleCode must be smaller than the second mainMdbCode.
        if (currentIndex === 0) {
            const next = productMappings[1];
            if (this.getNumericMdbCode(coupleCode) > this.getNumericMdbCode(next.mdb_code?.toString() ?? '0')) {
                return false;
            }
        }

        // 7.3 If the last digit of mainMdbCode is found, then the coupleCode must be larger than the penultimate mainMdbCode.
        if (currentIndex === productMappings.length - 1) {
            const previous = productMappings[currentIndex - 1];
            if (this.getNumericMdbCode(coupleCode) < this.getNumericMdbCode(previous.mdb_code?.toString() ?? '0')) {
                return false;
            }
        }

        // 7.4 If the mainMdbCode found is not the first one, the coupleCode must be larger than the previous mdbCode.
        if (currentIndex > 0) {
            const previous = productMappings[currentIndex - 1];
            if (this.getNumericMdbCode(coupleCode) < this.getNumericMdbCode(previous.mdb_code?.toString() ?? '0')) {
                return false;
            }
        }

        // 7.5 If the found mainMdbCode is not the last one, the coupleCode needs to be smaller than the next one.
        if (currentIndex < productMappings.length - 1) {
            const next = productMappings[currentIndex + 1];
            if (this.getNumericMdbCode(coupleCode) > this.getNumericMdbCode(next.mdb_code?.toString() ?? '0')) {
                return false;
            }
        }

        return true;

    }

    getNumericMdbCode(code:string) {
        if (code === "0") {
            return 0;
        }
        return parseInt(code.replace(/^0+/, ''));
    }


    updateMotorCoupling = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const normalizedMainMdbCode = parseInt(this.state.mainMdbCode?.toString() ?? '0', 10).toString();
        const normalizedCoupledMdbCode = parseInt(this.state.coupleMdbCode?.toString() ?? '0', 10).toString();
        const validations = this.validateCoupledMdbCode(normalizedMainMdbCode,normalizedCoupledMdbCode);

        if (
            !validations.isExistingMotorCoupling &&
            !validations.isExistingProductMapping &&
            !validations.isCoupleCodeSameAsMainMdbCode &&
            validations.isValidLength &&
            validations.isValidMainMdbCodeWithProductMappings &&
            !validations.isSpecialNumber &&
            validations.isValidateSamePrefix
        ) {
            getAdminVendingMachineServiceAPI().adminVendingMachineServiceUpdateMotorCouplings(this.props.vendingMachineUUID, {
                motor_couplings: [{
                    coupled_mdb_item_number: this.state.coupleMdbCode,
                    main_mdb_item_number: this.state.mainMdbCode,
                }, ...this.props.motorCouplings]
            }).then((resp) => {
                if (resp.status === 200) {
                    this.props.reloadProductMap();
                    this.closeModal();
                } else {
                    window.alert("Failed to add motor coupling")
                }
            }).finally(() => {
            });
        } else {
            if (validations.isExistingMotorCoupling) {
                window.alert("Main MDB Code cannot be an existing motor coupling main MDB code");
                return
            }
            if (validations.isExistingProductMapping) {
                window.alert("Coupled MDB Code cannot be an existing product mapping MDB code");
                return
            }
            if (validations.isCoupleCodeSameAsMainMdbCode) {
                window.alert("Coupled MDB Code cannot be the same as Main MDB Code in a motor coupling");
                return
            }
            if (!validations.isValidLength) {
                window.alert("Main MDB Code must have the same number of digits as Coupled MDB Code.");
                return
            }
            if (!validations.isValidMainMdbCodeWithProductMappings) {
                window.alert("CoupleCode cannot jump already existing MDBCode association");
                return
            }
            if (validations.isSpecialNumber) {
                window.alert("MDBCode or CoupleCode cannot be a special number");
                return
            }
            if (!validations.isValidateSamePrefix) {
                window.alert("The first three digits of Coupled Code and mdb Code need to be the same");
                return
            }
        }
    }

    onMdbCodeChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        const inputValue = e.target.value === '' ? '0' : e.target.value.trim();

        if (/^\d{0,4}$/.test(inputValue)) {
            const normalizedCode = parseInt(inputValue, 10);
            this.setState({mainMdbCode:normalizedCode})
        }
    }

    onCoupleMdbCodeChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        const inputValue = e.target.value === '' ? '0' : e.target.value.trim();
        if (/^\d{0,4}$/.test(inputValue)) {
            const normalizedCode = parseInt(inputValue, 10);
            this.setState({coupleMdbCode:normalizedCode});
        }
    }

    render() {
        const customStyles = {
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)',
                width:'50%'
            }
        };
        Modal.setAppElement('#root');

        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.closeModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Add Motor Coupling</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <form>
                    <label>Select template vending machine</label>

                    <div className="form-group">
                        <label>Main MDB Item Number: </label>
                        <input type="number" className={"form-control"} value={this.state.mainMdbCode} onChange={this.onMdbCodeChange.bind(this)}/>
                    </div>

                    <div className="form-group">
                        <label>Coupled MDB Item Number: </label>
                        <input type="number" className={"form-control"} value={this.state.coupleMdbCode} onChange={this.onCoupleMdbCodeChange.bind(this)}/>
                    </div>

                    <button onClick={this.updateMotorCoupling.bind(this)} className="btn btn-primary">Save</button>
                    <button type="button" className="btn iq-bg-danger"
                            onClick={this.closeModal.bind(this)}>Cancel
                    </button>
                </form>
            </div>
        </Modal>;
    }
}

export {AddMotorCoupling}
