import React, {Component} from 'react';
import {getAdminVendingMachineServiceAPI, getTrackableItemsServiceAPI} from "../../global/api";
import {IGlobalDataContext, withGlobalData} from "../../global/global-data";
import {ProductMapRow} from "./product_map_row";
import {AddProductMapModal} from "./add_product_map_modal";
import {Ppeemployerv1ProductMapInfo ,Ppeemployerv1MotorCoupling} from "../../gen/typescript-axios";
import {PpeTrackableItem} from "../../gen";
import { DownloadProductMapCsv } from "./download_product_map_csv";
import { UploadProductMapCsv } from './upload_product_map_csv';
import { AddMotorCoupling } from './add_motor_coupling';

interface Props extends IGlobalDataContext{
    match: any
}

interface State {
    allProductMaps:Ppeemployerv1ProductMapInfo[]
    isAddProductMap: boolean
    isAddMotorCoupling: boolean
    advancedMode: boolean
    isLoading: boolean
    allTrackableItems:PpeTrackableItem[]|undefined
    allMotorCouplings: Ppeemployerv1MotorCoupling[] | undefined
}

class _ProductMapPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            allProductMaps:[],
            isAddProductMap:false,
            isAddMotorCoupling:false,
            advancedMode: false,
            isLoading: false,
            allTrackableItems:[],
            allMotorCouplings: [],
        };
    }

    componentDidMount() {
        this.reloadAllProductMap();
        this.loadAllTrackableItems();
        this.loadMotorCouplings();
        }

    reloadAllProductMap(){
        this.setState({
            isLoading: true
        })
        getAdminVendingMachineServiceAPI().adminVendingMachineServiceListProductMaps(this.props.match.params.uuid ?? "").then((response)=>{
            if (response.data.success){
                this.setState({
                    allProductMaps: response.data.product_maps ?? []
                })
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(()=>{
            window.alert("Server error");
        }).finally(()=>{
            this.setState({
                isLoading: false
            })
        });
        this.loadMotorCouplings();
    }

    loadAllTrackableItems ()  {
        getTrackableItemsServiceAPI().adminTrackableItemServiceListTrackableItems(1, 10000).then((resp) => {
            return resp.data
        }).then((data) => {
            if (data.success) {
                this.setState({allTrackableItems: data.items});

            } else {
                window.alert(data.error_message);
            }
        }).catch(()=>{
            window.alert("Server error, try again later");
        })
    }

    loadMotorCouplings() {
        getAdminVendingMachineServiceAPI().adminVendingMachineServiceListMotorCouplings(this.props.match.params.uuid ?? "",{},{}).then((response)=>{
            return response.data
        }).then((data) => {
            this.setState({allMotorCouplings: data.motor_couplings})
        });
    }

    openAddProductMapModal() {
        this.setState({isAddProductMap: true})
    }

    closeAddProductMapModal() {
        this.setState({isAddProductMap: false})
    }

    openAddMotorCouplingModal() {
        this.setState({isAddMotorCoupling: true})
    }

    closeAddMotorCouplingModal() {
        this.setState({isAddMotorCoupling: false})
    }

    toggleAdvancedMode(e: any) {
        e.preventDefault();

        this.setState({advancedMode: !this.state.advancedMode});
    }

    render() {
        const vendingMachine = this.props.findVendingMachine(this.props.match.params.uuid)
        const productMapRows = this.state.allProductMaps.sort((a, b) => {
            return (a.mdb_code ?? 0) - (b.mdb_code ?? 0)
        }).map((productMap) => {
            return <ProductMapRow pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                                  key={productMap.uuid}
                                  advanced={this.state.advancedMode}
                                  reloadAllProductMap={this.reloadAllProductMap.bind(this)}
                                  productMap={productMap}
                                  allTrackableItems={this.state.allTrackableItems}
                                  vendingMachineUuid={this.props.match.params.uuid}
                                  motorCouplings={this.state.allMotorCouplings ?? []}/>
        })


        return <div id="content-page" className="content-page">
            <AddProductMapModal pushFlashMessage={this.props.pushFlashMessage.bind(this)} isOpen={this.state.isAddProductMap}
                                 closeAddProductMapModal={this.closeAddProductMapModal.bind(this)}
                                 reloadProductMap={this.reloadAllProductMap.bind(this)}
                                 vendingMachineUuid={this.props.match.params.uuid}
                                allTrackableItems={this.state.allTrackableItems}/>
            <AddMotorCoupling pushFlashMessage={this.props.pushFlashMessage.bind(this)} isOpen={this.state.isAddMotorCoupling}
                                 closeAddMotorCouplingModal={this.closeAddMotorCouplingModal.bind(this)}
                                 reloadProductMap={this.reloadAllProductMap.bind(this)}
                                 vendingMachineUuid={this.props.match.params.uuid}
                                 motorCouplings={this.state.allMotorCouplings ?? []}
                                 vendingMachineUUID={this.props.match.params.uuid}
                                 allProductMaps={this.state.allProductMaps}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Product Maps for {vendingMachine !== undefined ? vendingMachine.name : "Error"}</h4>
                                </div>
                                <div className="row">
                                        <span className="btn btn-primary btn-sm" onClick={this.openAddProductMapModal.bind(this)}>
                                              Add Product Map
                                        </span>
                                        &nbsp;&nbsp;
                                        <DownloadProductMapCsv allProductMaps={this.state.allProductMaps} />
                                        &nbsp;&nbsp;
                                        <UploadProductMapCsv  pushFlashMessage={this.props.pushFlashMessage.bind(this)}
                                                              reloadProductMap={this.reloadAllProductMap.bind(this)}
                                                              vendingMachineUuid={this.props.match.params.uuid ?? ""}/>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <button className="btn btn-primary btn-sm"
                                        onClick={this.toggleAdvancedMode.bind(this)}>Advanced
                                    Mode: {this.state.advancedMode ? "YES" : "NO"}</button>
                                    &nbsp;&nbsp;
                                <button className='btn btn-primary btn-sm' onClick={() => this.openAddMotorCouplingModal()}>
                                    Add Motor Coupling <i className={"fa fas fa-lock"}/>
                                </button>
                                <div>
                                    <table
                                        id="datatable"
                                        className="table table-striped table-bordered mt-4 text-center"
                                    >
                                        <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Product Name</th>
                                            <th>MDB Code</th>
                                            <th>Consumed</th>
                                            <th>Average Daily Consumption</th>
                                            <th>Low Stock Alert</th>
                                            <th>Trackable Item Name</th>
                                            <th>Operation</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {productMapRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export const ProductMapPage = withGlobalData(_ProductMapPage);
