import React, {Fragment, useState} from 'react';
import {V1DedicatedLocker, V1DedicatedLockerCell, V1DedicatedLockerDetails} from "../gen/typescript-axios";
import {
    AssignEmployeeToDedicatedLockersModal
} from "./assign_employee_to_dedicated_lockers_modal";
import {getAdminDedicatedLockerServiceAPI, getAdminLockerServiceApi} from "../global/api";

interface Props {
    cell: V1DedicatedLockerCell
    locker: V1DedicatedLockerDetails
    reload():void
}

function DedicatedLockerCellActions(props: Props) {
    const [isAssigningEmployee, setIsAssigningEmployee] = useState<boolean>(false);
    const [isCellDoorOpening, setIsCellDoorOpening] = useState<boolean>(false);
    const [isRevoking, setIsRevoking] = useState<boolean>(false);

    const toggleIsAssigningEmployee = () => {
        setIsAssigningEmployee(!isAssigningEmployee);
    }

    const openCell = (e: React.MouseEvent<HTMLDivElement>)=>{
        e.preventDefault();
        if (isCellDoorOpening){
            return
        }
        if (window.confirm("Are you sure to open the cell door?")){
            setIsCellDoorOpening(true);
            getAdminDedicatedLockerServiceAPI().adminDedicatedLockerServiceOpenDedicatedLockerCellDoor({
                locker_uuid:props.locker.uuid,
                cell_uuid:props.cell.uuid,
            }).then((response)=>{
                if (response.data.success) {
                    window.alert("cell door opened")
                } else {
                    window.alert(response.data.error_message);
                }
            }).catch(()=>{
                window.alert("Server error, please try again later");
            }).finally(()=>{
                setIsCellDoorOpening(false);
            })
        }
    }

    const revokeAccess = (e: React.MouseEvent<HTMLDivElement>)=>{
        e.preventDefault();
        if (isRevoking){
            return
        }
        if (window.confirm("Are you sure to revoke current employee access?")){
            setIsRevoking(true);
            getAdminDedicatedLockerServiceAPI().adminDedicatedLockerServiceRevokeEmployeeDedicatedLockerCellAccess(props.locker.uuid ?? "", props.cell.uuid ?? "", {}).then((response)=>{
                if (response.data.success) {
                    window.alert("access revoked")
                } else {
                    window.alert(response.data.error_message);
                }
            }).catch(()=>{
                window.alert("Server error, please try again later");
            }).finally(()=>{
                setIsRevoking(false);
                props.reload();
            })
        }
    }

    return (
        <Fragment>
            <AssignEmployeeToDedicatedLockersModal isOpen={isAssigningEmployee}
                                                   closeModal={toggleIsAssigningEmployee}
                                                   locker={props.locker}
                                                   cell={props.cell} reload={props.reload}/>
            {!props.cell.is_assigned && <div className={"py-2"}>
                <div onClick={toggleIsAssigningEmployee} style={{cursor: "pointer"}}>
                    <i className="fa fa-product-hunt" aria-hidden="true"></i> <span>Assign Employee</span>
                </div>
            </div>}

            {props.cell.is_assigned && <div className={"py-2"}>
                <div onClick={revokeAccess} style={{cursor: "pointer"}}>
                    <i className="fa fa-product-hunt" aria-hidden="true"></i> <span>Revoke Access</span>
                </div>
            </div>}

            <div onClick={openCell} style={{cursor: "pointer"}}>
                <i className="fa fa-folder-open-o" aria-hidden="true"></i> <span>Open Cell Door</span>
            </div>

        </Fragment>
    );
}

export default DedicatedLockerCellActions;
