import React, {Fragment, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {getAdminDedicatedLockerServiceAPI, getAdminLockerServiceApi} from "../global/api";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {Message} from "../global/models";
import {PpeCellInformation, PpeLockerInformation} from "../gen";
import DataTable, {TableColumn} from "react-data-table-component";
import {useParams} from "react-router-dom";
import {V1DedicatedLockerCell, V1DedicatedLockerDetails} from "../gen/typescript-axios";
import DedicatedLockerCellActions from "./dedicated_locker_cell_actions";


interface Props extends IGlobalDataContext {

}


interface Params {
    uuid: string
}

function _dedicatedLockerInformationPage(props: Props) {
    let {uuid} = useParams<Params>();
    const [loading, setLoading] = useState(false);
    const [locker, setLocker] = useState<V1DedicatedLockerDetails | null>(null);


    const columns: TableColumn<V1DedicatedLockerCell>[] = [

        {
            name: "Row",
            selector: (item) => {
                return `${item.row! }, ${item.col!}`
            },
            cell: (item) => {
                return item.row!
            },
            sortable: true,
        },
        {
            name: "Col",
            selector: (item) => {
                return item.col!
            },
            sortable: true,
        },
        {
            name: "Display Name",
            selector: (item) => {
                return item.display_name!
            },
            sortable: true,
        },
        {
            name: "Assigned Employee",
            cell: (item) => {
                return item.is_assigned ? item.employee?.full_name : "-"
            },
            sortable: true,
        },
        {
            name: "Actions",
            selector: (item)=>{
                return item.display_name || "-"
            },
            cell: (item) => {
                return <DedicatedLockerCellActions cell={item} locker={locker!} reload={reload}/>
            },
            sortable:false,
            style:{display:"inline"}
        }
    ]

    function reload() {
        setLoading(true);

        getAdminDedicatedLockerServiceAPI().adminDedicatedLockerServiceGetDedicatedLockerDetails(uuid).then((response) => {
            if (!response.data.success){
                props.pushFlashMessage(new Message(response.data.error_message ?? "Unknown Error", "error"));
            }
            setLocker(response.data.dedicated_locker ?? null);
        }).catch(()=>{
            props.pushFlashMessage(new Message("Unknown Error", "error"));
        }).finally(()=>{
            setLoading(false);
        })
    }

    useEffect(() => {
        reload();
    }, [])

    return (<div id="content-page" className="content-page">
        <Helmet>
            <title>Locker</title>
        </Helmet>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h3 className="card-title">{locker?.name} Information</h3>

                            </div>
                        </div>
                        <div className="iq-card-body">
                            <table className={"table table-bordered"}>
                                <tbody>
                                    <tr>
                                        <td>Rows</td>
                                        <td>{locker?.rows}</td>
                                    </tr>
                                    <tr>
                                        <td>Cols</td>
                                        <td>{locker?.cols}</td>
                                    </tr>
                                    <tr>
                                        <td>Cells</td>
                                        <td>{locker?.cells?.length}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{(locker?.is_online??false) ? "Online" : "Offline"}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h3 className="card-title">Cells Information</h3>

                            </div>
                        </div>
                        <div className="iq-card-body">
                            <DataTable title="" columns={columns} data={locker?.cells ?? []}
                                       progressPending={loading} defaultSortFieldId={1} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);

}

export const DedicatedLockerInformationPage = withGlobalData(_dedicatedLockerInformationPage);
