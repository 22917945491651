import {IAuthenticationContext, withAuthentication} from "../authentication";
import React, {useEffect, useState} from "react";
import {getAdminSsoServiceAPI} from "../global/api";
import {UserType} from "../global/models";
import {Link} from "react-router-dom";
import {PpeLoginResponseCode} from "../gen";

interface LinkAccountFormProps extends IAuthenticationContext {
    suggestedEmail: string
    accessToken: string
}

function _showAdminLinkAccountForm(props: LinkAccountFormProps) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loginInProgress, setLoginInProgress] = useState(false);

    const [show2fa, setShow2fa] = useState(false);
    const [tfaCode, setTfaCode] = useState('');

    function handleTfaChange(e: any) {
        setTfaCode(e.target.value);
    }

    useEffect(() => {
        setEmail(props.suggestedEmail);
    }, [props.suggestedEmail]);

    function handleEmailChange(e: any) {
        setEmail(e.target.value)
        setShow2fa(false);
    }

    function handlePasswordChange(e: any) {
        setPassword(e.target.value)
        setShow2fa(false);
    }

    function linkAccount(e: any) {
        e.preventDefault();

        setLoginInProgress(true);

        setErrorMessage("");

        getAdminSsoServiceAPI().adminSsoServiceLinkMicrosoftAccount({
            microsoft_token: props.accessToken,
            email: email,
            password: password,
            secret: tfaCode,
        }).then((response) => {
            console.log(response.data);
            if (response.data.success) {
                props.login(response.data.security_token ?? "", email, UserType.ADMIN)
                // @ts-ignore
                window.location = "/";
            } else {
                // failure
                if (response.data.code === PpeLoginResponseCode.TfaRequired) {
                    setShow2fa(true);
                }
                setErrorMessage(response.data.error_message ?? "");
            }
        }).catch((e) => {
            console.log(e);
            setErrorMessage("Failed to login. Please contact support.");
        }).finally(() => {
            setLoginInProgress(false);
        })
    }

    return (
        <div className="sign-in-page">
            <div className="container p-0">
                <div className="row no-gutters height-self-center">
                    <div className="col-sm-12 align-self-center rounded">
                        <div className="row m-0 justify-content-md-center">
                            <div className="col-md-5 bg-white sign-in-page-data">
                                <div className="sign-in-from">
                                    <div className={"mb-5"}>
                                        <div className={"alert alert-danger"}>
                                            Your account does not have link for this Microsoft 365 account:
                                            <br/>
                                            {props.suggestedEmail}
                                        </div>
                                        <p>Please login to your Vending on Track account to authorize this Microsoft 365
                                            account.</p>
                                        <form onSubmit={linkAccount} className="mt-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">PPE Vending Email</label>
                                                <input type="email" className="form-control mb-0"
                                                       id="exampleInputEmail1" placeholder="Email"
                                                       value={email}
                                                       onChange={handleEmailChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">PPE Vending Password</label>
                                                <input type="password" className="form-control mb-0"
                                                       id="exampleInputPassword1" placeholder="Password"
                                                       value={password}
                                                       onChange={handlePasswordChange}/>
                                            </div>
                                            {show2fa &&
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">
                                                    Google Authentication Field
                                                </label>
                                                <input type="password" className="form-control mb-0"
                                                       id="exampleInputPassword1"
                                                       placeholder="Google Authentication Code"
                                                       value={tfaCode}
                                                       onChange={handleTfaChange}/>
                                            </div>
                                            }
                                            {errorMessage &&
                                            <div className={"alert alert-danger"}>
                                                {errorMessage}
                                            </div>
                                            }
                                            <div className="sign-info text-center">
                                                {loginInProgress ?
                                                    <span>Login in progress</span>
                                                    :
                                                    <button className="btn btn-primary d-block w-100 mb-2">
                                                        Link Account
                                                    </button>
                                                }
                                            </div>
                                        </form>

                                        <Link to={"/login"}>Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

export const ShowAdminLinkAccountForm = withAuthentication(_showAdminLinkAccountForm);
