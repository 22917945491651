import React, {useEffect, useState} from "react";
import {ProductSync} from "../global/models";
import {getAPI, postAPI} from "../global/api";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import moment from "moment";

interface Props extends IGlobalDataContext {

}

function _serviceNowSyncProductCard(props: Props) {
    const [loading, setLoading] = useState(true);
    const [lastProductSync, setLastProductSync] = useState<ProductSync | null>(null);
    const [syncErrorMessage, setSyncErrorMessage] = useState("");
    const [syncInProgress, setSyncInProgress] = useState(false);

    function loadLastProductSync() {
        setLoading(true);

        getAPI("/api/servicenow/sync/products/last").then((response) => {
            if (response.data.success) {
                setLastProductSync(response.data.sync);
            } else {
                props.pushFlashMessage({
                    cls: "error",
                    message: response.data.message
                });
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        loadLastProductSync();
    }, []);

    function syncProduct(e: any) {
        e.preventDefault();

        setSyncErrorMessage("");

        setSyncInProgress(true);

        postAPI("/api/servicenow/sync/products", {}).then((response) => {
            if (response.data.success) {
                props.pushFlashMessage({message: "ServiceNow Product Sync finished.", cls: "success"});
            } else {
                setSyncErrorMessage(response.data.message);
            }
        }).finally(() => {
            loadLastProductSync();
            setSyncInProgress(false);
        });
    }

    return (
        <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">ServiceNow Integration - Product Sync</h4>
                </div>
            </div>
            <div className="iq-card-body">
                {loading ?
                    "loading..." :
                    <div>
                        {
                            lastProductSync ?
                                <div>
                                    Last Product Sync: {moment.unix(lastProductSync.created_at).toISOString()}
                                    <br/>
                                    {lastProductSync.finished ? "State: Finished" : "State: Processing"}
                                    <br/>
                                    Total Products: {lastProductSync.total_products}
                                    <br/>
                                    Processed Products: {lastProductSync.processed_products}
                                    <br/>
                                    Product Changes: {lastProductSync.removed} removed, {lastProductSync.added} added
                                    <br/>

                                </div>
                                :
                                "No Product Sync."
                        }
                        <hr/>
                        {syncInProgress ?
                            <div>ServiceNow Product Sync in-progrses...</div>
                            :
                            <button onClick={syncProduct} className={"btn btn-danger"}>Sync ServiceNow products</button>
                        }
                        {syncErrorMessage &&
                        <div>
                            <br/>
                            <div className="text text-danger">
                                {syncErrorMessage}
                            </div>
                        </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}


export const ServiceNowSyncProductCard = withGlobalData(_serviceNowSyncProductCard);
