import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import DataTable, {TableColumn} from "react-data-table-component";
import {PpeSessionRole, PpeTrackableItem, PpeTrackableItemSession} from "../gen";
import {getTrackableItemsServiceAPI} from "../global/api";
import {Message} from "../global/models";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import moment from "moment";

interface Props extends IGlobalDataContext {

}
interface Params {
    trackable_item_uuid: string
    page:string
}

const session_columns: TableColumn<PpeTrackableItemSession>[] = [
    {
        name: "Start By",
        selector: (session: PpeTrackableItemSession) => {
            return session.start_role_name ?? ""
        },
        sortable: true,
        cell:(session: PpeTrackableItemSession) => {
            return <div>
                <span>{session.start_role_name ?? ""}</span>&nbsp;&nbsp;
                {session.start_role === PpeSessionRole.Employee && <i className="las la-people-carry"></i>}
                {session.start_role === PpeSessionRole.Admin && <i className="las la-user-friends"></i>}
                {session.start_role === PpeSessionRole.System && <i className="las la-robot"></i>}
            </div>
        },
    },
    {
        name: "End By",
        selector: (session: PpeTrackableItemSession) => {
            return session.end_role_name ?? "-"
        },
        sortable: true,
        cell:(session: PpeTrackableItemSession) => {
            return <div>
                <span>{session.end_role_name ?? ""}</span>&nbsp;&nbsp;
                {session.end_role === PpeSessionRole.Employee && <i className="las la-people-carry"></i>}
                {session.end_role === PpeSessionRole.Admin && <i className="las la-user-friends"></i>}
                {session.end_role === PpeSessionRole.System && <i className="las la-robot"></i>}
            </div>
        },
    },
    {
        name: "Type",
        selector: (session: PpeTrackableItemSession) => {
            return session.type || "-"
        },
        sortable: true,
        compact: true,
    },
    {
        name: "Start Time",
        selector: (session: PpeTrackableItemSession) => {
            return moment.unix(parseInt(session.start || "0") || 0).toString()
        },
        grow: 2,
    },
    {
        name: "End Time",
        selector: (session: PpeTrackableItemSession) => {
            if (session.finish === null){
                return "-"
            }
            return moment.unix(parseInt(session.finish || "0") || 0).toString()
        },
        sortable: true,
        compact: true,
    },
]

function _TrackableItemSessions(props: Props) {
    let {trackable_item_uuid} = useParams<Params>();
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState<PpeTrackableItem>();
    const [sessions, setSessions] = useState<Array<PpeTrackableItemSession>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        reload();
    }, [currentPage])

    const previousPage = (e:any) => {
        e.preventDefault();
        if (currentPage === 1){
            return;
        }
        setCurrentPage(currentPage - 1);
    }

    const nextPage = (e:any) => {
        e.preventDefault();
        setCurrentPage(currentPage + 1);
    }


    const reload = ()=>{
        setLoading(true);

        Promise.all([
            getTrackableItemsServiceAPI().adminTrackableItemServiceGetTrackableItemSession(trackable_item_uuid, {
                page:currentPage
            }).then(resp => resp.data).then((resp) => {
                if (!resp.success) {
                    props.pushFlashMessage(new Message(resp.message!, "error"));
                } else {
                    setSessions(resp.sessions ?? []);
                }
            }),
            getTrackableItemsServiceAPI().adminTrackableItemServiceGetTrackableItem(trackable_item_uuid).then(resp => resp.data).then((resp) => {
                if (resp.success) {
                    setItem(resp.trackable_item);
                } else {
                    props.pushFlashMessage(new Message(resp.error_message ?? "", "error"));
                }
            }),
        ]).finally(() => {
            setLoading(false);
        })
    }
    return (
        <div id="content-page" className="content-page">
            <Helmet>
                <title>Trackable Item Sessions</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">
                                        Trackable Item Sessions - {item?.name}
                                        <small>
                                            <button className={"btn btn-default"} onClick={(e) => {
                                                e.preventDefault();
                                                reload();
                                            }}>
                                                <i className={"fa fa-refresh" + (loading ? " fa-spin" : "")}></i>
                                            </button>
                                        </small>
                                    </h4>

                                </div>
                            </div>

                            <div className="iq-card-body">
                                <DataTable title="" columns={session_columns} data={sessions}
                                           highlightOnHover
                                           // expandableRows expandableRowsComponent={DetailDropdownView}
                                           expandableRowsComponentProps={{reload: reload}}
                                           progressPending={loading}/>
                                <div>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <button className="page-link" onClick={previousPage}>Previous</button>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={nextPage}>Next</button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const TrackableItemSessions = withGlobalData(_TrackableItemSessions);
