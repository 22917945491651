import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from "../authentication";
import {Link, Redirect} from 'react-router-dom';
import {getPartnerUserAuthenticationServiceApi} from "../global/api";
import {UserType} from "../global/models";
import votLogo from '../VOT-logo.jpg';
import BusyIndicator, {LoadingSpanner} from "../busy_indicator";


interface Props extends IAuthenticationContext {
    location: any
    match: any
    history: any
}

interface State {
    email: string
    validating: boolean
    error_message: string | null
    isOnetimeCodeRequested: boolean
    onetimeCode: string,
    isRequestAuthCode: boolean
}

class _PartnerLogin extends Component<Props, State> {
    qs = require('qs');
    params = this.qs.parse(this.props.location.search.slice(1));

    constructor(props: Props) {
        super(props);
        this.state = {
            email: "",
            validating: false,
            error_message: null,
            isOnetimeCodeRequested: false,
            onetimeCode: "",
            isRequestAuthCode: false
        };
    }

    handleEmailChange(event
                          :
                          any
    ) {
        this.setState({
            email: event.target.value,
            isOnetimeCodeRequested: false
        });
    }

    requestLoginCode(event
                                 :
                                 any
    ) {
        event.preventDefault();
        event.stopPropagation();
        if (this.state.isRequestAuthCode){
            return;
        }
        this.setState({
            isRequestAuthCode: true
        })
        getPartnerUserAuthenticationServiceApi().partnerUserAuthenticationServicePartnerUserLogin({
            email:this.state.email
        }).then((result)=>{
            if (result.data.success){
                this.setState({
                    isOnetimeCodeRequested: true,
                    error_message:"Auth code has been sent to your email address"
                })
            } else {
                this.setState({error_message: result.data.message ?? ""});
            }
        }).finally(()=>{
            this.setState({
                isRequestAuthCode: false
            })
        })
    }

    handleOnetimeCodeChange(event
                                :
                                any
    ) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({onetimeCode: event.target.value})
    }

    handleLogin(event
                            :
                            any
    ) {
        if (this.state.validating){
            return;
        }
        this.setState({
            validating: true
        })
        event.preventDefault();
        event.stopPropagation();
        getPartnerUserAuthenticationServiceApi().partnerUserAuthenticationServicePartnerUserVerifyLogin({
            email: this.state.email,
            auth_code: this.state.onetimeCode
        }).then((result)=>{
            if (result.data.success) {
                this.props.login(result.data.token ?? "", this.state.email, UserType.PARTNER)
            } else {
                this.setState({error_message: result.data.message ?? ""});
            }
        }).catch((result)=>{
            this.setState({error_message: "Server error, please try again later."});
        }).finally(()=>{
            this.setState({
                validating: false
            })
        })
    }

    render() {
        if (this.props.authentication.authenticated) {
            return <Redirect to="/partners"/>;
        }

        return (
            <div className="sign-in-page">
                <div className="container p-0">
                    <div className="row no-gutters height-self-center">
                        <div className="col-sm-12 align-self-center rounded">
                            <div className="row m-0 justify-content-md-center">
                                <div className="col-md-5 bg-white sign-in-page-data">
                                    <div className="sign-in-from">
                                        <div className={"mb-5"}>
                                        </div>
                                        <h1 className="mb-0 text-center">Sign in</h1>
                                        <p className="text-center text-dark">Enter your email address and password
                                            to
                                            access PPE Partner panel.</p>

                                        {this.state.error_message &&
                                        <div className="alert alert-danger">{this.state.error_message}</div>
                                        }

                                        <div>
                                            <form className="mt-4">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="exampleInputEmail1">Email</label> {this.state.isOnetimeCodeRequested &&
                                                <button onClick={this.requestLoginCode.bind(this)}
                                                        className={"btn btn-link"}>Resend code</button>}
                                                    <input type="email" className="form-control mb-0"
                                                           id="exampleInputEmail1" placeholder="Enter email"
                                                           onChange={this.handleEmailChange.bind(this)}/>
                                                </div>
                                                {
                                                    this.state.isOnetimeCodeRequested &&
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Auth Code</label>
                                                        <input type="password" className="form-control mb-0"
                                                               placeholder="Code"
                                                               onChange={this.handleOnetimeCodeChange.bind(this)}
                                                               value={this.state.onetimeCode}/>
                                                    </div>
                                                }
                                                {
                                                    this.state.isOnetimeCodeRequested &&
                                                    <div className="sign-info text-center">
                                                        <BusyIndicator busy={this.state.validating} busyIndicator={<LoadingSpanner/>}>
                                                            <button onClick={this.handleLogin.bind(this)}
                                                                    className="btn btn-primary d-block w-100 mb-2">Login
                                                            </button>
                                                        </BusyIndicator>
                                                    </div>
                                                }
                                                {!this.state.isOnetimeCodeRequested &&
                                                <div className="sign-info text-center">
                                                    <BusyIndicator busy={this.state.isRequestAuthCode} busyIndicator={<LoadingSpanner/>}>
                                                        <button onClick={this.requestLoginCode.bind(this)}
                                                                className="btn btn-primary d-block w-100 mb-2">Get auth code
                                                        </button>
                                                    </BusyIndicator>
                                                </div>}
                                            </form>
                                        </div>


                                        <div>
                                            <hr/>
                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    <p><Link to={"/help"}>Support & Help</Link></p>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    <p>Powered by:</p>
                                                </div>
                                                <div className={"col-8 mb-3"}>
                                                    <a href="https://www.vendingontrack.com">
                                                        <img
                                                            src={votLogo} className=""
                                                            style={{maxWidth: "60%", marginTop: "2em"}} alt="logo"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


const
    PartnerLogin = withAuthentication(_PartnerLogin);

export {
    PartnerLogin
};

