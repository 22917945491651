import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {
    getPartnerUserAuthenticationServiceApi,
    getPartnerUserPartnerServiceApi,
    getPartnerUserPartnerUserServiceApi
} from "../global/api";
import formATree, {PartnerTreeNode} from "./partner_tree";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import {IAuthenticationContext, withAuthentication} from "../authentication";
import {UserType} from "../global/models";

interface Props extends IGlobalDataContext, IAuthenticationContext {

}

function _PartnerHierarchy(props: Props) {
    const [partnerTreeNode, setPartnerTreeNode] = useState<Array<PartnerTreeNode>>([]);



    const reloadPartnerHierarchy = () => {
        getPartnerUserPartnerServiceApi().partnerUserPartnerServicePartnerUserListPartners().then((response)=>{
            if (response.data.success){
                setPartnerTreeNode(formATree(response.data.partners ?? []))
            } else {
                window.alert(response.data.error_message)
            }
        }).catch(()=>{
            window.alert("Server Error");
        })
    }

    useEffect(()=>{reloadPartnerHierarchy()}, [props.authentication])
    console.log("partnerTreeNode", partnerTreeNode)

    const loginAsAdmin = (partnerUuid: string, organizationUuid: string) => {
        getPartnerUserAuthenticationServiceApi().partnerUserAuthenticationServicePartnerUserLoginToOrganization({
            partner_uuid: partnerUuid,
            organization_uuid: organizationUuid
        }).then((response)=>{
            if (response.data.success){
                props.login(response.data.admin_token ?? "", props.authentication.email ?? "", UserType.ADMIN);
            } else {
                window.alert(response.data.message)
            }
        }).catch((e)=>{
            console.log(e)
            window.alert("Server Error")
        })
    }


    return (
        <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Transactions - " + props.company + " - " + props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Partners </h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <SortableTree
                                    canDrag={false}
                                    treeData={partnerTreeNode}
                                    onChange={treeData => setPartnerTreeNode(treeData)}
                                    isVirtualized={false}
                                    generateNodeProps={(row)=>{
                                        if (row.node.isOrganization){
                                            return {
                                                buttons: [
                                                <button onClick={(e)=>{e.preventDefault(); loginAsAdmin(row.parentNode.uuid, row.node.uuid)}}>Login As Admin</button>
                                                ]
                                            }
                                        }
                                        return {buttons: []}
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const PartnerHierarchy = withAuthentication(withGlobalData(_PartnerHierarchy));
