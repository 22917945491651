import React from "react";
import {Helmet} from "react-helmet";
import {Link, useHistory} from "react-router-dom";

function PageNotFound() {
    const history = useHistory();

    return (
        <div>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h1 className="card-title">404 - The page does not exist.</h1>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <button className={"btn btn-primary"} onClick={()=>{history.go(-1)}}>Back</button>
                                            &nbsp;&nbsp;
                                            <Link to={"/"}>
                                                <button className={"btn btn-primary"}>Dashboard</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;
