import React, { Component } from 'react';
import {IGlobalDataContext, withGlobalData} from "../global/global-data";
import {getAdminKeyCardServiceAPI} from "../global/api";
import {Message} from "../global/models";
import {KeyCardTapEventRow} from "./key_card_tap_event_row";
import {Helmet} from "react-helmet";
import {PpeKeyCardTapEvent} from "../gen";


interface Props extends IGlobalDataContext{
}

interface State {
    vendingMachineUuid:string
    events:PpeKeyCardTapEvent[]
    loading: boolean
}


class _KeyCardTapEventPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            vendingMachineUuid:"",
            events:[],
            loading: false
        };
    }
    componentDidMount() {
        this.reloadAllKeyCardTapEvents()
    }

    reloadAllKeyCardTapEvents(isUpdate = false){
        this.setState({
            loading:true
        });
        getAdminKeyCardServiceAPI().adminKeyCardServiceListKeyCardTapEvent({
            page:1,
            per_page:50,
            vending_machine_uuid: this.state.vendingMachineUuid !== "" ? this.state.vendingMachineUuid : undefined
        }).then((response)=>{
            if (response.data.success ?? false){
                this.setState({
                    events: response.data.events ?? []
                })
                if (isUpdate){
                    this.props.pushFlashMessage(new Message("Key Card Tap Events Updated", "success"))
                }
            } else {
                window.alert(response.data.error_message ?? "")
            }
        }).catch(()=>{
            window.alert("Server error")
        }).finally(()=>{
            this.setState({
                loading:false
            });
        })
    }

    handleVendingMachineChange(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.setState({vendingMachineUuid:event.target.value!=="" ? event.target.value : null}, ()=>{this.reloadAllKeyCardTapEvents()})
    }

    updateKeyCardTapEvents(event:any){
        event.preventDefault();
        event.stopPropagation();
        this.reloadAllKeyCardTapEvents(true);
    }

    render() {
        const rows = this.state.events.map((keyCardTapEvent)=>{
            return <KeyCardTapEventRow pushFlashMessage={this.props.pushFlashMessage.bind(this)} key={keyCardTapEvent.uuid} keyCardTapEvent={keyCardTapEvent}
                                reloadKeyCardTapEvent={this.reloadAllKeyCardTapEvents.bind(this)}
                                allVendingMachine={this.props.allVendingMachines}
                                findVendingMachine={this.props.findVendingMachine.bind(this)} timezone={this.props.timezone}
                                hidInfo={this.props.hidInfo}/>
        })
        const vendingMachineOptions = this.props.allVendingMachines.map((vendingMachine)=>{
            return <option key={vendingMachine.uuid} value={vendingMachine.uuid}>{vendingMachine.name}</option>
        })
        return <div id="content-page" className="content-page">
            <Helmet>
                <title>{"Key Card Tap Events - " + this.props.company + " - " + this.props.globalTitle}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Latest Key Card Tap Events</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-row">
                                            <div className="col-md-4 mb-3 form-group">
                                                <label htmlFor="vendingMachines">Vending Machine:</label>
                                                <select name="vendingMachine" id="vendingMachines"
                                                        className="custom-select" value={this.state.vendingMachineUuid} onChange={this.handleVendingMachineChange.bind(this)}>
                                                    <option value="">All</option>
                                                    {vendingMachineOptions}
                                                </select>
                                            </div>
                                            <div className="col-md-4 mb-3 form-group">
                                                <br/>
                                                    <input style={{marginTop: "9px"}} className="btn btn-primary"
                                                           type="submit" value="Update" onClick={this.updateKeyCardTapEvents.bind(this)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <table
                                        id="datatable"
                                        className="table table-striped table-bordered mt-4"
                                    >
                                        <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Key Card Number</th>
                                            <th>Vending Machine</th>
                                            <th>Location</th>
                                            <th>Employee</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export const KeyCardTapEventPage = withGlobalData(_KeyCardTapEventPage)
